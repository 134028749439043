<template>
    <div>
        <b-field
            label="Search:">
            <b-autocomplete
                v-model="searchText"
                :data="data"
                placeholder="e.g. EX class Jsv"
                :loading="isFetching"
                :autofocus="true"
                icon="magnify"
                @select="itemSelected">
                <template #default="props">
                    <div
                        class="media">
                        <div class="media-left">
                            <b-icon
                                :icon="entityIcon(props.option)" />
                        </div>
                        <div class="media-content">
                            <b>{{ props.option.name }}</b><br>
                            <div>{{ props.option.description }}</div>
                            <div v-if="props.option.entityType === 'code'">
                                {{ props.option.additionalDescription }}
                            </div>
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>
    </div>
</template>

<script>
    import { globalSearch } from '../helpers/api';
    import { getLibraryLink, getLibraryListLink, getCodeLinkByB64Id } from '../helpers/routing';
    import { cancelPreviousWhenCalledWithDebouncing, encodeIdBase64 } from '@/shared/helpers/utils.js';

    export default {
        emits: [
            'select'
        ],
        data() {
            return {
                data: [],
                searchText: '',
                selected: null,
                isFetching: false
            };
        },
        watch: {
            searchText: cancelPreviousWhenCalledWithDebouncing(
                async function(abortSignal, readyPromise) {
                    if (!this.searchText) {
                        this.finishSearch([]);
                        return;
                    }
                    this.isFetching = true;

                    await readyPromise;

                    const results = await globalSearch(this, this.searchText, { signal: abortSignal })
                        .then(response => response);

                    if (abortSignal.aborted)
                        return;

                    this.finishSearch(results);
                },
                { delay: 500 }
            )
        },
        methods: {
            finishSearch(results) {
                this.data = results;
                this.isFetching = false;
            },
            itemSelected: function(option) {
                this.$emit('select', option);

                switch (option.entityType) {
                    case 'library':
                        this.$router.push(getLibraryLink(option.name));
                        break;
                    case 'tag':
                        this.$router.push(getLibraryListLink([option.name]));
                        break;
                    case 'code':
                        this.$router.push(getCodeLinkByB64Id(encodeIdBase64('Code', option.entityId)));
                        break;
                }
            },
            entityIcon: function(resultRecord) {
                switch (resultRecord.entityType) {
                    case 'tag':
                        return 'book-multiple';
                    case 'library':
                        return 'book';
                    case 'code':
                        return 'invoice-list';
                    default:
                        return 'file-question-outline';
                }
            }
        }
    };
</script>

<style scoped>
</style>
