<template>
    <div>
        <div
            v-if="current"
            v-cloak
            class="box">
            <div class="columns">
                <div class="column">
                    <h2 class="title is-3">
                        {{ current.name }}
                    </h2>
                </div>
                <div class="column is-clearfix">
                    <div class="field is-grouped is-pulled-right">
                        <p class="control">
                            <button
                                v-require-is-administrator
                                class="button is-primary"
                                @click="isNewSubModalActive = true">
                                <b-icon
                                    icon="plus-circle-outline"
                                    size="is-small" />
                                <span>New subscription</span>
                            </button>
                        </p>
                        <p class="control">
                            <router-link
                                :to="getReleaseLogLink({applicationName: current.name})"
                                class="button is-info">
                                <b-icon
                                    icon="format-list-numbered"
                                    size="is-small" />
                                <span>Release log</span>
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>

            <div class="box">
                <table class="table is-striped is-narrow is-fullwidth">
                    <tbody>
                        <tr>
                            <th>Receiver adapter</th>
                            <td>
                                <span :title="current.receiverAdapterName">
                                    {{ $filters.lastPart(current.receiverAdapterName) }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>Item extractor</th>
                            <td>
                                <span :title="current.codeExtractorName">
                                    {{ $filters.lastPart(current.codeExtractorName) }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="box">
                <h2 class="title is-5">
                    Subscriptions
                </h2>
                <b-table
                    v-if="subscriptions.length"
                    :data="subscriptions"
                    default-sort="name"
                    :striped="true"
                    :narrowed="true"
                    :hoverable="true"
                    class="site-sticky-header">
                    <b-table-column
                        v-slot="props"
                        field="currentStatus.successRate"
                        label="Status"
                        sortable>
                        <span class="icon is-small">
                            <i
                                v-if="props.row.currentStatus == null"
                                class="fa fa-question"
                                style="color:orange;" />
                            <i
                                v-else-if="props.row.currentStatus.successRate == 1"
                                class="fa fa-thumbs-up"
                                style="color:green;" />
                            <i
                                v-else
                                class="fa fa-thumbs-down"
                                style="color:red;" />
                        </span>
                        <span v-if="props.row.currentStatus">
                            {{ $filters.formatPercent(props.row.currentStatus.successRate) }}
                            ({{ props.row.currentStatus.statusCount }})
                        </span>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="library.name"
                        label="Name"
                        sortable>
                        <router-link :to="getSubscriptionLink(current.name, props.row.id)">
                            {{ current.name }} {{ props.row.library.name }} subscription
                        </router-link>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="library.name"
                        label="Library"
                        sortable>
                        <router-link :to="getLibraryLink(props.row.library.name)">
                            {{ props.row.library.name }}
                        </router-link>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="library.description"
                        label="Library description"
                        sortable>
                        {{ props.row.library.description }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="enabled"
                        label="Enabled"
                        checkable>
                        <b-switch
                            v-model="props.row.enabled"
                            disabled />
                        <span v-show="props.row.enabled">Enabled</span>
                        <span v-show="!props.row.enabled">Disabled</span>
                    </b-table-column>
                </b-table>
                <div
                    v-else
                    class="has-text-grey has-text-centered">
                    No subscriptions
                </div>
            </div>
        </div>

        <b-modal
            v-model="isNewSubModalActive"
            :height="500"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="New Subscription"
            aria-modal>
            <div>
                <new-subscription-modal
                    :existing-libraries="existingLibraries"
                    @selected="e => makeNewSubscription(e)" />
            </div>
        </b-modal>
    </div>
</template>

<script>

    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import {
        getInternalApplication,
        getInternalApplicationSubscriptions,
        saveNewInternalApplicationSubscription
    } from '@/shared/helpers/api';
    import { getLibraryLink, getReleaseLogLink, getSubscriptionLink } from '@/shared/helpers/routing';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';
    import NewSubscriptionModal from './NewSubscriptionModal.vue';

    export default {
        components: {
            NewSubscriptionModal
        },
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        filters: {
            lastPart: function(text) {
                if (!text) return text;

                const parts = text.split('.');
                return parts.pop();
            },
            formatPercent: function(val) {
                val = Math.floor(val * 100);
                return val + '%';
            }
        },
        mixins: [
            showMixin
        ],
        props: {
            appName: {
                default: null,
                type: String
            }
        },
        data: function() {
            return {
                current: null,
                subscriptions: [],
                isNewSubModalActive: false
            };
        },
        computed: {
            existingLibraries: function() {
                return this.subscriptions.map(s => s.library.name);
            }
        },
        watch: {
            appName() {
                this.load();
            }
        },
        mounted: function() {
            this.load();
        },
        methods: {
            load: async function() {
                if (this.appName === null) return;

                try {
                    this.current = await getInternalApplication(this, this.appName);
                    await this.loadSubscriptions();
                } catch (err) {
                    this.showError(`Unable to fetch for application name ${this.appName}`);
                }
            },
            loadSubscriptions: async function() {
                let subscriptionList;
                try {
                    subscriptionList = await getInternalApplicationSubscriptions(this, this.current.name);
                } catch (err) {
                    this.showError('Unable to fetch subscriptions');
                }
                if (subscriptionList !== undefined) {
                    this.subscriptions = _.sortBy(subscriptionList, (element) => element.library.libraryName);
                }
            },
            newSubscription: function() {
                const self = this;
                this.$buefy.dialog.prompt({
                    message: 'Library name:',
                    inputAttrs: {
                        minlength: 1,
                        maxlength: 64,
                        required: true
                    },
                    onConfirm: self.makeNewSubscription
                });
            },
            makeNewSubscription: async function(libraryName) {
                this.isNewSubModalActive = false;

                const requestBody = {
                    'libraryName': libraryName,
                    'applicationName': this.current.name
                };

                try {
                    await saveNewInternalApplicationSubscription(this, requestBody);
                    await this.load();
                } catch (err) {
                    this.showError(err);
                }
            },
            getLibraryLink,
            getReleaseLogLink,
            getSubscriptionLink
        }

    };

</script>
