<template>
    <div class="box">
        <h2 class="title is-5">
            Change details
        </h2>
        <table
            v-if="specIsScopeCodeSetRelease"
            class="table is-narrow is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>Scope</th>
                    <th>Change type</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(change, idx) in changes"
                    :key="idx">
                    <td>{{ change.scopeName }}</td>
                    <td>{{ change.changeType }}</td>
                </tr>
            </tbody>
        </table>
        <release-change-table
            v-else-if="changesReady && isCodeChangeDocument"
            :change-document="current.specification"
            :editable="current.state === 'Open'" />
    </div>
</template>

<script>
    import ReleaseChangeTable from './ReleaseChangeTable.vue';

    export default {
        components: {
            ReleaseChangeTable
        },
        props: {
            current: {
                type: Object,
                default: () => {}
            },
            changesReady: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            changes() {
                return this.current?.specification.changes;
            },
            isCodeChangeDocument() {
                return (
                    this.current?.specification.specificationType === 'CompositeChangeDocument'
                    || this.current?.specification.specificationType === 'ChangeDocument');
            },
            specIsScopeCodeSetRelease: function() {
                return this.current?.specification.specificationType === 'ScopeCodeSetRelease';
            }
        }
    };
</script>

<style>

</style>
