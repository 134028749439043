<template>
    <b-tabs
        v-model="localActiveTab"
        type="is-boxed">
        <b-tab-item>
            <template #header>
                <span title="Alt+1">Libraries</span>
            </template>
            <span
                id="libPane">
                <div class="field">
                    <b-input
                        ref="libSearch"
                        v-model="libFilter"
                        placeholder="Search.."
                        icon="magnify"
                        icon-right="close"
                        icon-right-clickable
                        @icon-right-click="clearLibFilter" />
                </div>
                <div
                    v-for="lib in librariesFiltered"
                    :key="lib.name"
                    class="no-wrap-overflow">
                    <a
                        tabindex="0"
                        @keypress.enter="selectLibrary(lib.name)"
                        @click="selectLibrary(lib.name)"><b-icon
                            icon="table"
                            size="is-small" />{{ lib.name }}</a>
                </div>
                <p style="padding-top:10px;">
                    <i>{{ libFilterMessage }}</i>
                </p>
            </span>
        </b-tab-item>
        <b-tab-item>
            <template #header>
                <span title="Alt+2">Queries</span>
            </template>
            <span id="queriesPane">
                <div class="field">
                    <b-input
                        ref="querySearch"
                        v-model="queryFilter"
                        placeholder="Search.."
                        icon="magnify"
                        icon-right="close"
                        icon-right-clickable
                        @icon-right-click="clearQueryFilter" />
                </div>
                <div style="padding-bottom:8px;">
                    <b-button
                        size="is-small"
                        icon-left="table-plus"
                        class="is-success"
                        @click="newQuery">New query</b-button>
                </div>
                <div v-show="publicQueries.length > 0"><b>Public queries:</b></div>
                <div
                    v-for="q in publicQueries"
                    :key="q.id"
                    class="no-wrap-overflow">
                    <a
                        tabindex="0"
                        @keypress.enter="selectQuery(q.id)"
                        @click="selectQuery(q.id)"><b-icon
                            :icon="savedQueryIcon(q)"
                            size="is-small" />{{ q.name }}</a>
                </div>
                <div
                    v-show="ownedQueries.length > 0"
                    style="padding-top:8px;"><b>Your queries:</b></div>
                <div
                    v-for="q in ownedQueries"
                    :key="q.id"
                    class="no-wrap-overflow">
                    <a
                        :title="savedQueryTooltip(q)"
                        tabindex="0"
                        @keypress.enter="selectQuery(q.id)"
                        @click="selectQuery(q.id)"><b-icon
                            :icon="savedQueryIcon(q)"
                            size="is-small" />{{ q.name }}</a>
                </div>
            </span>
        </b-tab-item>
    </b-tabs>
</template>

<script>
    export default {
        props: {
            libraries: {
                type: Array,
                required: true
            },
            queries: {
                type: Array,
                required: true
            },
            activeTab: {
                type: Number,
                required: true
            }
        },
        emits: [
            'tab-changed',
            'lib-selected',
            'query-selected',
            'new-query'
        ],
        data: function() {
            return {
                MAX_LIB_LEN: 30,
                localActiveTab: 0,
                libFilter: '',
                queryFilter: ''
            };
        },
        computed: {
            librariesFiltered: function() {
                const filter = this.libFilter.toLowerCase();
                return this.libraries.filter(
                    lib => lib.name.toLowerCase().includes(filter)
                ).slice(0, this.MAX_LIB_LEN);
            },
            queriesFiltered: function() {
                const filter = this.queryFilter.toLowerCase();
                return this.queries.filter(
                    q => q.name.toLowerCase().includes(filter)
                );
            },
            libFilterMessage: function() {
                const visibleLen = this.librariesFiltered.length;
                const libLen = this.libraries.length;
                if (visibleLen >= this.MAX_LIB_LEN)
                    return `Showing first ${visibleLen} of ${libLen} libraries`;
                return '';
            },
            publicQueries: function() {
                return this.queriesFiltered.filter(x => x.isPublic);
            },
            ownedQueries: function() {
                return this.queriesFiltered.filter(x => !x.isPublic);
            }
        },
        watch: {
            localActiveTab() {
                // Delaying focus to not mess up tab transition
                setTimeout(this.focusSearchInput, 500);

                if (this.localActiveTab !== this.activeTab) {
                    this.$emit('tab-changed', this.localActiveTab);
                }
            },
            activeTab() {
                if (this.localActiveTab !== this.activeTab) {
                    this.localActiveTab = this.activeTab;
                }
            }
        },
        mounted: function() {
            this.focusSearchInput();
        },
        methods: {
            clearLibFilter: function() {
                this.libFilter = '';
            },
            clearQueryFilter: function() {
                this.queryFilter = '';
            },
            selectLibrary: function(name) {
                this.$emit('lib-selected', name);
            },
            selectQuery: function(id) {
                this.$emit('query-selected', id);
            },
            newQuery: function() {
                this.$emit('new-query');
            },
            savedQueryIcon(q) {
                return q.isReport ? 'rss' : 'table-search';
            },
            savedQueryTooltip(q) {
                return q.isReport ? 'Report' : '';
            },
            focusSearchInput() {
                switch (this.localActiveTab) {
                    case 0:
                        this.$refs.libSearch.focus();
                        break;
                    case 1:
                        this.$refs.querySearch.focus();
                        break;
                }
            }
        }
    };
</script>
<style>
.no-wrap-overflow {
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
}
</style>
