/*
    Simple message relay to send arbitrary messages between Vue apps.
    Intended receiver is the headerApp.

    Note: Since this is being migrated to a SPA and we do not need to communicate this way between Apps,
    it may be other solutions that we can use, kept as is to avoid scope creep.
*/
export default function() {
    const receivers = {};
    return {
        on: function(messageName, receiverFunction) {
            receivers[messageName] = receiverFunction;
        },
        send: async function(messageName) {
            if (receivers[messageName]) {
                await receivers[messageName]();
            }
        }
    };
}
