<template>
    <div id="editor">
        <div class="columns">
            <div class="column is-one-third">
                <div
                    class="control has-icons-left">
                    <input
                        v-model="filter"
                        type="text"
                        class="input"
                        placeholder="Search by Name (minimum 3 characters)">
                    <span class="icon is-small is-left">
                        <i class="fa fa-search" />
                    </span>
                </div>
                <div style="color:#bababa;margin-top:8px;">
                    <i>Filter: {{ filterDisplayValue }}</i>
                </div>
                <b-message
                    type="is-warning"
                    has-icon
                    :model-value="showSearchHelpDialog">
                    Enter at least 3 characters into the search field to start displaying Projects.
                </b-message>
                <b-loading
                    v-model="loading"
                    :is-full-page="false"
                    :can-cancel="false" />
            </div>
            <div class="column is-clearfix">
                <div class="field is-grouped is-pulled-right">
                    <p class="control">
                        <b-button
                            v-require-can-edit-code="{ libraryName: 'Project' }"
                            class="is-primary"
                            @click="newProject">
                            <b-icon
                                icon="pencil"
                                size="is-small" />
                            <span>Create new project</span>
                        </b-button>
                    </p>
                </div>
            </div>
        </div>

        <div
            v-if="codeSetName && showNewDialog">
            <h2
                class="title">
                Create a new Project:
            </h2>
            <code-edit
                :key="projectCodeEditKey"
                :quick-commit="true"
                :show-cancel="true"
                :code-set-name="codeSetName"
                library="Project"
                @refresh="refreshProjects"
                @cancel="cancelNew" />
            <hr>
        </div>
        <project-virtual-list
            :rows="rows"
            :columns="columns"
            keyfield="id" />
    </div>
</template>

<script>
    import ProjectVirtualList from './ProjectVirtualList.vue';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { encodeIdBase64 } from '@/shared/helpers/utils';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit,
            BoolElement,
            ProjectVirtualList
        },
        data: function() {
            return {
                projectCodeEditKey: 1,
                showNewDialog: false,
                showSearchHelpDialog: true,
                filter: '',
                filterDisplayValue: '',
                codeSetName: null,
                projects: [],
                loading: false,
                columns: [
                    { label: 'Name', type: 'STRING', valueIdx: 0 },
                    { label: 'Description', type: 'STRING', valueIdx: 1 },
                    { label: 'Is valid', type: 'BOOL', valueIdx: 2 }
                ]
            };
        },
        computed: {
            rows: function() {
                if (this.projects) {
                    return this.projects.map(c => ({ id: encodeIdBase64('Code', c.Id), values: [c.Name, c.Description, true] }));
                } else return [];
            }
        },
        watch: {
            filter: async function() {
                if (this.filter.length >= 3) {
                    this.filterDisplayValue = `Name like "%${this.filter.trim()}%"`;
                    this.loadProjects();
                }
            }
        },
        async mounted() {
            this.codeSetName = (await getCodeSets(this, 'Project'))[0].name;
        },
        methods: {
            loadProjects: async function() {
                this.loading = true;
                this.showSearchHelpDialog = false;
                this.projects = (await genericViewQueryAsText(this, `
                    FROM Project
                    SELECT Id, Name, Description
                    WHERE IsValid=true AND Name LIKE @filter
                `, [{ name: '@filter', value: `%${this.filter.trim()}%` }])).data;
                this.loading = false;
            },
            newProject: function() {
                this.showNewDialog = true;
                this.projectCodeEditKey += 1;
            },
            cancelNew: function() {
                this.showNewDialog = false;
            },
            refreshProjects: async function() {
                this.showNewDialog = false;
                await this.loadProjects();
            }
        }
    };
</script>
