<template>
    <section v-if="dataValue !== undefined">
        <b-field>
            <b-radio-button
                v-if="!hideNull"
                native-value=""
                :disabled="disabled"
                :size="size"
                type="is-light"
                @update:model-value="$emit('bool-select', '')">
                <b-icon icon="minus" />
                <span>No Value</span>
            </b-radio-button>

            <b-radio-button
                v-model="dataValue"
                :size="size"
                :disabled="disabled"
                native-value="false"
                type="is-danger"
                @update:model-value="$emit('bool-select', 'False')">
                <b-icon icon="close" />
                <span>False</span>
            </b-radio-button>
            <b-radio-button
                v-model="dataValue"
                :disabled="disabled"
                native-value="true"
                :size="size"
                type="is-info"
                @update:model-value="$emit('bool-select', 'True')">
                <b-icon icon="check" />
                <span>True</span>
            </b-radio-button>
        </b-field>
    </section>
</template>

<script>
    export default {
        props: {
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            hideNull: {
                type: Boolean,
                required: false,
                default: true
            },
            value: {
                type: String,
                required: true
            },
            size: {
                type: String,
                required: false,
                default: ''
            }
        },
        emits: [
            'bool-select'
        ],
        data: function() {
            return {
                dataValue: this.value.toLowerCase()
            };
        },
        watch: {
            value: function(val) {
                this.dataValue = val.toLowerCase();
            }
        }
    };
</script>

<style scoped>
    :deep([disabled].radio.is-info) {
        color: white; /* Otherwise it's gray and unreadable */
    }
</style>
