<template>
    <div>
        <h2 class="title is-3">
            Master Tag & Doc Type mapping status for the various Facilities
        </h2>
        <b-tabs>
            <b-tab-item label="TagType -> MasterTagType">
                <spinner
                    v-if="loading"
                    :loading="loading" />
                <mapping-table
                    :data="tagData"
                    :headers="{EntityType: 'TagType', MasterEntityType: 'MasterTagType', Entities: 'Tags'}"
                    count-name="MasterTagTypes" />
            </b-tab-item>
            <b-tab-item label="DocType -> MasterDocType">
                <spinner
                    v-if="loading"
                    :loading="loading" />
                <mapping-table
                    :data="docData"
                    :headers="{EntityType: 'DocType', MasterEntityType: 'MasterDocType', Entities: 'Docs'}"
                    count-name="MasterDocTypes" />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { getReport } from '@/shared/helpers/api';
    import _ from 'lodash';
    import MappingTable from './MappingTable.vue';

    export default {
        components: { Spinner, MappingTable },
        data: () => {
            return {
                loading: false,
                tagData: [],
                docData: []
            };
        },
        mounted: async function() {
            this.loading = true;
            const tagView = await getReport(this, 'MasterTagTypeMappingCount', '');
            const docView = await getReport(this, 'MasterDocTypeMappingCount', '');

            this.tagData = _.sortBy(tagView, (x) => x.Description).map(row => ({
                Description: row.Description,
                EntityTypesWithMasterEntityType: row.TagTypesWithMasterTagType,
                EntityTypeCount: row.TagTypeCount,
                PercentEntityTypeMapped: row.PercentTagTypeMapped,
                MasterEntitiesUsed: row.MasterTagsUsed,
                MasterEntityTypeCount: row.MasterTagTypeCount,
                PercentMasterEntitiesUsed: row.PercentMasterTagsUsed,
                STIDEntitiesWithMasterEntityType: row.STIDTagsWithMasterTagType,
                STIDEntityCount: row.STIDTagCount
            }));
            this.docData = _.sortBy(docView, (x) => x.Description).map(row => ({
                Description: row.Description,
                EntityTypesWithMasterEntityType: row.DocTypesWithMasterDocType,
                EntityTypeCount: row.DocTypeCount,
                PercentEntityTypeMapped: row.PercentDocTypeMapped,
                MasterEntitiesUsed: row.MasterDocsUsed,
                MasterEntityTypeCount: row.MasterDocTypeCount,
                PercentMasterEntitiesUsed: row.PercentMasterDocsUsed,
                STIDEntitiesWithMasterEntityType: row.STIDDocsWithMasterDocType,
                STIDEntityCount: row.STIDDocCount
            }));
            this.loading = false;
        }
    };
</script>
