<template>
    <div class="wrapper">
        <p><a :href="'/Schema#/specification/' + identity">&laquo; Back to schema</a></p>
        <h1 class="title">
            Edit attributes in Schema: {{ identity }}
        </h1>
        <div class="columns">
            <div class="column is-one-quarter">
                <h5
                    class="subtitle is-5 column-header-light">
                    {{ selectedInterface ? `Classes realizing ${selectedInterface.Name}` : 'Classes' }}
                </h5>
                <div
                    class="field is-grouped"
                    style="">
                    <div class="control">
                        <b-button
                            type="is-default"
                            :disabled="selectedClass === null"
                            @click="selectedClass = null">
                            <b-icon
                                icon="close-octagon-outline"
                                size="is-small" />
                            <span>Clear selection</span>
                        </b-button>
                    </div>
                    <div class="control">
                        <i>{{ filteredClasses.length }} of {{ classes.length }}</i>
                    </div>
                </div>
                <div class="half-table-overflow">
                    <b-table
                        :hoverable="true"
                        :selected="selectedClass"
                        :data="filteredClasses"
                        class="cursorPointer"
                        @click="clickSelectClass">
                        <b-table-column
                            v-slot="props"
                            field="Name">
                            {{ props.row.Name }}
                        </b-table-column>
                    </b-table>
                </div>
                <h5
                    class="subtitle is-5 column-header-light"
                    style="margin-top:18px;">
                    {{ selectedClass ? `Interfaces realized by ${selectedClass.Name}` : 'Interfaces' }}
                </h5>
                <div
                    class="field is-grouped"
                    style="">
                    <div class="control">
                        <b-button
                            type="is-default"
                            :disabled="selectedInterface === null"
                            @click="selectedInterface = null">
                            <b-icon
                                icon="close-octagon-outline"
                                size="is-small" />
                            <span>Clear selection</span>
                        </b-button>
                    </div>
                    <div class="control">
                        <i>{{ filteredInterfaces.length }} of {{ interfaces.length }}</i>
                    </div>
                </div>
                <div class="half-table-overflow">
                    <b-table
                        :hoverable="true"
                        :selected="selectedInterface"
                        :data="filteredInterfaces"
                        class="cursorPointer"
                        @click="clickSelectInterface">
                        <b-table-column
                            v-slot="props"
                            field="Name">
                            <span
                                :id="'i:' + props.row.Identity">
                                {{ props.row.Name }}
                            </span>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <div class="column is-one-quarter">
                <h5
                    class="subtitle is-5 column-header-light">
                    {{ selectedInterface ? `Attributes exposed by ${selectedInterface.Name}` : 'Attributes' }}
                </h5>
                <div
                    class="field is-grouped">
                    <div class="control buttons">
                        <div
                            v-require-can-edit-code="{ libraryName: 'SchemaAttribute' }">
                            <b-button
                                type="is-primary"
                                :disabled="makeNewAttribute || selectedAttribute"
                                @click="createNewAttribute">
                                <b-icon
                                    icon="plus-circle-outline"
                                    size="is-small" />
                                <span>New</span>
                            </b-button>
                            <b-tooltip
                                label="SchemaAttribute library is configured locked for deletions"
                                position="is-right"
                                :value="attributeListLockedForDelete">
                                <b-button
                                    type="is-danger"
                                    :disabled="attributeListLockedForDelete || !selectedAttribute"
                                    @click="() => confirmDeleteCode(selectedAttribute.Identity)">
                                    <b-icon
                                        icon="skull"
                                        size="is-small" />
                                    <span>Delete</span>
                                </b-button>
                            </b-tooltip>
                        </div>
                    </div>
                    <div class="control">
                        <i>{{ filteredAttributes.length }} of {{ attributes.length }}</i>
                    </div>
                </div>

                <div class="table-overflow">
                    <b-table
                        :hoverable="true"
                        :selected="selectedAttribute"
                        :data="filteredAttributes"
                        class="cursorPointer"
                        @click="clickSelectAttribute">
                        <b-table-column
                            v-slot="props"
                            field="Name">
                            {{ props.row.Name }}
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <div class="column">
                <h5
                    class="subtitle is-5 column-header-light">
                    {{ selectedAttribute ? `Attribute properties for ${selectedAttribute.Name}` : 'Attribute properties' }}
                </h5>
                <div class="table-overflow">
                    <schema-attribute-edit-form
                        :attribute-id="selectedAttribute ? selectedAttribute.Id : null"
                        :interfaces="interfaces"
                        :schema-identity="identity"
                        :new-attribute-mode="makeNewAttribute"
                        :attribute-code-set="attributeCodeSet"
                        :selected-interface="selectedInterface ? selectedInterface.Identity : null"
                        style="margin-right:10px;"
                        @cancel-edit="handleEditCancel"
                        @new-attribute-saved="handleNewAttributeSaved"
                        @modified-attribute-saved="handleModifiedAttributeSaved" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { deleteCode, genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';
    import SchemaAttributeEditForm from './SchemaAttributeEditForm.vue';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            SchemaAttributeEditForm
        },
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data: function() {
            return {
                interfaces: [],
                classes: [],
                attributes: [],
                classInterfaceRelations: [],
                selectedInterface: null,
                selectedClass: null,
                selectedAttribute: null,
                attributeCodeSet: null,
                attributeListLockedForDelete: true,
                makeNewAttribute: false,
                queries: {
                    interfaces: 'FROM SchemaInterface SELECT Name, Id, Identity',
                    classes: 'FROM SchemaClass SELECT Name, Id',
                    attributes: 'FROM SchemaAttribute SELECT Name, Id, Identity, Interface_ID',
                    classInterfaces: 'FROM SchemaClassInterface SELECT Name, Class_ID, Interface_ID'
                }
            };
        },
        computed: {
            filteredInterfaces() {
                if (this.selectedClass) {
                    const c = this.selectedClass;
                    return this.interfaces.filter(i => _.find(this.classInterfaceRelations, { 'Interface_ID': i.Id, 'Class_ID': c.Id }));
                } else {
                    return this.interfaces;
                }
            },
            filteredClasses() {
                if (this.selectedInterface) {
                    const i = this.selectedInterface;
                    return this.classes.filter(c => _.find(this.classInterfaceRelations, { 'Interface_ID': i.Id, 'Class_ID': c.Id }));
                } else {
                    return this.classes;
                }
            },
            filteredAttributes() {
                if (this.selectedInterface) {
                    const iID = this.selectedInterface.Id;
                    return this.attributes.filter(a => a.Interface_ID === iID);
                } else if (this.selectedClass) {
                    return this.attributes.filter(a => _.find(this.filteredInterfaces, { 'Id': a.Interface_ID }));
                } else {
                    return this.attributes;
                }
            }
        },
        mounted: async function() {
            let acs = null;
            [this.interfaces, this.classes, this.attributes, this.classInterfaceRelations, acs] = await Promise.all([
                this.doSchemaQuery(this.queries.interfaces),
                this.doSchemaQuery(this.queries.classes),
                this.doSchemaQuery(this.queries.attributes),
                this.doSchemaQuery(this.queries.classInterfaces),
                getCodeSets(this, 'SchemaAttribute')
            ]);

            // TODO:
            /* Fix to vue event issue for observable arrays */
            // this.interfaces = this.interfaces;

            const codeSet = acs[0];
            this.attributeCodeSet = codeSet;
            this.attributeListLockedForDelete = codeSet.lockedForDelete;
        },
        methods: {
            async doSchemaQuery(query) {
                const result = await genericViewQueryAsText(
                    this,
                    query + ' WHERE IsValid = true AND Schema = @schema',
                    [{ name: '@schema', value: this.identity }]);
                return result.data.sort((a, b) => a.Name.localeCompare(b.Name));
            },
            clickSelectInterface(i) {
                this.selectedInterface = i;
            },
            clickSelectClass(c) {
                this.selectedClass = c;
            },
            clickSelectAttribute(a) {
                this.makeNewAttribute = false;
                this.selectedAttribute = a;
                this.clickSelectInterface(_.find(this.interfaces, { 'Id': a.Interface_ID }));
                document.getElementById('i:' + this.selectedInterface.Identity).scrollIntoView({
                    behaviour: 'smooth'
                });
            },
            createNewAttribute() {
                this.selectedAttribute = null;
                this.makeNewAttribute = true;
            },
            confirmDeleteCode(codeIdentity) {
                this.$buefy.dialog.confirm({
                    title: 'Please confirm delete',
                    message: 'Deleting items may cause issues if the item is known externally to Common Library, and should normally only be done during initial setup of libraries. Continue to delete item?',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        await this.deleteSchemaAttributeCode(codeIdentity);
                    }
                });
            },
            deleteSchemaAttributeCode: async function(codeIdentity) {
                try {
                    await deleteCode(this, this.attributeCodeSet.name, codeIdentity);
                    this.selectedAttribute = null;
                } catch (error) {
                    this.showError(_.get(error, 'response.data.Message', 'Unable to delete attribute'));
                }
                this.attributes = await this.doSchemaQuery(this.queries.attributes);
            },
            handleEditCancel() {
                this.selectedAttribute = null;
                this.makeNewAttribute = false;
            },
            async handleNewAttributeSaved() {
                this.attributes = await this.doSchemaQuery(this.queries.attributes);
                this.selectedAttribute = null;
                this.makeNewAttribute = false;
            },
            handleModifiedAttributeSaved() {
                this.selectedAttribute = null;
                this.makeNewAttribute = false;
            }
        }
    };
</script>

<style scoped>
.wrapper{
    min-height: 900px;
    height: 80vh;
}
.cursorPointer{
    cursor: pointer;
}
.table-overflow{
    overflow: auto;
    height: 70vh;
}
.half-table-overflow{
    overflow: auto;
    height: 31vh;
}
</style>
