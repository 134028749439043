<template>
    <div>
        <h2 class="title is-3">
            Report, Statistics of number of entries per library/set
        </h2>
        <div class="box">
            <label class="label">Select library:</label>
            <div
                v-if="libraries.length"
                v-cloak
                class="select">
                <select
                    v-model="selectedLibrary"
                    accesskey="s">
                    <option value="">
                        No library selected (Alt+S)
                    </option>
                    <option
                        v-for="lib in libraries"
                        :value="lib">
                        {{ lib.name }}
                    </option>
                </select>
            </div>
            <button
                class="button is-primary"
                @click="runReport">
                <span class="icon is-small">
                    <i
                        class="fa fa-play"
                        aria-hidden="true" />
                </span>
                <span>Run report</span>
            </button>
        </div>

        <spinner :loading="loading">
            <div
                v-if="data.length"
                v-cloak>
                <b-table
                    :data="data"
                    :columns="columns"
                    :striped="true" />
            </div>
        </spinner>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { getLibraries, getReport } from '@/shared/helpers/api';
    import _ from 'lodash';

    export default {
        name: 'LibraryCodeCount',
        components: {
            Spinner
        },
        data() {
            return {
                loading: false,
                libraries: [{ name: 'Area' }],
                selectedLibrary: '',
                data: [],
                columns: [
                    { field: 'LibraryName', label: 'Library' },
                    { field: 'CodeSetDescription', label: 'Code set' },
                    { field: 'TotalCount', label: 'Total code count', numeric: true },
                    { field: 'ValidCount', label: 'Valid code count', numeric: true }
                ]
            };
        },
        computed: {
            filter: function() {
                if (this.selectedLibrary.name !== undefined)
                    return `LibraryName='${this.selectedLibrary.name}'`;
                return '';
            }
        },
        mounted: async function() {
            const tmp = await getLibraries(this);
            this.libraries = _.sortBy(tmp, (x) => x.name);
        },
        methods: {
            runReport: async function() {
                this.loading = true;
                const tmp = await getReport(this, 'LibraryCodeSetCount', this.filter);
                this.data = _.sortBy(tmp, (x) => x.LibraryName + x.CodeSetDescription);
                this.loading = false;
            }
        }
    };
</script>

<style scoped>

</style>
