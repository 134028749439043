<template>
    <b-dropdown
        aria-role="list">
        <template #trigger>
            <button
                class="button field-wrapper"
                :class="type">
                <span>{{ title }}</span>
                <b-icon icon="menu-down" />
            </button>
        </template>

        <b-field
            position="is-centered"
            class="field-wrapper">
            <p class="control">
                <b-button
                    size="is-small"
                    @click="clearFilter()">
                    Clear all
                </b-button>
            </p>
        </b-field>
        <b-field
            v-for="(schema, idx) in getSliceOfSchemas()"
            :key="idx"
            class="field-wrapper"
            position="is-centered">
            <p class="control">
                <b-checkbox
                    :model-value="selected.includes(schema)"
                    @update:model-value="selectSchema(schema)">
                    {{ schema }}
                </b-checkbox>
            </p>
        </b-field>
        <b-pagination
            v-model="currentPage"
            :total="schemas.length"
            size="is-small"
            :simple="true"
            :rounded="true"
            order="is-centered"
            :per-page="dropdownPageSize" />
    </b-dropdown>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: 'Select schema',
                required: false
            },
            activeSchemas: {
                type: Array,
                default: () => [],
                required: false
            },
            schemas: {
                type: Array,
                default: () => [],
                required: false
            },
            type: {
                type: String,
                default: 'is-info'
            },
            dropdownPageSize: {
                type: Number,
                default: 20,
                required: false
            }
        },
        emits: [
            'update:schemas'
        ],
        data: function() {
            return {
                currentPage: 1,
                selected: []
            };
        },
        watch: {
            activeSchemas: {
                handler() {
                    this.selected = this.activeSchemas;
                },
                deep: true
            }
        },
        mounted: function() {
            this.selected = this.activeSchemas;
        },
        methods: {
            getSliceOfSchemas: function() {
                const end = this.currentPage * this.dropdownPageSize > this.schemas.length;
                return this.schemas.slice(
                    (this.currentPage - 1) * this.dropdownPageSize,
                    end ? this.schemas.length : this.currentPage * this.dropdownPageSize
                );
            },
            selectSchema: function(schema) {
                this.selected = this.selected.includes(schema)
                    ? this.selected.filter(s => s !== schema)
                    : this.selected.concat([schema]);
                this.$emit('update:schemas', this.selected);
            },
            clearFilter: function() {
                this.selected = [];
                this.$emit('update:schemas', this.selected);
            }
        }
    };
</script>
<style scoped>
.field-wrapper {
    margin: 2px 15px;
}
</style>
