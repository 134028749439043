<template>
    <nav
        class="panel pos-rel">
        <p class="panel-heading">
            PIMS DC
            <a
                v-if="applicationDocumentManagmentViewModel.canEdit"
                class="button is-pulled-right is-small"
                @click="$emit('editPIMSDC', applicationDocumentManagmentViewModel)">
                <b-icon
                    :icon="iconDisplayed"
                    size="is-small" />
            </a>
        </p>
        <div class="panel-block">
            <b-table
                class="headless-table"
                :data="applicationDocumentManagmentViewModel.properties"
                default-sort="name"
                :striped="false"
                :narrowed="true"
                :hoverable="false">
                <b-table-column
                    v-slot="props"
                    field="name"
                    label="Name">
                    {{ props.row.name }}:
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="value"
                    label="Value">
                    {{ props.row.value }}
                </b-table-column>
            </b-table>
            <b-loading
                v-model="loadingApplicationDocumentManagment"
                :is-full-page="false"
                :can-cancel="false" />
        </div>
    </nav>
</template>

<script>
    import { genericViewQueryAsText } from '@/shared/helpers/api';
    import { mapResultToDisplaySingle } from '@/shared/helpers/queryResultToDisplay.js';
    import { encodeIdBase64 } from '@/shared/helpers/utils';

    export default {
        props: {
            projectMaster: {
                type: String,
                default: '',
                required: true
            },
            canEditCode: {
                type: Boolean,
                default: false,
                required: true
            },
            attributeDefinitions: {
                type: Array,
                default: () => [],
                required: true
            },
            accessGroups: {
                type: Array,
                default: () => [],
                required: true
            }
        },
        emits: [
            'editPIMSDC'
        ],
        data() {
            return {
                applicationDocumentManagment: [],
                loadingApplicationDocumentManagment: false
            };
        },
        computed: {
            applicationDocumentManagmentViewModel: function() {
                if (this.applicationDocumentManagment.length > 0) {
                    if (this.applicationDocumentManagment.length > 1) {
                        return [
                            {
                                name: 'ERROR',
                                value: 'Found multiple PIMS linked'
                            }
                        ];
                    }

                    const linked = this.applicationDocumentManagment[0];
                    const attributeDefinitonsFiltered = this.attributeDefinitions.filter(ad => ad.name !== 'ProjectMaster');

                    return {
                        canEdit:
                            this.canEditCode
                            && window.authService.hasAnyAccessGroup(
                                this.accessGroups
                            ),
                        id: encodeIdBase64('Code', linked.Id),
                        properties: mapResultToDisplaySingle(linked, attributeDefinitonsFiltered, {
                            skipDescription: true,
                            skipIsValid: true
                        })
                    };
                }

                return {
                    canEdit: this.canEditCode,
                    properties: []
                };
            },
            iconDisplayed: function() {
                const admvm = this.applicationDocumentManagmentViewModel;
                const showAddSymbol = admvm.canEdit && admvm.properties.length === 0;
                return showAddSymbol ? 'plus-thick' : 'pencil';
            }
        },
        mounted: async function() {
            this.getApplicationDocumentManagment();
        },
        methods: {
            getApplicationDocumentManagment: async function() {
                this.loadingApplicationDocumentManagment = true;

                this.applicationDocumentManagment = (await genericViewQueryAsText(
                    this,
                    `FROM ApplicationDocumentManagement
                    WHERE IsValid = true
                    AND ProjectMaster = @ProjectMaster`,
                    [{ name: '@ProjectMaster', value: this.projectMaster }]
                )).data;

                this.loadingApplicationDocumentManagment = false;
            }
        }
    };
</script>

<style scoped>
    .pos-rel{
        position: relative;
    }
</style>
