<template>
    <nav
        class="breadcrumb is-medium has-margin-top has-margin-bottom2"
        aria-label="breadcrumbs">
        <ul>
            <li
                v-for="(crumb, idx) in breadElements"
                :key="crumb.name">
                <router-link
                    :to="crumb.link"
                    class="link">
                    {{ crumb.name }}
                </router-link>
                <favourite-icon
                    v-if="idx === breadElements.length - 1"
                    style="margin-left: -10px" />
            </li>
        </ul>
    </nav>
</template>

<script>

    import { isPrefixOfPath } from '@/shared/helpers/utils.js';
    import { getLibraryLink, getCodeSetLink, getCodeLinkByB64Id } from '../helpers/routing.js';
    import FavouriteIcon from './FavouriteIcon.vue';

    const truncate = string => {
        if (!string)
            return;
        const long = string.length > 50;
        return long ? string.substring(0, 50) : string;
    };

    export default {
        components: {
            FavouriteIcon
        },
        props: {
            code: {
                type: Object,
                required: false,
                default: null
            },
            codeSet: {
                type: Object,
                required: false,
                default: null
            },
            library: {
                type: Object,
                required: false,
                default: null
            }
        },
        computed: {
            breadElements: function() {
                if (isPrefixOfPath(this.$route.path, '/Library/')) {
                    const crumbs = [
                        { name: 'Home', link: '/' },
                        { name: 'Libraries', link: '/Library/' }
                    ];
                    if (this.library) {
                        crumbs.push({ name: truncate(this.library.name), link: getLibraryLink(this.library.name) });
                    }
                    if (this.codeSet) {
                        crumbs.push({ name: truncate(this.codeSet.description), link: getCodeSetLink(this.codeSet.name) });
                    }
                    if (this.code) {
                        crumbs.push({ name: truncate(this.code.name), link: getCodeLinkByB64Id(this.code.id) });
                    }
                    return crumbs;
                } else {
                    return [];
                }
            }
        }
    };
</script>
