const authorization = 'Authorization';
const bearer = 'Bearer';

const AuthInterceptor = {
    request: async function(request) {
        if (request.url.startsWith(window.config.commonLibraryApiUrl)) {
            const accessToken = await window.authService.acquireToken();

            if (accessToken) {
                request.headers[authorization] = `${bearer} ${accessToken}`;
                return request;
            } else {
                throw new Error('Attempt to send Unauthenticated request to API');
            }
        } else {
            return request;
        }
    }
};

export default AuthInterceptor;
