<template>
    <b-modal
        :model-value="active"
        has-modal-card
        @cancel="$emit('close')">
        <div
            class="modal-card"
            style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    {{ title }}
                </p>
            </header>
            <section class="modal-card-body">
                <b-field
                    :label="stepOneLabel">
                    <b-select
                        v-model="stepOneSelectedElement"
                        expanded>
                        <option
                            v-for="option in stepOneElements"
                            :key="option.Id"
                            :value="option">
                            {{ stepOneDisplayTemplate(option) }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                    v-show="stepOneSelectedElement"
                    :label="stepTwoLabel">
                    <b-select
                        v-model="stepTwoSelectedElement"
                        expanded>
                        <option
                            v-for="option in stepTwoElements"
                            :key="option.Id"
                            :value="option">
                            {{ stepTwoDisplayTemplate(option) }}
                        </option>
                    </b-select>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    label="Cancel"
                    @click="$emit('close')" />
                <b-button
                    label="OK"
                    :disabled="!stepTwoSelectedElement"
                    type="is-primary"
                    @click="$emit('selected', stepTwoSelectedElement)" />
            </footer>
        </div>
    </b-modal>
</template>

<script>
    import { genericViewQueryAsText } from '../helpers/api';

    export default {
        props: {
            active: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                required: true
            },
            stepOneLabel: {
                type: String,
                default: 'Step one'
            },
            stepTwoLabel: {
                type: String,
                default: 'Step two'
            },
            stepOneQuery: {
                type: String,
                required: true
            },
            stepTwoQuery: {
                type: Function,
                required: true
            },
            stepOneSort: {
                type: Function,
                default: null
            },
            stepTwoSort: {
                type: Function,
                default: null
            },
            stepOneDisplayTemplate: {
                type: Function,
                required: true
            },
            stepTwoDisplayTemplate: {
                type: Function,
                required: true
            }
        },
        emits: [
            'close',
            'selected'
        ],
        data: function() {
            return {
                stepOneElements: [],
                stepTwoElements: [],
                stepOneSelectedElement: null,
                stepTwoSelectedElement: null
            };
        },
        watch: {
            async active(value) {
                if (value) {
                    this.stepOneSelectedElement = null;
                    if (this.stepOneElements.length === 0) {
                        await this.runStepOne();
                    }
                } else {
                    this.stepTwoElements = [];
                    this.stepOneSelectedElement = null;
                    this.stepTwoSelectedElement = null;
                }
            },
            async stepOneSelectedElement(value) {
                if (value) {
                    this.stepTwoSelectedElement = null;
                    await this.runStepTwo(value);
                }
            }
        },
        methods: {
            async runStepOne() {
                const response = await genericViewQueryAsText(this, this.stepOneQuery);
                if (this.stepOneSort) {
                    this.stepOneElements = response.data.sort(this.stepOneSort);
                } else {
                    this.stepOneElements = response.data;
                }
            },
            async runStepTwo(stepOneElement) {
                const response = await genericViewQueryAsText(this, this.stepTwoQuery(stepOneElement));
                if (this.stepTwoSort) {
                    this.stepTwoElements = response.data.sort(this.stepTwoSort);
                } else {
                    this.stepTwoElements = response.data;
                }
            }
        }
    };
</script>

<style>

</style>
