<template>
    <div>
        <b-notification
            type="is-success"
            :closable="false">
            Please note that this page is work in progress and should be regarded as a preview. The ENS number validation functionality will be extended in the future.
        </b-notification>
        <h1 class="title">
            ENS number validation
        </h1>
        <h1 class="subtitle">
            Validate a TAG number or document number according to the TR0052 ENS formats stored in Common Library.
        </h1>
        <hr>

        <b-field label="Facility">
            <b-select
                v-model="selectedFacility"
                placeholder="Select facility.."
                :loading="facilitiesLoading"
                :disabled="facilities.length === 0">
                <option
                    v-for="option in facilities"
                    :value="option.name">
                    {{ option.name }} - {{ option.description }}
                </option>
            </b-select>
        </b-field>

        <b-field grouped>
            <b-field label="ENS format type">
                <b-select
                    v-model="formatType"
                    placeholder="Select format type..">
                    <option value="TagFormat">
                        TAG numbering
                    </option>
                    <option value="DocumentNumberingFormat">
                        Document numbering
                    </option>
                </b-select>
            </b-field>

            <b-field label="Format category">
                <b-select
                    v-model="selectedCategory"
                    placeholder="Select category.."
                    :loading="categoriesLoading"
                    :disabled="categories.length === 0">
                    <option
                        v-for="option in categories"
                        :value="option.name">
                        {{ option.description }}
                    </option>
                </b-select>
            </b-field>
        </b-field>

        <b-field label="ENS number">
            <b-input v-model="ensNumber" />
        </b-field>

        <b-button
            type="is-primary"
            :loading="validationLoading"
            :disabled="!validationEnabled"
            @click="validateClick">
            Validate
        </b-button>

        <hr>
        <ens-number-validation-result :result="validationResult" />
    </div>
</template>

<script>
    import { ensParse, genericViewQueryAsText } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import EnsNumberValidationResult from './EnsNumberValidationResult.vue';

    export default {
        name: 'EnsNumberValidation',
        components: {
            EnsNumberValidationResult
        },
        mixins: [
            showMixin
        ],
        data() {
            return {
                formatType: null,
                categories: [],
                categoriesLoading: false,
                selectedCategory: null,
                facilities: [],
                facilitiesLoading: false,
                selectedFacility: null,
                ensNumber: '',
                validationLoading: false,
                validationResult: null
            };
        },
        computed: {
            categoryLibrary() {
                switch (this.formatType) {
                    case 'TagFormat':
                        return 'TagCategory';
                    case 'DocumentNumberingFormat':
                        return 'DocCategory';
                    default:
                        return null;
                }
            },
            validationEnabled() {
                return this.formatType
                    && this.selectedCategory
                    && this.selectedFacility
                    && this.ensNumber;
            }
        },
        watch: {
            async formatType() {
                await this.loadCategories();
            },
            async selectedFacility() {
                await this.loadCategories();
            }
        },
        mounted() {
            this.loadFacilities();
        },
        methods: {
            async loadCategories() {
                this.selectedCategory = null;
                this.categories = [];

                if (this.formatType && this.selectedFacility) {
                    this.categoriesLoading = true;

                    try {
                        if (this.formatType === 'TagFormat') {
                            this.categories = (await genericViewQueryAsText(this, `
                                SELECT name, description
                                FROM TagCategory
                                WHERE IsValid = true
                                ORDER BY description
                            `)).data;
                        } else {
                            this.categories = (await genericViewQueryAsText(this, `
                                SELECT name, description
                                FROM DocCategory
                                WHERE IsValid = true
                                  AND Scope = ${this.selectedFacility}
                                ORDER BY name
                            `)).data.map(x => ({
                                name: x.name,
                                description: `${x.name} - ${x.description}`
                            }));
                        }
                    } catch (ex) {
                        this.showError(ex);
                    }

                    this.categoriesLoading = false;
                }
            },
            async loadFacilities() {
                this.selectedFacility = null;
                this.facilities = [];
                this.facilitiesLoading = true;
                const query = `
                    SELECT name, description
                    FROM Facility
                    WHERE IsValid = true
                    ORDER BY name
                `;
                try {
                    this.facilities = (await genericViewQueryAsText(this, query)).data;
                } catch (ex) {
                    this.showError(ex);
                }
                this.facilitiesLoading = false;
            },
            async validateClick() {
                this.validationResult = null;
                this.validationLoading = true;
                try {
                    this.validationResult = await ensParse(this, this.formatType, this.selectedCategory, this.selectedFacility, this.ensNumber);
                } catch (ex) {
                    this.showError(ex);
                }
                this.validationLoading = false;
            }
        }
    };
</script>

<style scoped>

</style>
