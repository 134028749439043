<template>
    <div>
        <div
            v-if="ready">
            <code-set-table
                :interactive="!createMode"
                :code-set="codeSet"
                @rowcount="codeCount = $event"
                @export-cols="exportColumns = $event" />
        </div>
    </div>
</template>

<script>
    import CodeSetTable from './CodeSetTable.vue';
    import { getLibraryFromCodeSet } from '@/shared/helpers/api';

    export default {
        components: {
            CodeSetTable
        },
        data: function() {
            return {
                createMode: false,
                ready: false,
                exportColumns: [],
                codeCount: 0,
                codeSet: null
            };
        },
        computed: {
            cappedScopeList: function() {
                if (this.codeSet && this.codeSet.scopes.length)
                    return this.codeSet.scopes.map(s => s.name).join(' | ');
                else
                    return 'No scope linked to library set';
            }
        },
        mounted: async function() {
            const name = this.$route.params.codeSetName;
            this.codeSet = await getLibraryFromCodeSet(this, name);
            document.title = this.codeSet.description;
            this.ready = true;
        }
    };
</script>
