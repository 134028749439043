<template>
    <div>
        <div class="columns">
            <div class="column is-clearfix">
                <div class="field is-grouped is-pulled-right">
                    <p class="control">
                        <b-button
                            v-require-can-edit-code="{ libraryName: 'Facility' }"
                            class="is-primary"
                            @click="newFacility">
                            <b-icon
                                icon="pencil"
                                size="is-small" />
                            <span>Create new facility</span>
                        </b-button>
                    </p>
                </div>
            </div>
        </div>

        <div
            v-if="codeSetName && showNewDialog"
            id="editor">
            <h2 class="title">
                Create a new Facility:
            </h2>
            <code-edit
                :key="facilityCodeEditKey"
                :quick-commit="true"
                :show-cancel="true"
                :code-set-name="codeSetName"
                library="Facility"
                @refresh="refreshFacilities"
                @cancel="cancelNew" />
            <hr>
        </div>

        <b-table
            :data="facilityList.data"
            default-sort="Name"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            class="site-sticky-header">
            <b-table-column
                v-slot="props"
                field="Name"
                label="Name"
                sortable>
                <router-link :to="getFacilityLink(props.row)">
                    {{ props.row.Name }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="ParentFacility"
                label="Parent"
                sortable>
                {{ props.row.ParentFacility }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="Description"
                label="Description"
                sortable>
                {{ props.row.Description }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="StartupDate"
                label="Startup date"
                sortable>
                {{ $filters.dateFormatShort(props.row.StartupDate) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="SAPPlant"
                label="SAP Plant no."
                sortable>
                {{ props.row.SAPPlant }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="NPDID"
                label="NPD Id"
                sortable>
                {{ props.row.NPDID }}
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { encodeIdBase64 } from '@/shared/helpers/utils';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit,
            BoolElement
        },
        data: function() {
            return {
                facilityList: {},
                codeSetName: null,
                facilityCodeEditKey: 1,
                showNewDialog: false,
                facilityQuery: `
                    FROM Facility
                    SELECT Id, Name, Description, ParentFacility, SAPPlant, NPDID, StartupDate
                    WHERE IsValid=true`
            };
        },
        mounted: async function() {
            this.facilityList = await genericViewQueryAsText(this, this.facilityQuery);
            this.codeSetName = (await getCodeSets(this, 'Facility'))[0].name;
        },
        methods: {
            newFacility: function() {
                this.showNewDialog = true;
                this.facilityCodeEditKey += 1;
            },
            cancelNew: function() {
                this.showNewDialog = false;
            },
            refreshFacilities: async function() {
                this.facilityList = await genericViewQueryAsText(this, this.facilityQuery);
                this.showNewDialog = false;
            },
            getFacilityLink: function(f) {
                return { name: 'FacilityById', params: { id: encodeIdBase64('Code', f.Id) } };
            }
        }
    };
</script>
