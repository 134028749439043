<template>
    <div>
        <div v-if="project">
            <h1 class="subtitle">
                Project
            </h1>
            <h1 class="title">
                {{ project.name }}
            </h1>
            <router-link :to="{ name: 'ProjectList' }">
                &laquo; Back to list
            </router-link>
            <hr>
            <span
                id="codeEditor">
                <div
                    v-if="showEditor">
                    <code-edit
                        :key="editorKey"
                        :quick-commit="true"
                        :show-cancel="true"
                        :code-set-id="editCodeSetId"
                        :code-id="editCodeId"
                        :form-title="formTitle"
                        :library="editLibrary"
                        @refresh="onSave"
                        @cancel="cancelEditor" />
                    <hr>
                </div>
            </span>
            <div class="columns">
                <div class="column is-6">
                    <div class="panel">
                        <div class="panel-heading is-flex is-justify-content-space-between ">
                            <p>
                                Project attributes
                            </p>
                            <b-button
                                v-require-can-edit-code="{ libraryName: 'Project' }"
                                class="is-primary"
                                @click="editProject">
                                <b-icon
                                    icon="pencil"
                                    size="is-small" />
                                <span>
                                    Edit
                                </span>
                            </b-button>
                        </div>
                        <div class="panel-block">
                            <b-table
                                class="headless-table"
                                :data="attributes"
                                :striped="false"
                                :narrowed="true"
                                :hoverable="false">
                                <b-table-column
                                    v-slot="props"
                                    field="name"
                                    label="Name">
                                    <b>{{ props.row.name }}</b>
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="value"
                                    label="Value">
                                    {{ props.row.value }}
                                </b-table-column>
                            </b-table>
                        </div>
                    </div>
                </div>
                <div class="column is-6">
                    <nav
                        style="position:relative"
                        class="panel">
                        <p class="panel-heading">
                            Facility associations
                        </p>
                        <div class="panel-block">
                            <b-table
                                v-if="projectFacility"
                                class="headless-table"
                                :data="projectFacility"
                                :striped="false"
                                :narrowed="true"
                                :hoverable="false">
                                <b-table-column
                                    v-slot="props"
                                    field="facility"
                                    label="Facility">
                                    {{ props.row.facility }}
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="deliveryCode"
                                    label="DeliveryCode"
                                    style="text-align:right">
                                    Delivery code: {{ props.row.deliveryCode }}
                                </b-table-column>
                            </b-table>
                        </div>
                        <b-loading
                            v-model="projectFacilityLoading"
                            :is-full-page="false"
                            :can-cancel="false" />
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import {
        genericViewQueryAsText,
        getCodeById
    } from '@/shared/helpers/api';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { decodeIdBase64 } from '@/shared/helpers/utils';
    import _ from 'lodash';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit
        },
        props: {
            id: {
                default: null,
                type: String
            }
        },
        data: function() {
            return {
                showEditor: false,
                formTitle: null,
                editorKey: 1,
                editLibrary: null,
                editCodeSetId: null,
                editCodeId: null,
                projectFacility: null,
                projectFacilityLoading: false,
                project: null,
                loading: true
            };
        },
        computed: {
            attributes: function() {
                if (this.project && !this.loading) {
                    const attrs = _.sortBy(this.project.attributes, x => x.attributeDefinition.sequenceNumber).map(function(x) {
                        return { name: x.name, value: x.value };
                    });
                    attrs.unshift({ name: 'IsValid', value: this.project.isValid ? 'True' : 'False' });
                    attrs.unshift({ name: 'Description', value: this.project.description });
                    return attrs;
                }
                return [];
            }
        },
        watch: {
            project: async function(value) {
                if (value) {
                    this.projectFacilityLoading = true;
                    const tmp = await genericViewQueryAsText(
                        this,
                        `FROM ProjectFacility
                        SELECT facility, deliveryCode
                        WHERE IsValid = true AND Project = @name`,
                        [{ name: '@name', value: `"${value.name}"` }]
                    );
                    this.projectFacility = tmp.data;
                    this.projectFacilityLoading = false;
                }
            }
        },
        mounted: async function() {
            await this.fetchProjectCode();
            this.loading = false;
        },
        methods: {
            fetchProjectCode: async function() {
                const projectId = decodeURIComponent(this.$route.params.id);
                const decodedId = decodeIdBase64(projectId);
                this.project = await getCodeById(this, decodedId);
                this.project.id = projectId;
                this.mapProjectAttributes();
            },
            mapProjectAttributes: function() {
                this.project.attributes.map(attr => {
                    const thisAttrDef = this.project.codeSet.library.attributeDefinitions.find(attrDef => attrDef.name === attr.definitionName);
                    attr.name = attr.definitionName;
                    attr.value = attr.displayValue;
                    attr.type = thisAttrDef.attributeType.toUpperCase();
                    attr.attributeDefinition = {
                        'sequenceNumber': thisAttrDef.sequenceNumber
                    };
                });
            },
            editProject() {
                this.formTitle = null;
                this.editCodeSetId = null;
                this.editCodeId = this.project.id;
                this.editLibrary = 'Project';
                this.editorKey += 1;
                this.showEditor = true;
            },
            onSave: async function() {
                this.showEditor = false;
                if (this.editLibrary === 'Project') {
                    await this.fetchProjectCode();
                }
            },
            cancelEditor: function() {
                this.showEditor = false;
            }
        }
    };
</script>
