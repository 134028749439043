<template>
    <a
        v-if="!itemHasSubItems"
        class="navbar-item is-size-5 menu-item"
        :data-active="dataActive"
        :href="item.link"
        :target="item.target">
        <span>{{ item.text }}</span>
        <notification-count
            v-if="item.notifications"
            :count="item.notifications" />
    </a>
    <div
        v-else
        class="navbar-item has-dropdown is-size-5">
        <a
            ref="reference"
            class="navbar-link menu-item"
            :data-active="dataActive"
            tabindex="0">
            <span>{{ item.text }}</span>
            <notification-count
                v-if="item.notifications"
                :count="item.notifications" />
        </a>
        <top-sub-menu
            ref="menu"
            :items="item.subItems" />
    </div>
</template>

<script>
    import { isPrefixOfPath } from '@/shared/helpers/utils.js';
    import { flip, offset, shift } from '@floating-ui/vue';
    import TopSubMenu from './TopSubMenu.vue';
    import NotificationCount from './NotificationCount.vue';

    export default {
        name: 'TopMenuItem',
        components: { NotificationCount, TopSubMenu },
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            itemHasSubItems() {
                return this.item.subItems?.length > 0;
            },
            dataActive: function() {
                if (this.item.isDynamic) {
                    // Highlighting dynamic items may result in multiple active items
                    return false;
                }
                if (isPrefixOfPath(this.$route.path, this.item.link)) {
                    return true;
                }
                if (this.itemHasSubItems) {
                    // The item might be active, depending on the CSS selector below
                    return null;
                }
                return false;
            }
        },
        mounted() {
            if (this.item.subItems?.length > 0) {
                const reference = this.$refs.reference;
                const menu = this.$refs.menu;
                menu.makeFloating(reference, reference.parentElement, {
                    placement: 'bottom-start',
                    middleware: [offset({ mainAxis: -2, crossAxis: -3 }), flip(), shift()]
                });
            }
        }
    };
</script>
<style scoped>
.menu-item[data-active=true],
.menu-item:not([data-active=false]):has(+ * [data-active=true]) {
    color: #FF1234;
}
</style>
