<template>
    <div>
        <div class="columns">
            <div class="column is-2">
                <h1 class="title">
                    <b-icon
                        icon="truck"
                        size="" />
                    ChangeLog admin
                </h1>
            </div>
            <div class="column">
                <div
                    class="field is-grouped is-pulled-right"
                    style="margin-top:10px;">
                    <div class="control buttons has-addons">
                        <button
                            v-if="!selectedRelease"
                            class="button is-primary"
                            @click="newRelease">
                            <b-icon
                                icon="plus-circle-outline"
                                size="is-small" />
                            <span>Make new release</span>
                        </button>
                        <button
                            v-if="selectedRelease"
                            class="button is-primary"
                            :disabled="releaseNameConflict"
                            @click="save">
                            <b-icon
                                icon="floppy"
                                size="is-small" />
                            <span>Save</span>
                        </button>
                        <button
                            v-if="selectedRelease"
                            class="button"
                            @click="cancel">
                            <b-icon
                                icon="close-circle"
                                size="is-small" />
                            <span>Cancel</span>
                        </button>
                        <button
                            v-if="selectedRelease"
                            class="button"
                            @click="addItem">
                            <b-icon
                                icon="plus-circle-outline"
                                size="is-small" />
                            <span>Add item</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div
                class="column is-2">
                <div
                    v-for="r in releases"
                    :key="r.Id">
                    <a @click="selectRelease(r)">
                        <b>{{ r.Name }}</b></a>
                    <b-icon
                        v-if="!r.IsValid"
                        icon="skull"
                        type="is-danger"
                        size="is-small" />
                </div>
            </div>
            <div class="column">
                <template v-if="selectedRelease">
                    <b-field
                        label="Name"
                        grouped
                        horizontal>
                        <b-field
                            :type="releaseNameConflict ? 'is-danger' : ''"
                            :message="releaseNameConflict ? 'Name is not unique, add a suffix' : ''">
                            <b-input
                                v-model="selectedRelease.Name"
                                :disabled="selectedRelease.Identity" />
                        </b-field>
                        <b-field>
                            <b-checkbox
                                v-model="selectedRelease.IsValid"
                                :disabled="selectedRelease.IsNewRelease"
                                @update:model-value="releaseValidityHandler">
                                IsValid
                            </b-checkbox>
                        </b-field>
                    </b-field>
                    <b-field
                        label="Build"
                        horizontal>
                        <b-input v-model="selectedRelease.Build" />
                    </b-field>
                    <b-field
                        label="Summary"
                        horizontal>
                        <b-input
                            v-model="selectedRelease.ReleaseDescription"
                            type="textarea" />
                    </b-field>
                    <div
                        v-for="(item, index) in items"
                        :key="item.Name">
                        <hr>
                        <b-field
                            :label="'Change #' + item.Name"
                            grouped
                            horizontal>
                            <b-field>
                                <b-input
                                    v-model="item.Description" />
                            </b-field>

                            <b-field>
                                <b-checkbox
                                    v-model="item.IsValid"
                                    :disabled="item.IsNewItem">
                                    IsValid
                                </b-checkbox>
                            </b-field>
                        </b-field>
                        <b-field
                            label="Details"
                            horizontal>
                            <b-input
                                v-model="item.ChangeDescription"
                                rows="2"
                                type="textarea" />
                        </b-field>
                        <b-field
                            grouped
                            horizontal>
                            <b-field
                                label="PR nos."
                                horizontal>
                                <b-input
                                    v-model="item.PRNo"
                                    :pattern="commaSeparatedListPattern" />
                            </b-field>
                            <b-field
                                label="DevOps nos."
                                horizontal
                                expanded>
                                <b-input
                                    v-model="item.DevOpsNo"
                                    :pattern="commaSeparatedListPattern" />
                            </b-field>
                            <b-field v-if="item.IsNewItem">
                                <p
                                    class="control">
                                    <b-button
                                        label="Cancel item"
                                        type="is-danger is-light"
                                        icon-left="close-circle"
                                        size="is-small"
                                        @click="cancelItem(index)" />
                                </p>
                            </b-field>
                        </b-field>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { createChangeDocAndCommit, genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';
    import moment from 'moment';

    export default {
        mixins: [
            showMixin
        ],
        data: function() {
            return {
                releases: [],
                selectedRelease: null,
                items: [],
                codeSetNameRelease: null,
                codeSetNameItem: null,
                commaSeparatedListPattern: /\d+(, *\d+)*/.source
            };
        },
        computed: {
            releaseNameConflict() {
                if (this.selectedRelease && this.selectedRelease.IsNewRelease) {
                    return _.find(this.releases, ['Name', this.selectedRelease.Name]) !== undefined;
                }
                return false;
            }
        },
        async mounted() {
            this.codeSetNameRelease = (await getCodeSets(this, 'CommonLibraryChangeLogRelease'))[0].name;
            this.codeSetNameItem = (await getCodeSets(this, 'CommonLibraryChangeLogItem'))[0].name;
            await this.loadReleases();
        },
        methods: {
            async loadReleases() {
                let releaseRows = (await genericViewQueryAsText(
                    this,
                    'FROM CommonLibraryChangeLogRelease'
                )).data;

                releaseRows = _.sortBy(releaseRows, 'Name');
                _.reverse(releaseRows);
                this.releases = releaseRows;
            },
            async selectRelease(r) {
                // Make a copy to edit (not to mess up releases with unsaved changes)
                this.selectedRelease = JSON.parse(JSON.stringify(r));

                this.items = [];
                this.items = (await genericViewQueryAsText(
                    this,
                    'FROM CommonLibraryChangeLogItem WHERE Release = @release',
                    [{ name: '@release', value: r.Identity }]
                )).data;
            },
            async cancel() {
                this.items = [];
                this.selectedRelease = null;
            },
            newRelease() {
                const newRelease = {
                    Name: this.releaseNameSuggestion(),
                    Description: '',
                    Build: '',
                    IsValid: true,
                    ReleaseDescription: '',
                    IsNewRelease: true
                };
                this.selectedRelease = newRelease;
            },
            addItem() {
                const newItem = {
                    Name: '' + this.nextChangeItemNumber(),
                    Description: '',
                    ChangeDescription: '',
                    DevOpsNo: '',
                    PRNo: '',
                    IsValid: true,
                    Release: this.selectedRelease.Identity,
                    IsNewItem: true
                };
                this.items.push(newItem);
            },
            releaseNameSuggestion() {
                const datePart = moment().format('YYYY-MM-DD');
                // Here we could check if this name has already been used,
                // and add the apropriate suffix
                return datePart;
            },
            nextChangeItemNumber() {
                if (this.items.length === 0) {
                    return 1;
                }
                return Math.max(...this.items.map(i => parseInt(i.Name))) + 1;
            },
            cancelItem(itemIndex) {
                let nameNumber = parseInt(this.items[itemIndex].Name);

                this.items.splice(itemIndex, 1);

                /* Re-number everything after this item */
                for (let i = itemIndex; i < this.items.length; i++) {
                    this.items[i].Name = '' + nameNumber;
                    nameNumber++;
                }
            },
            releaseValidityHandler() {
                if (this.selectedRelease.IsValid === false
                    && this.items.length > 0
                    && _.find(this.items, 'IsValid')) {
                    this.$buefy.dialog.confirm({
                        title: 'Cascade?',
                        message: 'To completely hide the release IsValid must be false for all items as well. Should I cascade the change for you?',
                        type: 'is-warning',
                        hasIcon: true,
                        onConfirm: () => {
                            this.items.forEach(x => {
                                x.IsValid = false;
                            });
                        }
                    });
                }
            },
            async save() {
                try {
                    const releaseToCommit = this.removeNulls(this.selectedRelease);

                    await createChangeDocAndCommit(
                        this,
                        this.codeSetNameRelease,
                        [releaseToCommit]);

                    if (this.items.length > 0) {
                        const itemsToCommit = this.items.map(x => {
                            x = this.removeNulls(x);
                            x.Release = releaseToCommit.Name; // Name and Identity should be equal
                            return x;
                        });

                        await createChangeDocAndCommit(
                            this,
                            this.codeSetNameItem,
                            itemsToCommit);
                    }

                    // Reload state
                    this.cancel();
                    await this.loadReleases();
                } catch (err) {
                    this.showError(err);
                }
            },
            removeNulls(obj) {
                /* Remove properties that has null value, returning a new object */
                return Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== null));
            }
        }
    };
</script>
