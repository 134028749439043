<template>
    <div>
        <div
            v-if="loading"
            :class="containerClass">
            <video
                :width="width"
                :height="height"
                autoplay
                loop
                muted
                inline>
                <source
                    src="/images/equinor_spinner_short.mp4"
                    type="video/mp4">
            </video>
        </div>
        <div v-if="hideonly">
            <div v-show="!loading">
                <slot />
            </div>
        </div>
        <div v-if="!hideonly && !loading">
            <slot />
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            loading: {
                type: Boolean,
                required: true
            },
            fullscreen: {
                type: Boolean,
                required: false,
                default: true
            },
            hideonly: {
                type: Boolean,
                required: false,
                default: false
            },
            size: {
                type: String,
                required: false,
                default: 'medium'
            }
        },
        computed: {
            containerClass: function() {
                return this.fullscreen ? 'loading-container-full' : 'loading-container-part';
            },
            width() {
                return this.calculateSize(this.size);
            },
            height() {
                return this.calculateSize(this.size);
            }
        },
        methods: {
            calculateSize(size) {
                switch (size) {
                    case 'small':
                        return 50;
                    case 'medium':
                        return 150;
                    case 'large':
                        return 200;
                    case 'xxlarge':
                        return 500;
                    default:
                        return 150;
                }
            }
        }
    };
</script>

<style scoped>
.loading-container-full {
    text-align: center;
    margin-top: 40vh;
    height: 80vh;
    width: 100%;
}

.loading-container-part {
    text-align: center;
    height: 100%;
    width: 100%;
}
</style>
