<template>
    <span>
        <b-tooltip
            class="wrap-tooltip"
            :active="shouldTruncate"
            :label="value"
            position="is-bottom"
            multilined>
            <a
                v-if="isLink"
                :href="value">
                <b-icon
                    v-show="hasIcon"
                    :icon="iconName"
                    size="is-small" /> {{ $filters.truncate(value, truncateAt) }}</a>
            <span v-else>{{ $filters.truncate(value, truncateAt) }}</span>
        </b-tooltip>
    </span>
</template>

<script>
    export default {
        name: 'UriElement',
        props: {
            value: {
                type: String,
                default: null
            },
            hasIcon: {
                type: Boolean,
                default: false
            },
            truncateAt: {
                type: Number,
                default: 0
            }
        },
        data: function() {
            return {
            };
        },
        computed: {
            isLink: function() {
                // While more types could be rendered as links,
                // let's keep it simple
                return this.value.startsWith('http')
                    || this.value.startsWith('mailto')
                    || this.value.startsWith('ftp');
            },
            iconName: function() {
                if (this.value.startsWith('mailto')) {
                    return 'email-outline';
                }
                return 'link-variant';
            },
            shouldTruncate: function() {
                return this.truncateAt > 0
                    && this.value.length > this.truncateAt;
            }
        }
    };
</script>
