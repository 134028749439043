<template>
    <div>
        <div class="vl-container-header">
            <div
                v-for="(col,idx) in internalColumns"
                :key="idx"
                :style="getStyleItem(idx)"
                class="vl-header"
                @click="sortCol(idx)">
                <b>{{ col.label }}</b>
                <span
                    v-show="col.sortable"
                    class="vl-header-sort">
                    {{ sortIcon(idx) }}
                </span>
            </div>
        </div>
        <recycle-scroller
            page-mode
            class="scroller"
            :item-size="26"
            :items="internalRows"
            :buffer="200"
            :key-field="keyfield">
            <template #default="{ item ,index}">
                <div
                    class="vl-container"
                    :style="getOddOrEven(index)">
                    <div
                        v-for="(col,idx) in columns"
                        :key="idx"
                        class="vl-item"
                        :style="getStyleItem(idx)">
                        <template v-if="col.label === 'Name'">
                            <router-link :to="getProjectLink(item.id)">
                                {{ item.values[col.valueIdx] }}
                            </router-link>
                        </template>
                        <template v-else-if="col.type === 'BOOL'">
                            <bool-element :value="item.values[col.valueIdx]" />
                        </template>
                        <template v-else>
                            <span
                                class="keep-spaces"
                                v-text="item.values[col.valueIdx]" />
                        </template>
                    &nbsp;
                    </div>
                </div>
            </template>
        </recycle-scroller>
    </div>
</template>

<script>
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { comparing, flipComparer } from '@/shared/helpers/utils.js';
    import _ from 'lodash';
    import 'intersection-observer';
    import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
    import { RecycleScroller } from 'vue-virtual-scroller';

    export default {
        components: {
            RecycleScroller,
            BoolElement
        },
        props: {
            rows: {
                type: Array,
                required: true
            },
            columns: {
                type: Array,
                required: true
            },
            keyfield: {
                type: String,
                required: true
            }
        },
        data: function() {
            return {
                internalColumns: _.cloneDeep(this.columns),
                internalRows: _.cloneDeep(this.rows),
                sortDirection: 'asc',
                sortColumn: 0
            };
        },
        watch: {
            columns: function(c) {
                this.internalColumns = _.cloneDeep(c);
                this.reRender();
            },
            rows: function(r) {
                this.internalRows = _.cloneDeep(r);
                this.reRender();
                this.sort();
            }
        },
        mounted() {
            this.reRender();
        },
        methods: {
            reRender() {
                const self = this;
                if (!this.rows || !this.columns) return [];

                self.internalColumns.forEach(c => {
                    const cc = _.clone(c);

                    let length = 1;
                    if (c.type !== 'BUTTON' && c.type !== 'BOOL') {
                        length = _(self.rows)
                            .map(
                                r =>
                                    Math.min(
                                        r.values[c.valueIdx].toString().length,
                                        100
                                    ) / 2
                            )
                            .sum();
                    }
                    const count = _(self.rows)
                        .filter(r => r.values[c.valueIdx].toString().length)
                        .value().length;
                    const avgLength = Math.max(
                        Math.max(length, 1) / Math.max(count, 1),
                        3
                    );

                    c['avgLength'] = avgLength;
                    return cc;
                });

                const totalLength = _(self.internalColumns)
                    .map(v => v.avgLength)
                    .sum();

                self.internalColumns = _.map(self.internalColumns, col => {
                    let textAlign = 'left';
                    const sortable = col.sortable !== false;
                    switch (col.type) {
                        case 'STRING':
                            break;
                        case 'FLOAT':
                        case 'INT':
                            textAlign = 'right';
                            break;
                        case 'BOOL':
                        case 'BUTTON':
                            textAlign = 'center';
                            break;
                        default:
                            break;
                    }

                    const width = col.avgLength / totalLength * 100;
                    col['sortable'] = sortable;
                    col['item-style'] = {
                        'max-width': width + '%',
                        'flex-basis': width + '%',
                        'text-align': textAlign
                    };

                    return col;
                });
            },
            getStyleItem(idx) {
                const self = this;
                const style = _.get(self.internalColumns[idx], 'item-style', {
                    visibility: 'hidden'
                });
                return style;
            },
            getOddOrEven(index) {
                if (index % 2) return { 'background-color': '#fafafa' };
            },
            sortIcon(idx) {
                const self = this;
                let str = '↕';
                if (idx === self.sortColumn) {
                    switch (self.sortDirection) {
                        case 'asc':
                            str = '↓';
                            break;
                        case 'desc':
                            str = '↑';
                            break;
                    }
                }
                return str;
            },
            sortCol(idx) {
                const self = this;
                if (self.sortColumn === idx)
                    self.sortDirection
                        = self.sortDirection === 'asc' ? 'desc' : 'asc';

                self.sortColumn = idx;
                self.sort();
            },
            sort() {
                const column = this.internalColumns[this.sortColumn];

                let comparer = this.getComparer(column);
                if (this.sortDirection === 'desc') {
                    comparer = flipComparer(comparer);
                }
                this.internalRows = this.internalRows.toSorted(comparer);
            },
            getComparer(column) {
                switch (column.type.toUpperCase()) {
                    case 'INT':
                    case 'FLOAT':
                        return comparing(a => Number(a.values[column.valueIdx]));
                    case 'BOOL':
                        return comparing(a => a.values[column.valueIdx]);
                    case 'STRING':
                    default:
                        return comparing(a => a.values[column.valueIdx]);
                }
            },
            getProjectLink(id) {
                return { name: 'ProjectById', params: { id } };
            }
        }
    };
</script>

<style scoped>
.vl-container {
    width: 100%;
    height: 26px;
    display: inline-flex;
    flex-direction: row;
    border-bottom: 0.5px solid lightgray;
    align-items: center;
}

.vl-container-header {
    width: 100%;
    height: auto;
    min-height: 2em;
    display: inline-flex;
    flex-direction: row;
    border-bottom: 1.6px solid lightgray;
    position: relative;
}

.vl-item {
    border-right: 0.5px dotted silver;
    padding: 0 0.2em 0 0.2em;
    overflow: hidden;
    white-space: nowrap;
}

.vl-header {
    border-right: 0.5px dotted silver;
    padding: 0 0.2em 0 0.2em;
    overflow: hidden;
    cursor: pointer;
}

.vl-header-sort {
    font-family: calibri, helvetica !important;
    font-weight: bold;
    font-size: smaller;
}

.keep-spaces {
    white-space: pre-wrap;
}
</style>
