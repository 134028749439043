<template>
    <div>
        <h2 class="title is-3">
            Library groups configuration
        </h2>
        <div class="columns is-desktop has-margin-top2">
            <div class="column is-2">
                <div class="">
                    <spinner
                        v-if="loading"
                        :loading="loading" />
                    <div v-else>
                        <b-field>
                            <b-button
                                label="Clear Selected and Reload"
                                type="is-info"
                                icon-left="refresh"
                                :disabled="!selected"
                                @click="clearSelected" />
                        </b-field>
                        <b-table
                            v-model:selected="selected"
                            :data="tags"
                            default-sort="name"
                            :striped="true"
                            :narrowed="true"
                            :loading="loading"
                            :hoverable="true">
                            <b-table-column
                                v-slot="props"
                                field="name"
                                label="Name"
                                sortable>
                                <a @click="selectTag(props.row)">
                                    {{ props.row.name }}
                                </a>
                            </b-table-column>
                            <template #empty>
                                <section class="section">
                                    <div class="content has-text-grey has-text-centered">
                                        <p>
                                            <b-icon
                                                icon="emoticon-sad"
                                                size="is-large" />
                                        </p>
                                        <p>Nothing here.</p>
                                    </div>
                                </section>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
            <spinner
                :loading="loadingGroupInfo"
                :fullscreen="false"
                class="column is-5">
                <div
                    v-if="selected">
                    <div class="block">
                        <div class="columns">
                            <div class="column">
                                <h3 class="title is-4">
                                    Group info
                                </h3>
                            </div>
                            <div class="column is-clearfix">
                                <div class="field is-grouped is-pulled-right">
                                    <p class="control">
                                        <button
                                            v-require-can-edit-tag="selected.name"
                                            class="button is-primary"
                                            @click="save">
                                            <b-icon
                                                icon="floppy"
                                                size="is-small" />
                                            <span>Save</span>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block">
                        <b-field
                            label="Name"
                            horizontal>
                            <b-input v-model="selected.name" />
                        </b-field>
                        <b-field
                            label="Description"
                            horizontal>
                            <b-input
                                v-model="selected.description"
                                type="textarea" />
                        </b-field>
                        <b-field
                            label="Promotion level"
                            horizontal>
                            <b-select v-model="selected.promotionLevel">
                                <option value="0">
                                    Not promoted
                                </option>
                                <option value="1">
                                    Promoted
                                </option>
                                <option value="2">
                                    Important
                                </option>
                            </b-select>
                        </b-field>
                        <b-field
                            label="Image"
                            class="file is-primary"
                            :class="{'has-name': !!file}"
                            horizontal>
                            <b-input
                                v-model="selected.imageUrl" />
                            <p
                                v-require-can-edit-tag="selected.id"
                                class="control">
                                <b-upload
                                    v-model="file"
                                    class="file-label is-primary right-align">
                                    <span
                                        v-if="file"
                                        class="file-name right-hack">
                                        {{ file.name }}
                                    </span>
                                    <span class="file-cta left-hack">
                                        <b-icon
                                            class="file-icon"
                                            icon="upload" />
                                        <span class="file-label">Click to upload</span>
                                    </span>
                                </b-upload>
                            </p>
                        </b-field>
                        <b-field
                            horizontal>
                            <tag-attachment
                                v-if="selected"
                                :attachment="attachment"
                                :tag-name="selected.name"
                                @updateAttachment="getAttachment" />
                        </b-field>
                        <b-field>
                            <div class="flex-col">
                                <p class="title is-5">
                                    Preview of tile
                                </p>
                                <library-group-tile
                                    v-if="selected.promotionLevel > 0"
                                    :tag="selected" />
                                <p v-else>
                                    Tile will not be displayed when promotion level is set to <b>Not Promoted</b>
                                </p>
                            </div>
                        </b-field>
                    </div>
                </div>
            </spinner>
            <div
                v-if="selected"
                class="column is-5">
                <tag :tag="selected.name" />
            </div>
        </div>
    </div>
</template>

<script>
    import LibraryGroupTile from '@/shared/components/LibraryGroupTile.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireCanEditTag } from '@/shared/directives/requirePermission.js';
    import { getAllTagsAsEntities, getAttachmentForTag, updateTag, uploadImage } from '@/shared/helpers/api';
    import { compressImage, dataURItoBlob } from '@/shared/helpers/utils.js';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import Tag from './Tag.vue';
    import TagAttachment from './TagAttachment.vue';

    export default {
        directives: {
            'require-can-edit-tag': requireCanEditTag
        },
        components: {
            Spinner,
            Tag,
            LibraryGroupTile,
            TagAttachment
        },
        mixins: [
            showMixin
        ],
        data: function() {
            return {
                loading: false,
                loadingGroupInfo: false,
                tags: [],
                selected: null,
                file: null,
                compressedFileBlob: null,
                attachment: null
            };
        },
        watch: {
            file(newValue) {
                if (newValue) {
                    const reader = new FileReader();
                    const img = new Image();
                    reader.onload = event => {
                        img.src = event.target.result;
                        img.onload = () => {
                            const imgUri = compressImage(img);
                            this.selected.imageUrl = imgUri;
                            this.compressedFileBlob = dataURItoBlob(imgUri);
                        };
                    };
                    reader.readAsDataURL(this.file);
                }
            }
        },
        created: async function() {
            await this.fetchTagsFromApi();
        },
        methods: {
            async clearSelected() {
                this.selected = null;
                await this.fetchTagsFromApi();
            },
            selectTag(tag) {
                this.selected = JSON.parse(JSON.stringify(tag));
                this.getAttachment();
            },
            async save() {
                this.loadingGroupInfo = true;
                try {
                    if (this.file) {
                        const formData = new FormData();
                        const imgFile = new File([this.compressedFileBlob], `${this.file.name.split('.')[0]}.jpeg`);
                        formData.append('file', imgFile);
                        const imageResponse = await uploadImage(this, formData);
                        this.selected.imageUrl = imageResponse.data.url;
                        this.file = null;
                    }
                    this.selected.promotionLevel = parseInt(this.selected.promotionLevel);
                    await updateTag(this, this.selected);
                    this.showInfo('Changes saved');
                    await this.fetchTagsFromApi();
                } catch (e) {
                    this.showError('Could not save changes');
                } finally {
                    this.loadingGroupInfo = false;
                }
            },
            async fetchTagsFromApi() {
                this.loading = true;
                this.tags = await getAllTagsAsEntities(this);
                this.loading = false;
            },
            async getAttachment() {
                if (!this.selected) return;
                this.loadingGroupInfo = true;

                // getAttachmentForTag requests return an empty string ""
                // because the underlying getResData does not handle 204s
                // TODO: devOps logged #100077
                const apiResponse = await getAttachmentForTag(this, this.selected.name);
                if (typeof apiResponse === 'object') {
                    this.attachment = apiResponse;
                } else {
                    this.attachment = null;
                }
                this.loadingGroupInfo = false;
            }
        }
    };
</script>
<style scoped>
.right-align {
    justify-content: flex-end;
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.right-hack {
    border-width: 1px 1px;
    border-radius: 4px 0 0 4px !important;
}
.left-hack {
    border-radius: 0 4px 4px 0 !important;
}
</style>
