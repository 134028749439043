<template>
    <div class="mapping-container">
        <div class="line">
            <b-field class="file">
                <b-upload
                    v-model="file"
                    expanded>
                    <a class="button is-primary is-fullwidth">
                        <b-icon icon="upload" />
                        <span>{{ file ? file.name : "Click to upload" }}</span>
                    </a>
                </b-upload>
            </b-field>
            <b-field class="file">
                <b-button
                    :disabled="testButtonDisabled || hasManyToMany"
                    class=" is-danger"
                    @click="mapFile">
                    Run Test
                </b-button>
            </b-field>
        </div>

        <div
            v-if="hasManyToMany"
            style="color: red">
            Can not map with multiple source schemas and multiple target schemas.
        </div>
    </div>
</template>

<script>
    import { mapMessageWithMultipleSchemas } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import saveAs from 'file-saver';

    export default {
        mixins: [showMixin],
        props: {
            sourceList: {
                type: Array,
                require: false,
                default: null
            },
            targetList: {
                type: Array,
                require: false,
                default: null
            }
        },
        data: function() {
            return {
                file: null
            };
        },
        computed: {
            testButtonDisabled: function() {
                return this.file === null || !this.targetList || !this.sourceList || this.targetList.length < 1 || this.sourceList.length < 1;
            },
            hasManyToMany: function() {
                return this.targetList.length > 1 && this.sourceList.length > 1;
            }
        },
        methods: {
            mapFile: async function() {
                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('schemaLists', JSON.stringify({ 'sourceList': this.sourceList, 'targetList': this.targetList }));

                try {
                    const response = await mapMessageWithMultipleSchemas(this, formData);
                    const responseHeader = response.headers['content-type'];
                    const fileName = this.file.name.split('.')[0];

                    if (response.data && responseHeader === 'application/json') {
                        const blob = new Blob([JSON.stringify(response.data, null, 4)], { type: response.headers['content-type'] });
                        saveAs(blob, `Mapped${fileName}.json`);
                    } else if (response.data && responseHeader === 'text/xml') {
                        const blob = new Blob([response.data], { type: response.headers['content-type'] });
                        saveAs(blob, `Mapped${fileName}.xml`);
                    }
                } catch (error) {
                    this.showError(error);
                }
            }
        }
    };
</script>

<style scoped>
.mapping-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
}
.line{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
}
</style>
