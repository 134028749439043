<template>
    <section class="block">
        <h2 class="title is-4">
            Preview
        </h2>
        <p
            v-if="receiver"
            class="block">
            To be published to {{ receiver }}:
        </p>
        <b-table
            :data="isEmpty ? [] : data.libraries"
            class="mx-auto"
            style="max-width: 900px"
            :loading="isLoading">
            <b-table-column
                v-slot="props"
                field="library"
                label="Library">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                field="library"
                numeric
                label="Count">
                {{ props.row.count }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                field="facilities"
                label="Facilities">
                <b-field
                    grouped
                    group-multiline>
                    <div
                        v-for="facility in props.row.facilities"
                        :key="facility.name"
                        class="control">
                        <b-taglist attached>
                            <b-tag type="is-dark">
                                {{ facility.name }}
                            </b-tag>
                            <b-tag type="is-success">
                                {{ facility.count }}
                            </b-tag>
                        </b-taglist>
                    </div>
                </b-field>
            </b-table-column>

            <b-table-column
                v-if="receiver=== 'TIE'"
                v-slot="props"
                label="Single object TIE Messages">
                <b-checkbox
                    title="Sends single object TIE message, if enabled."
                    type="is-info"
                    :model-value="isSingleMessageEnabled(props.row.name)"
                    @update:model-value="onToggleSingleMessageEnabled(props.row.name, $event)" />
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">
                    Nothing to publish
                </div>
            </template>
        </b-table>
    </section>
</template>

<script>
    export default {
        props: {
            data: {
                type: Object,
                default: () => ({})
            },
            receiver: {
                type: String,
                default: () => null
            },
            isLoading: {
                type: Boolean,
                default: false
            },
            singleMsgMetadataAllLibraries: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            isEmpty() {
                return !(Object.keys(this.data).length > 0 && this.data.libraries.length > 0);
            }
        },
        methods: {
            onToggleSingleMessageEnabled: function(libname, value) {
                this.$emit('toggleIsSingleMessageEnabled', libname, value);
            },
            isSingleMessageEnabled: function(libname) {
                return this.singleMsgMetadataAllLibraries[libname] ?? false;
            }

        }
    };
</script>

<style scoped>
</style>
