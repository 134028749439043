<template>
    <div>
        <spinner
            :loading="loading"
            size="small"
            :fullscreen="false">
            <table
                v-if="!loading"
                class="table is-striped is-fullwidth">
                <tbody>
                    <template
                        v-for="data in tagMetadataAndAttachments"
                        :key="data.tag + data.name">
                        <tr>
                            <td>
                                <span
                                    v-if="!data.attachment"
                                    class="tag is-info">{{ data.tag }}</span>
                                <span
                                    v-else
                                    :class="data.type === 'Library' ? 'tag is-library' : 'tag is-group'">{{ data.tag }}</span>
                            </td>
                            <td class="has-text-weight-bold">
                                <span v-if="!data.attachment">{{ data.name }}</span>
                                <span v-else>
                                    <a>
                                        <b-tooltip
                                            label="Download attachment">
                                            <b-icon
                                                icon="paperclip"
                                                size="is-small"
                                                @click="downloadAttachment(data.attachment)" />
                                        </b-tooltip>
                                    </a>
                                    Documentation
                                </span>
                            </td>
                            <td>
                                <vue-markdown
                                    v-if="!data.attachment"
                                    class="markdown"
                                    :source="data.value" />
                                <span v-else>{{ getAttachmentDescription(data) }}</span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </spinner>
        <template v-if="!hasData && !loading">
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p>No library information defined for this library</p>
                </div>
            </section>
        </template>
    </div>
</template>

<script>
    import saveAs from 'file-saver';
    import VueMarkdown from '@/shared/components/VueMarkdown.js';
    import { getAttachment, getTagMetadataForLibrary } from '@/shared/helpers/api.ts';
    import Spinner from '@/shared/components/Spinner.vue';

    export default {
        components: {
            VueMarkdown,
            Spinner
        },
        props: {
            libraryName: {
                type: String,
                required: true
            },
            libraryAttachment: {
                type: Object,
                default: null
            }
        },
        data: function() {
            return {
                loading: true,
                tagMetadataAndAttachments: [],
                anchorAttributes: {
                    target: '_blank',
                    rel: 'nofollow'
                }
            };
        },
        computed: {
            hasData() {
                return this.tagMetadataAndAttachments?.length > 0;
            }
        },
        mounted: async function() {
            this.tagMetadataAndAttachments = await this.getTagMetadataAndAttachments();
            this.open = this.hasData;
            this.loading = false;
        },
        methods: {
            async downloadAttachment(attachment) {
                try {
                    const resp = await getAttachment(this, attachment.key);
                    saveAs(resp, attachment.fileName);
                } catch (error) {
                    this.showError('Could not download attachment');
                }
            },
            getAttachmentDescription(attachment) {
                const sentence = ' has more in-depth documentation. Click binder for download of the file for further reading.';
                if (attachment.type === 'Library')
                    return attachment.tag + sentence;
                else
                    return `The ${attachment.tag} group` + sentence;
            },
            async getTagMetadataAndAttachments() {
                const result = await getTagMetadataForLibrary(this, this.libraryName);
                const tagMetadata = this.getTagAttachments(result.attachments).concat(result.tagMetadata).sort((a, b) => a.tag.localeCompare(b.tag));

                if (this.libraryAttachment) {
                    tagMetadata.unshift({
                        tag: this.libraryName,
                        attachment: this.libraryAttachment,
                        type: 'Library'
                    });
                }
                return tagMetadata;
            },
            getTagAttachments(attachments) {
                const result = [];
                if (attachments) {
                    for (const attachment of attachments.filter(t => t.attachment)) {
                        result.push({
                            tag: attachment.tagName,
                            attachment: attachment.attachment,
                            type: 'Tag'
                        });
                    }
                }
                return result;
            }
        }
    };
</script>

<style>
    div.markdown > ul {
        list-style-type: disc;
    }

    .is-library {
      background-color: #92d050 !important;
      color: #FFFFFF !important;
    }

    .is-group {
      background-color: #ed7d31 !important;
      color: #FFFFFF !important;
    }
</style>
