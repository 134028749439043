<template>
    <div>
        <div v-if="!loading">
            <div class="columns">
                <div class="column">
                    <section>
                        <create-query
                            :operands="columns"
                            :column-definitions="columnsDef"
                            :search-elements="rows.map(r => r.codeItems)"
                            @query-filter="addQuery"
                            @regex-filter="addRegex" />
                    </section>

                    <div class="filters">
                        <b-taglist v-if="queries.length > 0 || regex.length >0">
                            <b-tag
                                v-for="q in queriesDisplay"
                                :key="q"
                                type="is-light"
                                size="is-medium"
                                closable
                                @close="removeQuery(q)">
                                {{ q }}
                            </b-tag>
                            <b-tag
                                v-for="r in regex"
                                :key="r"
                                type="is-light"
                                size="is-medium"
                                closable
                                @close="removeRegex(r)">
                                {{ r }}
                            </b-tag>
                        </b-taglist>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CreateQuery from './CreateQuery.vue';

    export default {
        components: {
            CreateQuery
        },
        props: {
            rows: {
                type: Array,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            },
            columnsDef: {
                type: Array,
                required: true
            }
        },
        emits: [
            'queries'
        ],
        data: function() {
            return {
            };
        },
        url: {
            queriesParam: {
                param: 'queries',
                noHistory: true
            },
            regexParam: {
                param: 'regex',
                noHistory: true
            }
        },
        computed: {
            queriesDisplay: function() {
                return this.queries.map(q => q.join(' '));
            },
            queries() {
                return this.$store.state.codeTableModule.queries;
            },
            regex() {
                return this.$store.state.codeTableModule.regex;
            },
            filters() {
                return this.$store.state.codeTableModule.filters;
            },
            queriesParam: {
                get() {
                    return this.$store.state.codeTableModule.queries;
                },
                set(value) {
                    this.$store.dispatch('ADD_QUERIES', value);
                }
            },
            regexParam: {
                get() {
                    return this.$store.state.codeTableModule.regex;
                },
                set(value) {
                    this.$store.dispatch('ADD_REGEXES', value);
                }
            },
            columns() {
                return this.$store.state.codeTableModule.columns;
            },
            libraryName() {
                return this.$store.state.codeTableModule.libraryName;
            }
        },
        watch: {
            regex: function() {
                this.regexParam = this.regex;
            },
            libraryName() {
                if (this.libraryName.length === 0) return;
                const filtersLocalStorageKey = `Library.${this.libraryName}.filters`;
                if (this.regexParam && this.regexParam.length > 0 || this.queriesParam && this.queriesParam.length > 0) {
                    const urlFilters = { 'queries': this.queriesParam, 'regEx': this.regexParam };
                    localStorage.setItem(filtersLocalStorageKey, JSON.stringify(urlFilters));
                }
                const persistedFilters = JSON.parse(localStorage.getItem(filtersLocalStorageKey));
                if (persistedFilters) {
                    if (persistedFilters.queries.length)
                        this.$store.dispatch('ADD_QUERIES', persistedFilters.queries);
                    if (persistedFilters.regEx.length)
                        this.$store.dispatch('ADD_REGEXES', persistedFilters.regEx);
                } else {
                    this.$store.dispatch('ADD_QUERY', ['isValid', '=', 'true']);
                }
            }
        },
        unmounted() {
            this.$store.dispatch('RESET_FILTERS');
        },
        mounted() {
            this.$watch('filters', this.filterWatcher);
        },
        methods: {
            addQuery(queryFrags) {
                const self = this;
                self.$store.dispatch('ADD_QUERY', queryFrags);
            },
            removeQuery(query) {
                const self = this;
                const matches = query.match(/^(\w+)\s+(like|=|!=|<|>|is(?:\s*not)?)\s+(.*)$/);
                /*
                 * ^ start of string
                 * (\w+) any word (captured in output)
                 * \s+ any whitespace
                 * (like|=|!=|<|>|is(?:\s*not)?) either like, =, !=, <, >, is or is not (captured in output)
                 * \s+ any whitespace
                 * (.*)$ rest of string (captured in output)
                 */
                matches.shift(); // discard first group which is the entire matched string

                self.$store.dispatch('REMOVE_QUERY', matches);
            },
            addRegex(regex) {
                const self = this;
                self.$store.dispatch('ADD_REGEX', regex);
            },
            removeRegex(r) {
                const self = this;
                self.$store.dispatch('REMOVE_REGEX', r);
            },
            filterWatcher() {
                if (this.libraryName.length === 0) return;
                const filters = {
                    queries: this.queries,
                    regEx: this.regex
                };
                localStorage.setItem(`Library.${this.libraryName}.filters`, JSON.stringify(filters));
                this.$emit('queries', filters);
            }
        }
    };
</script>

<style scoped>
.filters {
    padding-top: 5px;
    padding-bottom: 20px;
}
</style>
