<template>
    <div
        v-require-is-administrator>
        <h1 class="title is-1 section-header">
            Administrator commands
        </h1>
        <b-notification
            type="is-warning is-light"
            aria-close-label="Close notification"
            role="alert">
            The commands available on this page are <b>dangerous</b>.
            Must only be used by administrators that know what they're doing! :D
        </b-notification>

        <!-- COMMAND BUTTONS -->
        <section v-show="!selectedCommand">
            <div class="buttons">
                <b-button
                    size="is-large"
                    type="is-primary"
                    :disabled="selectedCommand"
                    icon-left="auto-fix"
                    @click="selectedCommand = 'create-codesets'">
                    Create code sets for STID library
                </b-button>
                <b-button
                    size="is-large"
                    type="is-primary"
                    :disabled="selectedCommand"
                    icon-left="auto-fix"
                    @click="selectedCommand = 'create-facilitysets'">
                    Create STID code sets for facility
                </b-button>
                <b-button
                    size="is-large"
                    type="is-primary"
                    :disabled="selectedCommand"
                    icon-left="auto-fix"
                    @click="selectedCommand = 'create-datamagnet'">
                    Create datamagnet for library
                </b-button>
                <b-button
                    size="is-large"
                    type="is-primary"
                    :disabled="selectedCommand"
                    icon-left="auto-fix"
                    @click="selectedCommand = 'set-release-ready-library'">
                    Set library release ready
                </b-button>
                <b-button
                    size="is-large"
                    type="is-primary"
                    :disabled="selectedCommand"
                    icon-left="auto-fix"
                    @click="selectedCommand = 'edit-library-name'">
                    Edit library name
                </b-button>
                <b-button
                    size="is-large"
                    type="is-primary"
                    :disabled="selectedCommand"
                    icon-left="auto-fix"
                    @click="selectedCommand = 'edit-attribute-name'">
                    Edit attribute name
                </b-button>
            </div>
            <div class="buttons">
                <b-button
                    size="is-large"
                    type="is-danger"
                    :disabled="selectedCommand"
                    icon-left="delete"
                    @click="selectedCommand = 'delete-library'">
                    Delete library
                </b-button>
                <b-button
                    size="is-large"
                    type="is-danger"
                    :disabled="selectedCommand"
                    icon-left="delete"
                    @click="selectedCommand = 'delete-codesets'">
                    Delete all code sets for library
                </b-button>
                <b-button
                    size="is-large"
                    type="is-danger"
                    :disabled="selectedCommand"
                    icon-left="delete"
                    @click="selectedCommand = 'delete-facility-codesets'">
                    Remove facility from all code sets
                </b-button>
                <b-button
                    size="is-large"
                    type="is-danger"
                    :disabled="selectedCommand"
                    icon-left="delete"
                    @click="selectedCommand = 'delete-library-codes'">
                    Delete library codes
                </b-button>
                <b-button
                    size="is-large"
                    type="is-danger"
                    :disabled="selectedCommand"
                    icon-left="delete"
                    @click="selectedCommand = 'delete-codeset-codes'">
                    Delete codes from a set
                </b-button>
                <b-button
                    size="is-large"
                    type="is-danger"
                    :disabled="selectedCommand"
                    icon-left="delete"
                    @click="selectedCommand = 'delete-invalid-codeset-codes'">
                    Delete invalid codes from a set
                </b-button>
            </div>
        </section>

        <!-- COMMAND FORMS -->
        <div class="columns">
            <div class="column is-half">
                <create-code-sets-for-stid-library
                    :show="selectedCommand === 'create-codesets'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <create-code-sets-for-stid-facility
                    :show="selectedCommand === 'create-facilitysets'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <delete-library
                    :show="selectedCommand === 'delete-library'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <delete-library-codes
                    :show="selectedCommand === 'delete-library-codes'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <delete-invalid-code-set-codes
                    :show="selectedCommand === 'delete-invalid-codeset-codes'"
                    :enabled="!commandRunning"
                    :success="commandResult?.success"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <delete-code-set-codes
                    :show="selectedCommand === 'delete-codeset-codes'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <delete-code-sets-for-library
                    :show="selectedCommand === 'delete-codesets'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <delete-facility-from-code-sets
                    :show="selectedCommand === 'delete-facility-codesets'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <create-datamagnet
                    :show="selectedCommand === 'create-datamagnet'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <set-is-release-ready-for-library
                    :show="selectedCommand === 'set-release-ready-library'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <edit-library-name
                    :show="selectedCommand === 'edit-library-name'"
                    :enabled="!commandRunning"
                    @submit="run"
                    @cancel="cancelCommand()" />
                <edit-attribute-name
                    :show="selectedCommand === 'edit-attribute-name'"
                    :enabled="!commandRunning"
                    command="edit-attribute-name"
                    @submit="run"
                    @cancel="cancelCommand()" />
            </div>

            <!-- COMMAND RESULT -->
            <div class="column is-half">
                <spinner :loading="commandRunning">
                    <div v-if="commandResult">
                        <b-notification
                            v-if="commandResult.success"
                            type="is-success"
                            has-icon>
                            Command ran successfully!
                        </b-notification>
                        <b-notification
                            v-else
                            type="is-danger"
                            has-icon
                            role="alert">
                            Command failed!
                        </b-notification>

                        <pre>{{ commandResult.output }}</pre>
                    </div>
                </spinner>
            </div>
        </div>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import { runAdminCommand } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import CreateCodeSetsForStidFacility from './AdminCommands/CreateCodeSetsForStidFacility.vue';
    import CreateCodeSetsForStidLibrary from './AdminCommands/CreateCodeSetsForStidLibrary.vue';
    import CreateDatamagnet from './AdminCommands/CreateDatamagnet.vue';
    import DeleteCodeSetCodes from './AdminCommands/DeleteCodeSetCodes.vue';
    import DeleteCodeSetsForLibrary from './AdminCommands/DeleteCodeSetsForLibrary.vue';
    import DeleteFacilityFromCodeSets from './AdminCommands/DeleteFacilityFromCodeSets.vue';
    import DeleteInvalidCodeSetCodes from './AdminCommands/DeleteInvalidCodeSetCodes.vue';
    import DeleteLibrary from './AdminCommands/DeleteLibrary.vue';
    import DeleteLibraryCodes from './AdminCommands/DeleteLibraryCodes.vue';
    import EditAttributeName from './AdminCommands/EditAttributeName.vue';
    import EditLibraryName from './AdminCommands/EditLibraryName.vue';
    import SetIsReleaseReadyForLibrary from './AdminCommands/SetIsReleaseReadyForLibrary.vue';

    export default {
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        components: {
            EditAttributeName,
            Spinner,
            CreateCodeSetsForStidFacility,
            CreateCodeSetsForStidLibrary,
            CreateDatamagnet,
            DeleteCodeSetCodes,
            DeleteInvalidCodeSetCodes,
            DeleteCodeSetsForLibrary,
            DeleteFacilityFromCodeSets,
            DeleteLibrary,
            DeleteLibraryCodes,
            SetIsReleaseReadyForLibrary,
            EditLibraryName
        },
        mixins: [showMixin],
        data() {
            return {
                selectedCommand: undefined,
                commandResult: null,
                commandRunning: false
            };
        },
        methods: {
            async run(args) {
                this.commandRunning = true;
                try {
                    this.commandResult = (await runAdminCommand(this, args)).data;
                } catch (ex) {
                    this.showError(ex);
                }
                this.commandRunning = false;
            },
            cancelCommand() {
                this.commandResult = null;
                this.selectedCommand = undefined;
            }
        }
    };
</script>

<style>
</style>
