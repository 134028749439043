<template>
    <div>
        <div
            id="container-header"
            class="vl-container-header">
            <div
                v-for="(col,idx) in internalColumns"
                :key="idx"
                :style="getStyleItem(idx)"
                class="vl-header"
                @click.ctrl.exact="expandCol(idx)"
                @click.ctrl.alt.exact="contractCol(idx)">
                <div class="left-container">
                    <b class="item">{{ col.label === 'Name' ? getNameLabel(nameAlias) : col.label }}</b>
                    <span
                        v-show="col.sortable"
                        class="vl-header-sort tag header-action"
                        @click="sortCol(idx)">
                        {{ sortIcon(idx) }}
                    </span>
                </div>
                <div class="resize-container">
                    <span
                        class="resize-drag"
                        @mousedown="startResize($event, idx)" />
                </div>
            </div>
        </div>

        <recycle-scroller
            class="scroller"
            :items="rowsWithHeight"
            :buffer="400"
            :key-field="keyfield"
            page-mode>
            <template #default="{ item, index }">
                <div
                    class="vl-container"
                    :class="{'svg-container': hasSvgColumn}"
                    :style="getOddOrEven(index)"
                    @mouseover="cellTooltipEventListener">
                    <div
                        v-for="(col,idx) in columns"
                        :key="idx"
                        :style="getStyleItem(idx)"
                        class="vl-item">
                        <template v-if="col.field === 'name'">
                            <div class="name-column">
                                <a
                                    :href="getCodeLinkByB64Id(item.id)">
                                    {{ item.codeItems[col.valueIdx].value }}
                                </a>
                                <a
                                    class="attachment-button">
                                    <b-tooltip
                                        v-if="item.codeItems[6].value"
                                        style="position:fixed;"
                                        label="Download attachment"
                                        position="is-right"
                                        append-to-body>
                                        <b-icon
                                            icon="paperclip"
                                            size="is-small"
                                            @click="getAttachment(item.codeItems[6].value)" />
                                    </b-tooltip>
                                </a>
                            </div>
                        </template>
                        <template v-else-if="col.type.toUpperCase() === 'BOOL'">
                            <bool-element :value="item.codeItems[col.valueIdx].value" />
                        </template>
                        <template v-else-if="col.type.toUpperCase() === 'CODEREF'">
                            <a
                                v-if="item.codeItems[col.valueIdx].value !== ''"
                                :href="getCodeLinkByB64Id(item.codeItems[col.valueIdx].codeId)">
                                {{ displayRefCode(item, col.valueIdx, col) }}
                            </a>
                        </template>
                        <template v-else-if="col.type.toUpperCase() === 'LIBRARYREF'">
                            <router-link
                                v-if="item.codeItems[col.valueIdx].value !== ''"
                                :to="getLibraryLink(item.codeItems[col.valueIdx].value)">
                                {{ item.codeItems[col.valueIdx].value }}
                            </router-link>
                        </template>
                        <template v-else-if="col.type.toUpperCase() === 'URI'">
                            <uri-element :value="item.codeItems[col.valueIdx].value" />
                        </template>
                        <template v-else-if="col.field === 'attachmentKey'">
                            <a>
                                <b-tooltip
                                    v-if="item.codeItems[6].value"
                                    label="Download attachment"
                                    position="is-right"
                                    @click="getAttachment(item.codeItems[col.valueIdx].value)">
                                    <b-icon
                                        icon="paperclip"
                                        size="is-small" />
                                    {{ item.codeItems[col.valueIdx].value }}
                                </b-tooltip>
                            </a>
                        </template>
                        <template v-else-if="col.type === 'Svg'">
                            <img
                                v-if="item.codeItems[col.valueIdx].value"
                                :src="getImageDataURL(item.codeItems[col.valueIdx].value, maxSvgHeight, maxSvgWidth)">
                        </template>
                        <template v-else>
                            <span
                                class="keep-spaces"
                                v-text="item.codeItems[col.valueIdx].value" />
                        </template>
                        &nbsp;
                    </div>
                </div>
            </template>
        </recycle-scroller>
    </div>
</template>

<script>

    import saveAs from 'file-saver';
    import 'intersection-observer';
    import { RecycleScroller } from 'vue-virtual-scroller';
    import _ from 'lodash';
    import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { getCodeLinkByB64Id, getLibraryLink } from '@/shared/helpers/routing';
    import { getAttachmentName, getAttachment } from '@/shared/helpers/api';
    import { getNameLabel } from '@/shared/helpers/nameWithAliasHelper.js';
    import UriElement from '@/shared/components/UriElement.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { getImageDataURL } from '@/shared/helpers/svgHelpers';

    export default {
        components: {
            BoolElement,
            RecycleScroller,
            UriElement
        },
        mixins: [
            showMixin
        ],
        props: {
            rows: {
                type: Array,
                required: true
            },
            columns: {
                type: Array,
                required: true
            },
            keyfield: {
                type: String,
                required: true
            },
            nameAlias: {
                type: String,
                required: false,
                default: ''
            }
        },
        emits: [
            'sort'
        ],
        data: function() {
            return {
                internalColumns: [],
                sortDirection: 'asc',
                sortByColumnIndex: 0,
                draging: false,
                startX: 0,
                resizeIdx: 0,
                maxSvgHeight: 100,
                maxSvgWidth: 200
            };
        },
        computed: {
            tableWidth: function() {
                return document.getElementById('container-header').clientWidth;
            },
            libraryName: function() {
                return this.$store.state.codeTableModule.libraryName;
            },
            hasSvgColumn() {
                return this.columns.some(c => c.type === 'Svg');
            },
            rowsWithHeight() {
                return this.addHeightToRows();
            },
            sortColumnKey() {
                return `Library.${this.libraryName}.SortColumn`;
            },
            sortDirectionKey() {
                return `Library.${this.libraryName}.SortDirection`;
            }
        },
        event: 'sort',
        watch: {
            columns: function(newVal) {
                this.internalColumns = _(newVal).cloneDeep();
                const sortColName = localStorage.getItem(this.sortColumnKey);
                const sortColIndex = this.internalColumns.findIndex(_ => _.field === sortColName);
                this.sortByColumnIndex = sortColIndex === -1 ? 0 : sortColIndex;
                this.sortDirection = localStorage.getItem(this.sortDirectionKey) ?? 'asc';
                this.$emit('sort', sortColName, this.sortDirection);
                this.reRender();
            },
            rows: function() {
                this.reRender();
            }
        },
        mounted() {
            window.addEventListener('mouseup', this.stopDrag);
            window.addEventListener('mousemove', this.doResize);
        },
        methods: {
            async getAttachment(key) {
                try {
                    const [resp, fileName] = await Promise.all([getAttachment(this, key), getAttachmentName(this, key)]);
                    saveAs(resp, fileName);
                } catch (error) {
                    this.showError('Could not download attachment');
                }
            },
            displayRefCodeAsIdentity(item) {
                return item.value;
            },
            displayRefCodeAsName(item) {
                return item.name;
            },
            displayRefCodeAsDescription(item) {
                if (!item.description || /^\s*$/.test(item.description))
                    return '???';
                return item.description;
            },
            displayRefCodeAsNameAndDescription(item) {
                if (!item.description || /^\s*$/.test(item.description))
                    return this.displayRefCodeAsName(item);
                return `${item.name} - ${item.description}`;
            },
            displayRefCode(item, idx, col) {
                if (!item.codeItems[idx].codeId)
                    return item.codeItems[idx].value;

                switch (col.referenceDisplayMode) {
                    case 0: case 'IDENTITY': return this.displayRefCodeAsIdentity(item.codeItems[idx]);
                    case 1: case 'NAME': return this.displayRefCodeAsName(item.codeItems[idx]);
                    case 2: case 'DESCRIPTION': return this.displayRefCodeAsDescription(item.codeItems[idx]);
                    case 3: case 'NAMEANDDESCRIPTION': return this.displayRefCodeAsNameAndDescription(item.codeItems[idx]);
                }
            },
            dragRender(colIdx, diff) {
                this.internalColumns = this.internalColumns.map((col, i) => {
                    if (i === colIdx) {
                        let textAlign = 'left';
                        switch (col.type.toUpperCase()) {
                            case 'STRING':
                                break;
                            case 'FLOAT':
                            case 'INT':
                                textAlign = 'right';
                                break;
                            case 'BOOL':
                                textAlign = 'center';
                                break;
                            default:
                                break;
                        }
                        const prevWidth = parseInt(col['item-style'].width.replace('px', ''));
                        const width = Math.max(prevWidth + diff, 50);
                        col['item-style'] = { 'width': width + 'px', 'text-align': textAlign };
                        window.localStorage.setItem(`Library.${this.libraryName}.Column.${col.field}`, width);
                        return col;
                    }
                    return col;
                });
            },
            reRender() {
                const self = this;

                if (this.rows === null || this.rows.length === 0 || (this.columns === null || this.columns.length === 0)) {
                    return [];
                }

                _.forEach(self.internalColumns, (c) => {
                    const length = _(self.rows).map(r => Math.min(r.codeItems[c.valueIdx].value.length, 100) / 2).sum();
                    const count = _(self.rows).filter(r => r.codeItems[c.valueIdx].value.length).value().length;
                    const avgLength = Math.max(Math.max(length, 1) / Math.max(count, 1), 3);

                    c['avgLength'] = avgLength;
                });

                const totalLength = _(self.internalColumns).map(v => v.avgLength).sum();

                this.internalColumns = _(self.internalColumns).map(col => {
                    let textAlign = 'left';
                    const sortable = true;
                    switch (col.type.toUpperCase()) {
                        case 'STRING':
                            break;
                        case 'FLOAT':
                        case 'INT':
                            textAlign = 'right';
                            break;
                        case 'BOOL':
                            textAlign = 'center';
                            break;
                        default:
                            break;
                    }

                    const widthPercentage = col.avgLength / totalLength * 100;
                    const widthPixels = widthPercentage / 100 * this.tableWidth;
                    col['sortable'] = sortable;
                    col['item-style'] = { 'width': widthPixels + 'px', 'text-align': textAlign };
                    const widthFromLocalStorage = window.localStorage.getItem(`Library.${this.libraryName}.Column.${col.field}`);
                    if (widthFromLocalStorage) {
                        col['item-style'] = { 'width': widthFromLocalStorage + 'px', 'text-align': textAlign };
                    }
                    return col;
                }).value();
            },
            getStyleItem(idx) {
                const self = this;
                const style = _.get(self.internalColumns[idx], 'item-style', { 'visibility': 'hidden' });
                return style;
            },
            getOddOrEven(index) {
                if (index % 2)
                    return { 'background-color': '#fafafa' };
            },
            cellTooltipEventListener(e) {
                // Fetching tooltip dynamically when needed.
                e.target.title = e.target.innerText;
            },
            sortIcon(idx) {
                const self = this;
                let str = '↕';
                if (idx === self.sortByColumnIndex) {
                    switch (self.sortDirection) {
                        case 'asc':
                            str = '↓';
                            break;
                        case 'desc':
                            str = '↑';
                            break;
                    }
                }
                return str;
            },
            sortCol(idx) {
                const self = this;
                if (self.sortByColumnIndex === idx)
                    self.sortDirection = self.sortDirection === 'asc' ? 'desc' : 'asc';

                const sortColumnName = self.internalColumns[idx].field;
                localStorage.setItem(self.sortColumnKey, sortColumnName);
                localStorage.setItem(self.sortDirectionKey, self.sortDirection);

                self.sortByColumnIndex = idx;
                self.$emit('sort', self.internalColumns[self.sortByColumnIndex].field, self.sortDirection);
            },
            startResize(event, idx) {
                this.draging = true;
                event.preventDefault();
                this.startX = event.pageX;
                this.resizeIdx = idx;
            },
            doResize(event) {
                if (this.draging) {
                    event.preventDefault();
                    const diff = event.pageX - this.startX;
                    this.startX = event.pageX;
                    this.dragRender(this.resizeIdx, diff);
                }
            },
            stopDrag() {
                this.draging = false;
                this.startX = 0;
                this.resizeIdx = 0;
            },
            addHeightToRows() {
                return this.rows.map(row => {
                    return {
                        ...row,
                        size: this.hasSvgColumn ? this.maxSvgHeight : 26
                    };
                });
            },
            getCodeLinkByB64Id,
            getLibraryLink,
            getImageDataURL,
            getNameLabel
        }
    };
</script>

<style scoped>

    .vl-container {
        width: 100%;
        height: 26px;
        display: inline-flex;
        flex-direction: row;
        border-bottom: .5px solid lightgray;
        align-items: center;
    }

    .svg-container {
        height: 100px !important;
    }

    .vl-container-header {
        width: 100%;
        height: auto;
        min-height: 2em;
        display: inline-flex;
        flex-direction: row;
        border-bottom: 1.6px solid lightgray;
        background-color: white;
        position: sticky;
        top: 0;left: 0;
        z-index: 9;
    }

    .vl-item {
        border-right: .5px dotted silver;
        padding: 0 .2em 0 .2em;
        overflow: hidden;
        white-space: nowrap;
        height: 100%;
    }

    .vl-header {
        border-right: .5px dotted silver;
        padding: 0 0 0 .2em;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
    }

    .header-action {
        cursor: pointer;
    }

    .vl-header-sort {
        font-family: calibri, helvetica !important;
        font-weight: bold;
        font-size: smaller;
        width: 2em;
    }

    .resize-cursor:hover {
        cursor: ew-resize;
    }

    .resize-drag {
        background:#FF1234;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: col-resize;
        border-radius: 2px;
        transition: 0.2s;
    }

    .resize-drag:hover {
        opacity: 0.5;
        transition: 0.2s;
    }
    .left-container {
        display: flex;
        flex-direction: column;
        flex: 20;
        overflow: hidden;
    }
    .resize-container {
        flex: 1;
        display: flex;
        max-width: 0.4em;
        min-width: 0.4em;
    }
    .name-column {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    .attachment-button {
        right: 0.75rem;
        position: absolute;
        color: red;
    }
    .keep-spaces {
        white-space: pre;
    }
</style>
