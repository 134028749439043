<template>
    <div>
        <div v-if="selectedSpec">
            <slot :loading="loading" />
            <nav
                v-if="editMode">
                <div
                    class="toolbar is-flex is-flex-wrap-wrap is-align-items-center mb-2 is-gap-10">
                    <button
                        class="button is-info"
                        @click="wireCopyActive = true">
                        <span class="icon is-small">
                            <i
                                class="fa fa-copy"
                                aria-hidden="true" />
                        </span>
                        <span>
                            Copy from spec
                        </span>
                    </button>
                    <button
                        class="button is-info"
                        :disabled="wireCodes.length > 0"
                        @click="generateCodes">
                        <span class="icon is-small">
                            <i
                                class="fa fa-magic"
                                aria-hidden="true" />
                        </span>
                        <span>
                            Generate group-wire codes
                        </span>
                    </button>
                    <button
                        class="button is-info"
                        style="margin-right: auto"
                        :disabled="!enableCopyColor"
                        @click="copyColors">
                        <span class="icon is-small">
                            <i
                                class="fa fa-copy"
                                aria-hidden="true" />
                        </span>
                        <span>
                            Copy colors from first group to rest
                        </span>
                    </button>
                    <button
                        class="button is-info"
                        @click="activateModal">
                        <span class="icon is-small">
                            <i
                                class="fa fa-plus-circle"
                                aria-hidden="true" />
                        </span>
                        <span>
                            New row
                        </span>
                    </button>
                </div>
            </nav>
            <div
                class="mt-1">
                <b-table
                    :loading="loading"
                    :hoverable="true"
                    :bordered="true"
                    :data="wireCodes"
                    class="outlined-box sticky-headers site-sticky-header long-list"
                    :style="expanded ? 'padding-bottom: 200px;' : null">
                    <b-table-column
                        v-slot="props"
                        width="40">
                        <b-icon
                            v-if="props.row.meta.edited && !props.row.meta.isNew"
                            type="is-danger"
                            class="fa fa-fw fa-pencil-alt" />
                        <b-icon
                            v-if="props.row.meta.isNew"
                            type="is-danger"
                            class="fa fa-fw fa-plus-square" />
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="WireGroup"
                        label="Group No"
                        width="70">
                        {{ props.row.values.WireGroup }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="WireNo"
                        label="Wire"
                        width="60">
                        {{ props.row.values.WireNo }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="WireColour"
                        label="Colour code">
                        <code-ref-selector
                            :complex="false"
                            :disabled="!editMode"
                            :selected="props.row.values.WireColour"
                            :code-refs="originalCodes(libNames.cableWireColour).map(c => c.values)"
                            @blur="expanded = false"
                            @focus="expandBox(props)"
                            @ref-selected="handleChange(props.row, 'WireColour', $event)" />
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="isValid"
                        :label="editMode? 'Delete' : ''"
                        width="50">
                        <a
                            v-if="editMode"
                            :class="{'button': true, 'trash-icon': true, 'in-valid': !props.row.values.isValid}"
                            @click="handleChange(props.row, 'isValid', !props.row.values.isValid)">
                            <b-icon
                                pack="fas"
                                icon="trash"
                                :type="props.row.values.isValid ? '' : 'is-white '"
                                size="is-medium" />
                        </a>
                        <b-icon
                            v-if="!editMode && !props.row.values.isValid"
                            pack="fas"
                            icon="trash"
                            type="is-danger"
                            size="is-medium" />
                    </b-table-column>

                    <template #empty>
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p
                                    v-if="!loading">
                                    No wire color codes exists for this specification
                                </p>
                            </div>
                        </section>
                    </template>
                </b-table>
            </div>
            <b-modal
                v-model="wireCopyActive"
                has-modal-card>
                <wire-colour-copy
                    v-if="selectedSpec && selectedSpec.id"
                    :spec="selectedSpec.id"
                    :current-screen="selectedCode(libNames.cableCode).values.Screen"
                    @copy="editDone"
                    @close="onCloseWireColourCopy" />
            </b-modal>
            <b-modal
                v-if="template"
                v-model="modalActive"
                has-modal-card>
                <div
                    class="modal-card"
                    style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">
                            Add new wire color relation
                        </p>
                    </header>
                    <section
                        class="modal-card-body"
                        style="padding-bottom: 250px;">
                        <b-field
                            horizontal
                            label="Relation is valid:">
                            <bool-selector
                                :value="template.values.isValid"
                                @bool-select="template.values.isValid = $event" />
                        </b-field>
                        <b-field
                            v-if="originalCodes(libNames.cableWireGroup).length"
                            horizontal
                            label="Group number">
                            <code-ref-selector
                                :code-refs="originalCodes(libNames.cableWireGroup).map(c => c.values)"
                                :required="true"
                                :placeholder-override="''"
                                :complex="false"
                                :sort-func="(a, b) => a.name.localeCompare(b.name, undefined, {numeric: true})"
                                @ref-selected="template.values.WireGroup = $event" />
                        </b-field>
                        <b-field
                            horizontal
                            label="Wire number">
                            <b-input
                                v-model="template.values.WireNo"
                                type="number"
                                placeholder="Please enter a number"
                                min="1"
                                :required="true" />
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <button
                            class="button"
                            type="button"
                            @click="modalActive=false">
                            Cancel
                        </button>
                        <button
                            class="button is-primary"
                            :disabled="!canSaveColorRelation"
                            @click="addNew">
                            Create
                        </button>
                    </footer>
                </div>
            </b-modal>
        </div>
        <p
            v-else
            class="title is-6 has-text-centered has-margin-bottom">
            A new specification must be released before wire color codes can be created
        </p>
    </div>
</template>

<script>
    import { useCodeEditStore } from '@/stores/codeEditStore.js';
    import _ from 'lodash';
    import { mapActions, mapState, mapStores } from 'pinia';
    import BoolElement from '../../../shared/components/BoolElement.vue';
    import BoolSelector from '../../../shared/components/BoolSelector.vue';
    import CodeRefSelector from '../../../shared/components/CodeRefSelector.vue';
    import Spinner from '../../../shared/components/Spinner.vue';
    import { getCableSpecWire, libNames } from '../../../shared/helpers/cableEditHelpers';
    import { showMixin } from '../../../shared/mixins/showMixin';
    import WireColourCopy from './WireColourCopy.vue';

    export default {
        components: {
            Spinner,
            CodeRefSelector,
            BoolSelector,
            WireColourCopy,
            BoolElement
        },
        mixins: [
            showMixin
        ],
        props: {
            selectedSpec: {
                required: false,
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                required: true
            }
        },
        emits: [
            'edited',
            'done'
        ],
        data: function() {
            return {
                libNames,
                wireCodesCopy: [],
                template: null,
                wireGroupCopy: [],
                modalActive: false,
                wireCopyActive: false,
                originalWireColors: [],
                expanded: false,
                loading: false,
                loadedWireCodes: new Set(),
                filter: c => c.values.CableSpec === this.codeEditStore.selectedCodes[libNames.cableSpec]
            };
        },
        computed: {
            ...mapStores(useCodeEditStore),
            ...mapState(useCodeEditStore, ['scope']),
            wireCodes: function() {
                if (this.editMode)
                    return this.wireCodesCopy;
                else
                    return [...this.filteredCodes(libNames.cableSpecWire)].sort(this.sortWires);
            },
            hasChanges: function() {
                return this.wireCodes.some(wc => wc.meta.isNew || wc.meta.edited);
            },
            enableCopyColor: function() {
                if (this.selectedSpec.groups !== '1' && this.wireCodesCopy.length > 0)
                    return this.wireCodesCopy.filter(c => c.values.WireGroup === '001').every(c => c.values.WireColour !== '');
                else
                    return false;
            },
            canSaveColorRelation() {
                const wireNo = parseInt(this.template?.values.WireNo) === parseFloat(this.template?.values.WireNo);

                return this.template?.values.WireGroup && wireNo;
            }
        },
        watch: {
            editMode: function() {
                if (this.editMode) {
                    this.originalWireColors = JSON.parse(JSON.stringify(this.originalCodes(libNames.cableSpecWire))).filter(this.filter).sort(this.sortWires);
                    this.wireCodesCopy = JSON.parse(JSON.stringify(this.filteredCodes(libNames.cableSpecWire))).sort(this.sortWires);
                }
            },
            hasChanges: function(value) {
                this.$emit('edited', value);
            },
            selectedSpec: async function(spec) {
                await this.loadWireColorsForSpec(spec);
            }
        },
        mounted: function() {
            if (this.editMode)
                this.wireCodesCopy = JSON.parse(JSON.stringify(this.filteredCodes(libNames.cableSpecWire))).sort(this.sortWires);

            this.originalWireColors = JSON.parse(JSON.stringify(this.originalCodes(libNames.cableSpecWire))).filter(this.filter).sort(this.sortWires);
        },
        methods: {
            ...mapActions(useCodeEditStore, ['filteredCodes', 'originalCodes', 'selectedCode', 'isFullyLoaded']),
            expandBox: function(props) {
                if (props.index < 6 && this.wireCodes.length < 6) {
                    this.expanded = true;
                }
            },
            sortWires: function(a, b) {
                const g = parseInt(a.values.WireGroup, 10) - parseInt(b.values.WireGroup, 10);
                if (g === 0)
                    return parseInt(a.values.WireNo, 10) - parseInt(b.values.WireNo, 10);
                return g;
            },
            createSpecWireName: function(code) {
                return code.values.WireGroup + '-' + code.values.WireNo + '-' + code.values.WireColour;
            },
            handleChange: function(code, field, change) {
                code.values[field] = change;
                this.wireCodesCopy = this.wireCodesCopy.slice(); // force update on array
                if (field === 'WireColour')
                    code.values.name = this.createSpecWireName(code);

                code.meta.edited = this.checkEdited(code);
                this.$emit('edited');
            },
            addNew: function() {
                this.modalActive = false;
                if (this.wireCodesCopy.some(c => c.values.WireGroup + c.values.WireNo === this.template.values.WireGroup + this.template.values.WireNo)) {
                    this.showError('Cannot create code with group + wire number as it exists');
                } else {
                    this.template.values.name = this.createSpecWireName(this.template);
                    this.template.meta.isNew = true;
                    if (this.template.values.WireNo === '0')
                        this.template.values.WireColour = 'S';

                    this.wireCodesCopy = [...this.wireCodesCopy, this.template];
                    this.$emit('edited');
                }
                this.initTemplate();
            },
            addNewWireGroup: function(group) {
                if (!this.wireGroupCopy.some(wg => wg.values.name === group)) {
                    const temp = this.getWireGroupTemplate();
                    temp.meta.isNew = true;
                    temp.values.name = group;
                    this.wireGroupCopy.push(temp);
                }
            },
            editDone: function() {
                this.wireCodesCopy.forEach(wc => {
                    if (wc.meta.isNew) {
                        const cswChanges = this.codeEditStore.changes[libNames.cableSpecWire];
                        if (cswChanges && cswChanges.find(c => c.values.identity === wc.values.identity))
                            this.codeEditStore.removeChange(libNames.cableSpecWire, wc.values.identity);
                        this.codeEditStore.changeCode(libNames.cableSpecWire, wc, 'create');
                    } else {
                        this.codeEditStore.changeCode(libNames.cableSpecWire, wc, 'update');
                    }
                });

                this.wireGroupCopy.forEach(wgc => {
                    const changes = this.codeEditStore.changes[libNames.cableWireGroup];
                    const existingWireGroup = this.codeEditStore.originalCodes(libNames.cableWireGroup);
                    if (changes && changes.find(c => c.values.identity === wgc.values.identity)) {
                        this.codeEditStore.removeChange(libNames.cableWireGroup, wgc.values.identity);
                    } else if (!existingWireGroup.some(ewg => ewg.values.name === wgc.values.name)) {
                        this.codeEditStore.changeCode(libNames.cableWireGroup, wgc, 'create');
                    }
                });

                this.$emit('done');
            },
            activateModal: function() {
                this.initTemplate();
                this.modalActive = true;
            },
            onCloseWireColourCopy: function() {
                this.wireCopyActive = false;
            },
            generateCodes: function() {
                this.initTemplate();

                const screen = this.selectedCode(libNames.cableCode).values.Screen.toLowerCase();
                if (screen === 'c') {
                    this.template.values.WireNo = '0';
                    this.template.values.WireGroup = '000';
                    this.addNewWireGroup('000');
                    this.addNew();
                }

                const iMax = parseInt(this.selectedCode(libNames.cableSpec).values.NumberOfGroups, 10);
                const jMax = parseInt(this.selectedCode(libNames.cableSpec).values.NumberOfWires, 10);

                for (let i = 1; i <= iMax; i++) {
                    let j;
                    screen === 'i' ? j = 0 : j = 1;
                    for (j; j <= jMax; j++) {
                        let group = i.toString(10);
                        group = group.padStart(3, '0');
                        this.addNewWireGroup(group);
                        this.template.values.WireGroup = group;
                        this.template.values.WireNo = j.toString(10);
                        this.addNew();
                    }
                }
            },
            initTemplate: function() {
                this.template = JSON.parse(JSON.stringify(this.codeEditStore.templates[libNames.cableSpecWire]));
                this.template.values.CableSpec = this.selectedSpec.id;
                this.template.values.isValid = 'True';
            },
            loadWireColorsForSpec: async function(spec) {
                if (!spec
                    || this.isFullyLoaded(libNames.cableSpecWire)
                    || this.loadedWireCodes.has(spec.id))
                    return;

                this.loading = true;
                const cableSpecWire = await getCableSpecWire(this, spec.id, this.scope);
                await this.codeEditStore.addCodes(libNames.cableSpecWire, cableSpecWire);
                this.loading = false;

                this.loadedWireCodes.add(spec.id);
            },
            getWireGroupTemplate: function() {
                const wireGroupTemplate = JSON.parse(JSON.stringify(this.codeEditStore.templates[libNames.cableWireGroup]));
                wireGroupTemplate.values.isValid = 'True';

                return wireGroupTemplate;
            },
            eraseChanges: function() {
                const cswChanges = this.codeEditStore.changes[libNames.cableSpecWire];
                if (cswChanges && cswChanges.length) {
                    this.wireCodesCopy.forEach(wc => {
                        if ((wc.meta.edited || wc.meta.isNew)
                            && cswChanges.find(c => c.values.identity === wc.values.identity))
                            this.codeEditStore.removeChange(libNames.cableSpecWire, wc.values.identity);
                    });
                }

                this.wireCodesCopy = JSON.parse(JSON.stringify(this.filteredCodes(libNames.cableSpecWire))).sort(this.sortWires);
            },
            copyColors: function() {
                const groups = parseInt(this.selectedSpec.groups, 10);
                let wires = parseInt(this.selectedSpec.wires, 10);
                wires += this.selectedCode(libNames.cableCode).values.Screen.toLowerCase() === 'i' ? 1 : 0;

                let colors;

                for (let i = 1; i <= groups; i++) {
                    const g = i.toString(10).padStart(3, '0');
                    const currentGroup = this.wireCodesCopy.filter(c => c.values.WireGroup === g);
                    if (i === 1) {
                        colors = currentGroup.map(c => c.values.WireColour);
                    } else if (currentGroup.length === wires) {
                        for (let j = 0; j < wires; j++) {
                            if (currentGroup[j].values.WireColour !== colors[j]) {
                                currentGroup[j].values.WireColour = colors[j];
                                currentGroup[j].meta.edited = true;
                            }
                        }
                    }
                }
                this.$emit('edited');
            },
            checkEdited: function(row) {
                const originalRow = this.originalWireColors.find(owc => owc.identity === row.identity);
                return !_.isEqual(row.values, originalRow?.values);
            }
        }
    };
</script>
<style scoped>

.long-list {
    max-height: 66vh;
    overflow-x: hidden;
}
.wrap-around {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
}

.toolbar {
    row-gap: 0.5em;
}
.modal-card-foot {
    justify-content: flex-end;
}
</style>
