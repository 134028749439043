<template>
    <section v-if="libraries.length">
        <div>
            <b-autocomplete
                ref="listRef"
                v-model="filter"
                :disabled="disabled"
                :clear-on-select="false"
                placeholder="search for a library to select it"
                :keep-first="false"
                :open-on-focus="true"
                :loading="loading"
                :data="filteredRefs"
                @typing="handleTyping"
                @select="handleSelect">
                <template #default="props">
                    <div class="media">
                        <div
                            class="media-content">
                            <b>{{ props.option }}</b>
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </div>
    </section>
</template>

<script>

    import { getLibraryNames } from '../../shared/helpers/api';

    export default {
        props: {
            selected: {
                type: String,
                required: false,
                default: ''
            },
            loading: {
                type: Boolean,
                required: false,
                default: false
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            existingLibraries: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        emits: [
            'lib-selected'
        ],
        data: function() {
            return {
                filter: '',
                newSelected: null,
                listmode: true,
                libraries: []
            };
        },
        computed: {
            filteredRefs: function() {
                const search = this.filter?.toLowerCase();
                return this.libraries
                    .filter(lib => search === null || lib.toLowerCase().includes(search))
                    .filter(lib => !this.existingLibraries.includes(lib))
                    .sort((a, b) => a.localeCompare(b))
                    .slice(0, 100);
            }
        },
        watch: {
            selected: function(value) {
                this.setValue(value);
            }
        },
        mounted: async function() {
            this.libraries = await getLibraryNames(this);

            if (this.libraries.length && this.selected && this.selected.length) {
                this.setValue(this.selected);
            }
        },
        methods: {
            setValue: function(value) {
                this.newSelected = value;
                this.filter = value ?? '';
            },
            handleSelect: function(option) {
                this.newSelected = option;
                this.filter = option;
                this.$emit('lib-selected', this.newSelected ?? '');
            },
            handleTyping: function(input) {
                this.filter = input;
            }
        }
    };
</script>
