<template>
    <div class="is-ancestor">
        <b-collapse
            class="has-margin-bottom"
            :model-value="libraryHeaderOpen">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button">
                    <p class="card-header-title">
                        {{ props.open ? "Hide library header" : "Show library header" }}
                    </p>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
                    </a>
                </div>
            </template>
            <div>
                <code-set-header
                    :code-set="codeSet"
                    :code-count="tableRowsFiltered.length + ' / ' + tableRows.length"
                    :create-mode="!interactive"
                    :export-columns="exportCols"
                    :queries="queries"
                    @setcreatemode="setInteractive($event)" />
                <div
                    v-show="interactive"
                    class="tile has-margin-top2">
                    <div class="tile is-horizontal">
                        <div class="tile is-child is-6">
                            <query-table-container
                                :rows="tableRowsFiltered"
                                :loading="loading"
                                :columns-def="tableColumns"
                                @queries="setQueries($event)" />
                        </div>
                        <div class="tile is-child is-6">
                            <codeset-display-options
                                v-if="!loading"
                                :library="library"
                                @column-visibility="changeColumnsVisibility($event)"
                                @coderef-display-mode="changeCodeRefDisplayMode($event)" />
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>
        <div>
            <b-loading
                v-model="loading"
                :is-full-page="false"
                :can-cancel="false" />
            <virtual-list
                :rows="tableRowsFiltered"
                :columns="visibleTableColumns"
                :name-alias="nameAlias"
                keyfield="id"
                class="scroll-table"
                @sort="sortByColumn" />
            <div v-if="tableRowsFiltered.length === 0 && !loading">
                <section class="section has-margin-top2">
                    <div class="content has-text-grey has-text-centered title is-5">
                        <p>No Items Found</p>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import QueryTableContainer from '@/shared/components/QueryTableContainer.vue';
    import { getCodeSetViewOrderBy } from '@/shared/helpers/api';
    import { createCodeDefArray } from '@/shared/helpers/dataHelpers';
    import { chainComparers, comparing, flipComparer } from '@/shared/helpers/utils.js';
    import _ from 'lodash';
    import CodesetDisplayOptions from './CodesetDisplayOptions.vue';
    import CodeSetHeader from './CodeSetHeader.vue';
    import VirtualList from './VirtualList.vue';

    export default {
        components: {
            VirtualList,
            QueryTableContainer,
            CodesetDisplayOptions,
            CodeSetHeader
        },
        props: {
            codeSet: {
                type: Object,
                required: true
            }
        },
        emits: [
            'rowcount'
        ],
        data: function() {
            return {
                tableColumns: [],
                tableRows: [],
                tableRowsFiltered: [],
                loading: true,
                library: null,
                filterCache: null,
                sortCache: {
                    column: 'name',
                    direction: 'asc'
                },
                interactive: true,
                queries: {
                    regEx: [],
                    queries: []
                },
                exportCols: null,
                libraryHeaderOpen: true
            };
        },
        computed: {
            visibleTableColumns: function() {
                return this.tableColumns.filter(c => c.visible);
            },
            filters() {
                return this.$store.state.codeTableModule.filters;
            },
            nameAlias() {
                return this.library ? this.library.nameAlias : '';
            }
        },
        mounted: async function() {
            this.library = this.codeSet.library;
            await this.loadCodes();

            this.tableColumns = createCodeDefArray(this.library.attributeDefinitions).map(a => ({ ...a, visible: false }));
            this.$store.commit('SET_LIBRARYNAME', this.library.name);
            await this.$store.dispatch('ADD_COLUMNS', _.cloneDeep(this.tableColumns));
            this.$emit('rowcount', this.tableRows.length);

            this.libraryHeaderOpen = !this.$route.query.noheader;

            this.filterCodes();
            // Adding the watcher here, so that it does not run on initial page load
            this.$watch('filters', this.filterWatcher);
            this.loading = false;
        },
        methods: {
            changeColumnsVisibility: function(callback) {
                callback(this.tableColumns);
                localStorage.setItem('Library.' + this.library.name + '.SelectedTableColumns', this.tableColumns.filter(col => col.visible).map(col => col.field).toString());

                this.exportCols = this.tableColumns.filter(c => c.visible).map(col => {
                    switch (col.field) {
                        case 'name': return 'Name';
                        case 'description': return 'Description';
                        case 'identity': return 'Identity';
                        case 'isValid': return 'IsValid';
                        case 'dateUpdated': return 'DateUpdated';
                        case 'dateCreated': return 'DateCreated';
                        case 'attachmentKey': return 'AttachmentKey';
                        case 'commonLibraryIRI': return 'CommonLibraryIRI';
                        default: return col.field;
                    }
                });
            },
            changeCodeRefDisplayMode: function(callback) {
                callback(this.tableColumns);
                localStorage.setItem('Library.' + this.library.name + '.ReferenceDisplayOverrides', this.tableColumns.filter(c => c.type.toUpperCase() === 'CODEREF').map(c => `${c.field}:${c.referenceDisplayMode}`).toString());
            },
            sortByColumn: function(columnName, direction) {
                this.sortCache.column = columnName;
                this.sortCache.direction = direction;

                const column = this.tableColumns.find(c => c.field === columnName);
                if (!column)
                    return;

                let comparer = this.getComparer(column);
                if (direction === 'desc') {
                    comparer = flipComparer(comparer);
                }

                this.tableRowsFiltered = this.tableRowsFiltered.toSorted(comparer);
            },
            getComparer(column) {
                switch (column.type.toUpperCase()) {
                    case 'INT':
                    case 'FLOAT':
                        return comparing(a => Number(a.codeItems[column.valueIdx].value));
                    case 'CODEREF':
                        switch (column.referenceDisplayMode) {
                            case 'NAME':
                                return comparing(row => row.codeItems[column.valueIdx].name);
                            case 'IDENTITY':
                                return comparing(row => row.codeItems[column.valueIdx].value);
                            case 'DESCRIPTION':
                                return comparing(row => row.codeItems[column.valueIdx].description);
                            default:
                                return chainComparers(
                                    comparing(row => row.codeItems[column.valueIdx].name),
                                    comparing(row => row.codeItems[column.valueIdx].description)
                                );
                        }
                    case 'STRING':
                    default:
                        return comparing(a => a.codeItems[column.valueIdx].value);
                }
            },
            filterCodes: function() {
                if (this.filterCache === this.filters.toString()) return;
                this.filterCache = this.filters.toString();
                if (this.filters.length) {
                    this.tableRowsFiltered = [];
                    for (let i = 0; i < this.tableRows.length; i++) {
                        if (this.filters.every(f => f(this.tableRows[i].codeItems)))
                            this.tableRowsFiltered.push(this.tableRows[i]);
                    }
                } else {
                    this.tableRowsFiltered = this.tableRows.slice();
                }
            },
            filterWatcher: function() {
                this.filterCodes();
                this.sortByColumn(this.sortCache.column, this.sortCache.direction);
            },
            loadCodes: async function() {
                this.tableRows = (await getCodeSetViewOrderBy(this, this.codeSet.name, 'Name')).data.node.codeRow;
            },
            setQueries: function(query) {
                this.queries = query;
            },
            setInteractive: function(input) {
                this.interactive = input;
            }
        }
    };
</script>

<style scoped>
.scroll-table {
    max-height: 70vh;
    overflow-y: auto;
    cursor: pointer;
}
</style>
