<template>
    <div>
        <h2 class="title">
            Import changes
        </h2>
        <div class="has-text-weight-bold">
            1. Please click the import button and select Excel file containing codes.
        </div>
        <input
            id="fileId"
            type="file"
            hidden
            @change="importCodeSet">
        <button
            v-require-can-create-code-import
            class="button is-info"
            @click="triggerImportCodeSet">
            <i class="fa fa-download" />
            Import
        </button>
    </div>
</template>

<script>
    import { requireCanCreateCodeImport } from '@/shared/directives/requirePermission';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { excelImportCodeSet } from '@/shared/helpers/api';
    import { getReleaseLink } from '@/shared/helpers/routing';

    export default {
        directives: {
            'require-can-create-code-import': requireCanCreateCodeImport
        },
        mixins: [
            showMixin
        ],
        methods: {
            triggerImportCodeSet: function() {
                document.getElementById('fileId').click();
            },
            importCodeSet: async function(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;

                const formData = new FormData();
                formData.append('file', files[0]);

                const loadingComponent = this.$buefy.loading.open();

                try {
                    const response = await excelImportCodeSet(this, formData);
                    const releaseId = response.data.releaseId;
                    await this.$router.push(getReleaseLink(releaseId));
                } catch (err) {
                    this.showError(err);
                } finally {
                    loadingComponent.close();
                }
            }
        }
    };
</script>
