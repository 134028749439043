import { getCookie, setCookie } from '../helpers/utils';

export default {
    props: {},
    data: function() {
        return {
            md_message: '',
            md_notShowAgainOption: false
        };
    },
    methods: {
        messageDialog(
            message = 'Default dialog text',
            notShowAgainOption = false,
            okText = 'Ok'
        ) {
            const self = this;
            if (getCookie(message) !== null) {
                return new Promise(resolve => resolve());
            }
            self.md_message = message;
            self.md_notShowAgainOption = notShowAgainOption;
            return new Promise((resolve, reject) => {
                self.$buefy.dialog.confirm({
                    message: self.shownMessage,
                    confirmText: okText,
                    cancelText: 'Cancel',
                    onConfirm: () => {
                        if (
                            document.getElementById('checkedNotAgain') !== null
                            && document.getElementById('checkedNotAgain').checked
                        ) {
                            setCookie(message, 'hide', 30);
                        }
                        resolve();
                    },
                    onCancel: () => {
                        if (
                            document.getElementById('checkedNotAgain') !== null
                            && document.getElementById('checkedNotAgain').checked
                        ) {
                            setCookie(message, 'hide', 30);
                        }
                        reject();
                    }
                });
            });
        }
    },
    computed: {
        shownMessage: function() {
            const self = this;
            if (self.md_notShowAgainOption) {
                return (
                    self.md_message
                    + `<br/><br/>
          <input type="checkbox" id="checkedNotAgain">
                Do not show this message again 
            </input>`
                );
            } else return self.md_message;
        }
    }
};
