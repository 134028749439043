<template>
    <div>
        <h2 class="title is-4">
            Attribute definitions
        </h2>
        <table class="table is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th class>
                        Name
                    </th>
                    <th class>
                        Seq#
                    </th>
                    <th class>
                        Display name
                    </th>
                    <th class="is-hidden-mobile">
                        Description
                    </th>
                    <th class>
                        Regex
                    </th>
                    <th class>
                        Type
                    </th>
                    <th class>
                        CodeRef library
                    </th>
                    <th class>
                        CodeRef display
                    </th>
                    <th class>
                        In identity
                    </th>
                    <th class>
                        Required
                    </th>
                    <th class="has-text-centered">
                        Delete
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="attributeDef in editAttrDefs"
                    :key="attributeDef.name"
                    :class="{'has-text-grey-light': deleteAttrDefs.includes(attributeDef.name)}">
                    <td>{{ attributeDef.name }}</td>
                    <td>
                        <input
                            v-model.number="attributeDef.sequenceNumber"
                            class="input"
                            :disabled="isDisabled(attributeDef.name)"
                            style="width:5em;"
                            type="number">
                    </td>
                    <td>
                        <input
                            v-model="attributeDef.displayAs"
                            class="input"
                            :disabled="isDisabled(attributeDef.name)"
                            type="text">
                    </td>
                    <td>
                        <input
                            v-model="attributeDef.description"
                            class="input"
                            :disabled="isDisabled(attributeDef.name)"
                            type="text">
                    </td>
                    <td>
                        <input
                            v-model="attributeDef.regex"
                            class="input"
                            :disabled="isDisabled(attributeDef.name) || attributeDef.attributeType !== 'String'"
                            type="text">
                    </td>
                    <td>
                        <div class="select">
                            <select
                                v-model="attributeDef.attributeType"
                                class="input"
                                :disabled="isDisabled(attributeDef.name)">
                                <option
                                    v-for="attrType in attributeTypes"
                                    :key="attrType">
                                    {{ attrType }}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="select">
                            <select
                                v-model="attributeDef.referenceLibraryName"
                                class="input"
                                :disabled="!isReferenceType(attributeDef.attributeType)">
                                <option
                                    v-for="libName in allLibraryNames"
                                    :key="libName">
                                    {{ libName }}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="select">
                            <select
                                v-model="attributeDef.referenceDisplayMode"
                                class="input"
                                :disabled="!isReferenceType(attributeDef.attributeType)">
                                <option
                                    v-for="o in codeRefDisplayModes"
                                    :key="o.value"
                                    :value="o.value">
                                    {{ o.label }}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td class="has-text-centered">
                        <input
                            v-model="attributeDef.includeInIdentity"
                            type="checkbox">
                    </td>
                    <td class="has-text-centered">
                        <input
                            v-model="attributeDef.required"
                            type="checkbox">
                    </td>
                    <td
                        class="has-text-centered"
                        :disabled="isDisabled(attributeDef.name) || null">
                        <a
                            v-show="!isDisabled(attributeDef.name)"
                            @click="removeExistingAD(attributeDef.name)">
                            <i class="fa fa-trash" />
                        </a>
                    </td>
                </tr>

                <tr
                    v-for="(attributeDef, idx) in newAttrDefs"
                    :key="idx">
                    <td>
                        <input
                            v-model="attributeDef.name"
                            class="input"
                            type="text">
                    </td>
                    <td>
                        <input
                            v-model.number="attributeDef.sequenceNumber"
                            class="input"
                            type="number"
                            style="width:5em;">
                    </td>
                    <td>
                        <input
                            v-model="attributeDef.displayAs"
                            class="input"
                            type="text">
                    </td>
                    <td>
                        <input
                            v-model="attributeDef.description"
                            class="input"
                            type="text">
                    </td>
                    <td>
                        <input
                            v-model="attributeDef.regex"
                            class="input"
                            type="text">
                    </td>
                    <td>
                        <div class="select">
                            <select
                                v-model="attributeDef.attributeType"
                                class="input">
                                <option
                                    v-for="attrType in attributeTypes"
                                    :key="attrType">
                                    {{ attrType }}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="select">
                            <select
                                v-model="attributeDef.referenceLibraryName"
                                class="input"
                                :disabled="!isReferenceType(attributeDef.attributeType)">
                                <option
                                    v-for="libName in allLibraryNames"
                                    :key="libName">
                                    {{ libName }}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="select">
                            <select
                                v-model="attributeDef.referenceDisplayMode"
                                class="input"
                                :disabled="!isReferenceType(attributeDef.attributeType)">
                                <option
                                    v-for="o in codeRefDisplayModes"
                                    :key="o.value"
                                    :value="o.value">
                                    {{ o.label }}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td class="has-text-centered">
                        <input
                            v-model="attributeDef.includeInIdentity"
                            type="checkbox">
                    </td>
                    <td class="has-text-centered">
                        <input
                            v-model="attributeDef.required"
                            type="checkbox">
                    </td>
                    <td class="has-text-centered">
                        <a @click="removeNewAD(idx)">
                            <i class="fa fa-trash" />
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <p
            v-for="error in attributeErrors"
            :key="error"
            class="has-text-danger">
            {{ error }}
        </p>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { deleteAttributeDefinitions, putAttributeDefinitions, getLibraries } from '@/shared/helpers/api';
    import { codeRefDisplayModes, attributeTypes } from '@/shared/helpers/utils';

    export default {
        props: {
            attributedefs: {
                type: Array,
                required: true
            },
            library: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
                deleteAttrDefs: [],
                editAttrDefs: JSON.parse(JSON.stringify(this.attributedefs)),
                newAttrDefs: [],
                attributeErrors: [],
                allLibraryNames: null,
                codeRefDisplayModes,
                attributeTypes
            };
        },
        mounted: async function() {
            this.allLibraryNames = await this.loadLibraryNames();
        },
        methods: {
            updateAttributes: async function() {
                try {
                    await this.removeAttrDefinitons();
                    await this.addAttrDefinitons();
                } catch (err) {
                    this.attributeErrors = err.response.data.errors;
                    throw 'Error in attribute definiton input, cannot save';
                }
            },
            removeAttrDefinitons: async function() {
                if (this.deleteAttrDefs.length > 0)
                    return await deleteAttributeDefinitions(this, this.library.name, this.deleteAttrDefs);
            },
            addAttrDefinitons: async function() {
                let definitions = [];

                if (this.newAttrDefs.length > 0)
                    definitions = definitions.concat(this.newAttrDefs);

                this.editAttrDefs = this.editAttrDefs.map(editAttrDef => {
                    if (editAttrDef.attributeType !== 'String' && editAttrDef.regex !== null) {
                        editAttrDef.regex = null;
                    }
                    return editAttrDef;
                });

                if (JSON.stringify(this.editAttrDefs) !== JSON.stringify(this.attributedefs))
                    definitions = definitions.concat(this.editAttrDefs.filter(ed => !this.deleteAttrDefs.includes(ed.name)));

                if (definitions.length > 0)
                    return await putAttributeDefinitions(this, this.library.name, definitions);
            },
            isDisabled: function(item) {
                return this.deleteAttrDefs.includes(item);
            },
            isReferenceType: function(attributeType) {
                return attributeType === 'CodeRef';
            },
            newAD: function() {
                const maxSeqFromNew = this.newAttrDefs.length ? _.maxBy(this.newAttrDefs, 'sequenceNumber').sequenceNumber : 0;
                const maxSeqFromEdit = this.editAttrDefs.length ? _.maxBy(this.editAttrDefs, 'sequenceNumber').sequenceNumber : 0;
                const nextSeq = Math.max(maxSeqFromNew, maxSeqFromEdit) + 1;
                this.newAttrDefs.push(
                    {
                        'name': 'Attribute' + nextSeq,
                        'description': '',
                        'regex': '',
                        'displayAs': '',
                        'attributeType': 'String',
                        'sequenceNumber': nextSeq,
                        'required': false,
                        'includeInIdentity': false,
                        'referenceDisplayMode': 0
                    });
            },
            removeNewAD: function(index) {
                this.newAttrDefs.splice(index, 1);
            },
            removeExistingAD: function(name) {
                this.deleteAttrDefs.push(name);
            },
            loadLibraryNames: async function() {
                return (await getLibraries(this))
                    .filter(l => !l.isForeignObject && (l.isGlobal || !this.library.isGlobal && this.library.scopeType === l.scopeType))
                    .map(l => l.name)
                    .sort();
            }
        }
    };
</script>
