<template>
    <div class="component-wrapper">
        <p><a :href="'/Schema#/specification/' + identity">&laquo; Back to schema</a></p>
        <div class="header-wrap">
            <h1 class="title">
                Edit class mapping in Schema: {{ identity }} towards
            </h1>
            <b-dropdown
                :triggers="['hover']"
                aria-role="list"
                :scrollable="true"
                :max-height="400">
                <template #trigger>
                    <b-button
                        class="dropdownButton"
                        :label="selectedSecondSchema ? selectedSecondSchema.Name : 'Select Schema 2'"
                        icon-right="menu-down" />
                </template>
                <b-dropdown-item
                    v-for="(s, index) in dropdownSchemas"
                    :key="index"
                    aria-role="listitem"
                    @click="selectedSecondSchema = s">
                    {{ s ? s.Name : '' }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="columns">
            <div class="column is-one-quarter column-wrapper">
                <h5
                    class="subtitle is-5 column-header-light dont-wrap">
                    Schema 1: {{ identity }} Classes
                </h5>
                <p class="block">
                    <i>(y→x): mapped in base class</i>
                </p>
                <span>
                    <b-checkbox
                        v-model="includeAllExtensions"
                        class="extensions-checkbox" />
                    <b style="margin-left: -10px">Include all extensions</b>
                </span>
                <div class="table-overflow">
                    <spinner :loading="loading">
                        <b-table
                            v-if="schemaOneClassesSorted.length > 0"
                            v-model:selected="selectedSchema1Class"
                            :hoverable="true"
                            :data="schemaOneClassesSorted"
                            class="cursor-pointer">
                            <b-table-column
                                v-slot="props"
                                field="Name"
                                label="Class">
                                <span>
                                    {{ props.row.Name }}
                                </span>
                            </b-table-column>
                            <b-table-column
                                v-slot="props"
                                field="HasBaseClass"
                                label="Mapped to">
                                <span v-if="props.row.MappingLabel">
                                    {{ props.row.MappingLabel }}
                                </span>
                            </b-table-column>
                        </b-table>
                        <span v-else>No classes found in {{ identity }}</span>
                    </spinner>
                </div>
            </div>
            <div class="column is-one-fifth column-wrapper">
                <div>
                    <div class="schema2Title">
                        <h5
                            class="subtitle is-5 column-header-light dont-wrap">
                            Schema 2: {{ selectedSecondSchema ? selectedSecondSchema.Identity + ' classes' : 'Select schema 2' }}
                        </h5>
                    </div>
                    <div class="new-mapping-button">
                        <b-button
                            v-require-can-edit-code="{ libraryName: 'SchemaMapClass' }"
                            :type="'is-primary'"
                            :disabled="!possibleToCreateNewMapping"
                            @click="createNewMapping">
                            New mapping
                        </b-button>
                    </div>
                </div>
                <div class="table-overflow">
                    <b-table
                        v-if="schemaTwoClassesSorted.length > 0"
                        v-model:selected="selectedSchema2Class"
                        :columns="[{field: 'Name'}]"
                        :hoverable="true"
                        :data="schemaTwoClassesSorted"
                        class="cursor-pointer" />
                    <span v-else-if="selectedSecondSchema && schemaTwoClassesSorted.length === 0">No classes in {{ selectedSecondSchema.Name }}.</span>
                </div>
            </div>
            <div class="column column-wrapper">
                <h5
                    class="subtitle is-5 column-header-light dont-wrap">
                    Properties {{ (selectedSchema1Class && selectedSchema2Class)
                        ? `for the ${selectedSchema1Class.Name} &#8594; ${selectedSchema2Class.Name} mapping`
                        : '' }}
                </h5>
                <div>
                    <schema-class-mapping-form
                        v-if="displayForm"
                        :schema-objects="[selectedFirstSchema, selectedSecondSchema]"
                        :create-new="creatingNewMapping"
                        :selected-schema1-class="selectedSchema1Class"
                        :selected-schema2-class="selectedSchema2Class"
                        :mapping-class="currentSelectedMapping"
                        @refresh="onRefresh"
                        @clickCancel="onCancel" />
                    <schema-class-mapping-form
                        v-else-if="creatingNewMapping"
                        :schema-objects="[selectedFirstSchema, selectedSecondSchema]"
                        :create-new="creatingNewMapping"
                        :selected-schema1-class="selectedSchema1Class"
                        :selected-schema2-class="selectedSchema2Class"
                        @refresh="onRefresh"
                        @clickCancel="onCancel" />
                    <p v-else-if="!selectedSecondSchema || !selectedSchema1Class">
                        Select Class 1 and Schema 2.
                    </p>
                    <p v-else-if="selectedSchema1Class.MappingLabel">
                        Mapping found for base class <b>{{ selectedSchema1Class.MappingLabel }}</b>.
                    </p>
                    <p v-else-if="selectedSchema1Class && !selectedSchema2Class">
                        No mapping found for <b>{{ selectedSchema1Class.Name }}</b> from <b>{{ identity }}</b> to <b>{{ selectedSecondSchema.Name }}</b>. Select a class in <b>{{ selectedSecondSchema.Name }}</b> to create a new mapping.
                    </p>
                    <p v-else-if="selectedSchema2Class">
                        No mapping found for selected classes. Create a new mapping by clicking "New".
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { genericViewQuery, genericViewQueryAsText } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import SchemaClassMappingForm from './SchemaClassMappingForm.vue';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            SchemaClassMappingForm,
            Spinner
        },
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                schemaOneClasses: [],
                schemaTwoClasses: [],
                schemas: [],
                selectedFirstSchema: null,
                selectedSecondSchema: null,
                schemaMapClassList: [],
                selectedSchema1Class: null,
                selectedSchema2Class: null,
                creatingNewMapping: false,
                loading: false,
                includeAllExtensions: false
            };
        },
        computed: {
            dropdownSchemas: function() {
                return this.schemas.filter(schema => schema.Identity !== this.identity)
                    .sort((a, b) => a.Name.localeCompare(b.Name));
            },
            existingClassMapping: function() {
                if (this.selectedSchema1Class && this.selectedSecondSchema) {
                    return this.schemaMapClassList.find(schemaMapClass => schemaMapClass.Class1 === this.selectedSchema1Class.Identity);
                }
                return null;
            },
            schemaOneClassesSorted: function() {
                let sortedClasses = [...this.schemaOneClasses].sort((a, b) => a.Name.localeCompare(b.Name));
                if (!this.includeAllExtensions) {
                    sortedClasses = sortedClasses.filter(schemaClass => !schemaClass.isExtensionClass);
                }
                sortedClasses = sortedClasses.map(schemaClass => {
                    schemaClass = { ...schemaClass };
                    schemaClass.MappingLabel = this.schemaMapClassList.find(classMap => classMap.Class1 === schemaClass.Identity)?.Class2Name;

                    if (!schemaClass.MappingLabel) {
                        const baseClassMapping = this.schemaMapClassList.find(classMap => classMap.Class1 === schemaClass.HasBaseClass);
                        if (baseClassMapping) {
                            schemaClass.MappingLabel = `(${baseClassMapping.Class1Name} → ${baseClassMapping.Class2Name})`;
                        }
                    }
                    return schemaClass;
                });
                return sortedClasses;
            },
            schemaTwoClassesSorted: function() {
                return [...this.schemaTwoClasses].sort((a, b) => a.Name.localeCompare(b.Name));
            },
            displayForm: function() {
                return !(this.currentSelectedMapping === null || this.currentSelectedMapping === undefined);
            },
            currentSelectedMapping: function() {
                return this.schemaMapClassList?.find(schemaMapClass => schemaMapClass.Class1 === this.selectedSchema1Class?.Identity
                    && schemaMapClass.Class2 === this.selectedSchema2Class.Identity);
            },
            possibleToCreateNewMapping: function() {
                return !this.currentSelectedMapping
                    && this.selectedSchema2Class
                    && this.selectedSchema1Class
                    && !this.creatingNewMapping;
            }
        },
        watch: {
            selectedSecondSchema: async function(schema) {
                this.schemaTwoClasses = await this.loadClasses(schema.Identity);
                this.creatingNewMapping = false;
                if (this.selectedSecondSchema !== null) {
                    await this.loadSchemaMapClasses();
                }
            },
            existingClassMapping: async function(existingMapping) {
                this.creatingNewMapping = false;
                this.selectedSchema2Class = this.schemaTwoClassesSorted.find(schemaClass => schemaClass.Identity === existingMapping?.Class2);
            },
            selectedSchema2Class: function() {
                this.creatingNewMapping = false;
            }
        },
        mounted: async function() {
            this.loading = true;
            const extensions = await this.getSchemaExtensions(this.identity);
            this.schemaOneClasses
                = await Promise.all(
                    extensions.map(extension =>
                        this.loadClasses(extension.Name)
                            .then(extensionClasses =>
                                extensionClasses.map(extClass => {
                                    extClass.isExtensionClass = extension.Name !== this.identity;
                                    return extClass;
                                })
                            )
                    )
                ).then(extensions => extensions.flat());

            await this.loadSchemas();
            this.selectedFirstSchema = this.schemas.find(s => s.Identity === this.identity);
            this.loading = false;
        },
        methods: {
            loadClasses: async function(schemaId) {
                return (await genericViewQuery(this, {
                    version: 1,
                    name: 'SchemaClass',
                    include: [
                        {
                            field: 'Name'
                        },
                        {
                            field: 'Id'
                        },
                        {
                            field: 'Identity'
                        },
                        {
                            field: 'Schema'
                        },
                        {
                            field: 'HasBaseClass'
                        }
                    ],
                    where: {
                        field: 'Schema',
                        operator: '=',
                        value: schemaId
                    }
                })).data;
            },
            loadSchemas: async function() {
                this.schemas = (await genericViewQuery(this, {
                    'version': 1,
                    'skip': 0,
                    'take': 0,
                    'name': 'Schema',
                    'include': null,
                    'where': null,
                    'join': null
                })).data;
            },
            loadSchemaMapClasses: async function() {
                const extensions = await this.getSchemaExtensions(this.identity);
                this.schemaMapClassList
                    = await Promise.all(
                        extensions.map(extension =>
                            genericViewQueryAsText(
                                this,
                                this.getSchemaMapClassQuery(),
                                [
                                    { name: '@schema1', value: extension.Name },
                                    { name: '@schema2', value: this.selectedSecondSchema.Identity }
                                ]
                            ).then(list => list.data)
                        )
                    ).then(lists => lists.flat());
            },
            getSchemaExtensions: async function(schema) {
                return (await genericViewQueryAsText(
                    this,
                    'FROM Schema SELECT Name WHERE (Name = @schemaName OR ExtendingSchema = @schemaName) AND IsValid = true',
                    [{ name: '@schemaName', value: schema }])).data;
            },
            getSchemaMapClassQuery: function() {
                return `
                    FROM SchemaMapClass
                    SELECT Identity, Name, Description, Schema1, Schema2, Class1, Class2,
                        IsBidirectional, PassOnUnmappedAttributes, AddAttribute1,
                        ValueSource1, ValueConstant1, AddAttribute2, ValueSource2,
                        ValueConstant2, AddAttribute3, ValueSource3, ValueConstant3,
                        Comments, InternalComments
                    WHERE IsValid = true and Schema1 = @schema1 and Schema2 = @schema2
                    JOIN Class2
                        SELECT Name as Class2Name
                    END
                    JOIN Class1
                        SELECT Name as Class1Name
                    END`;
            },
            createNewMapping: function() {
                this.creatingNewMapping = !this.creatingNewMapping;
            },
            onRefresh: function() {
                this.creatingNewMapping = false;
                this.loadSchemaMapClasses();
            },
            onCancel: function() {
                this.creatingNewMapping = false;
            }
        }
    };
</script>

<style scoped>
h1 {
    margin-top: 5px;
    margin-right: 20px;
}
.dont-wrap {
    white-space: nowrap;
}
.schema2Title{
    float: left;
}
.header-wrap {
    display: flex;
}
.component-wrapper {
    min-height: 900px;
    height: 80vh;
}
.cursor-pointer {
    cursor: pointer;
}
.table-overflow {
    overflow: auto;
    overflow-y: scroll;
    height: 70vh;
}
.new-mapping-button {
    float: right;
}
.column-wrapper {
    height: 70vh;
    display: flex;
    flex-direction: column;
    min-width: 300px;
}
.extensions-checkbox {
    margin-right: 0px;
}
</style>
