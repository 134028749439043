<template>
    <div style="position:relative">
        <b-loading
            :is-full-page="false"
            :model-value="loading" />
        <div class="level">
            <div class="is-flex is-gap-10 is-align-items-center">
                <b-field
                    class="mb-0"
                    label="Change type"
                    label-position="inside">
                    <b-select
                        v-model="changeType"
                        @update:model-value="changeTypeFilter">
                        <option>All</option>
                        <option>Modified</option>
                        <option>New</option>
                    </b-select>
                </b-field>
                <b-field
                    class="mb-0"
                    label="Library sets"
                    label-position="inside">
                    <b-select
                        v-model="codeSetFilter"
                        placeholder="Select a name"
                        @update:model-value="changeCodeSetFilter">
                        <option
                            v-for="opt in selectCodeSets"
                            :key="opt.id"
                            :value="opt.id">
                            {{ $filters.truncate(opt.name, 40) }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                    class="mb-0"
                    label="Filter"
                    label-position="inside">
                    <b-input
                        v-model="changeTextFilter"
                        expanded
                        placeholder="Text"
                        @keyup.enter="search" />
                </b-field>
                <p class="control">
                    <b-button
                        class="button"
                        @click="search">
                        Search
                    </b-button>
                </p>
            </div>
            <div>
                <b>Items: {{ itemCount }} / {{ totalCount }}</b>
            </div>
            <div v-show="editable">
                <button
                    class="button is-danger"
                    :disabled="disableAction"
                    @click="onDeleteVisibleClick">
                    <b-icon
                        size="is-small"
                        icon="delete" />
                    <span>Delete visible</span>
                </button>
                <button
                    class="button is-danger"
                    :disabled="disableAction"
                    @click="onDeleteInvisibleClick">
                    <b-icon
                        size="is-small"
                        icon="skull" />
                    <span>Keep only visible</span>
                </button>
            </div>
        </div>
        <div>
            <div class="columns is-mobile table-header">
                <div class="column is-one-third">
                    Item
                </div>
                <div class="column is-two-thirds">
                    <div class="columns is-mobile">
                        <div class="column is-one-third l-border">
                            Field
                        </div>
                        <div class="column is-one-third l-border">
                            Old value
                        </div>
                        <div class="column is-one-third l-border">
                            New value
                        </div>
                    </div>
                </div>
            </div>
            <virtual-scroll-list
                ref="virtualList"
                :debounce="100"
                :size="90"
                :remain="20"
                :bench="15"
                :item="item"
                :pagemode="true"
                :variable="getVariableHeight"
                :itemcount="count"
                :itemprops="getItemProps" />
        </div>
    </div>
</template>
<script>
    import { getReleaseChanges, removeReleaseChanges } from '@/shared/helpers/api';
    import messageDialog from '@/shared/mixins/messageDialogMixin';
    import { showMixin } from '@/shared/mixins/showMixin';
    import crossfilter from 'crossfilter2';
    import _ from 'lodash';
    import { markRaw } from 'vue';
    import ChangeItem from './ChangeItem.vue';
    import VirtualScrollList from './VirtualScrollList.vue';

    export default {
        components: {
            VirtualScrollList
        },
        mixins: [
            showMixin,
            messageDialog
        ],
        props: {
            changeDocument: {
                type: Object,
                required: true,
                default: () => {}
            },
            editable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: true,
                item: markRaw(ChangeItem),
                changeTextFilter: '',
                changeType: 'All',
                codeSetFilter: 'All',
                selectCodeSets: [],
                crossfilter: null,
                codeSetDimension: null,
                changeTypeDimension: null,
                searchStringsDimension: null,
                count: 0
            };
        },
        computed: {
            itemCount() {
                return this.count;
            },
            totalCount() {
                if (this.crossfilter === null) return 0;
                return this.crossfilter.size();
            },
            disableAction() {
                return this.itemCount === this.totalCount;
            }
        },
        mounted: function() {
            this.loadChanges();
        },
        methods: {
            loadChanges: async function() {
                const self = this;
                if (!this.changeDocument) return;

                this.loading = true;
                try {
                    let changes = await getReleaseChanges(this, this.changeDocument.releaseId);
                    if (
                        self.changeDocument.specificationType
                        === 'CompositeChangeDocument'
                    ) {
                        self.selectCodeSets = _.concat(
                            [{ id: 'All', name: 'All' }],
                            self.changeDocument.changeDocuments.map(c => ({
                                id: c.codeSetName,
                                name: c.codeSetDescription
                            }))
                        );
                        changes = changes.map((c) => ({
                            ...c,
                            codeSetDescription: _.find(
                                self.changeDocument.changeDocuments,
                                ['codeSetName', c.codeSet]
                            ).codeSetDescription
                        }));
                    } else {
                        self.selectCodeSets = [
                            {
                                id: self.changeDocument.codeSetName,
                                name: self.changeDocument.codeSetDescription
                            }
                        ];
                        self.codeSetFilter = self.changeDocument.codeSetName;
                        changes = changes.map((c) => ({
                            ...c,
                            codeSetDescription: self.changeDocument.codeSetDescription
                        }));
                    }

                    function getSearchStrings(object) {
                        let r = [];
                        ['name', 'identity'].forEach(p => {
                            const i = _.chain(object).get(p).lowerCase().split(' ').value();
                            if (i) r = r.concat(i);
                        });
                        object.changes.forEach(c => {
                            ['field', 'newValue', 'oldValue'].forEach(p => {
                                const i = _.chain(c).get(p).lowerCase().split(' ').value();
                                if (i) r = r.concat(i);
                            });
                        });
                        return r;
                    }
                    changes.forEach(element => {
                        element.searchStrings = getSearchStrings(element);
                    });
                    this.crossfilter = crossfilter(changes);
                    this.codeSetDimension = this.crossfilter.dimension(d => d.codeSet);
                    this.changeTypeDimension = this.crossfilter.dimension(d => d.changeType);
                    this.searchStringsDimension = this.crossfilter.dimension(d => d.searchStrings);
                    this.count = this.crossfilter.groupAll().reduceCount().value();
                    this.loading = false;
                } catch (err) {
                    this.showError(err);
                } finally {
                    this.loading = false;
                }
            },
            getItemProps(itemIndex) {
                const data = this.codeSetDimension.top(1, itemIndex)[0];
                return {
                    key: itemIndex,
                    change: data,
                    height: this.getVariableHeight(data)
                };
            },
            getVariableHeight(item) {
                if (typeof item === 'number')
                    item = this.codeSetDimension.top(1, item)[0];
                return Math.max(item.changes.length * 20, 90);
            },
            changeTypeFilter() {
                const value = this.changeType;
                if (value === 'All') {
                    this.changeTypeDimension.filterAll();
                } else {
                    this.changeTypeDimension.filter(value);
                }
                this.count = this.crossfilter.groupAll().reduceCount().value();
            },
            search() {
                if (this.searchStringsDimension.hasCurrentFilter())
                    this.searchStringsDimension.filterAll();

                if (this.changeTextFilter.length !== 0) {
                    const inputs = _.chain(this.changeTextFilter).lowerCase().split(' ').value();
                    this.searchStringsDimension.filter(d => {
                        return _.difference(inputs, d).length === 0;
                    });
                }
                this.count = this.crossfilter.groupAll().reduceCount().value();
            },
            changeCodeSetFilter() {
                const value = this.codeSetFilter;
                if (value === 'All') {
                    this.codeSetDimension.filterAll();
                } else {
                    this.codeSetDimension.filter(value);
                }
                this.count = this.crossfilter.groupAll().reduceCount().value();
            },
            async deleteVisibleChanges(ids) {
                try {
                    this.loading = true;
                    await removeReleaseChanges(this, this.changeDocument.releaseId, ids);
                    await this.loadChanges();
                    this.search();
                    this.changeCodeSetFilter();
                    this.changeTypeFilter();
                    this.showInfo(`Deleted ${ids.length} changes`);
                    this.loading = false;
                } catch (err) {
                    this.showError(err);
                    this.loading = false;
                }
            },
            onDeleteVisibleClick() {
                const ids = this.codeSetDimension.top(Infinity).map(d => d.id);
                this.messageDialog(`Delete ${ids.length} visible changes?`)
                    .then(() => this.deleteVisibleChanges(ids));
            },
            onDeleteInvisibleClick() {
                const visible_ids = this.codeSetDimension.top(Infinity).map(d => d.id);
                const all_ids = this.crossfilter.all().map(d => d.id);
                const ids = _.difference(all_ids, visible_ids);
                this.messageDialog(`Delete ${ids.length} invisible changes?`)
                    .then(() => this.deleteVisibleChanges(ids));
            }

        }
    };

</script>

<style scoped>
.table-header {
    border: solid 1px silver;
    font-weight: bold;
    background-color: #ededed;
}
.l-border {
    border-left: solid 1px silver;
}
</style>
