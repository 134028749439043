<template>
    <b-table
        ref="table"
        :hoverable="true"
        :selected="selectedSync"
        :columns="columns"
        :data="data"
        class="cursorPointer"
        :mobile-cards="false"
        @click="selectClick" />
</template>

<script>
    export default {
        props: {
            selectedSync: {
                type: null,
                default: null
            },
            data: {
                type: Array,
                default: () => []
            },
            columns: {
                type: Array,
                default: () => []
            }
        },
        emits: [
            'clickElement'
        ],
        watch: {
            selectedSync: function(newValue) {
                this.scrollToSelected(newValue);
            }
        },
        mounted() {
            this.scrollToSelected(this.selectedSync);
        },
        methods: {
            selectClick: function(element) {
                this.$emit('clickElement', element);
            },
            scrollToSelected: function(row) {
                if (!row) { return; }

                const index = this.data.findIndex(otherRow => otherRow.Identity === row.Identity);
                if (index === -1) { return; }

                const buefyTable = this.$refs.table.$el,
                      tableRow = buefyTable.querySelectorAll('tbody tr')[index];

                tableRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };
</script>

<style>

</style>
