<template>
    <div class="main-container">
        <cl-header />
        <section
            class="section"
            style="padding-top: 1.5rem">
            <router-view :key="refreshKey" />
        </section>
        <cl-footer />
    </div>
</template>
<script>
    import ClHeader from '@/shared/ClHeader.vue';
    import ClFooter from '@/shared/ClFooter.vue';

    export default {
        name: 'CommonLibraryApp',
        components: { ClHeader, ClFooter },
        data() {
            return {
                refreshKey: 0
            };
        },
        created() {
            this.$watch(
                () => this.$route.meta.refreshKey?.(this.$route),
                (newKey, oldKey) => {
                    if (newKey !== oldKey && newKey !== undefined && oldKey !== undefined) {
                        this.refreshKey++;
                    }
                }
            );
        }
    };
</script>
<style scoped>
.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
</style>
