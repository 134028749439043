<template>
    <div
        class="modal-card"
        style="height: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Apply Access Group to new Library
            </p>
        </header>
        <section
            class="modal-card-body">
            <b-field label="Library">
                <b-select
                    v-model="selectedLibrary"
                    :loading="!allLibraries"
                    placeholder="Select Library">
                    <option
                        v-for="lib in allLibraries"
                        :key="lib.name">
                        {{ lib.name }}
                    </option>
                </b-select>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button
                class="button"
                @click="closeModal">
                <b-icon
                    icon="close-circle"
                    size="is-small" />
                <span>
                    Cancel
                </span>
            </button>
            <button
                class="is-primary button"
                :disabled="doneDisabled"
                @click="done">
                <span class="icon is-small">
                    <i
                        class="fa fa-check-circle"
                        aria-hidden="true" />
                </span>
                <span>
                    Add Library
                </span>
            </button>
        </footer>
    </div>
</template>

<script>

    import CodeRefSelector from '@/shared/components/CodeRefSelector.vue';
    import { getLibraries, postAccessGroupLibrary } from '@/shared/helpers/api.ts';

    export default {
        components: {
            CodeRefSelector
        },
        props: {
            accessGroup: {
                type: String,
                required: true,
                default: null
            }
        },
        emits: [
            'change',
            'close'
        ],
        data: function() {
            return {
                allLibraries: null,
                selectedLibrary: null,
                allScopes: null,
                selectedScope: null
            };
        },
        computed: {
            doneDisabled: function() {
                return !this.selectedLibrary;
            }
        },
        mounted: async function() {
            this.allLibraries = await getLibraries(this);
        },
        methods: {
            done: async function() {
                await postAccessGroupLibrary(this, this.accessGroup, this.selectedLibrary);
                this.$emit('change');
                this.closeModal();
            },
            closeModal: function() {
                this.$emit('close');
            }
        }
    };
</script>
