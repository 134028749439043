<template>
    <span
        v-if="hasValue && getBool">
        <b-icon
            :icon="'check-circle'"
            :custom-size="customSize"
            :size="size"
            style="color: green" />
        <span v-if="hasYesNo"> Yes</span>
    </span>
    <span
        v-else-if="hasValue && !getBool">
        <b-icon
            :icon="'close-circle'"
            :custom-size="customSize"
            :size="size"
            style="color: red" />
        <span v-if="hasYesNo"> No</span>
    </span>
</template>

<script>
    export default {
        name: 'BoolElement',
        props: {
            value: {
                type: [String, Boolean],
                default: null
            },
            size: {
                type: String,
                required: false,
                default: 'is-small'
            },
            customSize: {
                type: String,
                required: false,
                default: ''
            },
            hasYesNo: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            hasValue: function() {
                return (
                    typeof this.value === 'boolean'
                    || typeof this.value === 'string' && this.value.match(/^(true|false)$/i)
                );
            },
            getBool: function() {
                if (typeof this.value === 'boolean') return this.value;

                return this.value.toLowerCase() === 'true';
            }
        }
    };
</script>
