<template>
    <div>
        <div class="level">
            <div class="level-left">
                <div class="level-item title">
                    Piping Class & Size
                </div>
            </div>
            <div class="level-left">
                <div class="level-item subtitle">
                    <strong>{{ subTitle }}</strong>
                </div>
            </div>
        </div>
        <div class="level is-mobile">
            <div class="level-left">
                <scope-dropdown
                    scope-type="Facility" />
            </div>
            <b-tooltip
                class="level-right"
                label="View piping class sheet">
                <b-button
                    v-if="showTR2000"
                    class="level-item button is-info"
                    @click="openTR2000Link">
                    <i class="fa-solid fa-arrow-up-right-from-square" />
                    TR2000 PCS
                </b-button>
            </b-tooltip>
        </div>
        <hr>
        <b-tabs
            v-model="activeTab"
            type="is-boxed"
            style="margin-top: 10px">
            <b-tab-item
                label="Piping Class">
                <piping-class-tab
                    :piping-classes="pipingClasses"
                    :loading-piping-classes="loadingPipingClasses"
                    :code-set-name="currentPipingClassCodeSetName"
                    :loading-code-set-name="loadingCurrentPipingClassCodeSetName"
                    :referable-codes="referableCodes"
                    :selected-scope="selectedScope"
                    :view-valid-only="viewValidOnly"
                    :user-selected-piping-class="userSelectedPipingClass"
                    @selectPipingClass="onSelectPipingClassInPipingClassSizeTab"
                    @navigateToPipingClassTab="onNavigateToPipingClassSizeTab"
                    @savedPipingClass="loadPipingClasses(selectedScope)" />
            </b-tab-item>
            <b-tab-item
                label="Piping Class Size">
                <piping-class-size-tab
                    :piping-class-sizes="pipingClassSizes"
                    :loading-piping-class-sizes="loadingPipingClassSizes"
                    :code-set-name="currentPipingClassSizeCodeSetName"
                    :loading-code-set-name="loadingCurrentPipingClassSizeCodeSetName"
                    :piping-classes="pipingClasses"
                    :loading-piping-classes="loadingPipingClasses"
                    :selected-scope="selectedScope"
                    :view-valid-only="viewValidOnly"
                    :user-selected-piping-class="userSelectedPipingClass"
                    @selectPipingClass="onSelectPipingClassInPipingClassSizeTab"
                    @savedPipingClassSize="loadPipingClassSizes(selectedScope, userSelectedPipingClass.Identity)" />
            </b-tab-item>
            <b-tab-item
                label="Line Size">
                <line-size-tab
                    :line-sizes="lineSizes"
                    :loading-line-sizes="loadingLineSizes"
                    :code-set-name="currentLineSizeCodeSetName"
                    :loading-code-set-name="loadingCurrentLineSizeCodeSetName"
                    :selected-scope="selectedScope"
                    :view-valid-only="viewValidOnly"
                    @savedLineSize="lineSizeReload(selectedScope, userSelectedPipingClass?.Identity)" />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import LineSizeTab from './LineSizeTab.vue';
    import PipingClassSizeTab from './PipingClassSizeTab.vue';
    import PipingClassTab from './PipingClassTab.vue';
    import { fetchCodeEditContext, genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import _ from 'lodash';
    import ScopeDropdown from '@/shared/components/ScopeDropdown.vue';
    import { mapStores } from 'pinia';
    import { useScopeStore } from '@/stores/scopeStore.js';

    export default {
        components: {
            ScopeDropdown,
            PipingClassTab,
            PipingClassSizeTab,
            LineSizeTab
        },
        data() {
            return {
                activeTab: 0,
                pipingClasses: [],
                pipingClassSizes: [],
                lineSizes: [],
                userSelectedPipingClass: null,
                viewValidOnly: true,
                currentPipingClassCodeSetName: null,
                currentLineSizeCodeSetName: null,
                currentPipingClassSizeCodeSetName: null,
                loadingPipingClasses: false,
                loadingLineSizes: false,
                loadingPipingClassSizes: false,
                loadingCurrentPipingClassCodeSetName: false,
                loadingCurrentLineSizeCodeSetName: false,
                loadingCurrentPipingClassSizeCodeSetName: false,
                referableCodes: null
            };
        },
        computed: {
            ...mapStores(useScopeStore),
            selectedScope() {
                return this.scopeStore.getSelectedScope();
            },
            subTitle() {
                if (this.selectedScope && !this.userSelectedPipingClass) {
                    return this.selectedScope;
                } else if (this.selectedScope && this.userSelectedPipingClass) {
                    return `${this.selectedScope} / ${this.userSelectedPipingClass.Identity}`;
                } else {
                    return '';
                }
            },
            showTR2000() {
                return !!(this.selectedScope && this.userSelectedPipingClass?.Identity);
            }
        },
        watch: {
            async selectedScope(newScope) {
                if (!newScope) { return; }

                await this.loadForScope(newScope);
            }
        },
        async mounted() {
            if (this.selectedScope !== null) {
                await this.loadForScope(this.selectedScope);
            }
        },
        methods: {
            async loadForScope(scope) {
                this.userSelectedPipingClass = null;
                this.pipingClassSizes = [];
                await Promise.all([
                    this.loadPipingClasses(scope),
                    this.loadLineSizes(scope),
                    this.loadPipingClassCodeSetName(scope).then(async () => {
                        const codeSetName = this.currentPipingClassCodeSetName;
                        if (codeSetName === null) { return; }
                        await this.loadReferableCodes(codeSetName);
                    }),
                    this.loadLineSizeCodeSetName(scope),
                    this.loadPipingClassSizeCodeSetName(scope)
                ]);
            },
            sortByNumbers(obj) {
                return parseFloat(obj.Name);
            },
            async loadPipingClasses(scopeName) {
                this.loadingPipingClasses = true;
                const res = await genericViewQueryAsText(
                    this,
                    'FROM PipingClass WHERE Scope = "@Scope" ORDER BY Name',
                    [{ name: '@Scope', value: scopeName }]
                );
                this.pipingClasses = res.data;
                this.loadingPipingClasses = false;
            },
            async loadLineSizes(scopeName) {
                this.loadingLineSizes = true;
                const res = await genericViewQueryAsText(
                    this,
                    'FROM LineSize WHERE Scope = "@Scope"',
                    [{ name: '@Scope', value: scopeName }]
                );

                this.lineSizes = _.sortBy(res.data, this.sortByNumbers);
                this.loadingLineSizes = false;
            },
            async loadPipingClassSizes(scopeName, pipingClassIdentity) {
                this.loadingPipingClassSizes = true;
                const res = await genericViewQueryAsText(
                    this,
                    'FROM PipingClassSize WHERE Scope = "@Scope" AND PipingClass = "@PipingClass"',
                    [{ name: '@Scope', value: scopeName }, { name: '@PipingClass', value: pipingClassIdentity }]
                );

                this.pipingClassSizes = _.sortBy(res.data, this.sortByNumbers);
                this.loadingPipingClassSizes = false;
            },
            async loadPipingClassCodeSetName(scopeName) {
                this.loadingCurrentPipingClassCodeSetName = true;
                this.currentPipingClassCodeSetName = await this.fetchCodeSetName('PipingClass', scopeName);
                this.loadingCurrentPipingClassCodeSetName = false;
            },
            async loadLineSizeCodeSetName(scopeName) {
                this.loadingCurrentLineSizeCodeSetName = true;
                this.currentLineSizeCodeSetName = await this.fetchCodeSetName('LineSize', scopeName);
                this.loadingCurrentLineSizeCodeSetName = false;
            },
            async loadPipingClassSizeCodeSetName(scopeName) {
                this.loadingCurrentPipingClassSizeCodeSetName = true;
                this.currentPipingClassSizeCodeSetName = await this.fetchCodeSetName('PipingClassSize', scopeName);
                this.loadingCurrentPipingClassSizeCodeSetName = false;
            },
            async loadReferableCodes(pipingClassCodeSetName) {
                const res = (await fetchCodeEditContext(this, { codeSetName: pipingClassCodeSetName })).data;
                this.referableCodes = res.referableCodes;
            },
            async fetchCodeSetName(libraryName, scopeName) {
                const res = await getCodeSets(this, libraryName, scopeName);
                return res.length === 1 ? res[0].name : null;
            },
            async onSelectPipingClassInPipingClassSizeTab(newPipingClass) {
                if (!this.loadingPipingClassSizes) {
                    await this.loadPipingClassSizes(this.selectedScope, newPipingClass.Identity);
                    this.userSelectedPipingClass = newPipingClass;
                }
            },
            async lineSizeReload(scope, identity) {
                await this.loadLineSizes(scope);
                if (identity) {
                    await this.loadPipingClassSizes(scope, identity);
                }
            },
            onNavigateToPipingClassSizeTab() {
                this.activeTab = 1;
            },
            openTR2000Link() {
                if (this.selectedScope && this.userSelectedPipingClass?.Identity) {
                    const url = `https://tr2000.equinor.com/TR2000/integration/get_datasheet.jsp?SYS=STID&DS=PCS&ID=${this.userSelectedPipingClass.Identity}&PLANT=${this.selectedScope}`;
                    window.open(url, '_blank', 'noopener');
                }
            }
        }
    };
</script>

<style scoped>

</style>
