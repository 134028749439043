<template>
    <b-dropdown
        scrollable
        aria-role="list">
        <template #trigger>
            <b-tooltip
                :label="messageIfEmpty"
                :active="isDisabled"
                position="is-bottom">
                <b-button
                    :label="title"
                    :loading="isLoading"
                    :disabled="isDisabled"
                    type="is-info"
                    icon-right="menu-down" />
            </b-tooltip>
        </template>

        <b-dropdown-item
            custom
            position="is-centered">
            <b-input
                v-model="searchString"
                placeholder="Search..."
                type="search"
                icon-pack="fas"
                icon="search" />
        </b-dropdown-item>

        <b-dropdown-item
            custom
            position="is-centered">
            <p class="control">
                <b-button
                    size="is-small"
                    @click="clearFilter()">
                    Clear selected
                </b-button>
            </p>
        </b-dropdown-item>
        <b-dropdown-item
            v-for="option in filteredOptions"
            :key="option[selector]"
            custom
            position="is-centered">
            <p class="control">
                <b-checkbox
                    :model-value="selected.includes(option)"
                    @update:model-value="selectOption(option)">
                    {{ selector ? option[selector] : option }}
                </b-checkbox>
            </p>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
    export default {
        props: {
            options: {
                type: Array,
                default: () => []
            },
            title: {
                type: String,
                required: true
            },
            selector: {
                type: String,
                default: null
            },
            messageIfEmpty: {
                type: String,
                default: ''
            },
            isLoading: {
                type: Boolean,
                default: false
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },
        emits: [
            'update'
        ],
        data: function() {
            return {
                searchString: '',
                selected: []
            };
        },
        computed: {
            filteredOptions() {
                if (!this.options || !this.options.length)
                    return [];
                const searchSelector = this.selector
                    ? option => option[this.selector]
                    : option => option;
                return this.options.filter(option => searchSelector(option).toLowerCase().includes(this.searchString.toLowerCase()));
            }
        },
        methods: {
            clearFilter: function() {
                this.selected = [];
                this.$emit('update', this.selected);
            },
            selectOption: function(option) {
                this.selected = this.selected.includes(option)
                    ? this.selected.filter(o => o !== option)
                    : this.selected.concat([option]);
                this.$emit('update', this.selected);
            }
        }
    };
</script>

<style scoped>

</style>
