<template>
    <div
        v-if="selectedEleComponentType">
        <div class="column-header block">
            <h4 class="title is-4">
                {{
                    selectedEleComponentType && selectedEleComponentType.Id ? selectedEleComponentType.Description : "New component type"
                }}
            </h4>
        </div>
        <div
            class="radio-vertical-list">
            <b-field label="Codeset (affected facilities)">
                {{ getCodeSetAsString() }}
            </b-field>
            <b-tooltip
                label="Required field"
                position="is-right">
                <b-field
                    label="Description*" />
            </b-tooltip>
            <b-field
                :type="findFieldType"
                :message="invalidInputError">
                <b-input
                    v-model="selectedEleComponentType.Description"
                    :required="true"
                    style="margin-right:20px;"
                    :maxlength="showMaxLength()"
                    @update:model-value="handleEleComponentTypeChange()" />
            </b-field>
            <b-field label="Parent component type">
                <b-select
                    v-model="selectedEleComponentType.SuperType"
                    placeholder="Select parent component type"
                    name="Parent component type"
                    @update:model-value="handleEleComponentTypeChange()">
                    <option value="">
                        (No parent component type)
                    </option>
                    <option
                        v-for="p in possibleParentEleComponentTypes"
                        :key="p.Id"
                        :value="p.Name">
                        {{ p.Description || p.Name }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="Battery functionality">
                <b-checkbox
                    v-model="selectedEleComponentType.BatteryFunctionality"
                    type="is-info"
                    @update:model-value="handleEleComponentTypeChange()" />
            </b-field>
            <b-tooltip
                label="Required field"
                position="is-right">
                <b-field label="Drawer*" />
            </b-tooltip>
            <b-field
                v-for="u in eleComponentTypeDrawers"
                :key="u.Identity">
                <b-radio
                    v-model="selectedEleComponentType.Drawer"
                    :native-value="u.Identity"
                    @update:model-value="handleEleComponentTypeChange()">
                    {{ `${u.Name} - ${u.Description}` }}
                </b-radio>
            </b-field>
            <b-tooltip
                label="Required field"
                position="is-right">
                <b-field label="Component usage*" />
            </b-tooltip>
            <b-field
                v-for="u in eleComponentTypeUsages"
                :key="u.Identity">
                <b-radio
                    v-model="selectedEleComponentType.Usage"
                    :native-value="u.Identity"
                    @update:model-value="handleEleComponentTypeChange()">
                    {{ `${u.Name} - ${u.Description}` }}
                </b-radio>
            </b-field>

            <b-field label="Symbol">
                <b-select
                    v-model="selectedEleComponentType.Symbol"
                    placeholder="Select symbol"
                    name="Symbol"
                    @update:model-value="handleEleComponentTypeChange()">
                    <option value="">
                        (No symbol)
                    </option>
                    <option
                        v-for="c in eleSymbolCodes"
                        :key="c.Id"
                        :value="c.Name">
                        {{ c.Description }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="Is valid">
                <b-checkbox
                    v-model="selectedEleComponentType.IsValid"
                    type="is-info"
                    @update:model-value="handleEleComponentTypeChange()" />
            </b-field>
        </div>
    </div>
</template>
<script>
    import { getCodeSets, genericViewQueryAsText, getLibrary } from '@/shared/helpers/api';
    import { InputRegexValidator } from '@/shared/helpers/inputRegexValidator.ts';
    import { http } from '@/shared/httpWrapper.js';

    export default {
        props: {
            initialEleComponentType: {
                type: Object,
                required: true
            },
            selectedScope: {
                type: String,
                required: true
            },
            libraryName: {
                type: String,
                required: false,
                default: null
            }
        },
        emits: [
            'handleEleComponentTypeChange'
        ],
        data() {
            return {
                selectedEleComponentType: null,
                codeSet: null,
                eleComponentTypeUsages: [],
                eleComponentTypeDrawers: [],
                eleSymbolCodes: [],
                possibleParentEleComponentTypes: [],
                descriptionRegexValidator: null
            };
        },
        computed: {
            invalidInputError() {
                return this.descriptionRegexValidator?.validate(this.selectedEleComponentType.Description)?.error;
            },
            findFieldType() {
                return this.invalidInputError ? 'is-danger' : '';
            }
        },
        watch: {
            initialEleComponentType: async function() {
                await this.initForm();
            }
        },
        async mounted() {
            const [
                eleComponentTypeUsagesResponse,
                eleComponentTypeDrawersResponse,
                eleSymbolCodesResponse
            ] = await Promise.all([
                this.getEleComponentTypeUsages(),
                this.getEleComponentTypeDrawers(),
                this.getEleSymbolCodes()
            ]);
            this.eleComponentTypeUsages = eleComponentTypeUsagesResponse.data;
            this.eleComponentTypeDrawers = eleComponentTypeDrawersResponse.data;
            this.eleSymbolCodes = eleSymbolCodesResponse.data;
            await Promise.all([
                this.initForm(),
                this.loadDescriptionRegex()
            ]);
        },
        methods: {
            async initForm() {
                this.selectedEleComponentType = JSON.parse(JSON.stringify(this.initialEleComponentType));
                const [
                    codeSetResponse,
                    possibleParentEleComponentTypesResponse
                ] = await Promise.all([
                    this.getCodeSet(),
                    this.getPossibleParentEleComponentTypes()
                ]);
                // NB! This needs to be set here as well as above in order to avoid the parent component type being empty (Vue timing issue)
                this.selectedEleComponentType = JSON.parse(JSON.stringify(this.initialEleComponentType));
                this.codeSet = codeSetResponse[0];
                this.possibleParentEleComponentTypes = possibleParentEleComponentTypesResponse.data;
                this.handleEleComponentTypeChange();
            },
            async loadDescriptionRegex() {
                const library = await getLibrary(http, this.$props.libraryName);
                const descriptionRegex = library.descriptionRegex;
                this.descriptionRegexValidator = descriptionRegex
                    ? new InputRegexValidator(new RegExp(descriptionRegex))
                    : null;
            },
            showMaxLength: function() {
                return this.invalidInputError
                    ? this.descriptionRegexValidator?.maxLength
                    : undefined;
            },
            handleEleComponentTypeChange() {
                this.$emit('handleEleComponentTypeChange',
                           this.selectedEleComponentType,
                           !!this.invalidInputError);
            },
            getEleComponentTypeUsages: function() {
                return genericViewQueryAsText(
                    this,
                    'FROM ElectricComponentTypeUsage WHERE IsValid=true'
                );
            },
            getEleComponentTypeDrawers: function() {
                return genericViewQueryAsText(
                    this,
                    'FROM ElectricComponentTypeDrawer WHERE IsValid=true'
                );
            },
            getEleSymbolCodes: function() {
                return genericViewQueryAsText(
                    this,
                    'FROM ElectricSymbolCode WHERE IsValid=true ORDER BY Description'
                );
            },
            getPossibleParentEleComponentTypes: function() {
                if (this.selectedEleComponentType.Id) {
                    return genericViewQueryAsText(
                        this,
                        'FROM ElectricComponentType '
                            + 'WHERE SuperType_ID is null '
                            + 'And Scope = @Scope '
                            + 'And Id != @Id '
                            + 'ORDER BY Description',
                        [
                            {
                                name: '@Scope',
                                value: this.selectedScope
                            },
                            {
                                name: '@Id',
                                value: this.selectedEleComponentType.Id
                            }
                        ]
                    );
                } else {
                    return genericViewQueryAsText(
                        this,
                        'FROM ElectricComponentType WHERE SuperType_ID is null And Scope = @Scope ORDER BY Description',
                        [{ name: '@Scope', value: this.selectedScope }]
                    );
                }
            },
            getCodeSet: function() {
                return getCodeSets(this, 'ElectricComponentType', this.selectedScope);
            },
            getCodeSetAsString: function() {
                return this.codeSet ? this.codeSet.scopes.join(', ') : '';
            }

        }
    };

</script>

<style scoped>
.b-tooltip {
    margin-bottom: 6.4px;
}
</style>
