<!--
    General controller to define a selection (subset) of elements in a set.
-->
<template>
    <div>
        <div class="columns">
            <div class="column">
                <nav class="panel">
                    <p class="panel-heading">
                        {{ availableElementsTitle }}
                    </p>
                    <div class="panel-block">
                        <div class="elementsBox">
                            <div
                                v-for="e in filteredWrappedElements"
                                :key="identity(e.element)"
                                :class="elementClass(e)"
                                class="clickable"
                                @click="elementClicked(e)">
                                <slot
                                    name="left-item"
                                    :element="e.element">
                                    <!-- Fallback content -->
                                    {{ identity(e.element) }}
                                </slot>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div
                class="column is-2"
                style="padding-top:50px;">
                <b-button
                    expanded
                    class="is-light"
                    @click="remove">
                    <b-icon
                        icon="arrow-left"
                        size="is-small" />
                    <span>
                        Remove
                    </span>
                </b-button>
                <b-button
                    style="margin-top:5px;"
                    class="is-light"
                    expanded
                    @click="add">
                    <b-icon
                        icon="arrow-right"
                        size="is-small" />
                    <span>
                        Add
                    </span>
                </b-button>
                <b-button
                    style="margin-top:15px;"
                    :class="{'is-primary': true, 'is-loading': saveLoading}"
                    expanded
                    :disabled="!hasChanges"
                    @click="save">
                    <b-icon
                        icon="floppy"
                        size="is-small" />
                    <span>
                        Save
                    </span>
                </b-button>
                <b-button
                    style="margin-top:5px;"
                    class="is-light"
                    expanded
                    :disabled="!hasChanges"
                    @click="reset">
                    <b-icon
                        icon="refresh"
                        size="is-small" />
                    <span>
                        Reset
                    </span>
                </b-button>
            </div>
            <div class="column">
                <nav class="panel">
                    <p class="panel-heading">
                        {{ selectedElementsTitle }}
                    </p>
                    <div class="panel-block">
                        <div class="elementsBox">
                            <div
                                v-for="e in wrappedSelection"
                                :key="identity(e.element)"
                                :class="elementClass(e)"
                                class="clickable"
                                @click="elementClicked(e)">
                                <slot
                                    name="right-item"
                                    :element="e.element">
                                    <!-- Fallback content -->
                                    {{ identity(e.element) }}
                                </slot>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'SetSelector',
        props: {
            initialElements: {
                default: () => [],
                type: Array
            },
            initialSelection: {
                default: () => [],
                type: Array
            },
            identity: {
                type: Function,
                required: true
            },
            filter: {
                default: x => x,
                type: Function
            },
            sortBy: {
                default: x => x,
                type: [Array, Function]
            },
            availableElementsTitle: {
                default: 'Available elements',
                type: String
            },
            selectedElementsTitle: {
                default: 'Selected elements',
                type: String
            }
        },
        emits: [
            'save'
        ],
        data: function() {
            return {
                wrappedElements: [],
                wrappedSelection: [],
                saveLoading: false
            };
        },
        computed: {
            filteredWrappedElements: function() {
                const self = this;
                return this.wrappedElements.filter(function(x) {
                    return self.filter(x.element);
                });
            },
            hasChanges: function() {
                return !_.isEqual(_.sortBy(this.initialSelection, o => o.name), _.sortBy(this.wrappedSelection.map(x => x.element), o => o.name));
            }
        },
        watch: {
            initialElements: function() {
                this.wrappedElements = _.sortBy(this.initialElements.map(this.wrap), this.sortBy);
            }
        },
        created: function() {
            this.init();
        },
        methods: {
            init: function() {
                this.wrappedElements = _.sortBy(this.initialElements.map(this.wrap), this.sortBy);
                this.wrappedSelection = _.sortBy(this.initialSelection.map(this.wrap), this.sortBy);
            },
            wrap: function(e) {
                return {
                    element: e,
                    clicked: false
                };
            },
            add: function() {
                this.move('wrappedElements', 'wrappedSelection');
            },
            remove: function() {
                this.move('wrappedSelection', 'wrappedElements');
            },
            move: function(from, to) {
                const [toMove, toKeep] = _.partition(this[from], this.elementIsClicked);
                this[from] = toKeep;
                toMove.forEach(x => x.clicked = false);
                this[to] = _.sortBy(this[to].concat(toMove), this.sortBy);
            },
            save: function() {
                this.saveLoading = true;
                // Identify diff between this.initialSelection and this.wrappedSelection
                const initial = this.initialSelection.map(x => x);
                const current = this.wrappedSelection.map(x => x.element);
                const eventState = {
                    added: _.difference(current, initial),
                    removed: _.difference(initial, current)
                };
                this.$emit('save', eventState);
                // Control should be re-loaded by hosting container
            },
            reset: function() {
                this.init();
            },
            elementIsClicked: function(e) {
                return e.clicked;
            },
            elementClicked: function(e) {
                e.clicked = !e.clicked;
            },
            elementClass: function(e) {
                return {
                    selected: e.clicked
                };
            }
        }
    };
</script>

<style scoped>
.elementsBox {
    height:500px;
    width: 100%;
    overflow:scroll;
    padding: 4px;
}
.selected {
    border: solid 1px blue;
}
.clickable :hover{
    cursor: pointer;
}
</style>
