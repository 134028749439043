<template>
    <div
        class="level"
        style="position:relative">
        <b-loading
            :is-full-page="false"
            :model-value="loading" />
        <ul class="process flat">
            <li :class="progressClass('Open')">
                Open
            </li>
            <li :class="progressClass('Closed')">
                Closed
            </li>
            <li :class="progressClass('Verifying')">
                Verifying
            </li>
            <li :class="progressClass('Verified')">
                Verified
            </li>
            <li :class="progressClass('Approved')">
                Approved
            </li>
            <li :class="progressClass('Committing')">
                Committing
            </li>
            <li :class="progressClass('Committed')">
                Committed
            </li>
            <li :class="progressClass('Publishing')">
                Publishing
            </li>
            <li :class="progressClass('Published')">
                Published
            </li>
        </ul>
        <div
            v-if="!loading"
            class="field is-grouped is-pulled-right">
            <p
                v-if="logButton"
                class="control">
                <router-link
                    :to="getReleaseLogLink({releaseId: release.id})"
                    :class="['button', logButtonClass]">
                    <b-icon
                        icon="format-list-numbered"
                        size="is-small" />
                    <span>Release log</span>
                </router-link>
            </p>
            <p
                v-if="(release.state === 'Open') || ( release.state === 'Verified' && release.specification.specificationType === 'SubscriptionVerification') "
                class="control">
                <button
                    v-if="isMine"
                    class="button is-primary"
                    @click="transition('Discarded')">
                    <b-icon
                        icon="delete-outline"
                        size="is-small" />
                    <span>Discard</span>
                </button>
                <button
                    v-else
                    v-require-can-transition-release="release.id"
                    class="button is-primary"
                    @click="transition('Discarded')">
                    <b-icon
                        icon="delete-outline"
                        size="is-small" />
                    <span>Discard</span>
                </button>
            </p>
            <p
                v-if="release.state === 'Verified' && !hasErrors"
                class="control">
                <button
                    v-require-can-transition-release="release.id"
                    class="button is-primary"
                    @click="transition('Open')">
                    <b-icon
                        icon="replay"
                        size="is-small" />
                    <span>Re-open</span>
                </button>
            </p>
            <p
                v-if="isAbortable"
                class="control">
                <b-button
                    v-require-can-transition-release="release.id"
                    :loading="isAbortPublishing"
                    @click="confirmAbortPublishing()">
                    <b-icon
                        icon="stop"
                        size="is-small" />
                    <span>Abort publishing</span>
                </b-button>
            </p>
            <p
                v-if="linkToReleaseOnError && (hasErrors || hasJobErrors)"
                class="control">
                <router-link
                    :to="getReleaseLink(release.id)"
                    class="button">
                    <span> Show Release </span>
                </router-link>
            </p>
            <p
                v-else-if="release.state === 'Open' && !hasErrors"
                class="control">
                <button
                    v-require-can-transition-release="release.id"
                    class="button is-info"
                    @click="verify">
                    <b-icon
                        icon="play-circle-outline"
                        size="is-small" />
                    <span>Verify</span>
                </button>
            </p>
            <p
                v-else-if="release.state === 'Verified' && !hasErrors && release.specification.specificationType !== 'SubscriptionVerification'"
                class="control">
                <button
                    v-require-can-transition-release="release.id"
                    class="button is-info"
                    @click="transition('Approved')">
                    <b-icon
                        icon="play-circle-outline"
                        size="is-small" />
                    <span>Commit</span>
                </button>
            </p>
            <p
                v-else-if="!['Verified', 'Discarded', 'Published', 'Open'].includes(release.state)"
                class="control">
                <button class="button is-info is-loading">
                    <span>......</span>
                </button>
            </p>
            <b-modal
                v-model="approveModal"
                width="100"
                has-modal-card>
                <verify-release
                    :release="release"
                    @verify="handleVerifyRelease('Closed')" />
            </b-modal>
        </div>
    </div>
</template>

<script>

    import { isAdministrator, requireCanTransitionRelease } from '@/shared/directives/requirePermission.js';
    import { abortPublishingRelease, getLibrary, getMyUserName, transitionRelease } from '@/shared/helpers/api.ts';
    import { getReleaseLink, getReleaseLogLink } from '@/shared/helpers/routing.js';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import VerifyRelease from './VerifyRelease.vue';

    export default {
        components: {
            VerifyRelease
        },
        directives: {
            'require-can-transition-release': requireCanTransitionRelease
        },
        mixins: [
            showMixin
        ],
        props: {
            release: {
                type: Object,
                required: true,
                default: () => { }
            },
            verifyModal: {
                type: Boolean,
                required: false,
                default: false
            },
            logButton: {
                type: Boolean,
                required: false,
                default: false
            },
            logButtonClass: {
                type: String,
                required: false,
                default: 'is-info'
            },
            linkToReleaseOnError: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        emits: [
            'changeState',
            'showAccessDeniedMessage'
        ],
        data: function() {
            return {
                stateList: ['Discarded', 'Open', 'Closed', 'Verifying', 'Verified', 'Approved', 'Committing', 'Committed', 'Publishing', 'Published'],
                specification: this.release.specification,
                approveModal: false,
                libraryAg: [],
                loading: true,
                myUserId: null,
                isAdmin: false,
                isAbortPublishing: false
            };
        },
        computed: {
            hasJobErrors: function() {
                return this.release.jobs && this.release.jobs.some(j => j.state === 'Fail');
            },
            hasErrors: function() {
                return this.release.specification.errors && this.release.specification.errors.length;
            },
            isMine: function() {
                if (!this.loading && this.release.createdBy) {
                    return this.myUserId === this.release.createdBy.username;
                }

                return false;
            },
            isAbortable() {
                if (this.release.state !== 'Publishing')
                    return false;

                const publishJobs = this.release.jobs.filter(job => job.type === 'Publish');

                if (publishJobs.length === 0)
                    return false;

                if (publishJobs.some(job => job.retryCount === 0))
                    return false;

                if (this.isAdmin)
                    return true;

                return publishJobs.every(job => job.retryCount > 2);
            }
        },
        watch: {
            specification: {
                handler: async function() {
                    this.loading = true;
                    await this.getAccessIfProcessed();
                },
                deep: true
            }
        },
        mounted: async function() {
            this.myUserId = await getMyUserName(this);
            this.isAdmin = await isAdministrator();
            await this.getAccessIfProcessed();
        },
        methods: {
            confirmAbortPublishing: async function() {
                if (this.isAbortPublishing) return;

                await this.$buefy.dialog.confirm({
                    title: 'Please confirm abort publishing',

                    message: 'Abort publishing stops publishing jobs and sets release to published.<br><br> '
                        + '<b>Disclamer</b>:<br>'
                        + 'If publishing jobs is currently being run, state of release and jobs might be overwritten by the publishing service.',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => await this.abortPublishing()
                });
            },
            abortPublishing: async function() {
                if (this.isAbortPublishing) return;

                this.isAbortPublishing = true;

                try {
                    await abortPublishingRelease(this, this.release.id);
                    this.$emit('changeState', 'Published');
                } catch (ex) {
                    this.showError(ex);
                }

                this.isAbortPublishing = false;
            },
            transition: function(state) {
                transitionRelease(this, this.release.id, state)
                    .then(() => this.$emit('changeState', state))
                    .catch(err => {
                        if (err.response.status === 403) {
                            this.$emit('showAccessDeniedMessage', this.release.id);
                        }
                    });
            },
            handleVerifyRelease: function(state) {
                this.transition(state);
                this.approveModal = false;
            },
            getAccessIfProcessed: async function() {
                const self = this;
                const spec = this.release.specification;
                const libraryNames = [];
                const codeSetNames = [];

                if (!spec) return;

                if (spec.isComposite) {
                    for (const i in spec.libraries) {
                        libraryNames.push(spec.libraries[i]);
                    }
                    for (const j in spec.codeSets) {
                        codeSetNames.push(spec.codeSets[j]);
                    }
                } else if (spec.libraryName && spec.codeSetName) {
                    libraryNames.push(spec.libraryName);
                    codeSetNames.push(spec.codeSetName);
                }

                /*
                    WARNING: libraryAg is not used. Access control in the state changer
                    does not seem complete / up to date. Keeping loading of libraryAg in place for now.
                 */
                self.libraryAg.splice();

                for (const i in libraryNames) {
                    const lib = await getLibrary(self, libraryNames[i]);
                    lib.accessGroups.forEach(ag => self.libraryAg.push(ag));
                }
                self.loading = false;
            },
            verify: function() {
                if (this.verifyModal)
                    this.approveModal = true;
                else
                    this.transition('Closed');
            },
            progressClass: function(state) {
                return { active: this.stateList.indexOf(this.release.state) >= this.stateList.indexOf(state) };
            },
            getReleaseLogLink,
            getReleaseLink
        }
    };
</script>
