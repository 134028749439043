<template>
    <div class="wrapper">
        <p><a :href="'/Schema#/specification/' + identity">&laquo; Back to schema</a></p>
        <h1 class="title">
            Edit classes in Schema: {{ identity }}
        </h1>
        <div class="columns">
            <div class="list-column">
                <h5
                    class="subtitle is-5 column-header-light">
                    Classes
                </h5>
                <div
                    v-require-can-edit-code="{ libraryName: 'SchemaClass' }"
                    class="field is-grouped">
                    <div class="control buttons">
                        <b-button
                            type="is-primary"
                            @click="createNewClass">
                            New
                        </b-button>
                        <b-tooltip
                            label="SchemaClass library is configured locked for deletions"
                            :active="classListLockedForDelete">
                            <b-button
                                type="is-danger"
                                :disabled="classListLockedForDelete || !selectedClass"
                                @click="() => confirmDeleteCode(SchemaClassCodesetName, selectedClass.Identity)">
                                <b-icon
                                    icon="skull"
                                    size="is-small" />
                                <span>Delete</span>
                            </b-button>
                        </b-tooltip>
                    </div>
                </div>
                <div class="table-wrapper">
                    <b-table
                        :key="classListUpdateKey"
                        :data="classes"
                        class="cursorPointer"
                        :columns="column_class"
                        :hoverable="true"
                        :selected="selectedClass"
                        @click="updateElements" />
                </div>
            </div>
            <div class="attributes-wrapper">
                <h5
                    class="subtitle is-5 column-header-light">
                    {{ selectedClass ? `Class properties for ${selectedClass.Name}` : 'Class properties' }}
                </h5>
                <div class="code-edit-wrapper">
                    <code-edit
                        v-if="selectedClass !== null"
                        :key="codeEditUpdateKey"
                        :quick-commit="true"
                        library="SchemaClass"
                        :code-id="codeIdEncoded"
                        :referable-codes-filters="codeEditFilters" />
                    <div
                        v-else-if="newClassEditorActive">
                        <code-edit
                            library="SchemaClass"
                            :code-set-name="SchemaClassCodesetName"
                            :code-template-values="{'Schema': identity}"
                            submit-button-label="Save new class"
                            :referable-codes-filters="codeEditFilters"
                            @refresh="afterClassCreated"
                            @cancel="newClassEditorActive = false" />
                    </div>
                    <span v-else>Select a class</span>
                </div>
            </div>
            <div class="list-column">
                <h5
                    class="subtitle is-5 column-header-light">
                    {{ selectedClass ? `Interfaces realized by ${selectedClass.Name}` : 'Interfaces' }}
                </h5>
                <div
                    v-require-can-edit-code="{ libraryName: 'SchemaClassInterface' }"
                    class="field is-grouped">
                    <div class="control buttons">
                        <b-button
                            type="is-primary"
                            :disabled="selectedClass === null"
                            @click="addInterface">
                            {{ newInterfaceList.length > 0 ? 'Modify' : 'Add' }}
                        </b-button>
                        <b-button
                            type="is-primary"
                            :disabled="!canSaveInterfaceChange"
                            @click="releaseInterfaceChanges">
                            Save
                        </b-button>
                        <b-tooltip
                            label="SchemaClassInterface library is configured locked for deletions"
                            :active="classInterfaceLockedForDelete">
                            <b-button
                                type="is-danger"
                                :disabled="classInterfaceLockedForDelete || !selectedClassInterface || selectedClassInterface.isNew"
                                @click="() => confirmDeleteCode(SchemaClassInterfaceCodeset, selectedClassInterface.Identity)">
                                <b-icon
                                    icon="skull"
                                    size="is-small" />
                                <span>Remove</span>
                            </b-button>
                        </b-tooltip>
                    </div>
                </div>
                <div style="margin-top:5px;margin-bottom:5px;">
                    <span><i>(M): mandatory interface</i> </span><br>
                    <span><i>*: unsaved interface</i> </span>
                </div>
                <div class="table-wrapper">
                    <spinner
                        :fullscreen="false"
                        :loading="loadingInterfaces">
                        <b-table
                            v-if="selectedClass !== null && interfaces.length > 0"
                            :key="interfaceListUpdateKey"
                            v-model:selected="selectedClassInterface"
                            :data="interfaces"
                            class="interface_table"
                            :hoverable="true">
                            <b-table-column
                                v-slot="props"
                                width="80%"
                                field="DisplayName"
                                label="DisplayName">
                                {{ props.row.DisplayName }}
                            </b-table-column>
                            <b-table-column
                                v-slot="props"
                                width="20%"
                                field="DisplaySequenceNo"
                                label="DisplaySequenceNo">
                                <b-field>
                                    <b-input
                                        :model-value="props.row.DisplaySequenceNo"
                                        type="number"
                                        step="1"
                                        @update:model-value="changeDisplaySequenceNo($event, props.row)" />
                                </b-field>
                            </b-table-column>
                        </b-table>
                        <span v-else-if="selectedClass">No interfaces found for {{ selectedClass.Name }}. </span>
                        <span v-else>Select a class</span>
                    </spinner>
                </div>
            </div>
            <b-modal
                :model-value="addInterfaceModalActive"
                :cancel-callback="cancelNewInterfaces">
                <div class="modalContainer">
                    <div class="addInterfaceList">
                        <div class="block">
                            <div
                                v-for="singleInterface in relevantInterfaces"
                                :key="singleInterface.Name">
                                <b-checkbox
                                    v-model="tempNewInterfaceList"
                                    class="interfaceListItem"
                                    :native-value="singleInterface.Name">
                                    {{ singleInterface.Name }}
                                </b-checkbox>
                                <b-checkbox
                                    v-if="tempNewInterfaceList.some(i => i === singleInterface.Name)"
                                    v-model="tempNewInterfaceListMandatory"
                                    class="interfaceMandatoryCheckbox"
                                    :native-value="singleInterface.Name">
                                    Mandatory
                                </b-checkbox>
                            </div>
                        </div>
                    </div>
                    <b-button
                        type="is-primary is-light"
                        @click="cancelNewInterfaces">
                        Cancel
                    </b-button>
                    <b-button
                        type="is-danger is-light"
                        :disabled="tempNewInterfaceList.length === 0"
                        @click="tempNewInterfaceList = []; tempNewInterfaceListMandatory = []">
                        Clear selected
                    </b-button>
                    <b-button
                        type="is-primary"
                        :disabled="tempNewInterfaceList.length === newInterfaceList.length && tempNewInterfaceListMandatory.length === newInterfaceListMandatory.length"
                        @click="addSelectedInterfaces">
                        {{ newInterfaceList.length > 0 ? 'Update' : 'Add' }}
                    </b-button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import {
        createChangeDocAndCommit,
        deleteCode,
        genericViewQuery,
        genericViewQueryAsText,
        getCodeSets
    } from '@/shared/helpers/api';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit,
            Spinner
        },
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data: function() {
            return {
                SchemaClassInterfaceCodeset: '4f1c56465ac245078c7c2438e72b2028',
                SchemaClassCodesetName: 'f474b9c896514035ba10910247a8119f',
                classes: [],
                selectedClass: null,
                selectedClassInterface: null,
                column_class: [],
                schemaClassInterfaceList: [],
                schemaInterfaceList: [],
                codeEditUpdateKey: 0,
                classListUpdateKey: 0,
                interfaceListUpdateKey: 0,
                addInterfaceModalActive: false,
                tempNewInterfaceList: [],
                tempNewInterfaceListMandatory: [],
                newInterfaceList: [],
                newInterfaceListMandatory: [],
                classListLockedForDelete: null,
                classInterfaceLockedForDelete: null,
                newClassEditorActive: false,
                schemaClassesIncludingextensions: [],
                interfaceSeqNumberChanges: [],
                loadingInterfaces: false,
                schemaVersions: []
            };
        },
        computed: {
            interfaces: function() {
                if (this.selectedClass) {
                    let filteredInterfaces = this.schemaClassInterfaceList;
                    const unsavedInterfaces = this.newInterfaceList.map(i => {
                        i.isNew = true;
                        return i;
                    });
                    filteredInterfaces = filteredInterfaces
                        .filter(i => i.Class.toLowerCase() === this.selectedClass.Identity.toLowerCase())
                        .concat(unsavedInterfaces)
                        .map(i => {
                            let tagEnding = ' ';
                            if (i.IsMandatoryInterface) {
                                tagEnding = tagEnding + '(M)';
                            }
                            if (i.isNew) {
                                tagEnding = tagEnding + '(Not saved)';
                            }
                            i.DisplayName = i.InterfaceName ? i.InterfaceName : i.Name + tagEnding;
                            return i;
                        });

                    return filteredInterfaces.sort((a, b) => a.Name.localeCompare(b.Name));
                }
                return [];
            },
            relevantInterfaces: function() {
                return this.schemaInterfaceList
                    .filter(i => {
                        return !this.interfaces.some(
                            x => { return x.Interface === i.Identity; }
                        );
                    })
                    .concat(this.newInterfaceList);
            },
            codeIdEncoded: function() {
                if (this.selectedClass) {
                    return encodeIdBase64('Code', this.selectedClass.Id);
                }
                return null;
            },
            codeEditFilters: function() {
                const filterObject = {
                    HasBaseClass: this.getBaseClassFilter(),
                    VersionIn: this.getVersionFilter(),
                    VersionOut: this.getVersionFilter()
                };

                return filterObject;
            },
            canSaveInterfaceChange: function() {
                return this.newInterfaceList.length > 0 || this.interfaceSeqNumberChanges.length > 0;
            }
        },
        mounted: function() {
            this.column_class = [{ field: 'Name' }];
            this.loadData();
        },
        methods: {
            loadData: async function() {
                this.classes = await this.getSchemaClass(this.identity);
                this.loadSchemaInterface();
                this.loadSchemaClassInterface();
                this.loadCodeSet();
                this.loadSchemaClassesIncludingExtensions();
                this.loadSchemaVersions();
            },
            getSchemaExtensions: async function() {
                return (await genericViewQueryAsText(
                    this,
                    'FROM Schema SELECT Name, Identity WHERE (Name = @schemaName OR ExtendingSchema = @schemaName) AND IsValid = true',
                    [{ name: '@schemaName', value: this.identity }])).data;
            },
            loadSchemaVersions: async function() {
                const schemaFromDb = (await genericViewQueryAsText(
                    this,
                    'FROM Schema SELECT Name, Identity, ExtendingSchema WHERE Name = @schemaName AND IsValid = true',
                    [{ name: '@schemaName', value: this.identity }])).data;

                const schema = schemaFromDb.find(x => x.Identity === this.identity);

                let query = 'FROM SchemaVersion WHERE IsValid = true AND Schema = @Schema';
                let parameters = [{ name: '@Schema', value: this.identity }];

                if (schema?.ExtendingSchema) {
                    query = 'FROM SchemaVersion WHERE IsValid = true AND (Schema = @Schema OR Schema = @SchemaExtension)';
                    parameters = [{ name: '@Schema', value: this.identity }, { name: '@SchemaExtension', value: schema.ExtendingSchema }];
                }

                this.schemaVersions = (await genericViewQueryAsText(this, query, parameters)).data;
            },
            loadSchemaClassesIncludingExtensions: async function() {
                const extensions = await this.getSchemaExtensions();
                const classes = [];
                for (const extension of extensions) {
                    classes.push(...await this.getSchemaClass(extension.Identity));
                }

                this.schemaClassesIncludingextensions = classes;
            },
            loadCodeSet: async function() {
                try {
                    const ResSchemaClassCodeset = (await getCodeSets(this, 'SchemaClass'))[0];
                    const ResSchemaClassInterfaceCodeset = (await getCodeSets(this, 'SchemaClassInterface'))[0];
                    this.classListLockedForDelete = ResSchemaClassCodeset.lockedForDelete;
                    this.classInterfaceLockedForDelete = ResSchemaClassInterfaceCodeset.lockedForDelete;
                } catch (err) {
                    this.showError('could not get library set');
                }
            },
            getSchemaClass: async function(schema) {
                const classes = (await genericViewQuery(this, {
                    version: 1,
                    name: 'SchemaClass',
                    include: [
                        {
                            field: 'Name'
                        },
                        {
                            field: 'Id'
                        },
                        {
                            field: 'Identity'
                        },
                        {
                            field: 'HasBaseClass'
                        }
                    ],
                    where: {
                        field: 'Schema',
                        operator: '=',
                        value: schema
                    }
                })).data;

                return classes.sort((a, b) => a['Name'].localeCompare(b['Name']));
            },
            loadSchemaClassInterface: async function() {
                this.schemaClassInterfaceList = (await genericViewQuery(this, {
                    'version': 1,
                    'skip': 0,
                    'take': 0,
                    'name': 'SchemaClassInterface',
                    'include': [
                        {
                            'field': 'Identity',
                            'alias': null
                        },
                        {
                            'field': 'Name',
                            'alias': null
                        },
                        {
                            'field': 'Description',
                            'alias': null
                        },
                        {
                            'field': 'Schema',
                            'alias': null
                        },
                        {
                            'field': 'Class',
                            'alias': null
                        },
                        {
                            'field': 'Interface',
                            'alias': null
                        },
                        {
                            'field': 'IsMandatoryInterface',
                            'alias': null
                        },
                        {
                            'field': 'DisplaySequenceNo',
                            'alias': null
                        }
                    ],
                    'where': {
                        'Field': 'Schema',
                        'Operator': '=',
                        'Value': this.identity
                    },
                    'join': [
                        {
                            'name': 'Interface',
                            'include': [
                                {
                                    'field': 'Name',
                                    'alias': 'InterfaceName'
                                }
                            ],
                            'where': null,
                            'join': null
                        }
                    ]
                })).data;
            },
            loadSchemaInterface: async function() {
                this.schemaInterfaceList = (await genericViewQuery(this, {
                    version: 1,
                    name: 'SchemaInterface',
                    where: [
                        {
                            'field': 'Schema',
                            'operator': '=',
                            'value': this.identity
                        }
                    ]
                })).data.sort((a, b) => a.Name.localeCompare(b.Name));
            },
            updateElements: function(klass) {
                const doUpdate = () => {
                    this.selectedClassInterface = null;
                    this.codeEditUpdateKey++;
                    this.interfaceListUpdateKey++;
                    this.newInterfaceList = [];
                    this.newInterfaceListMandatory = [];
                    this.newClassEditorActive = false;
                    this.selectedClass = klass;
                };

                if (this.newInterfaceList.length > 0) {
                    this.$buefy.dialog.confirm({
                        title: 'Please confirm',
                        message: 'You have unsaved changes to interface list, do you want to discard them?',
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: doUpdate
                    });
                } else {
                    doUpdate();
                }
            },
            addInterface: function() {
                this.tempNewInterfaceList = this.newInterfaceList.map(i => i.Name);
                this.tempNewInterfaceListMandatory = this.newInterfaceListMandatory;
                this.addInterfaceModalActive = true;
            },
            addSelectedInterfaces: function() {
                const interfacecodes = [];
                this.tempNewInterfaceList.forEach(element => {
                    const interfaceIdentity = this.schemaInterfaceList.find(i => i.Name === element).Identity;
                    interfacecodes.push({
                        Name: element,
                        IsValid: true,
                        Description: '',
                        DateCreated: Date.now(),
                        DateUpdated: Date.now(),
                        Schema: this.identity,
                        Class: this.selectedClass.Identity,
                        Interface: interfaceIdentity,
                        IsMandatoryInterface: this.tempNewInterfaceListMandatory.includes(element)
                    });
                });

                this.newInterfaceList = interfacecodes;
                this.newInterfaceListMandatory = this.tempNewInterfaceListMandatory;

                this.addInterfaceModalActive = false;
                this.interfaceListUpdateKey++;
            },
            releaseInterfaceChanges: async function() {
                this.loadingInterfaces = true;
                if (this.newInterfaceList.length > 0) {
                    this.newInterfaceList = this.newInterfaceList.map(i => {
                        i.DisplaySequenceNo = i.DisplaySequenceNo?.length > 0 ? i.DisplaySequenceNo : '{null}';
                        return i;
                    });
                    try {
                        await createChangeDocAndCommit(this, this.SchemaClassInterfaceCodeset, this.newInterfaceList);
                        this.newInterfaceListMandatory = [];
                        this.newInterfaceList = [];
                    } catch (error) {
                        this.showError(_.get(error, 'response.data.Message', 'Unable to delete item'));
                    }
                }
                if (this.interfaceSeqNumberChanges.length > 0) {
                    this.interfaceSeqNumberChanges = this.interfaceSeqNumberChanges.map(i => {
                        i.DisplaySequenceNo = i.DisplaySequenceNo?.length > 0 ? i.DisplaySequenceNo : '{null}';
                        return i;
                    });
                    try {
                        await createChangeDocAndCommit(this, this.SchemaClassInterfaceCodeset, this.interfaceSeqNumberChanges);
                        this.interfaceSeqNumberChanges = [];
                    } catch (error) {
                        this.showError(_.get(error, 'response.data.Message', 'Unable to update sequence numbers'));
                    }
                }
                await this.loadSchemaClassInterface();
                this.interfaceListUpdateKey++;
                this.loadingInterfaces = false;
            },
            createNewClass: function() {
                this.selectedClass = null;
                this.newClassEditorActive = true;
            },
            confirmDeleteCode: function(codeset, codeIdentity) {
                const self = this;
                this.$buefy.dialog.confirm({
                    title: 'Please confirm delete',
                    message: 'Deleting items may cause issues if the item is known externally to Common Library, and should normally only be done during initial setup of libraries. Continue to delete item?',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        if (codeset === this.SchemaClassInterfaceCodeset) {
                            await this.deleteSchemaClassInterfaceCode(self, codeset, codeIdentity);
                        } else if (codeset === this.SchemaClassCodesetName) {
                            await this.deleteSchemaClassCode(self, codeset, codeIdentity);
                        }
                    }
                });
            },
            deleteSchemaClassCode: async function(self, codeset, codeIdentity) {
                try {
                    await deleteCode(self, codeset, codeIdentity);
                    this.selectedClass = null;
                    this.removeClassFrontend(codeIdentity);
                    this.classListUpdateKey++;
                } catch (error) {
                    this.showError(_.get(error, 'response.data.Message', 'Unable to delete item'));
                }
            },
            deleteSchemaClassInterfaceCode: async function(self, codeset, codeIdentity) {
                try {
                    await deleteCode(self, codeset, codeIdentity);
                    await this.loadSchemaClassInterface();
                    this.interfaceListUpdateKey++;
                } catch (error) {
                    this.showError(_.get(error, 'response.data.Message', 'Unable to delete item'));
                }
            },
            removeClassFrontend: function(codeIdentity) {
                this.classes = this.classes.filter(c => c.Identity !== codeIdentity);
            },
            cancelNewInterfaces: function() {
                this.addInterfaceModalActive = false;
                this.tempNewInterfaceListMandatory = [];
                this.tempNewInterfaceList = [];
            },
            afterClassCreated: async function(codes) {
                this.classes = await this.getSchemaClass(this.identity);
                this.newClassEditorActive = false;
                this.selectedClass = this.classes.find(c => c.Name === codes[0].Name);
            },
            changeDisplaySequenceNo: function(data, row) {
                if (!row.Identity) {
                    const unsavedExistingIndex = this.newInterfaceList.findIndex(i => {
                        return i.Name === row.Name
                            && i.Class === row.Class
                            && i.Interface === row.Interface;
                    });
                    this.newInterfaceList[unsavedExistingIndex].DisplaySequenceNo = data;
                    return;
                }

                const newChange = {
                    Identity: row.Identity,
                    Interface: row.Interface,
                    Name: row.Name,
                    Class: row.Class,
                    DisplaySequenceNo: data
                };

                const existingChangeIndex = this.interfaceSeqNumberChanges.findIndex(i => {
                    return i.Identity === row.Identity
                        && i.Name === row.Name
                        && i.Class === row.Class
                        && i.Interface === row.Interface;
                });

                if (existingChangeIndex >= 0) {
                    this.interfaceSeqNumberChanges[existingChangeIndex] = newChange;
                } else {
                    this.interfaceSeqNumberChanges.push(newChange);
                }
            },
            getBaseClassFilter: function() {
                let schemaClasses = [...this.schemaClassesIncludingextensions];
                if (this.selectedClass) {
                    schemaClasses = schemaClasses.filter(x => x.Identity !== this.selectedClass.Identity);
                }

                const baseClassIds = schemaClasses.filter(c => !c.HasBaseClass).map((x) => x.Identity);

                return (x) => { return baseClassIds.includes(x.identity); };
            },
            getVersionFilter: function() {
                const versionIdentities = [...this.schemaVersions].map((x) => x.Identity);

                return (x) => { return versionIdentities.includes(x.identity); };
            }
        }
    };
</script>
<style scoped>

.wrapper{
    background-color: white;
    min-height: 900px;
    height: 80vh;
}
.columns{
    margin:auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    height: 90%;
}
.list-column{
    min-width: 220px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
}
.table-wrapper{
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
}
.attributes-wrapper{
    overflow: hidden;
    padding: 0px 20px;
    min-width: 40%;
    flex-grow: 2;
}
.code-edit-wrapper{
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
    padding: 20px 5px;
}
.cursorPointer{
    cursor: pointer;
}
.interface_table{
    height: 90%;
}
.modalContainer{
    background-color: white;
    padding: 25px;
}
.addInterfaceList{
    column-count: 3;
    column-rule-style: solid;
    column-rule-width: 1px;
    column-rule-color: lightgray;
}
.interfaceListItem{
    padding-left: 15px;
    width: 60%;
}
.interfaceMandatoryCheckbox{
    width: 30%;
}
</style>
