<template>
    <div>
        <div
            class="field"
            :class="{'is-grouped': !stackDateAndTimeInput}">
            <b-datepicker
                v-model="date"
                placeholder="Click to select date..."
                icon="calendar-today"
                @update:model-value="handleInput"
                @blur="opacity = true"
                @fucus="opacity = false" />
            <b-timepicker
                v-if="!dateOnly"
                v-model="time"
                placeholder="Click to select time..."
                icon="clock"
                hour-format="24"
                @blur="opacity = true"
                @fucus="opacity = false"
                @update:model-value="handleInput" />
        </div>
    </div>
</template>

<script>

    import moment from 'moment';

    export default {
        props: {
            dateOnly: {
                type: Boolean,
                required: false,
                default: false
            },
            input: {
                type: String,
                required: false,
                default: ''
            },
            stackDateAndTimeInput: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        emits: [
            'dt-picker'
        ],
        data: function() {
            return {
                date: null,
                time: null,
                opacity: true
            };
        },
        watch: {
            input: function() {
                if (this.input === '') {
                    this.date = null;
                    this.time = null;
                }
            }
        },
        mounted: function() {
            if (this.input !== '') {
                const value = moment(this.input);
                this.date = value.toDate();
                this.time = value.toDate();
                this.opacity = false;
            }
        },
        methods: {
            handleInput: function() {
                if (this.dateOnly && this.date !== null) {
                    this.$emit('dt-picker', moment(this.date).format('YYYY-MM-DD'));
                } else if (this.time !== null && this.date !== null) {
                    const mDate = moment(this.date);
                    mDate.hours(this.time.getHours());
                    mDate.minutes(this.time.getMinutes());
                    this.$emit('dt-picker', mDate.format('YYYY-MM-DD HH:mm:ss'));
                }
            }
        }
    };
</script>
