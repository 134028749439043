<template>
    <router-link
        v-if="!itemHasSubItems && !item.target && item.link"
        :data-active="dataActive"
        class="menu-item"
        :to="item.link"
        :target="item.target">
        <span class="mr-4">
            <span :class="{ 'menu-item-highlight': item.highlight }">{{ item.text }}</span>
            <notification-count
                v-if="item.notifications"
                :count="item.notifications" />
        </span>
    </router-link>
    <a
        v-else-if="!itemHasSubItems && item.target"
        :data-active="dataActive"
        class="menu-item"
        :href="item.link"
        :target="item.target">
        <span class="mr-4">
            {{ item.text }}
        </span>
    </a>
    <a
        v-else-if="itemHasSubItems"
        ref="reference"
        tabindex="0"
        class="menu-item">
        <span class="mr-4">
            <span :class="{ 'menu-item-highlight': item.highlight }">{{ item.text }}</span>
            <notification-count
                v-if="item.notifications"
                :count="item.notifications" />
        </span>
        <i
            class="fa fa-angle-right sub-menu-indicator"
            aria-hidden="true" />
        <top-sub-menu
            ref="menu"
            :items="item.subItems" />
    </a>
</template>

<script>
    import { isPrefixOfPath } from '@/shared/helpers/utils.js';
    import { offset, flip, shift } from '@floating-ui/vue';
    import NotificationCount from './NotificationCount.vue';

    export default {
        name: 'TopSubMenuItem',
        components: {
            NotificationCount
            /* TopSubMenu.vue will add itself here... */
        },
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            itemHasSubItems() {
                return this.item.subItems?.length > 0;
            },
            dataActive() {
                return isPrefixOfPath(this.$route.path, this.item.link) || null;
            }
        },
        mounted() {
            if (this.item.subItems?.length > 0) {
                const reference = this.$refs.reference;
                const menu = this.$refs.menu;
                menu.makeFloating(reference, reference, {
                    placement: 'right-start',
                    middleware: [offset({ mainAxis: -2, crossAxis: -7 }), flip(), shift()]
                });
            }
        }
    };
</script>

<style scoped>
    .menu-item {
        display: flex;
        align-items: center;
        padding: 0.175rem 1.1rem;
    }
    .menu-item[data-active=true] {
        color: #FF1234;
    }
    .menu-item-highlight {
        font-weight: bold;
    }
    .sub-menu-indicator {
        margin-left: auto;
    }
</style>
