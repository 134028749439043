<template>
    <b-dropdown
        aria-role="list">
        <template #trigger>
            <b-button
                :label="buttonText"
                :disabled="disabled"
                type="is-info"
                icon-right="menu-down" />
        </template>

        <b-dropdown-item custom>
            <b-field
                position="is-centered">
                <p class="control">
                    <b-button
                        size="is-small"
                        @click="clearFilter()">
                        Clear selected
                    </b-button>
                </p>
                <p class="control">
                    <b-button
                        size="is-small"
                        @click="selectAll()">
                        Select all
                    </b-button>
                </p>
            </b-field>
        </b-dropdown-item>
        <b-dropdown-item
            v-for="(option, key) in options"
            :key="key"
            custom
            position="is-centered">
            <p class="control">
                <b-checkbox
                    :model-value="selected.includes(option)"
                    @update:model-value="selectTag(option)">
                    {{ option }}
                </b-checkbox>
            </p>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>

    export default {
        props: {
            options: {
                type: Array,
                default: () => [],
                required: false
            },
            title: {
                type: String,
                required: true
            },
            showSelectedCount: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                selected: []
            };
        },
        computed: {
            buttonText: function() {
                return this.showSelectedCount
                    ? `${this.title} (${this.selected.length})`
                    : this.title;
            }
        },
        watch: {
            options: function(newVal) {
                const selectable = new Set(newVal);
                this.selected = this.selected.filter(name => selectable.has(name));
            }
        },
        methods: {
            selectTag: function(option) {
                this.selected = this.selected.includes(option)
                    ? this.selected.filter(o => o !== option)
                    : this.selected.concat([option]);
                this.$emit('update', this.selected);
            },
            clearFilter: function() {
                this.selected = [];
                this.$emit('update', this.selected);
            },
            selectAll: function() {
                this.selected = [...this.options];
                this.$emit('update', this.selected);
            }
        }
    };
</script>

<style scoped>
</style>
