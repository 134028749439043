<template>
    <div class="input-fields-container">
        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapLibrary')"
                        :active="valueMapDefinitions.has('ValueMapLibrary')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapLibrary:</span>
                    </b-tooltip>
                </label>
            </div>
            <generic-dropdown-menu
                :is-loading="loadingAll"
                :alternatives="mapLibraries"
                :display-property="'name'"
                :selected="mappingForm.ValueMapLibrary"
                @setSelected="selectValueMapLibrary" />
        </div>
        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapSetColumn')"
                        :active="valueMapDefinitions.has('ValueMapSetColumn')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapSetColumn:</span>
                    </b-tooltip>
                </label>
            </div>
            <generic-dropdown-menu
                :is-loading="loadingAll || loadingLibrary"
                :alternatives="selectedMapLibraryAttributes"
                :display-property="'name'"
                :disabled="!mappingForm.ValueMapLibrary"
                :selected="mappingForm.ValueMapSetColumn"
                @setSelected="selectSetColumn" />
        </div>
        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapSet')"
                        :active="valueMapDefinitions.has('ValueMapSet')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapSet:</span>
                    </b-tooltip>
                </label>
            </div>
            <generic-dropdown-menu
                :is-loading="loadingAll || loadingLibrary"
                :alternatives="valueMapSetAlternatives"
                :disabled="!mappingForm.ValueMapSetColumn"
                :selected="mappingForm.ValueMapSet"
                :is-scrollable="true"
                @setSelected="selectValueMapSet" />
        </div>
        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapBehaviour')"
                        :active="valueMapDefinitions.has('ValueMapBehaviour')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapBehaviour:</span>
                    </b-tooltip>
                </label>
            </div>
            <generic-dropdown-menu
                :is-loading="loadingAll || loadingLibrary"
                :alternatives="schemaMapBehaviourValues"
                :disabled="!mappingForm.ValueMapLibrary"
                :display-property="'Name'"
                :selected="mappingForm.ValueMapBehaviour"
                @setSelected="selectValueMapBehaviour" />
        </div>

        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapColumn1')"
                        :active="valueMapDefinitions.has('ValueMapColumn1')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapColumn1:</span>
                    </b-tooltip>
                </label>
            </div>
            <generic-dropdown-menu
                :is-loading="loadingAll || loadingLibrary"
                :alternatives="valueMapColumn1Alternative"
                :display-property="'name'"
                :disabled="!mappingForm.ValueMapLibrary"
                :selected="mappingForm.ValueMapColumn1"
                @setSelected="selectColumn1" />
        </div>
        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapColumn2')"
                        :active="valueMapDefinitions.has('ValueMapColumn2')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapColumn2:</span>
                    </b-tooltip>
                </label>
            </div>
            <generic-dropdown-menu
                :is-loading="loadingAll || loadingLibrary"
                :alternatives="valueMapColumn2Alternative"
                :display-property="'name'"
                :disabled="!mappingForm.ValueMapLibrary"
                :selected="mappingForm.ValueMapColumn2"
                @setSelected="selectColumn2" />
        </div>
        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapContextAttribute1')"
                        :active="valueMapDefinitions.has('ValueMapContextAttribute1')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapContextAttribute1:</span>
                    </b-tooltip>
                </label>
            </div>
            <b-dropdown
                :triggers="['click']"
                aria-role="list"
                :scrollable="true"
                :max-height="400"
                :disabled="!mappingForm.ValueMapLibrary">
                <template #trigger>
                    <b-button
                        :loading="loadingAll || loadingLibrary"
                        class="dropdownButton"
                        :label="mappingForm.ValueMapContextAttribute1
                            ? `${mappingForm.ValueMapContextAttribute1.InterfaceName}.${mappingForm.ValueMapContextAttribute1.Name}`
                            : 'None selected'"
                        icon-right="menu-down" />
                </template>
                <b-dropdown-item>
                    <b-button
                        type="is-text"
                        @click="selectValueMapContextAttribute1(null)">
                        Clear selected
                    </b-button>
                </b-dropdown-item>
                <b-dropdown-item>
                    <div class="attribute-wrapper">
                        <span class="interface-tab">
                            <u>Interface</u>
                        </span>
                        <span class="interface-tab">
                            <u>Attribute</u>
                        </span>
                    </div>
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="(attribute, index) in schema1Attributes"
                    :key="index"
                    aria-role="listitem"
                    @click="selectValueMapContextAttribute1(attribute)">
                    <div class="attribute-wrapper">
                        <span class="interface-tab">
                            {{ attribute ? attribute.InterfaceName : '' }}
                        </span>
                        <span class="interface-tab">
                            {{ attribute ? attribute.Name : '' }}
                        </span>
                    </div>
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapContextColumn1')"
                        :active="valueMapDefinitions.has('ValueMapContextColumn1')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapContextColumn1:</span>
                    </b-tooltip>
                </label>
            </div>
            <generic-dropdown-menu
                :alternatives="selectedMapLibraryAttributes"
                :display-property="'name'"
                :disabled="!mappingForm.ValueMapContextAttribute1"
                :selected="mappingForm.ValueMapContextColumn1"
                @setSelected="selectValueMapContextColumn1" />
        </div>
        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapContextAttribute2')"
                        :active="valueMapDefinitions.has('ValueMapContextAttribute2')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapContextAttribute2:</span>
                    </b-tooltip>
                </label>
            </div>
            <b-dropdown
                :triggers="['click']"
                aria-role="list"
                :scrollable="true"
                :max-height="400"
                :disabled="!mappingForm.ValueMapLibrary">
                <template #trigger>
                    <b-button
                        class="dropdownButton"
                        :loading="loadingAll || loadingLibrary"
                        :label="mappingForm.ValueMapContextAttribute2
                            ? `${mappingForm.ValueMapContextAttribute2.InterfaceName}.${mappingForm.ValueMapContextAttribute2.Name}`
                            : 'None selected'"
                        icon-right="menu-down" />
                </template>
                <b-dropdown-item>
                    <b-button
                        type="is-text"
                        @click="selectValueMapContextAttribute2(null)">
                        Clear selected
                    </b-button>
                </b-dropdown-item>
                <b-dropdown-item>
                    <div class="attribute-wrapper">
                        <span class="interface-tab">
                            <u>Interface</u>
                        </span>
                        <span class="interface-tab">
                            <u>Attribute</u>
                        </span>
                    </div>
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="(attribute, index) in schema1Attributes"
                    :key="index"
                    aria-role="listitem"
                    @click="selectValueMapContextAttribute2(attribute)">
                    <div class="attribute-wrapper">
                        <span class="interface-tab">
                            {{ attribute ? attribute.InterfaceName : '' }}
                        </span>
                        <span class="interface-tab">
                            {{ attribute ? attribute.Name : '' }}
                        </span>
                    </div>
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="input-field">
            <div class="input-field-label">
                <label class="label pad-top">
                    <b-tooltip
                        class="tooltip"
                        :label="valueMapDefinitions.get('ValueMapContextColumn2')"
                        :active="valueMapDefinitions.has('ValueMapContextColumn2')"
                        :multilined="true"
                        position="is-top">
                        <span>ValueMapContextColumn2:</span>
                    </b-tooltip>
                </label>
            </div>
            <generic-dropdown-menu
                :alternatives="selectedMapLibraryAttributes"
                :display-property="'name'"
                :disabled="!mappingForm.ValueMapContextAttribute2"
                :selected="mappingForm.ValueMapContextColumn2"
                @setSelected="selectValueMapContextColumn2" />
        </div>
    </div>
</template>

<script>
    import GenericDropdownMenu from '@/shared/components/GenericDropdownMenu.vue';
    import {
        genericViewQueryAsText,
        getAttributeDefinitions,
        getLibraries,
        getLibraryWithScopes
    } from '@/shared/helpers/api';

    export default {
        components: { GenericDropdownMenu },
        props: {
            mapping: {
                default: null,
                type: Object
            },
            schema1Attributes: {
                default: () => [],
                type: Array
            },
            definitions: {
                type: Array,
                default: () => []
            }
        },
        emits: [
            'valueMapFormData'
        ],
        data: function() {
            return {
                displayFilter: ['ValueMapLibrary', 'ValueMapSetColumn', 'ValueMapBehaviour', 'ValueMapSet', 'ValueMapColumn1', 'ValueMapColumn2', 'ValueMapContextAttribute1', 'ValueMapContextAttribute2', 'ValueMapContextColumn1', 'ValueMapContextColumn2'],
                mappingForm: {},
                selectedMapLibraryAttributes: [],
                mapLibraries: null,
                schemaMapBehaviourValues: null,
                valueMapLibraryCodes: null,
                loadingLibrary: false,
                loadingAll: false
            };
        },
        computed: {
            valueMapSetAlternatives: function() {
                if (this.valueMapLibraryCodes) {
                    return [... new Set(this.valueMapLibraryCodes.map(i => i[this.mappingForm.ValueMapSetColumn]))];
                }
                return [];
            },
            valueMapColumn1Alternative: function() {
                return this.valueMapColumnAlternatives(this.mappingForm.ValueMapColumn2);
            },
            valueMapColumn2Alternative: function() {
                return this.valueMapColumnAlternatives(this.mappingForm.ValueMapColumn1);
            },
            valueMapDefinitions: function() {
                const definitionsMap = new Map();
                this.definitions?.forEach(def => {
                    if (def.description.length > 0) {
                        definitionsMap.set(def.name, def.description);
                    }
                });
                return definitionsMap;
            }
        },
        watch: {
            selectedMapLibrary: function(newValue) {
                if (this.mappingForm) {
                    this.mappingForm['ValueMapLibrary'] = newValue ? newValue.name : null;
                }
            },
            mappingForm: {
                handler: function(newValue) {
                    this.$emit('valueMapFormData', newValue);
                },
                deep: true
            }
        },
        mounted: async function() {
            this.loadingAll = true;
            this.mappingForm = this.getFilteredMapping();
            this.mapLibraries = await getLibraries(this, '', 'Map');
            this.schemaMapBehaviourValues = await this.getSchemaMapBehaviourValues();
            if (this.mapping) {
                await this.insertExistingValues();
            }
            this.mappingForm = { ... this.mappingForm };
            this.loadingAll = false;
        },
        methods: {
            loadAttributeDefinitionsOfMapValueLibrary: async function() {
                if (this.mappingForm.ValueMapLibrary) {
                    this.selectedMapLibraryAttributes = await getAttributeDefinitions(this, this.mappingForm.ValueMapLibrary);
                } else {
                    this.selectedMapLibraryAttributes = [];
                }
            },
            getFilteredMapping: function() {
                return Object.keys(this.mapping)
                    .filter(key => this.displayFilter.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = this.mapping[key];
                        return obj;
                    }, {});
            },
            getSelectedMapLibrary: async function(library) {
                if (library) {
                    const res = await getLibraryWithScopes(this, library.name);
                    return res[0];
                } else return null;
            },
            clearMappingForm: function() {
                return Object.keys(this.mappingForm)
                    .filter(key => this.displayFilter.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = null;
                        return obj;
                    }, {});
            },
            insertExistingValues: async function() {
                this.mappingForm.ValueMapLibrary = this.mapLibraries.find(lib => lib.name === this.mapping.ValueMapLibrary)?.name;
                await this.loadAttributeDefinitionsOfMapValueLibrary();
                await this.loadValueMapLibraryCodes();
                Object.entries(this.mapping).forEach(([propName, propValue]) => {
                    if (this.displayFilter.includes(propName)) {
                        if (propName === 'ValueMapBehaviour') {
                            this.mappingForm[propName] = this.schemaMapBehaviourValues.find(b => b.Identity === propValue);
                        } else if (propName === 'ValueMapContextAttribute1' || propName === 'ValueMapContextAttribute2') {
                            this.mappingForm[propName] = this.schema1Attributes.find(attr => attr.Identity === propValue);
                        } else {
                            this.mappingForm[propName] = propValue;
                        }
                    }
                });
            },
            getSchemaMapBehaviourValues: async function() {
                return (await genericViewQueryAsText(
                    this,
                    'FROM SchemaMapBehaviour WHERE IsValid = true')).data;
            },
            loadValueMapLibraryCodes: async function() {
                if (this.mappingForm.ValueMapLibrary) {
                    this.valueMapLibraryCodes = (await genericViewQueryAsText(
                        this,
                        `FROM ${this.mappingForm.ValueMapLibrary} WHERE IsValid = true`)).data;
                } else {
                    this.valueMapLibraryCodes = null;
                }
            },
            selectValueMapLibrary: async function(library) {
                if (library !== this.mappingForm.ValueMapLibrary?.name) {
                    this.mappingForm.ValueMapLibrary = null;
                    this.mappingForm = this.clearMappingForm();
                }
                this.loadingLibrary = true;
                if (library) {
                    this.mappingForm.ValueMapLibrary = (await this.getSelectedMapLibrary(library)).name;
                    await this.loadValueMapLibraryCodes();
                }
                await this.loadAttributeDefinitionsOfMapValueLibrary();
                this.loadingLibrary = false;
            },
            selectSetColumn: async function(column) {
                this.mappingForm.ValueMapSetColumn = column?.name;
                if (!column) {
                    this.mappingForm.ValueMapSet = null;
                }
            },
            selectValueMapSet: function(value) {
                this.mappingForm.ValueMapSet = value;
            },
            selectColumn1: function(column) {
                this.mappingForm.ValueMapColumn1 = column?.name;
            },
            selectColumn2: function(column) {
                this.mappingForm.ValueMapColumn2 = column?.name;
            },
            selectValueMapBehaviour: function(behaviour) {
                this.mappingForm.ValueMapBehaviour = behaviour;
            },
            selectValueMapContextAttribute1: function(attribute) {
                if (!attribute) {
                    this.mappingForm.ValueMapContextColumn1 = null;
                }
                this.mappingForm.ValueMapContextAttribute1 = attribute;
            },
            selectValueMapContextAttribute2: function(attribute) {
                if (!attribute) {
                    this.mappingForm.ValueMapContextColumn2 = null;
                }
                this.mappingForm.ValueMapContextAttribute2 = attribute;
            },
            selectValueMapContextColumn1: function(contextColumn) {
                this.mappingForm.ValueMapContextColumn1 = contextColumn?.name;
            },
            selectValueMapContextColumn2: function(contextColumn) {
                this.mappingForm.ValueMapContextColumn2 = contextColumn?.name;
            },
            valueMapColumnAlternatives: function(otherColumn) {
                if (!this.selectedMapLibraryAttributes) return [];
                const attributes = [{ name: 'Identity' }, { name: 'Name' }, { name: 'Description' }].concat(this.selectedMapLibraryAttributes);
                if (otherColumn) {
                    return attributes.filter(x => x.name !== otherColumn);
                }
                return attributes;
            }
        }
    };
</script>

<style scoped>
.input-fields-container {
    column-count: 2;
}
.input-field {
    display: flex;
    height: 40px;
    margin-top: 0.5rem;
}
.input-field-label {
    width: 180px;
    text-align: right;
    margin-right: 1rem;
    height: 100%;
}
.pad-top {
    padding-top: 15px;
}
.attribute-wrapper {
    display: flex;
    justify-content: space-between;
    width: 300px;
}
.interface-tab {
    width: 50%;
}
.interface-tab {
    width: 50%;
}
</style>
