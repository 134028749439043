<template>
    <div class="field is-grouped is-grouped-multiline">
        <div
            v-for="tag in allTags"
            v-cloak
            :key="tag"
            class="control">
            <div
                class="tags has-addons is-clickable"
                @click="$emit('click-tag', tag)">
                <span :class="tagClass(tag)">
                    {{ tag }}
                </span>
                <a class="tag is-dark">
                    <i
                        :class="iconClass(tag)"
                        aria-hidden="true" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            allTags: {
                type: Array,
                required: true
            },
            activeTags: {
                type: Array,
                required: true
            }
        },
        emits: [
            'click-tag'
        ],
        methods: {
            iconClass: function(tag) {
                const self = this;

                return {
                    fa: true,
                    'fa-filter': !self.activeTags.includes(tag),
                    'fa-times': self.activeTags.includes(tag)
                };
            },
            tagClass: function(tag) {
                const self = this;

                return {
                    tag: true,
                    'is-info': !self.activeTags.includes(tag),
                    'is-primary': self.activeTags.includes(tag)
                };
            }
        }
    };
</script>
