<template>
    <div style="width: auto; height: 300px">
        <library-treemap :csvdata="csvData" />
    </div>
</template>

<script>
    import LibraryTreemap from '@/shared/components/LibraryTreemap.vue';
    import { getLibraryTreeMap } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';

    export default {
        name: 'TreeMap',
        components: {
            LibraryTreemap
        },
        mixins: [
            showMixin
        ],
        data() {
            return {
                loading: true,
                csvData: null
            };
        },
        mounted: async function() {
            const loadingComponent = this.$buefy.loading.open();
            try {
                this.csvData = await getLibraryTreeMap(this);
                this.loading = false;
            } catch (err) {
                this.showError('Unable to load libraries');
            }
            loadingComponent.close();
        }
    };
</script>

<style scoped>

</style>
