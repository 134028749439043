<template>
    <b-dropdown
        aria-role="list"
        class="is-flex"
        append-to-body>
        <template #trigger>
            <b-button
                icon-right="menu-down"
                class="button is-info">
                Select attributes/columns ({{ selectedCount }})
            </b-button>
        </template>
        <div class="container is-fluid">
            <div class="columns">
                <span
                    style="text-decoration: underline"
                    class="column is-narrow is-light">
                    <a @click="onCheckAll">Check&nbsp;all</a>
                </span>

                <span
                    style="text-decoration: underline"
                    class="column is-narrow is-light">
                    <a @click="onUnCheckAll">Uncheck&nbsp;all</a>
                </span>
            </div>
            <div class="columns column-container">
                <div
                    v-for="c in columns"
                    :key="c.field"
                    class="column-item">
                    <p class="control is-pulled-left">
                        <b-checkbox
                            v-model="selectedTableColumns"
                            :native-value="c.field.toLowerCase()">
                            {{ c.label === 'Name' ? getNameLabel(nameAlias) : c.label }}
                        </b-checkbox>
                    </p>
                </div>
            </div>
        </div>
    </b-dropdown>
</template>

<script>

    import { getNameLabel } from '@/shared/helpers/nameWithAliasHelper.js';

    export default {
        props: {
            libraryName: {
                type: String,
                required: true
            },
            columns: {
                type: Array,
                required: true
            },
            nameAlias: {
                type: String,
                required: false,
                default: ''
            }
        },
        emits: [
            'visible',
            'hidden'
        ],
        data: function() {
            return {
                tableColumns: null,
                selectedTableColumns: []
            };
        },
        computed: {
            selectedCount() {
                return this.selectedTableColumns.length;
            }
        },
        watch: {
            selectedTableColumns: function(newSet, oldSet) {
                this.columns.map(c => c.field)
                    .forEach(c => {
                        if (newSet.includes(c.toLowerCase()) && !oldSet.includes(c.toLowerCase()))
                            this.$emit('visible', c);
                        else if (!newSet.includes(c.toLowerCase()) && oldSet.includes(c.toLowerCase()))
                            this.$emit('hidden', c);
                    });
            }
        },
        mounted: async function() {
            this.tableColumns = this.columns.map(c => ({ ...c, visible: this.getColumnVisibilities(c.field.toLowerCase()) }));
            this.selectedTableColumns = this.getSelectedRows();
        },
        methods: {
            onCheckAll: function() {
                this.selectedTableColumns = this.tableColumns.filter(c => c.field !== 'identity').map(c => c.field.toLowerCase());
            },
            onUnCheckAll: function() {
                this.selectedTableColumns = ['name'];
            },
            getSelectedRows: function() {
                const fromLocalstorage = this.getLocalStorageData();
                if (fromLocalstorage) {
                    return fromLocalstorage.split(',').map(c => c.toLowerCase());
                } else {
                    return this.tableColumns.filter(c => c.visible).map(c => c.field.toLowerCase());
                }
            },
            getColumnVisibilities: function(attrib) {
                const fromLocalstorage = this.getLocalStorageData();
                if (fromLocalstorage)
                    return fromLocalstorage.indexOf(attrib) >= 0;
                else
                    return !['identity', 'dateupdated', 'attachmentkey', 'commonlibraryiri'].includes(attrib);
            },
            getLocalStorageData: function() {
                return localStorage.getItem('Library.' + this.libraryName + '.SelectedTableColumns');
            },
            getNameLabel
        }
    };
</script>

<style scoped>
.column-container {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: auto;
    width: 500px;
    height:300px;
}

.column-item {
    flex-basis: 7%;
    flex-grow: 0;
    flex-shrink: 0;
}
</style>
