<template>
    <spinner
        :loading="loading">
        <div class="wrapper">
            <p><a :href="'/Schema#/specification/' + identity">&laquo; Back to schema</a></p>
            <h1 class="title">
                Edit relation mapping in schema {{ identity }} towards <span>
                    <b-dropdown
                        class="dropdownForm"
                        :triggers="['hover']"
                        aria-role="list">
                        <template #trigger>
                            <b-button
                                :label="selectedSecondSchema ? selectedSecondSchema.Name : 'Select Schema 2'"
                                icon-right="menu-down" />
                        </template>
                        <b-dropdown-item
                            v-for="(schema2, index) in dropdownSchemas"
                            :key="index"
                            aria-role="listitem"
                            @click="clickSecondSchema(schema2)">
                            {{ schema2 ? schema2.Name : '' }}
                        </b-dropdown-item>
                    </b-dropdown>
                </span> schema
            </h1>
            <div class="columns">
                <div class="column is-one-fifth">
                    <h5
                        class="subtitle is-5 column-header-light">
                        Schema 1: {{ identity }} Relations
                    </h5>
                    <div class="table-overflow">
                        <b-table
                            v-if="schema1RelationsSorted.length > 0"
                            v-model:selected="selectedSchema1Relation"
                            :columns="[{field: 'Name'}]"
                            :hoverable="true"
                            :data="schema1RelationsSorted"
                            class="cursorPointer" />
                        <span v-else>No relations found in {{ identity }}</span>
                    </div>
                </div>
                <div class="column is-one-quarter">
                    <div class="columnTopMenu">
                        <h5
                            class="subtitle is-5 column-header-light">
                            Schema 2: {{ selectedSecondSchema ? selectedSecondSchema.Name : 'not selected' }} mapped Relations or Attributes
                        </h5>
                        <div class="button-box">
                            <b-button
                                v-require-can-edit-code="{ libraryName: 'SchemaMapRelation' }"
                                :type="creatingNewMapping ? 'is-primary is-light' : 'is-primary'"
                                class="is-default"
                                :disabled="!possibleToCreateNewMapping"
                                @click="createNewMapping">
                                {{ creatingNewMapping ? "Cancel" : "New mapping" }}
                            </b-button>
                        </div>
                    </div>
                    <div class="table-overflow">
                        <b-table
                            :columns="[{field: 'Name'}, {field: 'shorthand'}]"
                            :hoverable="true"
                            :selected="selectedSchema2Target"
                            :data="schema2RelationsAttributesSorted" />
                    </div>
                </div>
                <div class="column">
                    <h5
                        v-if="identity && selectedSchema1Relation"
                        class="subtitle is-5 column-header-light">
                        Mapping properties for {{ identity }} {{ selectedSchema1Relation.Name }} relation
                    </h5>
                    <h5
                        v-else
                        class="subtitle is-5 column-header-light">
                        Select Schema 1 relation
                    </h5>
                    <div>
                        <schema-relation-mapping-form
                            v-if="showExistingMapping"
                            :key="uniqueKey"
                            :mapping-relation="existingRelationMapping"
                            :selected-schema1-relation="selectedSchema1Relation"
                            :selected-schema1="selectedFirstSchema"
                            :selected-schema2="selectedSecondSchema"
                            :selected-schema2-relations="schema2Relations"
                            @refresh="onSave"
                            @clickCancel="onCancel" />
                        <schema-relation-mapping-form
                            v-else-if="creatingNewMapping"
                            :key="uniqueKey"
                            :create-new="true"
                            :mapping-relation="existingRelationMapping"
                            :selected-schema1-relation="selectedSchema1Relation"
                            :selected-schema1="selectedFirstSchema"
                            :selected-schema2="selectedSecondSchema"
                            :selected-schema2-relations="schema2Relations"
                            @refresh="onSave"
                            @clickCancel="onCancel" />
                    </div>
                </div>
            </div>
        </div>
    </spinner>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { genericViewQueryAsText } from '@/shared/helpers/api';
    import SchemaRelationMappingForm from './SchemaRelationMappingForm.vue';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            SchemaRelationMappingForm,
            Spinner
        },
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                schemas: [],
                schema1Relations: [],
                schema2Relations: [],
                schema2RelationsAttributes: [],
                selectedSchema1Relation: null,
                selectedSchema2Target: null,
                selectedFirstSchema: null,
                selectedSecondSchema: null,
                schemaMapRelations: null,
                existingRelationMapping: null,
                creatingNewMapping: false,
                uniqueKey: 0,
                loading: true
            };
        },
        computed: {
            dropdownSchemas: function() {
                return this.schemas.filter(schema => schema.Identity !== this.identity)
                    .sort((a, b) => a.Name.localeCompare(b.Name));
            },
            schema1RelationsSorted: function() {
                return [...this.schema1Relations].sort((a, b) => a.Name.localeCompare(b.Name));
            },
            schema2RelationsAttributesSorted: function() {
                return [...this.schema2RelationsAttributes].sort((a, b) => a.Name.localeCompare(b.Name));
            },
            showExistingMapping: function() {
                return this.existingRelationMapping;
            },
            possibleToCreateNewMapping: function() {
                return !this.existingRelationMapping
                    && !this.selectedSchema2Target
                    && this.selectedSchema1Relation
                    && this.selectedSecondSchema;
            }
        },
        watch: {
            selectedSchema1Relation: function() {
                this.creatingNewMapping = false;
                this.existingRelationMapping = this.findExistingRelationMapping();
                this.uniqueKey++;
            },
            existingRelationMapping: async function(existingMapping) {
                this.creatingNewMapping = false;
                this.selectedSchema2Target = this.schema2RelationsAttributesSorted
                    ?.find(schemaRelation => schemaRelation.Identity === existingMapping?.Relation2
                        || schemaRelation.Identity === existingMapping?.Attribute2);
            },
            selectedSecondSchema: function() {
                this.creatingNewMapping = false;
                this.uniqueKey++;
            }
        },
        mounted: async function() {
            const extensions = await this.getSchemaExtensions(this.identity);
            extensions.forEach(async extension => {
                this.schema1Relations.push(...await this.getSchemaRelations(extension.Name));
            });
            this.schemas = await this.getSchemas();
            this.selectedFirstSchema = this.schemas.find(s => s.Identity === this.identity);
            this.loading = false;
        },
        methods: {
            findExistingRelationMapping: function() {
                if (this.selectedSchema1Relation && this.selectedSecondSchema) {
                    return this.schemaMapRelations.find(schemaMapRelation => schemaMapRelation.Relation1 === this.selectedSchema1Relation.Identity);
                }
                return null;
            },
            clickSecondSchema: async function(schema) {
                this.selectedSecondSchema = schema;
                this.selectedSchema2Target = null;
                this.selectedSecondSchema = schema;
                this.schema2Relations = await this.getSchemaRelations(schema.Identity);
                this.schema2RelationsAttributes = this.schema2Relations.map(x => {
                    return {
                        'Name': x.Name,
                        'shorthand': '(R)',
                        'Identity': x.Identity,
                        'Schema': x.Schema
                    };
                });
                this.schemaMapRelations = await this.getSchemaMapRelations(this.identity, schema.Name);
                this.schemaMapRelations.forEach(x => {
                    if (x.Attribute2) {
                        const name = this.getSchemaAttriubteName(x.Attribute2);
                        this.schema2RelationsAttributes.push({
                            'Name': name,
                            'shorthand': '(A)',
                            'Identity': x.Attribute2,
                            'Schema': x.Schema2
                        });
                    }
                });
                this.existingRelationMapping = this.findExistingRelationMapping();
                this.uniqueKey++;
            },
            createNewMapping: function() {
                this.creatingNewMapping = !this.creatingNewMapping;
            },
            getSchemaAttriubteName: function(attributeIdentity) {
                return attributeIdentity.substring(0, attributeIdentity.indexOf('|'));
            },
            onSave: function() {
                this.creatingNewMapping = false;
                this.clickSecondSchema(this.selectedSecondSchema);
                this.uniqueKey++;
            },
            onCancel: function() {
                this.creatingNewMapping = false;
                this.clickSecondSchema(this.selectedSecondSchema);
                this.uniqueKey++;
            },
            getSchemaMapRelations: async function(schema1, schema2) {
                return (await genericViewQueryAsText(
                    this,
                    `FROM SchemaMapRelation
                    WHERE IsValid = true AND (Schema1 = @schema1 AND Schema2 = @schema2)`,
                    [{ name: '@schema1', value: schema1 }, { name: '@schema2', value: schema2 }])).data;
            },
            getSchemas: async function() {
                return (await genericViewQueryAsText(
                    this,
                    'FROM Schema WHERE IsValid = true')).data;
            },
            getSchemaRelations: async function(schemaId) {
                return (await genericViewQueryAsText(
                    this,
                    'FROM SchemaRelation WHERE Schema = @schemaName AND IsValid = true',
                    [{ name: '@schemaName', value: schemaId }])).data;
            },
            getSchemaExtensions: async function(schema) {
                return (await genericViewQueryAsText(
                    this,
                    `FROM Schema SELECT Name
                    WHERE (Name = @schemaName OR ExtendingSchema = @schemaName)
                    AND IsValid = true`,
                    [{ name: '@schemaName', value: schema }])).data;
            }
        }
    };
</script>

<style scoped>
.schemaDropdown{
    width: 70%;
}
.newMappingButton{
    width: 10%;
}
.columnTopMenu{
    margin-bottom: 1vh;
    display: flex;
}
.cursorPointer{
    cursor: pointer;
}
.button-box{
    display: flex;
    justify-content: center;
    margin-left: 10px;
}
</style>
