<template>
    <table
        v-if="attributeDefinitions && attributeDefinitions.length > 0"
        class="table is-striped is-narrow is-fullwidth">
        <thead>
            <tr>
                <th>Attribute</th>
                <th>Type</th>
                <th>Value</th>
                <template v-if="!showUserInfo">
                    <th>
                        Description
                    </th>
                </template>
                <template v-else>
                    <th>
                        Date created
                    </th>
                    <th>
                        Created by
                    </th>
                    <th>
                        Date updated
                    </th>
                    <th>
                        Updated by
                    </th>
                </template>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="attr in sortedAttributeDefinitions"
                :key="attr.name">
                <td>{{ attr.displayAs }}</td>
                <td>{{ attr.attributeType }}</td>

                <td v-if="attr.attributeType.toUpperCase() === 'CODEREF'">
                    <a
                        v-if="hasValue(attr.name)"
                        :href="getCodeLinkByB64Id(codeAttributesMap[attr.name].referenceCode.id)">
                        {{ attr.referenceLibraryName }}: {{ getReferenceCodeLabel(attr, codeAttributesMap[attr.name].referenceCode) }}
                    </a>
                </td>
                <td v-else-if="attr.attributeType.toUpperCase() === 'LIBRARYREF'">
                    <router-link
                        v-if="hasValue(attr.name)"
                        :to="getLibraryLink(codeAttributesMap[attr.name].displayValue)">
                        {{ codeAttributesMap[attr.name].displayValue }}
                    </router-link>
                </td>
                <td v-else-if="attr.attributeType.toUpperCase() === 'URI'">
                    <uri-element
                        v-if="hasValue(attr.name)"
                        :value="codeAttributesMap[attr.name].displayValue"
                        :truncate-at="truncLimit" />
                </td>
                <td v-else-if="attr.attributeType.toUpperCase() === 'SVG'">
                    <img
                        v-if="hasValue(attr.name)"
                        :src="getImageDataURL(codeAttributesMap[attr.name].displayValue)">
                </td>
                <td v-else>
                    <p
                        v-if="hasValue(attr.name)"
                        class="string-break keep-spaces"
                        v-text="codeAttributesMap[attr.name].displayValue" />
                </td>

                <template v-if="!showUserInfo">
                    <td>
                        <p class="string-break">
                            {{ attr.description }}
                        </p>
                    </td>
                </template>
                <template v-else>
                    <td>
                        <b-tooltip
                            :label="getAttributeDateUpdated(attr.name)"
                            position="is-right">
                            {{ $filters.dateFormatShort(getAttributeDateCreated(attr.name)) }}
                        </b-tooltip>
                    </td>
                    <td>
                        <name-with-email :user="getAttributeUpdateInfo(attr.name)?.createdBy" />
                    </td>
                    <td>
                        <b-tooltip
                            :label="getAttributeDateUpdated(attr.name)"
                            position="is-right">
                            {{ $filters.dateFormatShort(getAttributeDateUpdated(attr.name)) }}
                        </b-tooltip>
                    </td>
                    <td>
                        <name-with-email :user="getAttributeUpdateInfo(attr.name)?.updatedBy" />
                    </td>
                </template>
            </tr>
        </tbody>
    </table>
    <div
        v-else
        class="has-text-grey has-text-centered">
        No Attributes
    </div>
</template>

<script>
    import { getCodeLinkByB64Id, getLibraryLink } from '@/shared/helpers/routing';
    import { getReferenceCodeLabel } from '@/shared/helpers/dataHelpers';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import UriElement from '@/shared/components/UriElement.vue';
    import NameWithEmail from './NameWithEmail.vue';
    import { getImageDataURL } from '@/shared/helpers/svgHelpers';

    export default {
        components: {
            UriElement,
            NameWithEmail
        },
        props: {
            code: {
                type: Object,
                required: true
            },
            attributeDefinitions: {
                type: Array,
                required: true
            },
            referableCodes: {
                type: Object,
                required: true
            },
            codeUpdateInfo: {
                type: Object,
                required: true
            },
            showUserInfo: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                codeAttributesMap: null,
                truncLimit: 50,
                query: null

            };
        },
        computed: {
            sortedAttributeDefinitions: function() {
                const sortedList = JSON.parse(JSON.stringify(this.attributeDefinitions)).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
                return sortedList;
            }
        },
        mounted: async function() {
            this.codeAttributesMap = {};
            this.code.attributes.map(a => a.definitionName)
                .forEach(aName => {
                    this.codeAttributesMap[aName] = this.code.attributes.find(b => b.definitionName === aName);
                    if (this.codeAttributesMap[aName]?.referenceCode?.id) {
                        this.codeAttributesMap[aName].referenceCode.id = encodeIdBase64('Code', this.codeAttributesMap[aName].referenceCode.id);
                        const referredCode = this.referableCodes[aName].find(refCode => refCode.identity === this.codeAttributesMap[aName].referenceCode.identity);
                        this.codeAttributesMap[aName].referenceCode.description = referredCode.description;
                        this.codeAttributesMap[aName].referenceCode.name = referredCode.name;
                    }
                });
        },
        onDestroy: function() {
            this.query.unsubscribe();
        },
        methods: {
            hasValue: function(field) {
                return this.codeAttributesMap && this.codeAttributesMap[field] !== undefined;
            },
            getAttributeUpdateInfo: function(attribute) {
                return this.codeUpdateInfo.attributes
                    .find(element => element.attributeName === attribute);
            },
            getAttributeDateCreated: function(attribute) {
                return this.getAttributeUpdateInfo(attribute)?.dateCreated;
            },
            getAttributeDateUpdated: function(attribute) {
                return this.getAttributeUpdateInfo(attribute)?.dateUpdated;
            },
            getReferenceCodeLabel,
            getCodeLinkByB64Id,
            getLibraryLink,
            getImageDataURL
        }
    };
</script>

<style scoped>
  .string-break {
    word-break: break-word;
  }
  .keep-spaces {
    white-space: pre-wrap;
  }
</style>
