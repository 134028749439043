<template>
    <b-table
        class="mt-2"
        height="70vh"
        :data="releasesWithIdAsString"
        :paginated="paginate"
        :per-page="50"
        sticky-header
        striped
        narrowed
        hoverable>
        <b-table-column
            v-slot="props"
            field="id"
            label="Name"
            sortable
            searchable>
            <router-link
                :to="getReleaseLink(props.row.id)">
                {{ props.row.id }}
            </router-link>
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="state"
            label="State"
            sortable>
            {{ props.row.state }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="specification.specificationType"
            label="Type"
            sortable>
            {{ props.row.specification.specificationType }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="specification.libraryName"
            label="Library"
            sortable>
            <router-link
                v-if="props.row.specification && props.row.specification.libraryName"
                :to="getLibraryLink(props.row.specification.libraryName)">
                {{ props.row.specification.libraryName }}
            </router-link>
            <div
                v-else-if="props.row.specification && props.row.specification.libraryNames"
                class="is-flex">
                <div
                    v-for="(lib, idx) in props.row.specification.libraryNames"
                    :key="`${lib}-${idx}`">
                    <router-link
                        :to="getLibraryLink(lib)">
                        <span v-if="idx !== 0">, </span>
                        {{ lib }}
                    </router-link>
                </div>
            </div>
            <router-link
                v-else-if="props.row.specification.isComposite && props.row.specification.libraries.length===1"
                :to="getLibraryLink(props.row.specification.libraries[0])">
                {{ props.row.specification.libraries[0] }}
            </router-link>
            <b v-else>{{ props.row.specification.isComposite ? 'Multiple' : 'None' }}</b>
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="specification.codeSetDescription"
            label="Set"
            sortable>
            <a
                v-if="props.row.specification.codeSetName"
                :href="getCodeSetLink(props.row.specification.codeSetName)">
                {{ props.row.specification.codeSetDescription }}
            </a>
            <p
                v-else-if="props.row.specification && props.row.specification.facilityNames">
                {{ props.row.specification.facilityNames.join(', ') }}
            </p>
            <b v-else>{{ props.row.specification.isComposite ? 'Multiple' : 'None' }}</b>
        </b-table-column>
        <b-table-column
            v-slot="props"
            label="Scopes"
            sortable>
            <div class="tags">
                <span
                    v-for="scope in getScopes(props.row.codeSets)"
                    :key="scope"
                    class="tag is-small is-success">
                    {{ scope }}
                </span>
            </div>
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="dateCreated"
            label="Created"
            sortable>
            {{ $filters.dateFormatLong(props.row.dateCreated) }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="dateUpdated"
            label="Updated"
            sortable>
            {{ $filters.dateFormatLong(props.row.dateUpdated) }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="createdBy.name"
            label="Created by"
            sortable>
            <b-tooltip
                v-if="props.row.createdBy.name && props.row.createdBy.email"
                position="is-left"
                :label="props.row.createdBy.email">
                {{ props.row.createdBy.name }}
            </b-tooltip>
            <div v-else>
                {{ props.row.createdBy.name ?? 'Unknown user' }}
            </div>
        </b-table-column>

        <template #empty>
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p> {{ emptyText }}</p>
                </div>
            </section>
        </template>
    </b-table>
</template>

<script>
    import { getCodeSetLink, getLibraryLink, getReleaseLink } from '@/shared/helpers/routing.js';

    export default {
        props: {
            releases: {
                type: Array,
                required: true,
                default: () => []
            },
            paginate: {
                type: Boolean,
                required: false,
                default: true
            },
            emptyText: {
                type: String,
                required: false,
                default: 'No releases found'
            }
        },
        computed: {
            releasesWithIdAsString() {
                return this.releases.map(r => ({ ...r, id: `${r.id}` }));
            }
        },
        methods: {
            getLibraryLink,
            getReleaseLink,
            getCodeSetLink,
            getScopes: function(codeSets) {
                if (codeSets) {
                    return codeSets.flatMap(cs => cs.scopes).sort((a, b) => a > b ? 1 : -1);
                } else {
                    return '';
                }
            }
        }
    };
</script>
