<template>
    <div v-require-is-administrator>
        <spinner
            v-if="loading"
            :loading="loading" />
        <div v-else>
            <div
                class="columns">
                <div class="column">
                    <h2 class="title is-3">
                        Access Group: {{ group.name }}
                    </h2>
                </div>
                <div class="column is-clearfix">
                    <div class="field is-grouped is-pulled-right">
                        <p class="control">
                            <button
                                class="button is-info"
                                @click="editPolicy">
                                <b-icon
                                    icon="lead-pencil"
                                    size="is-small" />
                                <span>Edit policy</span>
                            </button>
                        </p>
                        <p class="control">
                            <button
                                class="button is-primary"
                                @click="onDelete">
                                <b-icon
                                    icon="delete-outline"
                                    size="is-small" />
                                <span>Delete Access Group</span>
                            </button>
                        </p>
                    </div>
                </div>
            </div>

            <div class="columns">
                <h2 class="column title is-5 has-margin-top2">
                    Policy: {{ group.policy }}
                </h2>
            </div>

            <div class="columns has-margin-top2">
                <div class="column">
                    <div class="box">
                        <nav
                            class="level">
                            <div class="level-left">
                                <h2 class="title is-5">
                                    Libraries applied to
                                </h2>
                            </div>

                            <div class="level-right">
                                <p class="level-item is-flex">
                                    <button
                                        class="button is-info"
                                        @click="openModal">
                                        <span class="icon is-small">
                                            <i class="fa fa-plus" />
                                        </span>
                                        <span>Add Library</span>
                                    </button>
                                </p>
                                <p class="level-item is-flex">
                                    <button
                                        class="button is-primary"
                                        :disabled="!selectedLibrary"
                                        @click="unapply">
                                        <span class="icon is-small">
                                            <i class="fa fa-trash" />
                                        </span>
                                        <span>Remove Library</span>
                                    </button>
                                </p>
                            </div>
                        </nav>

                        <b-table
                            v-model:selected="selectedLibrary"
                            class="has-margin-top2 site-sticky-header"
                            :striped="true"
                            :hoverable="true"
                            :data="libraries">
                            <b-table-column
                                v-slot="props"
                                label="Library Name">
                                <router-link :to="getLibraryLink(props.row.name)">
                                    {{ props.row.name }}
                                </router-link>
                            </b-table-column>
                            <template #empty>
                                <section class="section">
                                    <div class="content has-text-grey has-text-centered">
                                        <p>Not used to restrict any libraries</p>
                                    </div>
                                </section>
                            </template>
                        </b-table>
                    </div>
                </div>
                <b-modal
                    v-model="modalActive"
                    has-modal-card>
                    <access-group-apply-form
                        :access-group="group.name"
                        @change="init"
                        @close="onClose" />
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import { deleteAccessGroupLibrary, deleteAccessGroups, getAccessGroup, putAccessGroups } from '@/shared/helpers/api.ts';
    import { getLibraryLink } from '@/shared/helpers/routing';
    import messageDialog from '@/shared/mixins/messageDialogMixin';
    import { showMixin } from '@/shared/mixins/showMixin';
    import AccessGroupApplyForm from './AccessGroupApplyForm.vue';

    export default {
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        components: {
            Spinner,
            AccessGroupApplyForm
        },
        mixins: [
            showMixin,
            messageDialog
        ],
        props: {
            groupId: {
                type: Number,
                required: true
            }
        },
        data: () => {
            return {
                loading: true,
                isEditing: false,
                modalActive: false,
                selectedLibrary: null
            };
        },
        computed: {
            libraries: function() {
                if (this.group)
                    return this.group.libraries.map(l => ({ name: l }));
                return [];
            }
        },
        watch: {
            groupId() {
                this.init();
            }
        },
        mounted: async function() {
            await this.init();
        },
        methods: {
            init: async function() {
                this.group = await getAccessGroup(this, this.groupId);
                this.loading = false;
            },
            editPolicy: function() {
                this.$buefy.dialog.prompt({
                    message: `new policy for ${this.group.name}:`,
                    inputAttrs: {
                        minlength: 1,
                        maxlength: 64,
                        required: true
                    },
                    onConfirm: this.updateAccessGroup
                });
            },
            openModal: function() {
                this.modalActive = true;
            },
            onClose: function() {
                this.modalActive = false;
            },
            unapply: function() {
                this.messageDialog(`This will remove the access group from the library ${this.selectedLibrary.name}`, false, 'Confirm Removal')
                    .then(async () => {
                        await deleteAccessGroupLibrary(this, this.group.name, this.selectedLibrary.name);
                        this.selectedLibrary = null;
                        await this.init();
                    })
                    .catch(() => 0);
            },
            onDelete: function() {
                this.messageDialog(`This will permanently delete ${this.group.name}`, false, 'Confirm delete')
                    .then(() => this.deleteAccessGroup())
                    .catch(() => 0);
            },
            deleteAccessGroup: async function() {
                try {
                    await deleteAccessGroups(this, this.group);
                    window.location = '/Config#/AccessGroups';
                    return true;
                } catch {
                    this.showError('Unable to delete access group');
                }
            },
            updateAccessGroup: async function(policy) {
                try {
                    const group = { id: this.group.id, name: this.group.name, policy };
                    await putAccessGroups(this, group);
                    this.group.policy = policy;
                } catch {
                    this.showError('Unable to update access group');
                }
            },
            getLibraryLink
        }
    };
</script>
