<template>
    <div
        class="modal-card"
        style="height: 90vh; width: 50vw;">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Copy Wire Color from specification
            </p>
        </header>
        <section
            class="modal-card-body">
            <b-field
                label="Select Cable Code">
                <code-ref-selector
                    :code-refs="originalCodes(libNames.cableCode).map(c => c.values).filter(c => c.Screen === currentScreen)"
                    :selected="sourceCableCode"
                    :required="true"
                    :complex="true"
                    @ref-selected="sourceCableCode = $event" />
            </b-field>
            <b-field
                label="Select Cable spec">
                <code-ref-selector
                    :code-refs="relevantSpecs"
                    :selected="sourceCableSpec"
                    :disabled="!sourceCableCode"
                    :required="true"
                    :complex="true"
                    @ref-selected="sourceCableSpec = $event" />
            </b-field>

            <b-field
                label="Select rows to copy">
                <p v-if="sourceCableSpec && sourceCableSpec.length && wireCodes.length < 1">
                    No Wire Color codes found for selected spec
                </p>
                <div v-else>
                    <b-table
                        v-if="wireCodes.length"
                        v-model:checked-rows="checkedRows"
                        :striped="true"
                        :hoverable="true"
                        :bordered="true"
                        :data="wireCodes"
                        checkable>
                        <b-table-column
                            v-slot="props"
                            field="WireGroup"
                            label="Group No">
                            {{ props.row.values.WireGroup }}
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            field="WireNo"
                            label="Wire">
                            {{ props.row.values.WireNo }}
                        </b-table-column>
                        <b-table-column
                            v-slot="props"
                            field="WireColour"
                            label="Colour code">
                            {{ props.row.values.WireColour }}
                        </b-table-column>
                    </b-table>
                </div>
            </b-field>
            <b-loading
                v-model="loadingCopyWireColor"
                :is-full-page="false"
                :can-cancel="true" />
        </section>
        <footer class="modal-card-foot">
            <button
                class="button"
                @click="closeModal">
                <b-icon
                    icon="close-circle"
                    size="is-small" />
                <span>
                    Cancel
                </span>
            </button>
            <button
                class="is-primary button"
                :disabled="checkedRows.length < 1"
                @click="copy">
                <span class="icon is-small">
                    <i
                        class="fa fa-check-circle"
                        aria-hidden="true" />
                </span>
                <span>
                    {{ copyProgress ? copyProgress : 'Copy' }}
                </span>
            </button>
        </footer>
    </div>
</template>

<script>

    import { useCodeEditStore } from '@/stores/codeEditStore.js';
    import { mapActions, mapState, mapStores } from 'pinia';
    import BoolElement from '../../../shared/components/BoolElement.vue';
    import CodeRefSelector from '../../../shared/components/CodeRefSelector.vue';
    import { getCableSpecWire, libNames } from '../../../shared/helpers/cableEditHelpers';
    import { showMixin } from '../../../shared/mixins/showMixin';

    export default {
        components: {
            CodeRefSelector,
            BoolElement
        },
        mixins: [
            showMixin
        ],
        props: {
            spec: {
                type: String,
                required: true
            },
            currentScreen: {
                type: String,
                required: true
            }
        },
        emits: [
            'copy',
            'close'
        ],
        data: function() {
            return {
                libNames,
                relevantSpecs: [],
                // The sourceXXX variables refer to the values selected in the popup, which identify the spec you
                // are copying wire colors FROM
                sourceCableCode: null,
                sourceCableSpec: null,
                wireCodes: [],
                checkedRows: [],
                loadingCopyWireColor: false,
                copyProgress: '',
                copyErrors: [],
                loadedWireCodes: new Set(),
                filter: c => c.values.CableSpec === this.codeEditStore.selectedCodes[libNames.cableSpec]
            };
        },
        computed: {
            ...mapStores(useCodeEditStore),
            ...mapState(useCodeEditStore, ['scope'])
        },
        watch: {
            sourceCableCode: function(newValue) {
                this.wireCodes = [];
                this.sourceCableSpec = null;
                this.relevantSpecs = this.codeEditStore.originalCodes(libNames.cableSpec)
                    .map(c => c.values)
                    .filter(c => c.CableCode === newValue);
                this.selectClosestCableSpec();
            },
            sourceCableSpec: async function(newValue) {
                this.initTemplate();
                await this.loadWireColorsForSpec(newValue);
                const copyToSpec = this.codeEditStore.selectedCodes[libNames.cableSpec];
                const targetNumberOfGroups = this.getNumberOfGroupsForSpec(copyToSpec);
                const targetNumberOfWires = this.getNumberOfWiresForSpec(copyToSpec);
                this.wireCodes = this.addMissingWires(this.codeEditStore.originalCodes(libNames.cableSpecWire)
                    .filter(c => c.values.CableSpec === newValue
                        && parseInt(c.values.WireGroup) <= targetNumberOfGroups
                        && c.values.WireNo <= targetNumberOfWires), targetNumberOfGroups, targetNumberOfWires)
                    .sort((a, b) => {
                        if (a.values.WireGroup === b.values.WireGroup) {
                            return a.values.WireNo - b.values.WireNo;
                        }
                        return a.values.WireGroup - b.values.WireGroup;
                    });
            }
        },
        mounted: function() {
            this.sourceCableCode = this.codeEditStore.originalCodes(libNames.cableCode)
                .map(c => c.values)
                .find(c => c.identity === this.codeEditStore.selectedCodes[libNames.cableCode])
                .identity;
        },
        methods: {
            ...mapActions(useCodeEditStore, ['originalCodes', 'isFullyLoaded']),
            initTemplate: function() {
                this.template = JSON.parse(JSON.stringify(this.codeEditStore.templates[libNames.cableSpecWire]));
                this.template.values.CableSpec = this.codeEditStore.selectedCodes[libNames.cableSpec];
                this.template.values.isValid = 'True';
            },
            addMissingWires: function(wireList, targetNumberOfGroups, targetNumberOfWires) {
                const expandedWireList = JSON.parse(JSON.stringify(wireList));
                for (let groupNo = 1; groupNo <= targetNumberOfGroups; groupNo++) {
                    const groupNoStr = groupNo.toString().padStart(3, '0');
                    for (let wireNo = 1; wireNo <= targetNumberOfWires; wireNo++) {
                        if (!(expandedWireList.find(w => w.values.WireGroup === groupNoStr && w.values.WireNo === wireNo))) {
                            const wire = JSON.parse(JSON.stringify(this.template));
                            wire.values.WireGroup = groupNoStr;
                            wire.values.WireNo = wireNo;
                            expandedWireList.push(wire);
                        }
                    }
                }
                return expandedWireList;
            },
            selectClosestCableSpec: function() {
                const copyToSpec = this.codeEditStore.selectedCodes[libNames.cableSpec];
                const targetNumberOfGroups = this.getNumberOfGroupsForSpec(copyToSpec);
                const targetNumberOfWires = this.getNumberOfWiresForSpec(copyToSpec);
                let minNumberOfGroupsDiff = Infinity;
                let minNumberOfWiresDiff = Infinity;
                let closestSpec = null;

                this.relevantSpecs.forEach(candidateSpec => {
                    const numberOfGroupsDiff = Math.abs(candidateSpec.NumberOfGroups - targetNumberOfGroups);
                    const numberOfWiresDiff = Math.abs(candidateSpec.NumberOfWires - targetNumberOfWires);
                    if ((numberOfGroupsDiff < minNumberOfGroupsDiff)
                        || (numberOfGroupsDiff === minNumberOfGroupsDiff && numberOfWiresDiff < minNumberOfWiresDiff)) {
                        closestSpec = candidateSpec;
                        minNumberOfGroupsDiff = numberOfGroupsDiff;
                        minNumberOfWiresDiff = numberOfWiresDiff;
                    }
                });
                this.sourceCableSpec = closestSpec.identity;
            },
            loadWireColorsForSpec: async function(spec) {
                const mustLoadWireColours = !(!spec
                    || this.isFullyLoaded(libNames.cableSpecWire)
                    || this.loadedWireCodes.has(spec));
                if (mustLoadWireColours) {
                    this.loadingCopyWireColor = true;
                    const cableSpecWire = await getCableSpecWire(this, spec, this.scope);
                    await this.codeEditStore.addCodes(libNames.cableSpecWire, cableSpecWire);
                    this.loadingCopyWireColor = false;
                    this.loadedWireCodes.add(spec);
                }
            },
            getCableSpecObject(identity) {
                return this.codeEditStore
                    .unfilteredCodes(libNames.cableSpec)
                    .find(c => c.values.identity === identity);
            },
            getNumberOfGroupsForSpec: function(identity) {
                return this.getCableSpecObject(identity).values.NumberOfGroups;
            },
            getNumberOfWiresForSpec: function(identity) {
                return this.getCableSpecObject(identity).values.NumberOfWires;
            },
            copy: function() {
                this.loadingCopyWireColor = true;
                const promises = this.checkedRows.map(async row => {
                    const newCode = JSON.parse(JSON.stringify(row));
                    newCode.meta = { isNew: true };
                    newCode.values.identity = '';
                    newCode.values.CableSpec = this.spec;
                    return this.codeEditStore.changeCode(libNames.cableSpecWire, newCode, 'create');
                });
                this.allProgress(
                    promises,
                    (progress) => {
                        this.copyProgress = `Done: ${progress.toFixed(0)}%`;
                        if (progress === 100) {
                            this.loadingCopyWireColor = false;
                            this.closeModal();
                            this.$emit('copy');
                            this.showCopyErrors();
                        }
                    }
                );
            },
            showCopyErrors() {
                const errorCounter = new Map();
                const uniqueCopyErrors = this.copyErrors.filter((value, index) => {
                    const _value = JSON.stringify(value.message);
                    return index === this.copyErrors.findIndex(obj => {
                        const isEqual = JSON.stringify(obj.message) === _value;
                        if (isEqual) {
                            if (errorCounter.has(value.message)) {
                                errorCounter.set(value.message, errorCounter.get(value.message) + 1);
                            } else {
                                errorCounter.set(value.message, 1);
                            }
                        }
                        return isEqual;
                    });
                });
                const errorTitles = [];
                const errorMessages = [];
                uniqueCopyErrors.forEach(error => {
                    errorTitles.push(`${error.title ? error.title : error.message} x${errorCounter.get(error.message)}`);
                    errorMessages.push(error.message);
                });
                if (uniqueCopyErrors.length > 0) {
                    this.showAlert(this, errorTitles.join(', '), errorMessages.join(', '));
                }
            },
            allProgress(promises, progressCallback) {
                let promiseNumber = 0;
                progressCallback(0);
                for (const promise of promises) {
                    promise.then(() => {
                        promiseNumber++;
                        progressCallback(promiseNumber * 100 / promises.length);
                    }).catch((error) => {
                        this.copyErrors.push(error);
                        promiseNumber++;
                        progressCallback(promiseNumber * 100 / promises.length);
                    });
                }
                return Promise.all(promises);
            },
            closeModal: function() {
                this.$emit('close');
            }
        }
    };
</script>

<style scoped>
div :deep(.dropdown-content) {
    max-height: 50vh !important;
}

.modal-card-foot {
    justify-content: flex-end;
}
</style>
