<template>
    <nav
        class="navbar"
        :style="computedStyle"
        role="navigation"
        aria-label="main navigation">
        <div class="navbar-brand">
            <router-link
                class="navbar-item"
                :to="{ name: 'FrontPage' }">
                <img
                    alt="Common Library logo"
                    src="/images/logo_light_white_inside.png">
            </router-link>
            <a
                role="button"
                class="navbar-burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample">
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
            </a>
        </div>
        <div
            v-if="!loading"
            id="navbarBasicExample"
            class="navbar-menu">
            <top-menu />
            <div class="navbar-end">
                <div class="navbar-item is-flex is-gap-10">
                    <template v-if="user">
                        <b-dropdown>
                            <template #trigger>
                                <button class="button is-dropdown-trigger">
                                    <strong>{{ user.name }}</strong>
                                </button>
                            </template>
                            <b-dropdown-item custom>
                                <strong>Assigned roles:</strong>
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-for="(role, idx) in roles"
                                :key="`${role}-${idx}`"
                                custom>
                                {{ role }}
                            </b-dropdown-item>
                            <b-dropdown-item custom>
                                <strong>Access groups:</strong>
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-for="(accessGroup, idx) in accessGroups"
                                :key="`${accessGroup}-${idx}`"
                                custom>
                                {{ accessGroup }}
                            </b-dropdown-item>
                            <b-dropdown-item separator />
                            <b-dropdown-item has-link>
                                <router-link to="/UserProfile">
                                    Profile
                                </router-link>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="refreshAttributes">
                                Refresh Profile
                            </b-dropdown-item>
                            <b-dropdown-item has-link>
                                <a @click="signOut">Sign out</a>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <a
                        v-else
                        @click="signIn">Sign in</a>
                    <button
                        v-if="systemInfo.environment"
                        class="button"
                        @click="toggleSystemInfo">
                        <b-icon
                            icon="cog"
                            size="is-small" />
                        <span
                            v-if="systemInfo.environment !== 'Production'"
                            style="margin-left:0.1em;">
                            {{ systemInfo.environment }}
                        </span>
                    </button>
                </div>

                <b-modal
                    v-cloak
                    v-model="systemInfoVisible">
                    <div class="box">
                        <table class="table is-fullwidth">
                            <tbody>
                                <tr>
                                    <td><b>API environment:</b></td>
                                    <td>{{ systemInfo.environment }}</td>
                                </tr>
                                <tr>
                                    <td><b>API version:</b></td>
                                    <td>{{ systemInfo.apiVersion }}</td>
                                </tr>
                                <tr>
                                    <td><b>API server name:</b></td>
                                    <td>{{ systemInfo.machineName }}</td>
                                </tr>
                                <tr>
                                    <td><b>Azure resource group:</b></td>
                                    <td>{{ systemInfo.resourceGroup }}</td>
                                </tr>
                                <tr>
                                    <td><b>OS:</b></td>
                                    <td>{{ systemInfo.os }}</td>
                                </tr>
                                <tr v-for="s in systemInfo.settings">
                                    <td><b>{{ s.key }}</b></td>
                                    <td>{{ s.value }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </b-modal>
            </div>
        </div>
    </nav>
</template>
<script>
    import TopMenu from '@/shared/components/TopMenu.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { getSystemInfo } from '@/shared/helpers/api';
    import addClickEventForNavBar from '@/addClickEventForNavbar';

    export default {
        components: { TopMenu },
        mixins: [showMixin],
        data() {
            return {
                loading: true,
                roles: [],
                user: null,
                accessGroups: [],
                systemInfoVisible: false,
                systemInfo: {},
                backgroundColor: 'white'

            };
        },
        computed: {
            computedStyle: function() {
                return `border-bottom: 1px solid #DBDBDB; background-color: ${this.backgroundColor}`;
            }
        },
        mounted: async function() {
            await window.authService.redirectHandled();

            this.user = window.authService.getUser();
            this.roles = this.getRolesToShow(this.user);

            this.accessGroups = await window.authService.getAccessGroups();

            this.systemInfo = await getSystemInfo(this);
            this.backgroundColor = this.navbarColorFromEnvironment(this.systemInfo.environment);
            addClickEventForNavBar();

            this.loading = false;
        },
        methods: {
            signIn: async function() {
                await window.authService.signIn();
            },
            refreshAttributes: async function() {
                await window.authService.refreshUserAttributes();
            },
            /**
             * Returns the list of important roles to show in User menu.
             *
             * @param user : {AccountInfo}
             * @returns {string[]}
             */
            getRolesToShow(user) {
                const roleFilter = ['Administrator', 'CodeEditor', 'LibraryEditor', 'Viewer'];
                return user.idTokenClaims.roles.filter(roles => roleFilter.includes(roles));
            },
            signOut: async function() {
                await window.authService.signOut();
            },
            navbarColorFromEnvironment: function(env) {
                if (env === 'Development') {
                    return '#ff9ed9';
                } else if (env === 'Staging') {
                    return '#fdee92';
                } else if (env === 'QA') {
                    return '#a190de';
                } else if (env === 'QA2') {
                    return '#ff9200';
                } else {
                    return 'white';
                }
            },
            toggleSystemInfo() {
                this.systemInfoVisible = !this.systemInfoVisible;
            }
        }
    };
</script>

<style scoped>

</style>
