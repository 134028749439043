<template>
    <div
        id="treemap"
        ref="treemap"
        class="treemap" />
</template>

<script>
    import * as d3 from 'd3';

    export default {
        name: 'LibraryTreemap',

        props: {
            csvdata: {
                type: [String],
                default: null
            }
        },
        computed: {
            width: function() {
                return window.innerWidth;
            },
            height: function() {
                return this.$el.clientHeight;
            }
        },
        watch: {
            csvdata: function() {
                this.render();
            }
        },
        mounted: function() {
            window.addEventListener('resize', this.resize);
        },
        unmounted: function() {
            window.removeEventListener('resize', this.resize);
        },
        methods: {
            resize: function() {
                this.render();
            },
            render: function() {
                const body = document.body,
                      html = document.documentElement;

                const height = Math.max(body.scrollHeight, body.offsetHeight,
                                        html.clientHeight, html.scrollHeight, html.offsetHeight) - 80;

                const width = window.innerWidth - 50;
                const format = d3.format(',d');

                const color = d3.scaleOrdinal()
                    .range(d3.schemeCategory10
                        .map(function(c) {
                            c = d3.rgb(c);
                            c.opacity = 0.6;
                            return c;
                        }));

                const stratify = d3.stratify()
                    .parentId(function(d) { return d.id.substring(0, d.id.lastIndexOf('.')); });

                const treemap = d3.treemap()
                    .size([width, height])
                    .padding(1)
                    .round(true);

                const data = d3.csvParse(this.csvdata);
                const root = stratify(data)
                    .sum(function(d) { return d.value; })
                    .sort(function(a, b) { return b.height - a.height || b.value - a.value; });

                treemap(root);

                const tree = d3.select('#treemap')
                    .selectAll('.node')
                    .data(root.leaves());

                tree.style('left', function(d) { return d.x0 + 'px'; })
                    .style('top', function(d) { return d.y0 + 'px'; })
                    .style('width', function(d) { return d.x1 - d.x0 + 'px'; })
                    .style('height', function(d) { return d.y1 - d.y0 + 'px'; });

                tree.enter().append('div')
                    .attr('class', 'node')
                    .attr('title', function(d) { return d.id.substring(d.id.indexOf('.') + 1) + '\n' + format(d.value); })
                    .style('left', function(d) { return d.x0 + 'px'; })
                    .style('top', function(d) { return d.y0 + 'px'; })
                    .style('width', function(d) { return d.x1 - d.x0 + 'px'; })
                    .style('height', function(d) { return d.y1 - d.y0 + 'px'; })
                    .style('background', function(d) { while (d.depth > 1) d = d.parent; return color(d.id); })
                    .append('div')
                    .attr('class', 'node-label')
                    .append('a')
                    .attr('href', function(d) {
                        return '/Library#/' + d.id.split('.')[1];
                    })
                    .text(function(d) {
                        return d.id.substring(d.id.lastIndexOf('.') + 1).split(/(?=[A-Z][^A-Z])/g).join('\n');
                    })
                    .append('div')
                    .attr('class', 'node-value')
                    .text(function(d) { return format(d.value); });
            }
        }
    };
</script>

<style>

    .treemap {
        font: 10px sans-serif;
        position: relative;
    }

    .node {
        box-sizing: border-box;
        position: absolute;
        overflow: hidden;
    }

    .node-label {
        padding: 4px;
        line-height: 1em;
        white-space: pre;
    }

    .node-value {
        color: rgba(0,0,0,0.8);
        font-size: 9px;
        margin-top: 1px;
    }
</style>
