<template>
    <div
        v-if="localCodeCopy"
        class="modal-card"
        style="height: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ header }}
            </p>
        </header>
        <section
            class="modal-card-body"
            :style="'padding-bottom: '+(numberOfSelects * 50)+'px;'">
            <b-field
                v-for="attr in cableCodeAttributes"
                :key="attr.field"
                :type="findFieldType(attr.field ,localCodeCopy.values[attr.field])"
                :label="attr.label"
                :message="invalidInputError(attr.field ,localCodeCopy.values[attr.field])">
                <code-ref-selector
                    v-if="attr.type === 'CodeRef' && !(attr.includeInIdentity && existingId)"
                    :code-refs="originalCodes(attr.refLibraryName).map(c => c.values)"
                    :selected="localCodeCopy.values[attr.field]"
                    :required="isFieldRequired(attr.field) && localCodeCopy.meta.isNew"
                    :complex="false"
                    :disabled="!localCodeCopy.meta.isNew"
                    @ref-selected="localCodeCopy.values[attr.field] = $event" />
                <bool-selector
                    v-else-if="attr.type.toLowerCase() === 'bool'"
                    :value="localCodeCopy.values[attr.field] ? 'True' : 'False'"
                    @bool-select="handleBoolSelect(attr.field , $event)" />
                <b-input
                    v-else
                    v-model="localCodeCopy.values[attr.field]"
                    :maxlength="showMaxLength(attr.field ,localCodeCopy.values[attr.field])"
                    :disabled="!localCodeCopy.meta.isNew && attr.field !== 'description'" />
            </b-field>
            <div
                v-for="(space, idx) in numberOfSelects"
                :key="idx"
                class="has-margin-top2" />
        </section>
        <footer class="modal-card-foot">
            <button
                class="button"
                @click="closeModal">
                <b-icon
                    icon="close-circle"
                    size="is-small" />
                <span>
                    Cancel
                </span>
            </button>
            <button
                class="is-primary button"
                :disabled="!isDone || loading"
                @click="editDone">
                <span class="icon is-small">
                    <i
                        class="fa fa-check-circle"
                        aria-hidden="true" />
                </span>
                <span>
                    {{ existingId ? 'Update ' : 'Create ' }} code
                </span>
                <b-loading
                    :is-full-page="false"
                    :model-value="loading" />
            </button>
        </footer>
    </div>
</template>

<script>

    import { InputRegexValidator } from '@/shared/helpers/inputRegexValidator.ts';
    import { useCodeEditStore } from '@/stores/codeEditStore.js';
    import _ from 'lodash';
    import { mapActions, mapStores } from 'pinia';
    import BoolSelector from '../../../shared/components/BoolSelector.vue';
    import CodeRefSelector from '../../../shared/components/CodeRefSelector.vue';
    import { libNames } from '../../../shared/helpers/cableEditHelpers';
    import { showMixin } from '../../../shared/mixins/showMixin';

    export default {
        components: {
            CodeRefSelector,
            BoolSelector
        },
        mixins: [
            showMixin
        ],
        props: {
            library: {
                type: String,
                required: true
            },
            existingId: {
                type: String,
                required: false,
                default: null
            },
            header: {
                type: String,
                required: false,
                default: 'New Element'
            }
        },
        emits: [
            'saving',
            'close'
        ],
        data: function() {
            return {
                libNames,
                localCodeCopy: null,
                originalCode: null,
                cableCodeAttributes: null,
                requiredFields: null,
                loading: false,
                descriptionRegexValidator: null,
                hasValidDescription: null
            };
        },
        computed: {
            ...mapStores(useCodeEditStore),
            numberOfSelects: function() {
                return this.cableCodeAttributes.length;
            },
            missingRequired: function() {
                if (this.localCodeCopy) {
                    const required = this.cableCodeAttributes
                        .filter(d => this.isFieldRequired(d.field))
                        .map(d => d.field);
                    return required.some(r => this.localCodeCopy.values[r] === '');
                } else {
                    return true;
                }
            },
            isDone: function() {
                return !_.isEqual(this.localCodeCopy.values, this.originalCode.values)
                    && !this.missingRequired
                    && this.hasValidDescription;
            }
        },
        mounted: function() {
            if (this.existingId) {
                let code;
                code = this.codeEditStore.changes[this.library]
                    ? this.codeEditStore.changes[this.library].find(c => c.values.identity === this.existingId)
                    : null;
                if (!code)
                    code = this.codeEditStore.originalCodes(this.library)
                        .find(c => c.values.identity === this.existingId);

                this.localCodeCopy = JSON.parse(JSON.stringify(code));
            } else {
                this.localCodeCopy = JSON.parse(JSON.stringify(this.codeEditStore.templates[libNames.cableCode]));
                this.localCodeCopy.meta.isNew = true;
                this.localCodeCopy.values.isValid = true;
                this.localCodeCopy.values.MudResistFlag = false;
            }
            const fieldsToBeIgnored = ['identity', 'dateCreated', 'dateUpdated', 'attachmentKey', 'commonLibraryIRI'];
            this.cableCodeAttributes = this.attributes(libNames.cableCode).filter(a => !fieldsToBeIgnored.includes(a.field));
            this.originalCode = _.cloneDeep(this.localCodeCopy);

            this.requiredFields = this.cableCodeAttributes
                .filter(attr => attr.required)
                .map(d => d.field)
                .concat(this.localCodeCopy.meta.isNew ? 'Screen' : '');
            // Screen is added to required fields here because it was not possible to change library definition
            // due to the DB already populated with entries where 'Screen' is null.
            const descriptionRegex = this.codeEditStore?.libraries[this.library].descriptionRegex;
            this.descriptionRegexValidator = descriptionRegex
                ? new InputRegexValidator(new RegExp(descriptionRegex))
                : null;
        },
        methods: {
            ...mapActions(useCodeEditStore, ['originalCodes', 'attributes']),
            editDone: async function() {
                let create;
                this.loading = true;
                this.$emit('saving', this.loading);

                if (this.existingId && this.localCodeCopy.meta.isNew) {
                    this.codeEditStore.removeChange(this.library, this.existingId);
                    create = false;
                } else if (this.existingId) {
                    create = false;
                } else {
                    create = true;
                }

                try {
                    this.codeEditStore.changeCode(this.library, this.localCodeCopy, create ? 'create' : 'update');
                } catch (error) {
                    this.showAlert(this, _.get(error, 'title', 'Error'), _.get(error, 'message', 'Something went wrong'));
                }

                this.loading = false;
                this.$emit('saving', this.loading);
                this.closeModal();
            },
            closeModal: function() {
                this.$emit('close', this.localCodeCopy);
            },
            isFieldRequired: function(field) {
                return this.requiredFields.some(req => req === field);
            },
            handleBoolSelect: function(field, value) {
                value = value.toString().toLowerCase();
                if (value === 'true')
                    this.localCodeCopy.values[field] = true;
                if (value === 'false')
                    this.localCodeCopy.values[field] = false;
            },
            showMaxLength: function(field, value) {
                return this.invalidInputError(field, value)
                    ? this.descriptionRegexValidator?.maxLength
                    : undefined;
            },
            invalidInputError: function(field, value) {
                if (field === 'description') {
                    const error = this.descriptionRegexValidator?.validate(value);
                    this.hasValidDescription = !error?.error;
                    return error?.error || '';
                }
            },
            findFieldType: function(field, value) {
                if (this.isFieldRequired(field) && value === ''
                    || this.invalidInputError(field, value)) {
                    return 'is-danger';
                } else {
                    return '';
                }
            }
        }
    };
</script>

<style scoped>
.modal-card-foot {
    justify-content: flex-end;
}
</style>
