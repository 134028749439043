<template>
    <div class="wrapper">
        <p><a :href="'/Schema#/specification/' + identity">&laquo; Back to schema</a></p>
        <h1 class="title">
            Edit relations in Schema: {{ identity }}
        </h1>
        <div class="columns">
            <div class="column is-one-quarter">
                <h5
                    class="subtitle is-5 column-header-light">
                    Classes
                </h5>
                <div
                    class="field is-grouped"
                    style="">
                    <div class="control">
                        <b-button
                            type="is-default"
                            :disabled="selectedClass === null"
                            @click="selectedClass = null">
                            <b-icon
                                icon="close-octagon-outline"
                                size="is-small" />
                            <span>Clear selection</span>
                        </b-button>
                    </div>
                    <div class="control">
                        <i>{{ filteredClasses.length }} of {{ classes.length }}</i>
                    </div>
                </div>
                <div class="half-table-overflow">
                    <b-table
                        :hoverable="true"
                        :selected="selectedClass"
                        :data="filteredClasses"
                        class="cursorPointer"
                        @click="clickSelectClass">
                        <b-table-column
                            v-slot="props"
                            field="Name">
                            {{ props.row.Name }}
                        </b-table-column>
                    </b-table>
                </div>
                <h5
                    class="subtitle is-5 column-header-light"
                    style="margin-top:18px;">
                    Interfaces
                </h5>
                <div
                    class="field is-grouped"
                    style="">
                    <div class="control">
                        <b-button
                            type="is-default"
                            :disabled="selectedInterfaces.length === 0"
                            @click="selectedInterfaces = []">
                            <b-icon
                                icon="close-octagon-outline"
                                size="is-small" />
                            <span>Clear selection</span>
                        </b-button>
                    </div>
                    <div class="control">
                        <i>{{ filteredInterfaces.length }} of {{ interfaces.length }}</i>
                        <i v-show="selectedInterfaces.length > 1">, {{ selectedInterfaces.length }} selected</i>
                    </div>
                </div>
                <div class="half-table-overflow">
                    <table class="table is-hoverable is-fullwidth cursorPointer">
                        <tr
                            v-for="i in filteredInterfaces"
                            :key="i.Id"
                            :class="interfaceClass(i)"
                            @click="clickSelectInterface(i)">
                            <td><span :id="'i:' + i.Identity">{{ i.Name }}</span></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="column is-one-quarter">
                <h5
                    class="subtitle is-5 column-header-light">
                    Relations
                </h5>
                <div
                    class="field is-grouped">
                    <div class="control buttons">
                        <div
                            v-require-can-edit-code="{ libraryName: 'SchemaRelation' }">
                            <b-button
                                type="is-primary"
                                :disabled="makeNewRelation || selectedRelation"
                                @click="createNewRelation">
                                <b-icon
                                    icon="plus-circle-outline"
                                    size="is-small" />
                                <span>New</span>
                            </b-button>
                            <b-tooltip
                                label="SchemaRelation library is configured locked for deletions"
                                position="is-right"
                                :active="relationListLockedForDelete">
                                <b-button
                                    type="is-danger"
                                    :disabled="relationListLockedForDelete || !selectedRelation"
                                    @click="() => confirmDeleteCode(selectedRelation.Identity)">
                                    <b-icon
                                        icon="skull"
                                        size="is-small" />
                                    <span>Delete</span>
                                </b-button>
                            </b-tooltip>
                        </div>
                    </div>
                    <div class="control">
                        <i>{{ filteredRelations.length }} of {{ relations.length }}</i>
                    </div>
                </div>
                <div class="table-overflow">
                    <b-table
                        :hoverable="true"
                        :selected="selectedRelation"
                        :data="filteredRelations"
                        class="cursorPointer"
                        @click="clickSelectRelation">
                        <b-table-column
                            v-slot="props"
                            field="Name">
                            {{ props.row.Name }}
                        </b-table-column>
                    </b-table>
                </div>
            </div>
            <div class="column">
                <h5
                    class="subtitle is-5 column-header-light">
                    Relation properties
                </h5>
                <div class="table-overflow">
                    <schema-relation-edit-form
                        :relation-id="selectedRelation ? selectedRelation.Id : null"
                        :new-relation-mode="makeNewRelation"
                        :interfaces="interfaces"
                        :schema-identity="identity"
                        :relation-code-set="relationCodeSet"
                        @cancel-edit="handleEditCancel"
                        @new-relation-saved="handleNewRelationSaved"
                        @modified-relation-saved="handleModifiedRelationSaved" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { deleteCode, genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import _ from 'lodash';
    import SchemaRelationEditForm from './SchemaRelationEditForm.vue';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            SchemaRelationEditForm
        },
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        emits: [
            'cancel-edit',
            'modified-relation-saved',
            'new-relation-saved'
        ],
        data: function() {
            return {
                interfaces: [],
                classes: [],
                relations: [],
                classInterfaceRelations: [],
                relationCodeSet: null,
                selectedInterfaces: [],
                selectedClass: null,
                selectedRelation: null,
                relationListLockedForDelete: true,
                makeNewRelation: false,
                queries: {
                    interfaces: 'FROM SchemaInterface SELECT Name, Id, Identity',
                    classes: 'FROM SchemaClass SELECT Name, Id',
                    relations: 'FROM SchemaRelation SELECT Name, Id, Identity, LeftInterface_ID, RightInterface_ID',
                    classInterfaces: 'FROM SchemaClassInterface SELECT Name, Class_ID, Interface_ID'
                }
            };
        },
        computed: {
            filteredInterfaces() {
                if (this.selectedClass) {
                    const c = this.selectedClass;
                    return this.interfaces.filter(i => _.find(this.classInterfaceRelations, { 'Interface_ID': i.Id, 'Class_ID': c.Id }));
                } else {
                    return this.interfaces;
                }
            },
            filteredClasses() {
                if (this.selectedInterfaces.length > 0) {
                    return this.classes.filter(c =>
                        _.find(this.selectedInterfaces, i =>
                            _.find(this.classInterfaceRelations, { 'Interface_ID': i.Id, 'Class_ID': c.Id })
                        )
                    );
                } else {
                    return this.classes;
                }
            },
            filteredRelations() {
                let interfacesToUse = this.selectedInterfaces;
                if (interfacesToUse.length === 0 && this.selectedClass) {
                    interfacesToUse = this.filteredInterfaces;
                }
                if (interfacesToUse.length > 0) {
                    return this.relations.filter(r =>
                        _.find(interfacesToUse, { 'Id': r.LeftInterface_ID })
                        || _.find(interfacesToUse, { 'Id': r.RightInterface_ID })
                    );
                }
                return this.relations;
            }
        },
        mounted: async function() {
            let rcs = null;
            [this.classes, this.interfaces, this.classInterfaceRelations, this.relations, rcs] = await Promise.all([
                this.doSchemaQuery(this.queries.classes),
                this.doSchemaQuery(this.queries.interfaces),
                this.doSchemaQuery(this.queries.classInterfaces),
                this.doSchemaQuery(this.queries.relations),
                getCodeSets(this, 'SchemaRelation')
            ]);

            // TODO:
            /* Fix to vue event issue for observable arrays */
            // this.interfaces = this.interfaces;

            const codeSet = rcs[0];
            this.relationCodeSet = codeSet;
            this.relationListLockedForDelete = codeSet.lockedForDelete;
        },
        methods: {
            async doSchemaQuery(query) {
                const result = await genericViewQueryAsText(
                    this,
                    query + ' WHERE IsValid = true AND Schema = @schema',
                    [{ name: '@schema', value: this.identity }]);
                return result.data.sort((a, b) => a.Name.localeCompare(b.Name));
            },
            clickSelectClass(c) {
                this.selectedClass = c;
            },
            clickSelectInterface(i) {
                this.selectedInterfaces = [i];
                this.scrollInterfaceIntoView();
            },
            clickSelectRelation(r) {
                this.selectedRelation = r;
                this.selectedInterfaces = [
                    _.find(this.interfaces, { 'Id': r.LeftInterface_ID }),
                    _.find(this.interfaces, { 'Id': r.RightInterface_ID })
                ].filter(x => x !== undefined);
                this.scrollInterfaceIntoView();
            },
            scrollInterfaceIntoView() {
                if (this.selectedInterfaces.length !== 1)
                    return;

                document.getElementById('i:' + this.selectedInterfaces[0].Identity).scrollIntoView({
                    behaviour: 'smooth'
                });
            },
            confirmDeleteCode(codeIdentity) {
                this.$buefy.dialog.confirm({
                    title: 'Please confirm delete',
                    message: 'Deleting items may cause issues if the item is known externally to Common Library, and should normally only be done during initial setup of libraries. Continue to delete item?',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        await this.deleteSchemaRelationCode(codeIdentity);
                    }
                });
            },
            deleteSchemaRelationCode: async function(codeIdentity) {
                try {
                    await deleteCode(this, this.relationCodeSet.name, codeIdentity);
                    this.selectedAttribute = null;
                } catch (error) {
                    this.showError(_.get(error, 'response.data.Message', 'Unable to delete relation'));
                }
                this.relations = await this.doSchemaQuery(this.queries.relations);
            },
            createNewRelation() {
                this.selectedRelation = null;
                this.makeNewRelation = true;
            },
            handleEditCancel() {
                this.selectedRelation = null;
                this.makeNewRelation = false;
            },
            async handleNewRelationSaved() {
                this.relations = await this.doSchemaQuery(this.queries.relations);
                this.selectedRelation = null;
                this.makeNewRelation = false;
            },
            handleModifiedRelationSaved() {
                this.selectedRelation = null;
                this.makeNewRelation = false;
            },
            interfaceClass(i) {
                return {
                    'is-selected': this.selectedInterfaces.includes(i)
                };
            }
        }
    };
</script>

<style scoped>
.wrapper{
    min-height: 900px;
    height: 80vh;
}
.cursorPointer{
    cursor: pointer;
}
.table-overflow{
    overflow: auto;
    height: 70vh;
}
.half-table-overflow{
    overflow: auto;
    height: 31vh;
}
</style>
