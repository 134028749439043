<template>
    <section v-if="codeRefs.length || show">
        <div v-show="!listmode && codeRefs.length > 0">
            <a
                class="expand-to-container button is-outlined"
                :class="{'is-danger' :required && !select}"
                :disabled="disabled || codeRefs.length === 0 || null"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @click.prevent="changeToListMode">
                <span>{{ selectedDisplay ? selectedDisplay : placeholder }}</span>
                <span class="icon is-small align-icon-left">
                    <i class="fa fa-fw fa-chevron-down" />
                </span>
            </a>
        </div>
        <div v-show="listmode || codeRefs.length === 0">
            <b-autocomplete
                ref="listRef"
                v-model="filter"
                :disabled="disabled || codeRefs.length === 0"
                clear-on-select
                :placeholder="placeholder"
                keep-first
                open-on-focus
                :loading="loading"
                :data="filteredRefs"
                :dropdown-position="position"
                append-to-body
                @blur="handleBlur"
                @focus="handleFocus"
                @select="handleSelect">
                <template #default="props">
                    <div class="media">
                        <div
                            v-if="complex"
                            class="media-content">
                            {{ props.option.name }}
                            <small> - </small>{{ props.option.description }}
                            <b-tag
                                v-if="props.option.isNew"
                                class="level-right"
                                type="is-danger is-light">
                                not released
                            </b-tag>
                            <br>
                            <small>
                                Identity: <b>{{ props.option.identity }}</b>
                                , Is Valid: <bool-element :value="props.option.isValid" />
                            </small>
                        </div>
                        <div
                            v-else
                            class="media-content">
                            <b>{{ props.option.name }}</b>
                            <small>
                                {{ showFullText(props.option) }}
                            </small>
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </div>
    </section>
</template>

<script>

    import BoolElement from './BoolElement.vue';
    import { levenshtein } from '../helpers/utils';

    export default {
        components: {
            BoolElement
        },
        props: {
            show: {
                type: Boolean,
                required: false,
                default: false
            },
            complex: {
                type: Boolean,
                required: false,
                default: true
            },
            selected: {
                type: String,
                required: false,
                default: ''
            },
            codeRefs: {
                type: Array,
                required: true
            },
            loading: {
                type: Boolean,
                required: false,
                default: false
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            sortFunc: {
                type: Function,
                default: null,
                require: false
            },
            placeholderOverride: {
                type: String,
                default: null,
                require: false
            },
            position: {
                type: String,
                default: 'auto',
                require: false
            }
        },
        emits: [
            'blur',
            'focus',
            'ref-selected'
        ],
        data: function() {
            return {
                filter: '',
                selectedDisplay: '',
                select: null,
                listmode: false
            };
        },
        computed: {
            filteredRefs: function() {
                const search = this.filter.toLowerCase();
                const self = this;

                return this.codeRefs
                    .filter(ref => ref.name.toLowerCase().includes(search) || ref.description.toLowerCase().includes(search))
                    .sort(function(a, b) {
                        if (self.sortFunc) {
                            return self.sortFunc(a, b);
                        }
                        if (search === '')
                            return a.name.localeCompare(b.name);

                        return levenshtein(a.name, search) - levenshtein(b.name, search);
                    })
                    .slice(0, 100);
            },
            placeholder: function() {
                if (this.placeholderOverride !== null || this.placeholderOverride !== undefined)
                    return this.placeholderOverride;
                if (this.codeRefs.length > 100)
                    return 'search for an item to select it';
                else
                    return 'select an item';
            }
        },
        watch: {
            selected: function(id) {
                if (!id || id === '') {
                    this.selectedDisplay = '';
                } else {
                    const sel = this.codeRefs.find(r => r.identity === id);
                    this.handleSelectedText(sel);
                }
            }
        },
        mounted: function() {
            if (this.codeRefs.length && this.selected && this.selected.length) {
                const current = this.codeRefs.find(ref => ref.identity === this.selected);
                this.handleSelectedText(current);
            }
        },
        methods: {
            showFullText: function(option) {
                if (option.description.length > 0) {
                    return `- ${option.description}`;
                }
            },
            handleBlur: function() {
                this.$emit('blur');
                const self = this;
                setTimeout(() => self.listmode = false, 300);
            },
            handleFocus: function() {
                this.$emit('focus');
            },
            handleSelect: function(option) {
                this.handleSelectedText(option);
                this.filter = '';
                this.$emit('ref-selected', this.select);
            },
            handleSelectedText: function(obj) {
                this.listmode = false;
                this.select = obj.identity;

                this.selectedDisplay = obj.name + (obj.description ? ' - ' + obj.description : '');
                if (obj.isNew) {
                    this.selectedDisplay += '   (not released)';
                }
            },
            changeToListMode: function() {
                const self = this;
                if (!this.disabled) {
                    this.listmode = true;
                    setTimeout(() => self.$refs.listRef.focus(), 150);
                }
            }
        }
    };
</script>

<style scoped>
.align-icon-left {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.expand-to-container {
    width: 100%;
}
</style>
