/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param codeSetName
 * @param identity
 * @returns {string}
 */
export function getCodeLink(codeSetName, identity) {
    return `/Library#/CodeSet/${codeSetName}/Code/${encodeURIComponent(identity)}`;
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param b64Id
 * @returns {string}
 */
export function getCodeLinkByB64Id(b64Id) {
    return `/Library#/Code/${b64Id}`;
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param tags
 * @returns {string}
 */
export function getLibraryListLink(tags) {
    return `/Library?TagFilter=${encodeURIComponent(JSON.stringify(tags))}`;
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param libraryName
 * @returns {{name: string, params: {libraryName}}}
 */
export function getLibraryLink(libraryName) {
    return { name: 'Library', params: { libraryName: libraryName } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param libraryName
 * @returns {{name: string, params: {libraryName: string}}}
 */
export function getForeignLibrarySearch(libraryName) {
    return { name: 'ForeignObjectLibrary', params: { libraryName: encodeURIComponent(libraryName) } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @returns {{name: string}}
 */
export function getLibraryNetworkLink() {
    return { name: 'LibraryNetwork' };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param codeSetName
 * @returns {string}
 */
export function getCodeSetLink(codeSetName) {
    return `/Library#/CodeSet/${codeSetName}`;
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param releaseId
 * @returns {{name: string, params: Dictionary<string> & {release: number}}}
 */
export function getReleaseLink(releaseId) {
    return { name: 'ReleaseById', params: { release: releaseId } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @returns {{name: string}}
 */
export function getMyReleaseArchiveLink() {
    return { name: 'MyReleasesArchive' };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @returns {{name: string}}
 */
export function getMyReleaseLink() {
    return { name: 'MyReleases' };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param applicationName
 * @returns {{name: string, params: {appName: string}}}
 */
export function getApplicationLink(applicationName) {
    const uriEncodedApplicationName = encodeURIComponent(applicationName);
    return { name: 'ApplicationDetails', params: { appName: uriEncodedApplicationName } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param groupId
 * @returns {{name: string, params: {groupId}}}
 */
export function getAccessGroupLink(groupId) {
    return { name: 'AccessGroupDetails', params: { groupId: groupId } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param applicationName
 * @param subscriptionId
 * @returns {{name: string, params: {appName, subscriptionId}}}
 */
export function getSubscriptionLink(applicationName, subscriptionId) {
    return { name: 'SubscriptionDetails', params: { appName: applicationName, subscriptionId: subscriptionId } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param quantityIdentity
 * @returns {string}
 */
export function getQuantityLink(quantityIdentity) {
    return { name: 'Quantity', params: { quantityIdentity: quantityIdentity } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param libraryName
 * @param applicationName
 * @param codeSetName
 * @param releaseId
 * @returns {{query: {libraryName: string, codeSetName: string, releaseId: string, applicationName: string}, name: string}}
 */
export function getReleaseLogLink({ libraryName = '', applicationName = '', codeSetName = '', releaseId = '' }) {
    const encodedLib = encodeURIComponent(libraryName);
    const encodedApp = encodeURIComponent(applicationName);
    const encodedCodeSet = encodeURIComponent(codeSetName);
    const encodedReleaseId = encodeURIComponent(releaseId);

    return { name: 'Log', query: {
        libraryName: encodedLib,
        applicationName: encodedApp,
        codeSetName: encodedCodeSet,
        releaseId: encodedReleaseId
    } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param lib
 * @returns {{name: string, params: {library}}}
 */
export function queryLinkLibrary(lib) {
    return { name: 'QueryBuilderByLibrary', params: { library: lib } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param lib
 * @returns {{name: string, params: {library}}}
 */
export function explorerLinkLibrary(lib) {
    return { name: 'LibraryNetworkForLibrary', params: { library: lib } };
}

/**
 * Fetch route {object} or url {string} PS:`<router-link>` requires an object.
 * @param hookId
 * @returns {string}
 */
export function getWebhookLink(hookId) {
    return `/Config/Webhooks?hookId=${hookId}`;
}
