<template>
    <div
        class="columns library-group-container">
        <div
            v-for="(scope, idx) in store.scopes"
            :key="idx"
            class="library-group-item">
            <p class="control is-pulled-left">
                <b-checkbox
                    :model-value="store.selectedScopeFilter.includes(scope)"
                    @input="store.updateScopeFilter(scope)">
                    {{ scope }}
                </b-checkbox>
            </p>
        </div>
    </div>
</template>

<script setup>
    import { useScopeFilterStore } from '@/stores/scopeFilterStore.js';

    const store = useScopeFilterStore();
</script>
<style scoped>
    .library-group-container {
        padding: 5px;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        overflow-x: auto;
        width:100%;
        height:100%;
    }
    .library-group-item {
        flex-basis: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        height:20px;
    }
</style>
