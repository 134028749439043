<template>
    <div class="level-item has-text-centered">
        <div>
            <p class="heading">
                {{ header }}
            </p>
            <p
                class="title is-5">
                {{ data }}
            </p>
            <p class="heading">
                Status:
                <span
                    v-if="changes"
                    class="redtext">
                    Changed
                </span>
                <span
                    v-else-if="data===null"
                    class="greentext">
                    Not Set
                </span>
                <span
                    v-else
                    class="greentext">
                    Unchanged
                </span>
            </p>
        </div>
    </div>
</template>

<script>
    import { libNames } from '../../../shared/helpers/cableEditHelpers';
    import { mapStores } from 'pinia';
    import { useCodeEditStore } from '@/stores/codeEditStore.js';

    export default {
        props: {
            library: {
                type: String,
                required: true
            },
            header: {
                type: String,
                required: true
            },
            cableCode: {
                type: String,
                required: true
            },
            changeObj: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
                changes: null,
                data: '',
                currentPage: 1
            };
        },
        computed: {
            ...mapStores(useCodeEditStore)
        },
        mounted: function() {
            this.changes = this.changeObj[this.library];
            this.resolveData();
        },
        methods: {
            resolveData: function() {
                if (this.library === libNames.cableCode)
                    this.data = this.getCableCodeData();
                else if (this.library === libNames.cableCodeType)
                    this.data = this.stringifyArray(this.getDataArray('CableCode', 'CableType'));
                else if (this.library === libNames.cableCodeSheeth)
                    this.data = this.stringifyArray(this.getDataArray('CableCode', 'SheathColour'));
            },
            getDataArray: function(cableCodeField = 'CableCode', field = 'name') {
                let returnArray = [];
                let changedCodes = [];

                const onlyValid = c => c.values.isValid === 'True';

                if (this.changes) {
                    if (this.changes.changed.length) {
                        changedCodes = this.changes.changed.map(c => c.values.identity);
                        returnArray = returnArray.concat(this.changes.changed.filter(onlyValid).map(c => c.values[field]));
                    }
                    if (this.changes.new.length)
                        returnArray = returnArray.concat(this.changes.new.filter(onlyValid).map(c => c.values[field]));
                }

                const oldCodes = this.codeEditStore.originalCodes(this.library)
                    .filter(c => c.values[cableCodeField] === this.cableCode && !changedCodes.some(id => id === c.values.identity))
                    .filter(onlyValid);

                if (oldCodes && oldCodes.length)
                    returnArray = returnArray.concat(oldCodes.map(c => c.values[field]));
                return returnArray;
            },
            stringifyArray: function(arr) {
                if (arr.length)
                    return arr.reduce((a, c) => a + ' ' + c);
                else
                    return null;
            },
            getCableCodeData: function() {
                if (this.changes && this.changes.new.length) {
                    return this.changes.new[0].values.name;
                } else {
                    return this.codeEditStore.originalCodes(libNames.cableCode)
                        .find(c => c.values.identity === this.cableCode).values.name;
                }
            },
            getSliceOfData: function() {
                const end = this.currentPage * 20 > this.data.length;
                return this.data.slice((this.currentPage - 1) * 20, end ? this.data.length : this.currentPage * 20);
            }
        }
    };
</script>
