<template>
    <div>
        <div v-if="codeSet">
            <breadcrumbs
                :code-set="codeSet"
                :library="library" />

            <b-notification
                v-show="codeSet && !codeSet.isReleaseReady"
                type="is-warning"
                :closable="false"
                has-icon
                role="alert">
                <p><b>Not release ready</b></p>
                <p>
                    This library set has not been marked as ready for release. The content will not be communicated to other systems.
                </p>
            </b-notification>

            <b-notification
                v-show="codeSet && !codeSet.lockedForDelete"
                type="is-warning"
                :closable="false"
                has-icon
                role="alert">
                <p><b>Items may be deleted</b></p>
                <p>
                    This set is opened so that items may be deleted. This generally means the set is <u>not production ready</u>, and it must be locked for deletion when appropriate.
                </p>
            </b-notification>

            <div class="columns is-multiline is-desktop is-8 is-variable">
                <div class="column">
                    <div class="columns is-multiline">
                        <div class="column is-full">
                            <nav class="level">
                                <div class="level-item has-text-centered">
                                    <div>
                                        <p class="heading">
                                            Library Set
                                        </p>
                                        <b-tooltip
                                            :active="codeSet.description.length > 40"
                                            :label="codeSet.description"
                                            position="is-bottom"
                                            multilined>
                                            <p class="title is-4">
                                                {{ $filters.truncate(codeSet.description, 40) }}
                                            </p>
                                        </b-tooltip>
                                    </div>
                                </div>
                                <div class="level-item has-text-centered">
                                    <div>
                                        <p class="heading">
                                            Created
                                        </p>
                                        <p class="title is-5">
                                            {{ $filters.dateFormatShort(codeSet.dateCreated) }}
                                        </p>
                                    </div>
                                </div>
                                <div class="level-item has-text-centered">
                                    <div>
                                        <b-tooltip
                                            label="Filtered / Total"
                                            position="is-bottom">
                                            <p class="heading">
                                                Number of Items
                                            </p>
                                            <p class="title is-5">
                                                {{ codeCount }}
                                            </p>
                                        </b-tooltip>
                                    </div>
                                </div>
                                <div
                                    v-show="!library.isGlobal"
                                    class="level-item has-text-centered">
                                    <div>
                                        <p class="heading">
                                            Scope
                                        </p>
                                        <b-tooltip
                                            :active="cappedScopeList.length > 25"
                                            :label="cappedScopeList"
                                            position="is-bottom"
                                            multilined>
                                            <p class="title is-5">
                                                {{ $filters.truncate(cappedScopeList, 25) }}
                                            </p>
                                        </b-tooltip>
                                    </div>
                                </div>
                                <div
                                    v-if="regexDisplay"
                                    class="level-item has-text-centered">
                                    <div>
                                        <p class="heading">
                                            {{ `${codeSet.codeNameRegex ? 'Library set' : 'Library'} regex` }}
                                        </p>
                                        <b-tooltip
                                            :active="regexDisplay.length > 25"
                                            :label="regexDisplay"
                                            position="is-bottom"
                                            multilined>
                                            <p class="title is-5">
                                                {{ $filters.truncate(regexDisplay, 25) }}
                                            </p>
                                        </b-tooltip>
                                    </div>
                                </div>
                            </nav>
                        </div>

                        <div class="column is-clearfix">
                            <div class="field is-grouped is-pulled-right">
                                <p class="control">
                                    <b-button
                                        v-if="showClearScopeButton"
                                        class="is-primary"
                                        icon-left="delete"
                                        outlined
                                        @click="clearLookupScope">
                                        Clear scope lock: <b>{{ lookupScope }}</b>
                                    </b-button>
                                </p>
                                <specialized-ui-button
                                    v-if="!createMode"
                                    button-icon="plus"
                                    button-label="Add new item"
                                    dropdown-text="Add new item using code editor"
                                    :code-set="codeSet"
                                    :library="library"
                                    :disabled="!hasScopes && !library.isGlobal"
                                    @clickEdit="createMode = true" />
                                <p
                                    v-else
                                    class="control">
                                    <b-button
                                        label="Cancel"
                                        icon-left="close-circle"
                                        @click="createMode = false" />
                                </p>
                                <p class="control">
                                    <b-button
                                        v-require-can-edit-code="{ libraryName: library.name, scopes: codeSet?.scopes }"
                                        class="is-primary"
                                        @click="modalActive = true">
                                        <b-icon
                                            icon="lead-pencil"
                                            size="is-small" />
                                        <span>Edit library set</span>
                                    </b-button>
                                </p>
                                <p class="control">
                                    <generate-lookup-url-button
                                        :library-name="library.name"
                                        :code-set-description="codeSet.description"
                                        :is-global="library.isGlobal"
                                        :scope="codeSet.scopes[0]"
                                        :queries="queries.queries"
                                        :reg-ex="queries.regEx" />
                                </p>
                                <p class="control">
                                    <export-button
                                        :disabled="!hasScopes && !library.isGlobal"
                                        :code-set-name="codeSet.name"
                                        :code-set-description="codeSet.description"
                                        :code-filter="exportFilters" />
                                </p>
                                <p class="control">
                                    <router-link
                                        :to="getReleaseLogLink({codeSetName: codeSet.name})"
                                        class="button is-info">
                                        <b-icon
                                            icon="format-list-numbered"
                                            size="is-small" />
                                        <span>Release log</span>
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal
                v-model="modalActive"
                has-modal-card>
                <edit-code-set
                    :codeset="codeSet"
                    :library="library"
                    :scopes="scopes"
                    @refresh="reload"
                    @close="onClose" />
            </b-modal>
        </div>

        <div
            v-if="createMode"
            class="has-margin-top2 columns is-desktop">
            <div class="column is-hidden-desktop-only is-one-fifth" />
            <div class="column flex has-margin-top2">
                <code-edit
                    :library="library.name"
                    :scopes="codeSet?.scopes"
                    :code-set-name="codeSet.name"
                    @refresh="reload"
                    @cancel="createMode = false" />
            </div>
            <div class="column is-hidden-desktop-only is-one-fifth" />
            <hr>
        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@/shared/components/Breadcrumbs.vue';
    import { getReleaseLogLink, getLibraryLink } from '@/shared/helpers/routing';
    import ToggleButton from '@/shared/components/ToggleButton.vue';
    import ExportButton from '@/shared/components/ExportButton.vue';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import EditCodeSet from './EditCodeSet.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import SpecializedUiButton from '@/shared/components/SpecializedUiButton.vue';
    import generateLookupUrlButton from '@/shared/components/GenerateLookupUrlButton.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';

    const localStorageKeys = {
        lookupScope: 'lookupScope'
    };
    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            generateLookupUrlButton,
            SpecializedUiButton,
            Breadcrumbs,
            ToggleButton,
            ExportButton,
            CodeEdit,
            EditCodeSet
        },
        mixins: [
            showMixin
        ],
        props: {
            codeSet: {
                type: Object,
                required: false,
                default: null
            },
            codeCount: {
                type: String,
                required: true
            },
            queries: {
                type: Object,
                required: true
            },
            exportColumns: {
                type: Array,
                required: false,
                default: null
            }
        },
        emits: [
            'set-createmode'
        ],
        data() {
            return {
                createMode: false,
                modalActive: false,
                lookupScope: null,
                showClearScopeButton: false
            };
        },
        computed: {
            library: function() {
                return this.codeSet ? this.codeSet.library : null;
            },
            cappedScopeList: function() {
                if (this.codeSet && this.codeSet.scopes.length)
                    return this.codeSet.scopes.join(' | ');
                else
                    return 'No scope linked to library set';
            },
            exportFilters: function() {
                return {
                    RegEx: this.queries.regEx,
                    Queries: this.queries.queries,
                    Columns: this.exportColumns
                };
            },
            scopes: function() {
                return this.codeSet ? this.codeSet.scopes : null;
            },
            hasScopes: function() {
                return this.codeSet && this.codeSet.scopes && this.codeSet.scopes.length > 0;
            },
            regexDisplay: function() {
                return this.codeSet.codeNameRegex ? this.codeSet.codeNameRegex : this.library?.codeNameRegex;
            }
        },
        watch: {
            createMode(newValue) {
                this.$emit('set-createmode', newValue);
            }
        },
        mounted() {
            const lookupScope = localStorage.getItem(localStorageKeys.lookupScope);
            if (lookupScope) {
                this.lookupScope = lookupScope;
                this.showClearScopeButton = true;
            }
        },
        methods: {
            reload: function() {
                this.modalActive = false;
                window.location.reload(true);
            },
            onClose: function() {
                this.modalActive = false;
            },
            clearLookupScope: function() {
                localStorage.removeItem(localStorageKeys.lookupScope);
                this.showClearScopeButton = false;
            },
            getReleaseLogLink,
            getLibraryLink
        }
    };
</script>
