<template>
    <div
        v-if="selectedEleComponentType">
        <div class="column-header block">
            <h4 class="title is-4">
                Tag Type Relations for {{ selectedEleComponentType.Description }}
            </h4>
        </div>
        <spinner :loading="isLoading">
            <b-table
                :striped="true"
                :hoverable="true"
                :data="tagTypeRelations"
                class="table-height overflow-x"
                :narrowed="true">
                <b-table-column
                    v-slot="props"
                    key="scope"
                    field="scope"
                    label="Facility">
                    <b-field>
                        <b-select
                            v-model="props.row.Scope"
                            placeholder="Select scope"
                            name="Scope"
                            :required="true"
                            @update:model-value="handleTagTypeRelationsChange()">
                            <option
                                v-for="(s, idx) in codeSet.scopes"
                                :key="idx"
                                :value="s">
                                {{ s }}
                            </option>
                        </b-select>
                    </b-field>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    key="tagCategory"
                    field="tagCategory"
                    label="Tag Category">
                    <b-field>
                        <b-select
                            v-model="props.row.TagCategory"
                            placeholder="Tag Category"
                            name="Tag Category"
                            :required="true">
                            <option
                                v-for="(s, idx) in tagCategories"
                                :key="idx"
                                :value="s.Name">
                                {{ s.Description }}
                            </option>
                        </b-select>
                    </b-field>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    key="name"
                    field="name"
                    label="Tag Type">
                    <b-field>
                        <b-select
                            v-model="props.row.Name"
                            placeholder="Tag Type"
                            name="Tag Type"
                            @update:model-value="handleTagTypeRelationsChange()">
                            <option
                                v-for="(s, idx) in tagTypesData.filter(x => x.Scope === props.row.Scope && x.TagCategory === props.row.TagCategory)"
                                :key="idx"
                                :value="s.Name"
                                :required="true">
                                {{ s.Description }}
                            </option>
                        </b-select>
                    </b-field>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    label="Delete">
                    <a @click="removeRow(props.index)">
                        <i class="fa fa-trash" />
                    </a>
                </b-table-column>
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>No items here</p>
                        </div>
                    </section>
                </template>
            </b-table>
            <br>
            <div style="position:relative;">
                <button
                    v-require-can-edit-code="{ libraryName, scopes: [selectedScope] }"
                    class="button is-info"
                    style="float:right;"
                    @click="addNewRow()">
                    <b-icon
                        icon="plus"
                        size="is-small" />
                    <span>Add row</span>
                </button>
                <button
                    class="button is-info"
                    :disabled="!isDirty"
                    style="float:right;margin-right:20px;"
                    @click="undoEdits()">
                    <b-icon
                        icon="eraser"
                        size="is-small" />
                    <span>Undo edits</span>
                </button>
            </div>
        </spinner>
    </div>
</template>
<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { getCodeSets, genericViewQueryAsText } from '@/shared/helpers/api';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            Spinner
        },
        props: {
            selectedScope: {
                type: String,
                default: null
            },
            selectedEleComponentType: {
                type: Object,
                default: null
            },
            libraryName: {
                type: String,
                required: true
            },
            permissions: {
                type: Array,
                required: true
            }
        },
        emits: [
            'handleTagTypeRelationChange'
        ],
        data() {
            return {
                isLoading: false,
                codeSet: null,
                initialTagTypeRelations: [],
                tagTypeRelations: [],
                tagTypesData: [],
                tagCategories: []
            };
        },
        computed: {
            isDirty: function() {
                if (this.initialTagTypeRelations.length !== this.tagTypeRelations.length) {
                    return true;
                }
                let diffCount = 0;

                this.initialTagTypeRelations.forEach(original => {
                    if (this.tagTypeRelations.filter(updated =>
                        updated.Scope === original.Scope
                        && updated.TagCategory === original.TagCategory
                        && updated.Name === original.Name
                    ).length === 0) {
                        diffCount++;
                    }
                });

                this.tagTypeRelations.forEach(updated => {
                    if (this.initialTagTypeRelations.filter(original =>
                        original.Scope === updated.Scope
                        && updated.TagCategory === original.TagCategory
                        && original.Name === updated.Name
                    ).length === 0) {
                        diffCount++;
                    }
                    if (updated.Name === null || updated.Scope === null || updated.TagCategory === null) {
                        diffCount++;
                    }
                });

                return diffCount > 0;
            }
        },
        watch: {
            selectedEleComponentType: async function() {
                this.loadData();
            }
        },
        async mounted() {
            this.loadData();
        },
        methods: {
            async loadData() {
                this.isLoading = true;
                await this.loadCodeSet();
                await this.loadInitialTagTypeRelations();
                const [
                    tagCategoriesResponse,
                    tagTypesDataResponse
                ] = await Promise.all([
                    this.getTagCategories(),
                    this.getTagTypesData()
                ]);
                this.tagCategories = tagCategoriesResponse.data;
                this.tagTypesData = tagTypesDataResponse.data;
                this.isLoading = false;
            },
            handleTagTypeRelationsChange() {
                const tagTypesUpdateList = [];
                this.initialTagTypeRelations.forEach(original => {
                    if (this.tagTypeRelations.filter(updated =>
                        updated.Scope === original.Scope
                        && updated.Name === original.Name
                    ).length === 0) {
                        tagTypesUpdateList.push(
                            {
                                Name: original.Name,
                                TagCategory: original.TagCategory,
                                Scope: original.Scope,
                                ElectricComponentType: '{null}'
                            }
                        );
                    }
                });

                // For alle elementer i current listen: hvis elementet ikke finnes i initiell liste - sett electric component type til valgt component for denne tagtypen
                this.tagTypeRelations.forEach(updated => {
                    if (this.initialTagTypeRelations.filter(original =>
                        original.Scope === updated.Scope
                        && original.Name === updated.Name
                    ).length === 0) {
                        tagTypesUpdateList.push(
                            {
                                Name: updated.Name,
                                TagCategory: updated.TagCategory,
                                Scope: updated.Scope,
                                ElectricComponentType: this.selectedEleComponentType.Name
                            }
                        );
                    }
                });

                this.$emit('handleTagTypeRelationChange', tagTypesUpdateList);
            },
            addNewRow() {
                this.tagTypeRelations = [...this.tagTypeRelations, { Scope: null, TagCategory: null, Name: null }];
            },
            removeRow(rowIndex) {
                this.tagTypeRelations = this.tagTypeRelations.filter((_, i) => i !== rowIndex);
                this.handleTagTypeRelationsChange();
            },
            undoEdits() {
                this.tagTypeRelations = JSON.parse(JSON.stringify(this.initialTagTypeRelations));
                this.handleTagTypeRelationsChange();
            },
            loadCodeSet: async function() {
                const [
                    codeSetResponse
                ] = await Promise.all ([
                    getCodeSets(this, 'ElectricComponentType', this.selectedScope)
                ]);
                this.codeSet = codeSetResponse[0];
            },
            loadInitialTagTypeRelations: async function() {
                const [
                    initialTagTypeRelationsResponse
                ] = await Promise.all ([
                    this.getTagTypeRelations()
                ]);
                this.initialTagTypeRelations = initialTagTypeRelationsResponse.data;
                this.tagTypeRelations = JSON.parse(JSON.stringify(this.initialTagTypeRelations));
            },
            getTagTypeRelations: function() {
                return genericViewQueryAsText(
                    this,
                    `FROM TagType WHERE Scope IN (${this.codeSet.scopes}) And ElectricComponentType_ID = ${this.selectedEleComponentType.Id} And IsValid = True ORDER By Description`
                );
            },
            getTagCategories: function() {
                return genericViewQueryAsText(
                    this,
                    'FROM TagCategory WHERE IsValid = true  ORDER BY Description'
                );
            },
            getTagTypesData() {
                return genericViewQueryAsText(
                    this,
                    `FROM TagType WHERE Scope IN (${this.codeSet.scopes}) And IsValid = True ORDER By Description`
                );
            }
        }

    };
</script>
