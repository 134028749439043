<template>
    <div>
        <div v-if="projectMaster">
            <h1 class="subtitle">
                Master Project
            </h1>
            <h1 class="title">
                {{ projectMaster.Name }}
            </h1>
            <router-link :to="{ name: 'ProjectMasterList' }">
                &laquo; Back to list
            </router-link>
            <b-tabs
                v-model="activeTab"
                position="is-right"
                class="block">
                <b-tab-item label="Overview">
                    <span id="pimsEditor">
                        <div v-if="showPIMSEditor">
                            <code-edit
                                :key="pimsEditKey"
                                :quick-commit="true"
                                :show-cancel="true"
                                :code-set-name="applicationPIMSCodeSetName"
                                :code-id="applicationPIMSViewModel.id"
                                :code-template-values="{
                                    ProjectMaster: projectMaster.Identity,
                                }"
                                :custom-labels="{ name: 'PIMS domain' }"
                                form-title="ApplicationPIMS"
                                library="ApplicationPIMS"
                                @refresh="onSavePIMS"
                                @cancel="cancelPIMS" />
                            <hr>
                        </div>
                    </span>
                    <div v-if="showPimsDcEditor">
                        <code-edit
                            :quick-commit="true"
                            :show-cancel="true"
                            :code-set-name="pimsDcCodeSetName"
                            :code-id="pimsDcViewModel.id"
                            :code-template-values="{
                                ProjectMaster: projectMaster.Identity,
                            }"
                            form-title="PIMS DC"
                            library="ApplicationDocumentManagement"
                            :fields-to-disable="['ProjectMaster']"
                            @refresh="onSavePIMSDC"
                            @cancel="showPimsDcEditor = false" />
                        <hr>
                    </div>
                    <div class="columns">
                        <div class="column is-5">
                            <nav class="panel">
                                <p class="panel-heading">
                                    Attributes
                                    <a
                                        v-if="attributesViewModel.canEdit"
                                        class="button is-pulled-right is-small"
                                        @click="gotoEditCode()"><b-icon
                                            icon="pencil"
                                            size="is-small" /></a>
                                </p>
                                <div class="panel-block">
                                    <b-table
                                        class="headless-table"
                                        :data="attributesViewModel.attributes"
                                        :striped="false"
                                        :narrowed="true"
                                        :hoverable="false">
                                        <b-table-column
                                            v-slot="props"
                                            field="name"
                                            label="Name">
                                            <b>{{ props.row.name }}</b>
                                        </b-table-column>
                                        <b-table-column
                                            v-slot="props"
                                            field="value"
                                            label="Value">
                                            {{ props.row.value }}
                                        </b-table-column>
                                    </b-table>
                                </div>
                            </nav>
                        </div>
                        <div class="column is-3">
                            <nav
                                class="panel"
                                style="position: relative">
                                <p class="panel-heading">
                                    Linked Facilities
                                    <a
                                        v-if="facilityViewModel.canEdit"
                                        class="button is-pulled-right is-small"
                                        @click="gotoFacilities()"><b-icon
                                            icon="pencil"
                                            size="is-small" /></a>
                                </p>
                                <div class="panel-block">
                                    <b-table
                                        v-if="facilityViewModel"
                                        class="headless-table"
                                        :data="facilityViewModel.referenced"
                                        default-sort="name"
                                        :striped="false"
                                        :narrowed="true"
                                        :hoverable="false">
                                        <b-table-column
                                            v-slot="props"
                                            field="name"
                                            label="Name">
                                            {{ props.row.Name }}
                                        </b-table-column>
                                        <b-table-column
                                            v-slot="props"
                                            field="description"
                                            label="Description">
                                            {{ props.row.Description }}
                                        </b-table-column>
                                    </b-table>
                                </div>
                                <b-loading
                                    v-model="loadingFacilities"
                                    :is-full-page="false"
                                    :can-cancel="false" />
                            </nav>
                            <nav
                                class="panel"
                                style="position: relative">
                                <p class="panel-heading">
                                    ProCoSys
                                </p>
                                <div class="panel-block">
                                    <b-table
                                        v-if="applicationProCoSysViewModel"
                                        class="headless-table"
                                        :data="applicationProCoSysViewModel"
                                        default-sort="facility"
                                        :striped="false"
                                        :narrowed="true"
                                        :hoverable="false">
                                        <b-table-column
                                            v-slot="props"
                                            field="name"
                                            label="Name">
                                            {{ props.row.facility }}
                                        </b-table-column>
                                        <b-table-column
                                            v-slot="props"
                                            field="description"
                                            label="Description">
                                            {{ props.row.projectSchema }}
                                        </b-table-column>
                                    </b-table>
                                </div>
                                <b-loading
                                    v-model="loadingApplicationProCoSys"
                                    :is-full-page="false"
                                    :can-cancel="false" />
                            </nav>
                            <nav
                                class="panel"
                                style="position: relative">
                                <p class="panel-heading">
                                    PIMS
                                    <a
                                        v-if="applicationPIMSViewModel.canEdit"
                                        class="button is-pulled-right is-small"
                                        @click="editPIMS()"><b-icon
                                            :icon="pimsIconDisplayed"
                                            size="is-small" /></a>
                                </p>
                                <div class="panel-block">
                                    <b-table
                                        class="headless-table"
                                        :data="applicationPIMSViewModel.properties"
                                        default-sort="name"
                                        :striped="false"
                                        :narrowed="true"
                                        :hoverable="false">
                                        <b-table-column
                                            v-slot="props"
                                            field="name"
                                            label="Name">
                                            {{ props.row.name }}:
                                        </b-table-column>
                                        <b-table-column
                                            v-slot="props"
                                            field="value"
                                            label="Value">
                                            {{ props.row.value }}
                                        </b-table-column>
                                    </b-table>
                                    <b-loading
                                        v-model="loadingApplicationPIMS"
                                        :is-full-page="false"
                                        :can-cancel="false" />
                                </div>
                            </nav>
                            <pims-dc-panel
                                :key="pimsDcKey"
                                :project-master="projectMaster.Identity"
                                :can-edit-code="canEditCode"
                                :attribute-definitions="pimsDcAttributeDefinitions"
                                :access-groups="pimsDcAccessGroups"
                                @editPIMSDC="editPIMSDC" />
                        </div>
                        <div class="column is-4">
                            <contracts-box
                                v-if="projectMaster"
                                ref="contractsBox"
                                :code-id="projectMaster.Identity"
                                :can-edit="canEditContract"
                                @go-to-contract="gotoContracts" />
                            <nav
                                style="position: relative"
                                class="panel">
                                <p class="panel-heading">
                                    Linked Projects
                                    <a
                                        v-if="projectViewModel.canEdit"
                                        class="button is-pulled-right is-small"
                                        @click="gotoProjects()"><b-icon
                                            icon="pencil"
                                            size="is-small" /></a>
                                </p>
                                <div class="panel-block">
                                    <b-table
                                        v-if="projectViewModel"
                                        class="headless-table"
                                        :data="projectViewModel.referenced"
                                        default-sort="Name"
                                        :striped="false"
                                        :narrowed="true"
                                        :hoverable="false">
                                        <b-table-column
                                            v-slot="props"
                                            field="Name"
                                            label="Name">
                                            {{ props.row.Name }}
                                        </b-table-column>
                                        <b-table-column
                                            v-slot="props"
                                            field="Description"
                                            label="Description">
                                            {{ props.row.Description }}
                                        </b-table-column>
                                    </b-table>
                                </div>
                                <b-loading
                                    v-model="loadingProjectMasterProjects"
                                    :is-full-page="false"
                                    :can-cancel="false" />
                            </nav>
                        </div>
                    </div>
                </b-tab-item>

                <b-tab-item
                    :disabled="!attributesViewModel.canEdit"
                    label="Edit attributes">
                    <code-edit
                        :key="projectMasterCodeEditKey"
                        :quick-commit="true"
                        :show-cancel="false"
                        :code-id="codeId"
                        library="ProjectMaster"
                        @refresh="refreshProjectMaster" />
                </b-tab-item>
                <b-tab-item
                    :disabled="!projectViewModel.canEdit"
                    label="Projects">
                    <div style="margin-bottom: 10px">
                        <div class="control has-icons-left">
                            <input
                                v-model="projectFilterText"
                                type="text"
                                class="input"
                                placeholder="Search available by Name (minimum 3 characters)">
                            <span class="icon is-small is-left">
                                <i class="fa fa-search" />
                            </span>
                        </div>
                        <b-loading
                            v-model="loadingProjectSearch"
                            :is-full-page="false"
                            :can-cancel="false" />
                    </div>
                    <set-selector
                        v-if="projectViewModel"
                        :key="projectSetSelectorKey"
                        :identity="elementIdentity"
                        :initial-elements="projectSearch"
                        :sort-by="['element.Name']"
                        :initial-selection="projectViewModel.referenced"
                        available-elements-title="Available projects"
                        selected-elements-title="Selected projects"
                        @save="projectRelationsSave">
                        <template #left-item="props">
                            <div
                                class="tile notification is-light"
                                style="margin-bottom: 2px">
                                <span><b>{{ props.element.Name }}</b>
                                    {{ props.element.Description }}</span>
                            </div>
                        </template>
                        <template #right-item="props">
                            <div
                                class="tile notification is-light"
                                style="margin-bottom: 2px">
                                <span><b>{{ props.element.Name }}</b>
                                    {{ props.element.Description }}</span>
                            </div>
                        </template>
                    </set-selector>
                </b-tab-item>
                <b-tab-item
                    :disabled="!facilityViewModel.canEdit"
                    label="Facilities">
                    <div style="margin-bottom: 10px">
                        <div class="control has-icons-left">
                            <input
                                v-model="facilityFilterText"
                                type="text"
                                class="input"
                                placeholder="Filter available facilities">
                            <span class="icon is-small is-left">
                                <i class="fa fa-search" />
                            </span>
                        </div>
                    </div>
                    <set-selector
                        v-if="facilityViewModel"
                        :key="facilitySetSelectorKey"
                        :identity="elementIdentity"
                        :sort-by="['element.Name']"
                        :initial-elements="facilityViewModel.available"
                        :initial-selection="facilityViewModel.referenced"
                        :filter="facilityFilter"
                        available-elements-title="Available facilities"
                        selected-elements-title="Selected facilities"
                        @save="facilityRelationsSave">
                        <template #left-item="props">
                            <div
                                class="tile notification is-light"
                                style="margin-bottom: 2px">
                                <span><b>{{ props.element.Name }}</b>
                                    {{ props.element.Description }}</span>
                            </div>
                        </template>
                        <template #right-item="props">
                            <div
                                class="tile notification is-light"
                                style="margin-bottom: 2px">
                                <span><b>{{ props.element.Name }}</b>
                                    {{ props.element.Description }}</span>
                            </div>
                        </template>
                    </set-selector>
                </b-tab-item>
                <b-tab-item
                    label="Contracts"
                    :disabled="!canEditContract">
                    <contracts-tab
                        :project-master="projectMaster"
                        @refresh="refreshContractsBox" />
                </b-tab-item>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ContractsBox from './ContractsBox.vue';
    import ContractsTab from './ContractsTab.vue';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import SetSelector from '@/shared/components/SetSelector.vue';
    import PimsDcPanel from './PimsDcPanel.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { createChangeDocAndCommit, genericViewQueryAsText, getMetadata } from '@/shared/helpers/api';
    import { decodeIdBase64, encodeIdBase64 } from '@/shared/helpers/utils';

    // TODO "loading"

    export default {
        components: {
            CodeEdit,
            SetSelector,
            ContractsBox,
            ContractsTab,
            PimsDcPanel
        },
        mixins: [showMixin],
        props: {
            id: {
                default: null,
                type: String
            }
        },
        data: function() {
            return {
                activeTab: 0,
                applicationPIMS: [],
                applicationPIMSAccessGroups: [],
                applicationPIMSAttributeDefinitions: [],
                applicationPIMSCodeSetName: null,
                applicationProCoSys: [],
                canEditCode: false,
                canEditContract: false,
                contractsAccessGroups: [],
                facilityFilter: (x) => x,
                facilityFilterText: '',
                facilitySearch: [],
                facilityProjectMasterAccessGroups: [],
                facilityProjectMasterCodeSetName: null,
                facilitySetSelectorKey: 1,
                loadingApplicationPIMS: false,
                loadingApplicationProCoSys: false,
                loadingFacilities: false,
                loadingProjectMasterProjects: false,
                loadingProjectSearch: false,
                pimsEditKey: 1,
                projectAccessGroups: [],
                projectCodeSetName: null,
                projectFilter: (x) => x,
                projectFilterText: '',
                projectMaster: null,
                projectMasterAccessGroups: [],
                projectMasterAttributeDefinitions: [],
                projectMasterCodeEditKey: 1,
                projectMasterNameHistory: null, // TODO rename
                projectMasterProjects: [],
                projectSearch: [],
                projectSetSelectorKey: 1,
                referencedFacilities: [],
                showPIMSEditor: false,
                pimsDcAttributeDefinitions: [],
                pimsDcCodeSetName: null,
                pimsDcAccessGroups: [],
                showPimsDcEditor: false,
                pimsDcViewModel: null,
                pimsDcKey: 1
            };
        },
        computed: {
            codeId: function() {
                if (this.projectMaster) {
                    return encodeIdBase64('Code', this.projectMaster.Id);
                }
                return null;
            },
            attributesViewModel: function() {
                const self = this;
                if (this.projectMaster) {
                    const attrs = _.sortBy(
                        this.projectMasterAttributeDefinitions,
                        (x) => x.sequenceNumber
                    ).map(function(x) {
                        return { name: x.displayAs, value: self.projectMaster[x.name] };
                    });
                    attrs.unshift({
                        name: 'IsValid',
                        value: this.projectMaster.IsValid ? 'True' : 'False'
                    });
                    attrs.unshift({
                        name: 'Description',
                        value: this.projectMaster.Description
                    });
                    return {
                        canEdit:
                            this.canEditCode
                            && window.authService.hasAnyAccessGroup(
                                this.projectMasterAccessGroups
                            ),
                        attributes: attrs
                    };
                }
                return {
                    canEdit: false,
                    attributes: []
                };
            },
            facilityViewModel: function() {
                if (this.projectMaster && !this.loadingFacilities) {
                    return {
                        canEdit: this.canEditCode
                            && window.authService.hasAnyAccessGroup(
                                this.facilityProjectMasterAccessGroups
                            ),
                        available: this.facilitySearch,
                        referenced: this.referencedFacilities
                    };
                }
                return {
                    canEdit: false,
                    available: [],
                    referenced: []
                };
            },
            projectViewModel: function() {
                if (this.projectMaster && this.projectMasterProjects) {
                    return {
                        canEdit:
                            this.canEditCode
                            && window.authService.hasAnyAccessGroup(
                                this.projectAccessGroups
                            ),
                        referenced: this.projectMasterProjects
                    };
                }
                return {
                    canEdit: false,
                    referenced: []
                };
            },
            applicationPIMSViewModel: function() {
                if (this.projectMaster && this.applicationPIMS.length > 0) {
                    if (this.applicationPIMS.length > 1) {
                        return [
                            {
                                name: 'ERROR',
                                value: 'Found multiple PIMS linked'
                            }
                        ];
                    }
                    const linked = this.applicationPIMS[0];
                    const model = [
                        { name: 'PIMS domain', value: linked.Name }
                    ];
                    return {
                        canEdit:
                            this.canEditCode
                            && window.authService.hasAnyAccessGroup(
                                this.applicationPIMSAccessGroups
                            ),
                        id: encodeIdBase64('Code', linked.Id),
                        properties: model
                    };
                }
                return {
                    canEdit: this.canEditCode,
                    properties: []
                };
            },
            applicationProCoSysViewModel: function() {
                if (this.referencedFacilities.length > 0 && this.applicationProCoSys) {
                    const facilities = this.facilityViewModel.referenced;
                    if (facilities) {
                        const proCoSysList = this.applicationProCoSys;
                        return facilities.map(f => {
                            const found = _.find(proCoSysList, (p) => p.Facility === f.Identity);
                            return {
                                facility: f.Identity,
                                projectSchema: found ? found.ProjectSchema : '**missing**'
                            };
                        });
                    }
                }
                return [];
            },
            pimsIconDisplayed: function() {
                const apvm = this.applicationPIMSViewModel;
                const showAddSymbol = apvm.canEdit && apvm.properties.length === 0;
                return showAddSymbol ? 'plus-thick' : 'pencil';
            }
        },
        watch: {
            contractsAccessGroups: async function() {
                this.canEditContract = await this.canEditCode
                    && await window.authService.hasAnyAccessGroup(
                        this.contractsAccessGroups
                    );
            },
            projectFilterText: async function() {
                this.loadProjects();
            },
            facilityFilterText: function(value) {
                if (value === '') this.facilityFilter = (x) => x;

                const parts = value.toLowerCase().split(' ');
                this.facilityFilter = function(x) {
                    const tmp = (x.Name + x.Description).toLowerCase();
                    return _.every(parts, function(p) {
                        return tmp.indexOf(p) > -1;
                    });
                };
            },
            projectViewModel: function(value) {
                if (value) {
                    this.projectSetSelectorKey += 1;
                }
            },
            facilityViewModel: function(value) {
                if (value) {
                    this.facilitySetSelectorKey += 1;
                }
            },
            applicationPIMSViewModel: function(value) {
                if (value) {
                    this.pimsEditKey += 1;
                }
            }
        },
        mounted: async function() {
            const metadataRequest = [
                {
                    request: 'access-groups-for-library',
                    values: ['ApplicationPIMS', 'Contract', 'FacilityProjectMaster', 'ProjectMaster', 'ApplicationDocumentManagement']
                },
                {
                    request: 'code-sets',
                    values: ['ApplicationPIMS', 'FacilityProjectMaster', 'Project', 'ProjectMasterNameHistory', 'ApplicationDocumentManagement']
                },
                {
                    request: 'attribute-definitions',
                    values: ['ApplicationPIMS', 'ProjectMaster', 'ApplicationDocumentManagement']
                }
            ];
            const metadataResponse = await getMetadata(this, metadataRequest);
            const metadata = metadataResponse.data;
            this.canEditCode = await window.authService.hasPermission('CanEditCode');

            this.applicationPIMSAccessGroups = metadata['access-groups-for-library']['ApplicationPIMS'];
            this.pimsDcAccessGroups = metadata['access-groups-for-library']['ApplicationDocumentManagement'];
            this.facilityProjectMasterAccessGroups = metadata['access-groups-for-library']['FacilityProjectMaster'];
            this.projectMasterAccessGroups = metadata['access-groups-for-library']['ProjectMaster'];
            this.contractsAccessGroups = metadata['access-groups-for-library']['Contract'];

            this.applicationPIMSCodeSetName = metadata['code-sets']['ApplicationPIMS'][0].name;
            this.facilityProjectMasterCodeSetName = metadata['code-sets']['FacilityProjectMaster'][0].name;
            this.projectCodeSetName = metadata['code-sets']['Project'][0].name;
            this.projectMasterNameHistory = metadata['code-sets']['ProjectMasterNameHistory'][0].name;
            this.pimsDcCodeSetName = metadata['code-sets']['ApplicationDocumentManagement'][0].name;

            this.applicationPIMSAttributeDefinitions = metadata['attribute-definitions']['ApplicationPIMS'].attributeDefinitions;
            this.pimsDcAttributeDefinitions = metadata['attribute-definitions']['ApplicationDocumentManagement'].attributeDefinitions;
            this.projectMasterAttributeDefinitions = metadata['attribute-definitions']['ProjectMaster'].attributeDefinitions;

            await this.loadProjectMaster();
            await this.loadProjectMasterProjects();
            await this.loadApplicationPIMS();
            await this.facilityLoader();
            await this.loadApplicationProCoSys();
        },
        methods: {
            loadProjectMaster: async function() {
                this.projectMaster = (await genericViewQueryAsText(
                    this,
                    'FROM ProjectMaster WHERE Id = @Id',
                    [{ name: '@Id', value: decodeIdBase64(this.id) }]
                )).data[0];
            },
            loadProjectMasterProjects: async function() {
                this.loadingProjectMasterProjects = true;
                this.projectMasterProjects = (await genericViewQueryAsText(
                    this,
                    `FROM Project
                    WHERE ProjectMaster = @pmIdentity
                    AND IsValid = true`,
                    [{ name: '@pmIdentity', value: this.projectMaster.Identity }]
                )).data;
                this.loadingProjectMasterProjects = false;
            },
            loadProjects: async function() {
                if (this.projectFilterText.length >= 3) {
                    this.loadingProjectSearch = true;
                    this.projectSearch = (await genericViewQueryAsText(
                        this,
                        `FROM Project
                         WHERE ProjectMaster IS NULL
                         AND Name LIKE @nameFilter
                         AND IsValid=true`,
                        [{
                            name: '@nameFilter',
                            value: `%${this.projectFilterText.trim()}%`
                        }]
                    )).data;
                    this.loadingProjectSearch = false;
                }
            },
            loadApplicationPIMS: async function() {
                this.loadingApplicationPIMS = true;
                this.applicationPIMS = (await genericViewQueryAsText(
                    this,
                    `FROM ApplicationPIMS
                     WHERE IsValid=true
                     AND ProjectMaster = @masterIdentity`,
                    [{ name: '@masterIdentity', value: this.projectMaster.Identity }]
                )).data;
                this.loadingApplicationPIMS = false;
            },
            loadApplicationProCoSys: async function() {
                this.loadingApplicationProCoSys = true;
                this.applicationProCoSys = (await genericViewQueryAsText(
                    this,
                    'FROM ApplicationProCoSys WHERE IsValid=true'
                )).data;
                this.loadingApplicationProCoSys = false;
            },
            loadFacilityRelations: async function() {
                this.referencedFacilities = (await genericViewQueryAsText(this, `
                    FROM FacilityProjectMaster
                    WHERE IsValid = true
                    JOIN Facility
                        SELECT Identity, Name, Description
                        WHERE IsValid = true
                    END
                    JOIN ProjectMaster
                        WHERE Identity = @masterIdentity AND IsValid = true
                    END
                `, [{ name: '@masterIdentity', value: this.projectMaster.Identity }])).data;
            },
            loadFacilities: async function() {
                this.facilitySearch = (await genericViewQueryAsText(
                    this,
                    `FROM Facility
                     SELECT Name, Identity, Description
                     WHERE IsValid = true`
                )).data.filter(f => !this.referencedFacilities.some(rf => rf.Name === f.Name));
            },
            // These need to be loaded in correct order, so they are wrapped in helping function
            facilityLoader: async function() {
                this.loadingFacilities = true;
                await this.loadFacilityRelations();
                await this.loadFacilities();
                this.loadingFacilities = false;
            },
            refreshContractsBox: function() {
                this.$refs.contractsBox.refresh();
            },
            elementId: function(e) {
                return e.id;
            },
            elementIdentity: function(e) {
                return e.identity;
            },
            gotoEditCode: function() {
                this.activeTab = 1;
            },
            gotoProjects: function() {
                this.activeTab = 2;
            },
            gotoFacilities: function() {
                this.activeTab = 3;
            },
            gotoContracts: function() {
                this.activeTab = 4;
            },
            editPIMS: function() {
                if (!this.showPimsDcEditor)
                    this.showPIMSEditor = true;
            },
            editPIMSDC: function(pimsDcViewModel) {
                if (!this.showPIMSEditor) {
                    this.showPimsDcEditor = true;
                    this.pimsDcViewModel = pimsDcViewModel;
                }
            },
            cancelPIMS: function() {
                this.showPIMSEditor = false;
            },
            onSavePIMS: function() {
                this.showPIMSEditor = false;
                this.loadApplicationPIMS();
            },
            onSavePIMSDC: function() {
                this.showPimsDcEditor = false;
                this.pimsDcKey++;
            },
            refreshProjectMaster: async function(codes) {
                if (
                    codes
                    && codes.length > 0
                    && codes[0].Name !== this.projectMaster.Name
                ) {
                    const historyChange = [
                        {
                            Name: codes[0].Name,
                            Description: '',
                            IsValid: 'true',
                            ProjectMaster: this.projectMaster.Identity,
                            OldName: this.projectMaster.Name,
                            NameChangeDecisionDate: new Date().toJSON()
                        }
                    ];

                    await createChangeDocAndCommit(
                        this,
                        this.projectMasterNameHistory,
                        historyChange
                    );
                }

                await this.loadProjectMaster();

                // Force Attributes code-edit to re-mount
                this.projectMasterCodeEditKey += 1;
            },
            projectRelationsSave: async function(state) {
                try {
                    const changes = _.concat(
                        state.added.map((project) => ({
                            Name: project.Name,
                            ProjectMaster: this.projectMaster.Identity
                        })),
                        state.removed.map((project) => ({
                            Name: project.Name,
                            ProjectMaster: '{null}'
                        }))
                    );
                    const res = await createChangeDocAndCommit(
                        this,
                        this.projectCodeSetName,
                        changes
                    );
                    this.showInfo(res.data.message);
                    await this.loadProjectMasterProjects();
                    await this.loadProjects();
                } catch (err) {
                    this.showError(err);
                }
            },
            facilityRelationsSave: async function(state) {
                try {
                    const changes = _.concat(
                        state.added.map(
                            this.facilityChangeConstructor(this.projectMaster, true)
                        ),
                        state.removed.map(
                            this.facilityChangeConstructor(
                                this.projectMaster,
                                false
                            )
                        )
                    );
                    const res = await createChangeDocAndCommit(
                        this,
                        this.facilityProjectMasterCodeSetName,
                        changes
                    );
                    this.showInfo(res.data.message);
                    this.facilityLoader();
                } catch (err) {
                    this.showError(err);
                }
            },
            facilityChangeConstructor: function(projectMaster, isValid) {
                return function(facility) {
                    const tmp = `${facility.Name} rel ${projectMaster.Name}`;
                    return {
                        Facility: facility.Identity,
                        ProjectMaster: projectMaster.Identity,
                        Name: tmp,
                        Description: tmp,
                        IsValid: isValid
                    };
                };
            }
        }
    };
</script>

<style>
.headless-table thead {
    display: none;
}
.headless-table {
    width: 100%;
}
</style>
