<template>
    <tab-layout
        :has-selected-scope="!!selectedScope"
        :is-form-active="isFormActive"
        :exists-code-set-for-scope="existsCodeSetForScope"
        :code-edit-title="codeEditTitle"
        one-third>
        <template #toolbar>
            <div
                v-require-can-edit-code="{ libraryName: 'PipingClassSize', scopes: selectedScope ? [selectedScope] : [] }"
                class="level has-margin-bottom">
                <div class="level-left">
                    <b-button
                        class="level-item"
                        type="is-info"
                        :disabled="!(selectedScope && selectedPipingClass)"
                        :model-value="isCreating"
                        @click="onNewPipingClassSizeClicked">
                        <span class="icon is-small">
                            <i
                                class="fa fa-plus"
                                aria-hidden="true" />
                        </span>
                        <span>
                            New Piping Class Size
                        </span>
                    </b-button>
                </div>
            </div>
        </template>
        <template #picker>
            <div class="table-container">
                <b-table
                    v-model:selected="selectedPipingClass"
                    height="60vh"
                    sticky-header
                    hoverable
                    striped
                    bordered
                    :data="visiblePipingClasses"
                    :loading="loadingPipingClasses"
                    :mobile-cards="false">
                    <b-table-column
                        field="Name"
                        label="Piping class"
                        width="120px"
                        sortable
                        searchable>
                        <template #default="{ row }">
                            {{ row.Name }}
                        </template>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        field="IsValid"
                        centered
                        label="Valid">
                        <bool-element :value="props.row.IsValid" />
                    </b-table-column>
                    <template #empty>
                        <div class="has-text-centered">
                            No piping classes
                        </div>
                    </template>
                </b-table>
                <b-table
                    v-model:selected="selectedPipingClassSize"
                    height="60vh"
                    sticky-header
                    hoverable
                    striped
                    bordered
                    :data="visiblePipingClassSizes"
                    :loading="loadingPipingClassSizes"
                    :mobile-cards="false">
                    <b-table-column
                        field="Name"
                        label="Line size"
                        width="100px"
                        sortable>
                        <template #default="{ row }">
                            {{ row.Name }}
                        </template>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        centered
                        field="IsValid"
                        label="Valid">
                        <bool-element :value="props.row.IsValid" />
                    </b-table-column>
                    <template #empty>
                        <div class="has-text-centered">
                            {{ selectedPipingClass ? "No sizes" : "No piping class selected" }}
                        </div>
                    </template>
                </b-table>
            </div>
        </template>
        <template #form>
            <code-edit
                :key="selectedPipingClassSizeId"
                library="PipingClassSize"
                :scopes="selectedScope"
                :code-id="selectedPipingClassSizeId"
                :code-set-name="codeSetName"
                :fields-to-disable="isEditing ? ['LineSize'] : ['PipingClass']"
                :fields-to-ignore="['attachmentKey', 'name', 'description']"
                :code-template-values="{ 'PipingClass': selectedPipingClass.Identity, 'name': 'DUMMY' }"
                :custom-labels="labels"
                :computed-fields="calculations"
                :before-save-code="beforeSaveCodeInCodeEdit"
                :use-box-render="false"
                :use-columns="true"
                @cancel="onCancelClicked"
                @refresh="onSavedPipingClassSize" />
        </template>
    </tab-layout>
</template>
<script>
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import TabLayout from './TabLayout.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import tmin_calculations from './pipingClassSizes';
    import _ from 'lodash';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            Spinner,
            CodeEdit,
            TabLayout,
            BoolElement
        },
        props: {
            pipingClasses: {
                type: Array,
                required: true
            },
            loadingPipingClasses: {
                type: Boolean,
                required: true
            },
            pipingClassSizes: {
                type: Array,
                required: true
            },
            loadingPipingClassSizes: {
                type: Boolean,
                default: true
            },
            codeSetName: {
                type: String,
                default: null
            },
            loadingCodeSetName: {
                type: Boolean,
                default: true
            },
            selectedScope: {
                type: String,
                default: null
            },
            viewValidOnly: {
                type: Boolean,
                required: true
            },
            userSelectedPipingClass: {
                type: Object,
                default: null
            }
        },
        emits: [
            'selectPipingClass',
            'savedPipingClassSize'
        ],
        data() {
            return {
                isFormActive: false,
                selectedPipingClassSize: null,
                selectedPipingClass: null,
                labels: {
                    UpdateRef: 'Reference',
                    Stress: 'Stress [N/mm2]',
                    WallThickness: 'Wall Thickness [mm]',
                    OuterDiameter: 'Outer Diameter [mm]'
                }
            };
        },
        computed: {
            selectedPipingClassSizeId() {
                return this.selectedPipingClassSize ? encodeIdBase64('Code', this.selectedPipingClassSize.Id) : null;
            },
            codeEditTitle() {
                const verb = this.isEditing ? 'Edit' : 'New';
                return `${verb} Piping Class Size`;
            },
            isCreating() {
                return this.isFormActive && !this.selectedPipingClassSizeId;
            },
            isEditing() {
                return this.isFormActive && !!this.selectedPipingClassSizeId;
            },
            visiblePipingClasses() {
                return this.viewValidOnly ? this.pipingClasses.filter(code => code.IsValid) : this.pipingClasses;
            },
            visiblePipingClassSizes() {
                return this.viewValidOnly ? this.pipingClassSizes.filter(code => code.IsValid) : this.pipingClassSizes;
            },
            existsCodeSetForScope() {
                return !this.loadingCodeSetName && this.codeSetName !== null;
            }
        },
        watch: {
            selectedScope() {
                this.selectedPipingClass = null;
            },
            selectedPipingClass(newPipingClass) {
                this.selectedPipingClassSize = null;
                this.isFormActive = false;

                if (!newPipingClass) { return; }
                this.$emit('selectPipingClass', newPipingClass);
            },
            selectedPipingClassSize(newValue, oldValue) {
                if (newValue !== null) {
                    this.isFormActive = true;
                } else if (!oldValue) {
                    this.isFormActive = false;
                }
            },
            userSelectedPipingClass(newValue) {
                if (newValue !== null) {
                    this.selectedPipingClass = newValue;
                }
            },
            pipingClassSizes(newPipingClassSizes, oldPipingClassSizes) {
                if (this.isEditing) {
                    this.selectedPipingClassSize = newPipingClassSizes.find(ls => ls.Id === this.selectedPipingClassSize.Id);
                }
                if (this.isCreating) {
                    const addedLineSize = _.differenceBy(newPipingClassSizes, oldPipingClassSizes, 'Id');
                    this.selectedPipingClassSize = addedLineSize.length > 0 ? addedLineSize[0] : null;
                }
            }
        },
        methods: {
            onNewPipingClassSizeClicked() {
                this.selectedPipingClassSize = null;
                this.isFormActive = true;
            },
            onCancelClicked() {
                this.selectedPipingClassSize = null;
                this.isFormActive = false;
            },
            beforeSaveCodeInCodeEdit(code) {
                if (!this.isCreating) {
                    return;
                }
                const lineSize = code['LineSize'];
                code['name'].value = lineSize.value;
            },
            calculations(code) {
                if (this.selectedPipingClass !== null) {
                    return tmin_calculations(this.selectedScope, code, this.selectedPipingClass);
                }
            },
            onSavedPipingClassSize() {
                this.$emit('savedPipingClassSize');
            }
        }
    };
</script>
<style scoped>
    .table-container {
      display: flex;
      gap: 20px;
    }
</style>
