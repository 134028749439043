<template>
    <spinner :loading="loading">
        <h2 class="title is-3">
            My Release Archive
        </h2>
        <nav class="level has-margin-top2">
            <div class="level-left" />

            <div class="level-right">
                <p class="level-item">
                    <router-link
                        :to="getMyReleaseLink()"
                        class="button is-info">
                        <span class="icon is-small">
                            <i
                                class="fa fa-fas fa-play"
                                aria-hidden="true" />
                        </span>
                        <span>Active Releases</span>
                    </router-link>
                </p>
            </div>
        </nav>

        <div class="box">
            <h2 class="title is-5">
                Published:
            </h2>
            <nav class="level has-margin-top2">
                <div class="level-left">
                    <div class="level-item">
                        <b-select v-model="publishedFilters.library">
                            <option :value="''">
                                All Libraries
                            </option>
                            <option
                                v-for="lib in libraries"
                                :key="lib.name">
                                {{ lib.name }}
                            </option>
                        </b-select>
                    </div>
                </div>
                <div class="level-right" />
            </nav>
            <release-table
                :paginate="true"
                empty-text="No published releases found"
                :releases="published" />
        </div>

        <hr>

        <div class="box">
            <h2 class="title is-5">
                Discarded:
            </h2>
            <nav class="level has-margin-top2">
                <div class="level-left">
                    <div class="level-item">
                        <b-select v-model="discardedFilters.library">
                            <option :value="''">
                                All Libraries
                            </option>
                            <option
                                v-for="lib in libraries"
                                :key="lib.name">
                                {{ lib.name }}
                            </option>
                        </b-select>
                    </div>
                </div>

                <div class="level-right" />
            </nav>
            <release-table
                empty-text="No discarded releases found"
                :releases="discarded" />
        </div>
    </spinner>
</template>

<script>

    import Spinner from '@/shared/components/Spinner.vue';
    import { getLibraries, getMyReleases } from '@/shared/helpers/api';
    import { getCodeSetLink, getLibraryLink, getMyReleaseLink } from '@/shared/helpers/routing.js';
    import ReleaseTable from './ReleaseTable.vue';

    export default {
        components: {
            Spinner,
            ReleaseTable
        },
        data: function() {
            return {
                publishedFilters: {
                    library: ''
                },
                discardedFilters: {
                    library: ''
                },
                libraries: null,
                pub: null,
                disc: null,
                loading: true
            };
        },
        computed: {
            published: function() {
                if (this.pub === null)
                    return [];
                if (this.publishedFilters.library !== '')
                    return this.pub.filter(r => r.specification.libraryName === this.publishedFilters.library);
                return this.pub;
            },
            discarded: function() {
                if (this.disc === null)
                    return [];
                if (this.discardedFilters.library !== '')
                    return this.disc.filter(r => r.specification.libraryName === this.discardedFilters.library);
                return this.disc;
            }
        },
        mounted: async function() {
            this.libraries = (await getLibraries(this)).sort((a, b) => a.name > b.name ? 1 : -1);
            this.pub = await getMyReleases(this, [5]);
            this.disc = await getMyReleases(this, [7]);
            this.loading = false;
        },
        methods: {
            getCodeSetLink,
            getLibraryLink,
            getMyReleaseLink
        }
    };
</script>
