<template>
    <div>
        <b-loading
            v-if="viewState === 'LOADING'"
            :model-value="true"
            :is-full-page="false">
            <b-icon
                pack="fas"
                icon="sync-alt"
                size="is-large"
                custom-class="fa-spin" />
        </b-loading>
        <div v-if="viewState === 'QUANTITIES'">
            <h1 class="title">
                Unit of Measure
            </h1>
            <b-table
                :data="quantities"
                default-sort="Name"
                :striped="true"
                :narrowed="true"
                :hoverable="true"
                class="table-height site-sticky-header">
                <b-table-column
                    v-slot="props"
                    field="Name"
                    label="Name"
                    sortable>
                    <router-link :to="{ name:'Quantity', params: { quantityIdentity: props.row.Identity }}">
                        {{ props.row.Name }}
                    </router-link>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="Description"
                    label="Description"
                    sortable>
                    {{ props.row.Description }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="UoMDescription"
                    label="Core measure"
                    sortable>
                    {{ $filters.coreUoM(props.row) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="IsBaseQuantity"
                    label="Base quantity"
                    sortable>
                    {{ $filters.isit(props.row.IsBaseQuantity) }}
                </b-table-column>
            </b-table>
        </div>
        <div v-if="viewState === 'QUANTITY_SELECTED'">
            <h1 class="title">
                Quantity: {{ selectedQuantity.Name }}
            </h1>
            <p>
                <router-link :to="{ name: 'UnitOfMeasure' }">
                    <button class="button">
                        &laquo; Back to Quantities
                    </button>
                </router-link>
            </p>
            <spinner :loading="loadingSelected">
                <b-table
                    v-if="!loadingSelected"
                    :data="unitOfMeasures"
                    default-sort="Name"
                    :striped="true"
                    :narrowed="true"
                    :hoverable="true"
                    class="table-height site-sticky-header">
                    <b-table-column
                        v-slot="props"
                        field="Name"
                        label="Name"
                        sortable>
                        {{ props.row.Name }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="Description"
                        label="Description"
                        sortable>
                        {{ props.row.Description }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="MeasureSystem"
                        label="MeasureSystem"
                        sortable>
                        {{ props.row.MeasureSystem }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="FactorA"
                        label="Convertion">
                        {{ $filters.convertion(props.row, selectedQuantity) }}
                    </b-table-column>
                </b-table>
            </spinner>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { genericViewQueryAsText } from '@/shared/helpers/api';
    import Spinner from '@/shared/components/Spinner.vue';

    export default {
        components: {
            Spinner
        },
        filters: {
            isit: function(x) {
                return x ? 'yes' : '';
            },
            coreUoM: function(q) {
                if (q?.UoMDescription)
                    return `${q.UoMDescription} (${q.UoMName})`;
                return '';
            },
            convertion: function(uom, q) {
                if (!uom || !q)
                    return '';
                if (q.UoMName === uom.Name)
                    return ''; // core unit
                if (uom.FactorA === null)
                    return 'Conversion missing';
                const partA = `[${q.UoMName}]` + (uom.FactorA !== 1 ? ` × ${uom.FactorA}` : '');
                const partB = uom.FactorB !== 0 ? ` ${uom.FactorB > 0 ? '+' : '-'} ${Math.abs(uom.FactorB)}` : '';
                return `[${uom.Name}] ≡ ${partA}${partB}`;
            }
        },
        props: {
            quantityIdentity: {
                type: String,
                default: null
            }
        },
        data: function() {
            return {
                quantities: [],
                selectedQuantity: null,
                unitOfMeasures: [],
                loadingSelected: false
            };
        },
        computed: {
            viewState: function() {
                if (this.selectedQuantity)
                    return 'QUANTITY_SELECTED';

                if (this.quantities.length > 0)
                    return 'QUANTITIES';

                return 'LOADING';
            }
        },
        watch: {
            async quantityIdentity(newValue) {
                if (!newValue) {
                    this.deselectQuantity();
                }
                await this.load();
            }
        },
        mounted: async function() {
            await this.load();
        },
        methods: {
            load: async function() {
                if (this.quantities.length === 0) {
                    this.quantities = (await genericViewQueryAsText(this, `
                        FROM Quantity
                        SELECT Identity, Name, Description, IsBaseQuantity
                        JOIN CoreUoM
                        SELECT Name AS UoMName, Description as UoMDescription
                    `)).data;
                }

                if (this.quantityIdentity) {
                    const quantity = _.find(this.quantities, ['Identity', this.quantityIdentity]);
                    await this.selectQuantity(quantity);
                }
            },
            selectQuantity: async function(q) {
                this.loadingSelected = true;
                this.selectedQuantity = q;
                this.unitOfMeasures = (await genericViewQueryAsText(
                    this,
                    `FROM UnitOfMeasure
                    SELECT Name, Description, MeasureSystem
                    WHERE Quantity = @selectedQuantity
                    JOIN Conversion
                    SELECT FactorA, FactorB`,
                    [{ name: '@selectedQuantity', value: this.selectedQuantity.Identity }])).data;
                this.loadingSelected = false;
            },
            deselectQuantity: function() {
                this.selectedQuantity = null;
                this.unitOfMeasures = [];
            }
        }
    };
</script>
<style scoped>
p {
    margin-bottom:10px;
}
</style>
