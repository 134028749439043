import { SnackbarProgrammatic as Snackbar } from 'buefy';

// TODO: We should look into unifying the way we handle errors
// from the API between this interceptor and the showError mixin
const ApiErrorInterceptor = {
    errorResponse: function(error) {
        if (error.response && error.response.status === 408)
            Snackbar.open({
                duration: 5000,
                message: '<p>There was a problem connecting to common library.</p>Please try to reload the page',
                actionText: 'reload',
                position: 'is-top',
                type: 'is-danger',
                onAction: () => window.location.reload()
            });

        return Promise.reject(error);
    }
};
export default ApiErrorInterceptor;
