import axios from 'axios';
import Buefy from 'buefy';
import { createApp } from 'vue';
import { createStore } from 'vuex';
import UrlSync from '@/shared/mixins/urlSync';
import VueScrollTo from 'vue-scrollto';
import ApiErrorInterceptor from '@/shared/interceptors/apiErrorInterceptor';
import authInterceptor from '@/shared/interceptors/authInterceptor';
import AuthService from '@/shared/services/authService';
import { monkeyPatchBTableSort } from '@/btableMonkeyPatch.js';
import { getAzureConfiguration, getWebClientConfig } from './appConfig';
import {
    router,
    rewriteTitle,
    rewriteLegacyRouteUrls,
    ensureLoggedIn,
    requireAuthForRoutes,
    redirectOldCode
} from '@/router';
import CommonLibraryApp from '@/apps/CommonLibraryApp.vue';
import mailboxQueue from '@/headerMailbox';
import { legacyFiltersPlugin } from '@/filters';
import { storeConfig } from '@/shared/store';

/**
 * Loads the configuration and binds the AuthService to the Window.
 */
const webclientConfig = await getWebClientConfig();
const azureConfig = await getAzureConfiguration();
const auth = new AuthService(axios, azureConfig);
await auth.init();
window.authService = auth;
window.config = webclientConfig;
await window.authService.signIn();

const app = createApp(CommonLibraryApp);

// Styles & Component library
import '../css/equinor.scss';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@mdi/font/scss/materialdesignicons.scss';
import { createPinia } from 'pinia';

const buefyOptions = {
    defaultTooltipType: 'is-info'
};
app.use(Buefy, buefyOptions);
monkeyPatchBTableSort(app);

// Axios
axios.interceptors.request.use(authInterceptor.request);
axios.interceptors.response.use(null, ApiErrorInterceptor.errorResponse);
app.config.globalProperties.$http = axios;

// Store Config
// Pinia
const pinia = createPinia();
app.use(pinia);
// Vuex
const store = createStore(storeConfig);
app.use(store);

// Router
router.beforeEach(ensureLoggedIn);
router.beforeEach(requireAuthForRoutes);
router.beforeEach(rewriteTitle);
router.beforeEach(rewriteLegacyRouteUrls);
router.beforeEach(redirectOldCode);
app.use(router);

// Filters
app.use(legacyFiltersPlugin);

// Mixin
app.use(UrlSync);

// Directives
app.directive('scroll-to', VueScrollTo);

// Misc helpers
document.headerMailbox = document.headerMailbox || mailboxQueue();

app.mount('#app');
