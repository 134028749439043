<template>
    <div
        class="container"
        style="max-width: 1280px">
        <h1 class="title">
            Publisher <span class="icon is-small has-text-info pl-4"><i class="fa fa-rocket" /></span>
        </h1>
        <h2 class="subtitle">
            Publishing of Code Sets
        </h2>
        <spinner :loading="loading">
            <div class="toolbar block">
                <generic-dropdown-menu
                    :alternatives="receivers"
                    :button-type="'is-info'"
                    :none-selected-text="'Select receiver'"
                    :selected-text-prefix="'Receiver: '"
                    @setSelected="newValue => selectedReceiver = newValue" />
                <generic-dropdown-checkbox
                    :options="libraryGroups"
                    title="Library groups"
                    :show-selected-count="selectedGroups.length > 0"
                    @update="newValue => selectedGroups = newValue" />
                <generic-dropdown-checkbox
                    :options="getFilteredLibraries.map(l => l.name)"
                    title="Libraries"
                    @update="selectLibraries" />
                <generic-dropdown-checkbox
                    :options="facilities"
                    :disabled="facilities.length === 0"
                    title="Facilities"
                    @update="newValue => selectedFacilities = newValue" />
                <generic-dropdown-checkbox
                    :options="filters"
                    title="Filters"
                    @update="newValue => selectedFilters = newValue" />
                <b-button
                    class="button is-primary"
                    style="margin-left: auto"
                    :disabled="!canPublish"
                    :loading="isPublishing"
                    @click="publish">
                    <span class="icon is-small">
                        <i class="fa fa-play" />
                    </span>
                    <span>
                        Publish {{ codesToPublish }} codes
                    </span>
                </b-button>
            </div>

            <section class="block">
                <h3 class="title is-4">
                    Filters
                </h3>
                <b-field
                    v-if="selectedFilters.length > 0"
                    grouped>
                    <b-field
                        v-if="isValidFilterEnabled"
                        label="IsValid =">
                        <b-select
                            v-model="isValid"
                            placeholder="No filter">
                            <option :value="null" />
                            <option :value="true">
                                True
                            </option>
                            <option :value="false">
                                False
                            </option>
                        </b-select>
                    </b-field>
                    <b-field
                        v-if="updatedSinceFilterEnabled"
                        class="date-updated-field"
                        label="DateUpdated ≥">
                        <b-datetimepicker
                            v-model="updatedSince"
                            placeholder="Type or select a date..."
                            icon="calendar-today"
                            :icon-right="updatedSince ? 'close-circle' : ''"
                            icon-right-clickable
                            :datetime-formatter="formatDatetime"
                            :datetime-parser="parseDatetime"
                            :timepicker="{ enableSeconds: true, hourFormat: '24' }"
                            editable
                            @icon-right-click="updatedSince = null" />
                    </b-field>
                </b-field>
                <p v-else>
                    All codes will be published.
                </p>
            </section>

            <publisher-preview
                :data="selected"
                :is-loading="loadingPreview"
                :receiver="selectedReceiver"
                :filters="selectedFilters"
                :single-msg-metadata-all-libraries="singleMsgMetadataAllLibraries"
                @toggleIsSingleMessageEnabled="onToggleIsSingleMessageEnabled" />
        </spinner>
    </div>
</template>

<script>
    import DateTimePicker from '@/shared/components/DateTimePicker.vue';
    import GenericDropdownCheckbox from '@/shared/components/GenericDropdownCheckbox.vue';
    import GenericDropdownMenu from '@/shared/components/GenericDropdownMenu.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import {
        createPublishingRelease,
        genericViewQueryAsText,
        getAllTags,
        getAttributeDefinitions,
        getInternalApplicationSubscriptions,
        getLibraries,
        getPublishCount
    } from '@/shared/helpers/api';
    import { getReleaseLink } from '@/shared/helpers/routing';
    import { showMixin } from '@/shared/mixins/showMixin';
    import moment from 'moment';
    import PublisherPreview from './PublisherPreview.vue';

    export default {
        components: {
            Spinner,
            DateTimePicker,
            GenericDropdownCheckbox,
            GenericDropdownMenu,
            PublisherPreview
        },
        mixins: [showMixin],
        data: function() {
            return {
                receivers: ['STID', 'TIE'],
                libraryGroups: [],
                facilities: [],
                libraries: [],
                filters: ['IsValid', 'DateUpdated'],
                isValid: true,
                updatedSince: new Date(),
                selectedReceiver: null,
                selectedGroups: [],
                selectedFacilities: [],
                selectedLibraries: [],
                selectedFilters: [],
                selected: {},
                isPublishing: false,
                loadingPreview: false,
                loading: false,
                singleMsgMetadataAllLibraries: {},
                librariesByReceiverType: []
            };
        },
        computed: {
            getFilteredLibraries: function() {
                if (!this.selectedReceiver) {
                    return this.selectedGroups.length
                        ? this.libraries
                            .filter(lib => lib.tags.filter(tag => this.selectedGroups.includes(tag)).length > 0)
                        : this.libraries;
                } else {
                    return this.selectedGroups.length
                        ? this.librariesByReceiverType
                            .filter(lib => lib.tags.filter(tag => this.selectedGroups.includes(tag)).length > 0)
                        : this.librariesByReceiverType;
                }
            },
            canPublish: function() {
                const hasSelectedLib = this.selectedLibraries.length > 0;

                const onlyGlobalLibs = this.libraries
                    .filter(lib => this.selectedLibraries.includes(lib.name))
                    .every(x => x.isGlobal);
                const validScopes = onlyGlobalLibs ? true : this.selectedFacilities.length > 0;

                const hasSelectedApp = this.selectedReceiver !== null;

                return hasSelectedLib && validScopes && hasSelectedApp;
            },
            previewKey: function() {
                return [
                    this.selectedLibraries,
                    this.selectedFacilities,
                    this.selectedFilters,
                    this.isValid,
                    this.updatedSince
                ];
            },
            codesToPublish: function() {
                return this.selected.count ?? 0;
            },
            isValidFilterEnabled: function() {
                return this.selectedFilters.includes('IsValid');
            },
            updatedSinceFilterEnabled: function() {
                return this.selectedFilters.includes('DateUpdated');
            }
        },
        watch: {
            previewKey: async function() {
                await this.refreshPreview();
            },
            selectedReceiver: async function() {
                this.selectedFacilities = [];
                this.facilities = [];

                if (this.selectedReceiver) {
                    this.facilities = await this.getFacilities();
                    const subscriptions = await getInternalApplicationSubscriptions(this, this.selectedReceiver);
                    this.librariesByReceiverType = this.libraries.filter((library) => {
                        return subscriptions.find((subscription) => {
                            return subscription.library.name === library.name && subscription.enabled;
                        });
                    });
                }
            }
        },
        created: async function() {
            this.loading = true;

            const loadTags = async () => {
                const tags = await getAllTags(this);
                this.libraryGroups = tags.sort();
            };

            const loadLibraries = async () => {
                const libraries = await getLibraries(this);
                this.libraries = libraries
                    .filter(lib => !lib.isForeignObject)
                    .sort((a, b) => a.name.localeCompare(b.name));
            };

            await Promise.all([
                loadTags(),
                loadLibraries()
            ]);

            await this.loadLibrariesMetadata();

            this.loading = false;
        },
        methods: {
            publish: async function() {
                this.isPublishing = true;

                const specification = {
                    applicationName: this.selectedReceiver,
                    libraryNames: this.selectedLibraries.map(lib => lib.name),
                    facilityNames: this.selectedFacilities,
                    codesCount: this.codesToPublish
                };
                if (this.selectedReceiver === 'TIE') {
                    let singleMsgEnabledMetadata = {};
                    this.selectedLibraries.forEach(selectedLib => {
                        singleMsgEnabledMetadata = {
                            ...singleMsgEnabledMetadata,
                            [selectedLib.name]: this.singleMsgMetadataAllLibraries[selectedLib.name]
                        };
                    });
                    specification.singleMsgEnabledMetadata = singleMsgEnabledMetadata;
                }
                if (this.isValidFilterEnabled) {
                    specification.isValid = true;
                }
                if (this.updatedSinceFilterEnabled) {
                    specification.updatedSince = this.updatedSince;
                }
                const response = await createPublishingRelease(this, specification);

                this.isPublishing = false;

                if (response.status === 200 && response.data.id) {
                    await this.$router.push(getReleaseLink(response.data.id));
                } else {
                    this.showError('Could not publish selected libraries');
                }
            },
            refreshPreview: async function() {
                if (this.selectedLibraries.length === 0 || this.selectedFacilities.length === 0) {
                    this.selected = {};
                    return;
                }

                this.loadingPreview = true;

                const libraryQueries = await Promise.all(
                    this.selectedLibraries.map(async lib => ({
                        'libraryName': lib.name,
                        'isGlobal': lib.isGlobal,
                        'hasFacilityCodeRef': (await getAttributeDefinitions(this, lib.name))
                            .some(attr => attr.attributeType === 'CodeRef' && attr.name === 'Facility')
                    }))
                );
                const query = {
                    'libraries': libraryQueries,
                    'facilities': this.selectedFacilities,
                    'isValid': this.isValidFilterEnabled ? this.isValid : null,
                    'updatedSince': this.updatedSinceFilterEnabled ? this.updatedSince : null
                };

                this.selected = (await getPublishCount(this, query)).data;

                this.loadingPreview = false;
            },
            getFacilities: async function() {
                const query = {
                    TIE: 'from Facility select Name where IsValid = true and IsForTIELibraryPublish = true',
                    STID: 'from Facility select Name where IsValid = true and IsForSTID = true'
                }[this.selectedReceiver];
                const facilities = (await genericViewQueryAsText(this, query)).data;
                return facilities.map(fac => fac.Name).sort();
            },
            selectLibraries: async function(selected) {
                selected = new Set(selected);
                this.selectedLibraries = this.libraries.filter(lib => selected.has(lib.name));
            },
            formatDatetime: function(dateTime) {
                return moment(dateTime).format('YYYY-MM-DD HH:mm:ss UTC');
            },
            parseDatetime: function(string) {
                return moment(string).toDate();
            },
            onToggleIsSingleMessageEnabled: function(libname, isEnabled) {
                this.singleMsgMetadataAllLibraries[libname] = isEnabled;
            },
            loadLibrariesMetadata: async function() {
                const metadataList = (await genericViewQueryAsText(
                    this,
                    'from LibraryMetadata select Name, UseSingleObjectTIEMessages  where IsValid = true'
                )).data;
                this.libraries.forEach(library => {
                    const singleMsgEnabledMetadata = metadataList
                        ?.find(metadata => metadata.Name === library.name);
                    this.singleMsgMetadataAllLibraries[library.name] = singleMsgEnabledMetadata
                        ?.UseSingleObjectTIEMessages ?? false;
                });
            }
        }
    };
</script>

<style scoped>
.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0;
}

/* space for icon + input text + icon */
.date-updated-field {
  min-width: calc(32px + 23ch + 24px);
}
</style>
