<template>
    <spinner :loading="loading">
        <h2 class="title is-3">
            My Releases
        </h2>

        <b-message
            v-if="connectionLost"
            title="Problem With Live Updates"
            type="is-warning"
            :closable="false">
            <p>Try to refresh the page </p>
        </b-message>

        <b-message
            v-if="showAccessDenied"
            title="The requested action is forbidden for the user"
            type="is-warning"
            :closable="false">
            <p>The performed action on release {{ releaseId }} resulted in forbidden. Try to refresh the page if this was an error</p>
        </b-message>

        <nav class="level has-margin-top2">
            <div class="level-left" />

            <div class="level-right">
                <p class="level-item">
                    <router-link
                        :to="getMyReleaseArchiveLink()"
                        class="button is-info">
                        <span class="icon is-small">
                            <i
                                class="fa fa-fas fa-archive"
                                aria-hidden="true" />
                        </span>
                        <span>Archive</span>
                    </router-link>
                </p>
            </div>
        </nav>

        <div
            class="has-margin-top2">
            <div
                v-for="release in releases"
                :key="release.id"
                :class="{errors: hasErrors(release), warnings: hasWarnings(release)}"
                class="box has-margin-top2">
                <release-info-header :release="release" />
                <hr>
                <release-state-changer
                    :release="release"
                    :link-to-release-on-error="true"
                    :verify-modal="true"
                    @showAccessDeniedMessage="showAccessDeniedMessage" />
            </div>
        </div>
        <div v-show="releases.length < 1">
            <p class="title is-5 has-text-centered">
                You currently have no active releases.
            </p>
        </div>
    </spinner>
</template>

<script>

    import Spinner from '@/shared/components/Spinner.vue';
    import { getMyReleases, getRelease } from '@/shared/helpers/api';
    import { getMyReleaseArchiveLink } from '@/shared/helpers/routing';
    import * as signalR from '@microsoft/signalr';
    import moment from 'moment';
    import ReleaseInfoHeader from './ReleaseInfoHeader.vue';
    import ReleaseStateChanger from './ReleaseStateChanger.vue';

    export default {
        components: {
            Spinner,
            ReleaseInfoHeader,
            ReleaseStateChanger
        },
        mixins: [
        ],
        data: function() {
            return {
                releaseId: null,
                showAccessDenied: false,
                loading: true,
                releases: [],
                connectionLost: false
            };
        },
        computed: {
        },
        created: function() {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(window.config.signalRUrlMyReleases, {
                    accessTokenFactory: async () => await window.authService.acquireToken()
                })
                .configureLogging(signalR.LogLevel.None)
                .build();
        },
        mounted: async function() {
            this.releases = (await getMyReleases(this, [0, 1, 2, 3, 4, 6, 8, 9, 10, 11]))
                .sort((a, b) => moment(b.dateCreated).isBefore(a.dateCreated) ? 1 : -1);
            this.loading = false;
            try {
                this.connection.on('change', id => this.reLoadRelease(id));
                await this.connection.start();
                this.connection.onclose(() => this.connectionLost = true);
            } catch {
                this.connectionLost = true;
            }
        },
        beforeUnmount: function() {
            this.connection.stop();
        },
        methods: {
            showAccessDeniedMessage(releaseId) {
                this.releaseId = releaseId;
                this.showAccessDenied = true;
            },
            reLoadRelease: async function(releaseId) {
                const index = this.releases.map(r => r.id).indexOf(releaseId);
                if (index !== -1) {
                    const update = await getRelease(this, releaseId);
                    this.releases[index].state = update.state;
                    this.releases[index].jobs = update.jobs;
                    this.releases[index].specification = update.specification;
                    if (update.state === 'Discarded' || update.state === 'Published') {
                        setTimeout(() => {
                            const index = this.releases.map(r => r.id).indexOf(releaseId);
                            if (index !== -1)
                                this.releases.splice(index, 1);
                        }, 500);
                    }
                }
            },
            hasErrors: function(release) {
                return release.specification.errors && release.specification.errors.length || release.jobs && release.jobs.some(j => j.state === 'Fail');
            },
            hasWarnings: function(release) {
                return release.specification.warnings && release.specification.warnings.length;
            },
            getMyReleaseArchiveLink
        }
    };
</script>

<style scoped>
.errors {
    border: #FF1234 !important;
    border-style: solid !important;
}

.warnings {
    border: #FBE5D5;
    border-style: solid;
}
</style>
