<template>
    <div>
        <div
            v-if="error">
            <h1 class="title is-2">
                Something went wrong when composing schema {{ identity }}!!!
            </h1>
            <div class="columns">
                <div class="column is-6">
                    <div
                        class="notification is-danger">
                        <p>{{ error }}</p>
                    </div>
                </div>
                <div class="column is-6">
                    <schema-buttons :identity="identity" />
                </div>
            </div>
        </div>
        <div
            v-else-if="schema">
            <p><a href="/Schema">&laquo; Back to list</a></p>
            <h1 class="title">
                Schema: {{ schema.name }}
            </h1>
            <h2 class="subtitle">
                {{ schema.description }}
            </h2>

            <b-tabs
                v-model="activeTab"
                type="is-boxed">
                <b-tab-item
                    label="Info"
                    icon="information">
                    <div class="columns">
                        <div class="column is-half">
                            <h4 class="title is-4 block">
                                Comments
                            </h4>
                            <p class="block">
                                {{ schema.comments }}
                            </p>
                            <h4 class="title is-4 block">
                                Versions
                            </h4>
                            <b-table
                                :data="schema.versions"
                                :striped="true"
                                :narrowed="true"
                                :hoverable="false"
                                class="classTable">
                                <b-table-column
                                    v-slot="props"
                                    field="versionNumber"
                                    label="Version number">
                                    {{ props.row.versionNumber }}
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="description"
                                    label="Description">
                                    {{ props.row.description }}
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="releaseDate"
                                    label="Release date">
                                    {{ $filters.dateFormatShort(props.row.releaseDate) }}
                                </b-table-column>
                            </b-table>
                            <h4 class="title is-4 block">
                                References
                            </h4>
                            <p class="block">
                                {{ schema.references }}
                            </p>
                        </div>
                        <div
                            class="column">
                            <schema-buttons :identity="identity" />
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item
                    label="Classes"
                    icon="format-list-bulleted">
                    <div class="columns">
                        <div class="column is-2">
                            <b-menu>
                                <b-menu-list label="Select class">
                                    <b-menu-item
                                        v-for="c in schema.classes"
                                        :id="c.name"
                                        :key="c.name"
                                        icon="format-list-bulleted"
                                        :label="c.name"
                                        @click="selectClass(c)" />
                                </b-menu-list>
                            </b-menu>
                        </div>
                        <div class="column">
                            <div v-if="selectedClass">
                                <h2 class="title">
                                    {{ selectedClass.name }}
                                </h2>
                                <p>{{ selectedClass.comments }}</p>
                                <b-table
                                    :data="selectedClassAttributes"
                                    :striped="true"
                                    :narrowed="true"
                                    :hoverable="true"
                                    class="classTable">
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="interfaceName"
                                        label="Interface">
                                        {{ props.row.interfaceName }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="name"
                                        label="Attribute name">
                                        {{ props.row.name }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="description"
                                        label="Suggested display as">
                                        {{ props.row.description }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="dataType"
                                        label="Type">
                                        {{ datatype(props.row) }}
                                        <router-link
                                            v-if="props.row.enumLibrary || props.row.quantity"
                                            :to="props.row.enumLibrary ?
                                                getLibraryLink(props.row.enumLibrary) : getQuantityLink(props.row.quantity)">
                                            {{ props.row.enumLibrary ? props.row.enumLibrary : props.row.quantity }}
                                        </router-link>
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="defaultUnitOfMeasure"
                                        label="Default UoM">
                                        <b-tooltip label="Default Unit of Measure">
                                            {{ $filters.defaultUoM(props.row) }}
                                        </b-tooltip>
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="required"
                                        label="Required">
                                        {{ $filters.required(props.row.required) }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="comments"
                                        label="Comments">
                                        {{ props.row.comments }}
                                    </b-table-column>
                                </b-table>
                            </div>
                            <div v-if="selectedClassRelations.length > 0">
                                <h3>{{ selectedClass.name }} may participate in following relations:</h3>
                                <b-table
                                    :data="selectedClassRelations"
                                    :narrowed="true"
                                    class="class-table">
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="name"
                                        label="Relation Name">
                                        {{ props.row.name }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="description"
                                        label="Description">
                                        {{ props.row.description }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="primeInterfaceName"
                                        :label="selectedClass.name + ' interface'">
                                        {{ props.row.primeInterfaceName }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="primeRole"
                                        label="Role">
                                        {{ props.row.primeRole }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="Cardinality"
                                        label="Cardinality">
                                        {{ props.row.Cardinality }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="secondaryRole"
                                        label="Other side role">
                                        {{ props.row.secondaryRole }}
                                    </b-table-column>
                                    <b-table-column
                                        v-slot="props"
                                        sortable
                                        field="secondaryInterfaceName"
                                        label="Other side interface">
                                        {{ props.row.secondaryInterfaceName }}
                                    </b-table-column>
                                </b-table>
                            </div>
                            <div v-else-if="selectedClass">
                                No relations found for {{ selectedClass.name }}
                            </div>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item
                    label="Graph"
                    icon="graph">
                    <schema-model :identity="identity" />
                </b-tab-item>
                <b-tab-item>
                    <template #header>
                        <b-icon icon="alert" />
                        <span> Issues <b-tag
                            v-show="schema.warnings.length > 0"
                            type="is-danger"
                            rounded> {{ schema.warnings.length }} </b-tag> </span>
                    </template>
                    <div
                        v-for="(w, i) in schema.warnings"
                        :key="i"
                        class="notification is-warning">
                        {{ w.description }}
                    </div>
                    <p v-show="schema.warnings.length == 0">
                        There are no issues.
                    </p>
                </b-tab-item>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import { getSchema } from '@/shared/helpers/api.ts';
    import { getLibraryLink, getQuantityLink } from '@/shared/helpers/routing.js';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import _ from 'lodash';
    import SchemaButtons from './SchemaButtons.vue';
    import SchemaModel from './SchemaModel.vue';

    export default {
        components: {
            SchemaModel,
            SchemaButtons
        },
        filters: {
            required: function(x) {
                return x ? 'yes' : '';
            },
            defaultUoM: function(attribute) {
                if (attribute.defaultUnitOfMeasure) {
                    const suffixLength = attribute.quantity.length + 1;
                    // example defaultUnitOfMeasure: m|length
                    return attribute.defaultUnitOfMeasure.substring(0, attribute.defaultUnitOfMeasure.length - suffixLength);
                }
                return '';
            }
        },
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data: function() {
            return {
                schema: null,
                activeTab: 0,
                selectedClass: null,
                error: null
            };
        },
        computed: {
            selectedClassAttributes() {
                return this.selectedClass?.attributes;
            },
            selectedClassRelations: function() {
                const relevantRelations = [];
                this.schema.relations.forEach(schemaRelation => {
                    if (this.selectedClass?.interfaces.includes(schemaRelation.left.interfaceName)) {
                        relevantRelations.push({
                            description: schemaRelation.description,
                            name: schemaRelation.name,
                            primeInterfaceName: schemaRelation.left.interfaceName,
                            Cardinality: `${schemaRelation.left.cardinality ?? 'null'}:${schemaRelation.right.cardinality ?? 'null'}`,
                            primeRole: schemaRelation.left.name,
                            secondaryRole: schemaRelation.right.name,
                            secondaryInterfaceName: schemaRelation.right.interfaceName
                        });
                    }
                    if (this.selectedClass?.interfaces.includes(schemaRelation.right.interfaceName)) {
                        relevantRelations.push({
                            description: schemaRelation.description,
                            name: schemaRelation.name,
                            primeInterfaceName: schemaRelation.right.interfaceName,
                            Cardinality: `${schemaRelation.right.cardinality ?? 'null'}:${schemaRelation.left.cardinality ?? 'null'}`,
                            primeRole: schemaRelation.right.name,
                            secondaryRole: schemaRelation.left.name,
                            secondaryInterfaceName: schemaRelation.left.interfaceName
                        });
                    }
                });
                return relevantRelations;
            }
        },
        mounted: async function() {
            try {
                this.schema = await getSchema(this, this.identity);
                this.schema.classes = _.sortBy(this.schema.classes, x => x.name);
            } catch (err) {
                this.showError(err, e => {
                    this.error = e;
                });
            }
        },
        methods: {
            datatype: function(attribute) {
                switch (attribute.dataType) {
                    case 'enum':
                        if (attribute.enumLibrary)
                            return 'enum:';
                        return 'enum';
                    case 'quantity':
                        return 'quantity:';
                    case 'integer':
                        return `integer (${attribute.minValue}-${attribute.maxValue})`;
                    case 'string':
                        return `string (${attribute.maxValue})`;
                    default:
                        return attribute.dataType;
                }
            },
            selectClass(c) {
                this.selectedClass = c;
            },
            getLibraryLink,
            getQuantityLink
        }
    };
</script>
<style scoped>
p {
    margin-bottom:10px;
}
h2 {
    font-weight: bold;
}
h3 {
    font-weight: bold;
}
.classTable {
    margin-bottom: 25px;
    overflow-x: hidden;
}
</style>
