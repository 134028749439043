<template>
    <b-field>
        <div
            v-require-can-edit-code="{ libraryName: library.name, scopes: codeSet?.scopes }">
            <b-button
                v-if="library.specializedUI"
                :label="buttonLabel"
                :icon-left="buttonIcon"
                :icon-right="'open-in-new'"
                :disabled="disabled"
                class="specialized-ui-button"
                type="is-primary"
                @click="reRouteToSpecializedUI" />
            <b-button
                v-else
                :label="buttonLabel"
                :icon-left="buttonIcon"
                :disabled="disabled"
                type="is-primary"
                @click="toggleEditMode" />
        </div>
        <div
            v-if="library.specializedUI"
            v-require-can-edit-library="library.name">
            <b-dropdown position="is-bottom-left">
                <template #trigger>
                    <b-button
                        type="is-primary"
                        icon-left="menu-down"
                        class="specialized-ui-button-dropdown"
                        :disabled="disabled" />
                </template>
                <b-dropdown-item @click="toggleEditMode">
                    {{ dropdownText }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </b-field>
</template>

<script>
    import { requireCanEditCode, requireCanEditLibrary } from '@/shared/directives/requirePermission';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode,
            'require-can-edit-library': requireCanEditLibrary
        },
        props: {
            library: {
                type: Object,
                required: true
            },
            codeSet: {
                type: Object,
                required: true
            },
            code: {
                type: Object,
                required: false,
                default: () => {}
            },
            buttonLabel: {
                type: String,
                required: true
            },
            buttonIcon: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            dropdownText: {
                type: String,
                required: true
            }
        },
        emits: [
            'clickEdit'
        ],
        methods: {

            async reRouteToSpecializedUI() {
                const specializedUiRouteName = this.specializedUiRouteName(this.library.specializedUI);
                if (specializedUiRouteName) {
                    await this.$router.push(specializedUiRouteName);
                } else {
                    this.toggleEditMode();
                }
            },
            toggleEditMode() {
                this.$emit('clickEdit');
            },
            specializedUiRouteName(specializedUI) {
                switch (specializedUI) {
                    case 'CableEdit':
                        return {
                            name: 'CableEdit'
                        };
                    case 'EleComponentType':
                        return {
                            name: 'ElectricalComponentType'
                        };
                    case 'DocumentNumberingFormat': {
                        let formatIdentity;
                        if (this.library.name === 'DocumentNumberingFormatElement') {
                            formatIdentity = this.getCodeAttributeIdentity('DocNoFormat');
                        } else {
                            formatIdentity = this.code?.identity;
                        }

                        return {
                            name: 'DocumentNumberingFormat',
                            query: {
                                facility: this.codeSet.scopes[0],
                                formatIdentity
                            }
                        };
                    }
                    case 'TagFormat': {
                        let formatIdentity;
                        if (this.library.name === 'TagFormatElement') {
                            formatIdentity = this.getCodeAttributeIdentity('TagFormat');
                        } else {
                            formatIdentity = this.code?.identity;
                        }

                        return {
                            name: 'TagFormat',
                            query: {
                                facility: this.codeSet.scopes[0],
                                formatIdentity
                            }
                        };
                    }
                    case 'PipeClassEditor':
                        return {
                            name: 'PipeClassEditor'
                        };
                    case 'FacilityAndProject':
                        return {
                            name: 'ProjectMasterList'
                        };
                    case 'Schema':
                        return {
                            name: 'SchemaDetails',
                            params: {
                                identity: this.code.identity
                            }
                        };
                    case 'ProjectFacility':
                        return {
                            name: 'LCITab',
                            params: {
                                tab: 'Projects'
                            },
                            query: {
                                facility: this.getCodeAttributeValue('Facility'),
                                project: encodeURI(this.code?.name)
                            }
                        };
                    case 'LCIContractor':
                        return {
                            name: 'LCITab',
                            params: {
                                tab: 'Contractors'
                            },
                            query: {
                                facility: this.codeSet.scopes[0],
                                contractor: this.code?.name
                            }
                        };
                    case 'LCIPurchaseOrder':
                        return {
                            name: 'LCITab',
                            params: {
                                tab: 'PurchaseOrders'
                            },
                            query: {
                                facility: this.codeSet.scopes[0],
                                purchaseOrder: this.code?.name
                            }
                        };
                    default:
                        return null;
                }
            },
            getCodeAttribute(attributeDefinitionName) {
                return this
                    .code
                    ?.attributes
                    ?.find(attribute => attribute.definitionName === attributeDefinitionName);
            },
            getCodeAttributeValue(attributeDefinitionName) {
                return this
                    .getCodeAttribute(attributeDefinitionName)
                    ?.displayValue;
            },
            getCodeAttributeIdentity(attributeDefinitionName) {
                return this
                    .getCodeAttribute(attributeDefinitionName)
                    ?.referenceCode
                    ?.identity;
            }
        }
    };
</script>

<style scoped>
.specialized-ui-button-dropdown {
    border-left-color: #ec1635 !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.specialized-ui-button {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
</style>
