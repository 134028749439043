<template>
    <b-dropdown aria-role="list">
        <template #trigger>
            <b-button
                :disabled="!hasCodeRefColumns"
                icon-right="menu-down"
                class="button is-info">
                Select related attribute formatting
            </b-button>
        </template>
        <div class="container is-fluid">
            <b-field
                v-for="col in codeRefColumns"
                :key="col.field"
                :label="col.label">
                <b-select
                    v-model="col.referenceDisplayMode"
                    @update:model-value="newSelected(col)">
                    <option
                        v-for="o in options"
                        :key="o.value"
                        :value="o.value">
                        {{ o.display }}
                    </option>
                </b-select>
            </b-field>
        </div>
    </b-dropdown>
</template>

<script>

    const options = [
        { value: 'IDENTITY', display: 'Display Identity' },
        { value: 'NAME', display: 'Display Name' },
        { value: 'DESCRIPTION', display: 'Display Description' },
        { value: 'NAMEANDDESCRIPTION', display: 'Display Name and Description' }
    ];
    export default {
        props: {
            libraryName: {
                type: String,
                required: true
            },
            columns: {
                type: Array,
                required: true
            }
        },
        emits: [
            'change'
        ],
        data: function() {
            return {
                options: options,
                selected: 0,
                codeRefColumns: []
            };
        },
        computed: {
            hasCodeRefColumns() {
                return this.columns.some(c => c.type.toUpperCase() === 'CODEREF');
            }
        },
        watch: {
            columns: {
                handler() {
                    const storedOverrides = this.getLocalStorageData();
                    const overrideValues = new Map(storedOverrides?.split(',').map(kv => kv.split(':')));
                    const codeRefs = this.columns.filter(c => c.type.toUpperCase() === 'CODEREF');
                    codeRefs.forEach(c => {
                        if (overrideValues.has(c.field)) {
                            c.referenceDisplayMode = overrideValues.get(c.field);
                        } else if (c.referenceDisplayMode === 0) {
                            c.referenceDisplayMode = 'IDENTITY';
                        } else if (c.referenceDisplayMode === 1) {
                            c.referenceDisplayMode = 'NAME';
                        } else if (c.referenceDisplayMode === 2) {
                            c.referenceDisplayMode = 'DESCRIPTION';
                        } else if (c.referenceDisplayMode === 3) {
                            c.referenceDisplayMode = 'NAMEANDDESCRIPTION';
                        }
                        this.$emit('change', c);
                    });
                    this.codeRefColumns = codeRefs;
                },
                immediate: true
            }
        },
        methods: {
            newSelected: function(col) {
                this.$emit('change', col);
            },
            getLocalStorageData: function() {
                return localStorage.getItem('Library.' + this.libraryName + '.ReferenceDisplayOverrides');
            }
        }
    };
</script>

<style>

</style>
