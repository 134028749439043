<template>
    <div>
        <p><a :href="'/Schema#/specification/' + identity">&laquo; Back to schema</a></p>
        <h1 class="title">
            Mapping to other schemas
        </h1>
        <b-message
            v-model="noSchemaMappingWarning"
            title="Warning"
            type="is-warning"
            aria-close-label="Close message">
            There is no mapping from schema {{ identity }}. Select another schema source in the dropdown below.
        </b-message>
        <spinner
            v-if="loadingSchemaList"
            :loading="loadingSchemaList" />
        <div
            v-else
            class="schema_form">
            <div class="schema-parent">
                <div class="schema-child">
                    <b-field
                        label="Schema from (source)"
                        label-position="labelPosition">
                        <schema-filter-dropdown
                            :title="`${schemaFromFilter.length} of ${SchemaMapSchema1Flat.length} schemas selected`"
                            :schemas="SchemaMapSchema1Flat"
                            :active-schemas="schemaFromFilter"
                            @update:schemas="updateSchemaFromFilter" />
                    </b-field>
                </div>
                <div class="schema-child">
                    <b-field
                        label="Schema to (target)"
                        label-position="labelPosition">
                        <schema-filter-dropdown
                            :title="`${schemaToFilter.length} of ${SchemaMapSchema2Flat.length} schemas selected`"
                            :schemas="SchemaMapSchema2Flat"
                            :active-schemas="schemaToFilter"
                            type="is-primary"
                            @update:schemas="updateSchemaToFilter" />
                    </b-field>
                </div>
                <div class="schema-child">
                    <b-field
                        label="Include all extensions"
                        label-position="">
                        <b-checkbox v-model="queryOptions.includeAllExtensions" />
                    </b-field>
                </div>
                <div class="schema-child">
                    <b-field>
                        <div
                            class="control">
                            <b-button
                                :disabled="schemaFromFilter.length < 1"
                                class="button is-info"
                                size="is-medium"
                                :loading="loading"
                                @click="loadSchema">
                                <span>Re-fetch</span>
                            </b-button>
                            <div
                                v-if="inputChange && activeTab !== 2"
                                class="has-text-primary">
                                * Input changed, Re-fetch needed
                            </div>
                            <div v-if="loading">
                                Loading schemas: {{ progressBar }}%
                            </div>
                        </div>
                    </b-field>
                </div>
            </div>
            <b-table
                v-show="schemaMapSchemaAttributes.length > 0"
                narrowed
                class="attributes-wrapper"
                :data="schemaMapSchemaAttributes">
                <b-table-column
                    v-slot="props"
                    field="Mapping"
                    label="Mapping">
                    <div
                        class="mapping-container">
                        <div
                            class="mapping-side-left"
                            :style="{width: attributesTableLeftWidth}">
                            {{ props.row.Mapping[0] }}
                        </div> ↔ <div
                            class="mapping-side-right"
                            :style="{width: attributesTableRightWidth}">
                            {{ props.row.Mapping[1] }}
                        </div>
                    </div>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="MappingName"
                    label="Mapping Name">
                    {{ props.row.MappingName }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="PassOnUnmappedClasses"
                    label="Pass on unmapped classes">
                    {{ props.row.PassOnUnmappedClasses }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="Comments"
                    label="Comments">
                    {{ props.row.Comments }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="InternalComments"
                    label="Internal Comments">
                    {{ props.row.InternalComments }}
                </b-table-column>
            </b-table>
            <span v-show="schemaMapSchemaAttributes.length === 0">No schema mapping for selected source-target combination.</span>
        </div>
        <br>
        <div v-if="schemaList">
            <b-tabs
                v-model="activeTab"
                type="is-boxed">
                <b-tab-item
                    label="View mapping overview">
                    <schema-d-t-o-inspector
                        :loading="loading"
                        :show-map="schemaToFilter.length > 0 && !inputChange"
                        :schema-list="schemaList"
                        :schema-to="schemaToFilter || ['empty']" />
                </b-tab-item>
                <b-tab-item
                    label="Mapping test">
                    <b-field
                        label="Mapping test">
                        <mapping-tester
                            :source-list="schemaFromFilter"
                            :target-list="schemaToFilter" />
                    </b-field>
                </b-tab-item>
                <b-tab-item
                    v-if="numberOfWarnings > 0">
                    <template #header>
                        <span>Warnings <b-tag
                            rounded
                            type="is-danger"> {{ numberOfWarnings }} </b-tag> </span>
                    </template>
                    <div class="warningWrapper">
                        <div class="warningTree">
                            <tree-view
                                :data="allWarnings"
                                :options="warningTreeOptions" />
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item
                    label="View as tree">
                    <tree-view
                        :data="schemaList"
                        :options="treeOptions" />
                </b-tab-item>
                <b-tab-item
                    label="Download Schemas for mapping">
                    <div v-if="!hasSchemasWithMappings">
                        <h3>Select a target schema and re-fetch to see mappings</h3>
                    </div>
                    <div v-else>
                        <h3 class="title is-3">
                            Schemas to be included in zipped package
                        </h3>
                        <div
                            v-for="(schema, idx) in schemaList"
                            :key="idx"
                            class="field">
                            <div class="control">
                                <b-tag
                                    v-if="schema.mapping"
                                    size="is-medium">
                                    {{ schema.name }}-{{ schema.mapping.schema }}
                                </b-tag>
                            </div>
                        </div>
                        <button
                            class="button is-info"
                            @click="downloadSchema">
                            Download zipped package
                        </button>
                    </div>
                </b-tab-item>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import TagList from '@/shared/components/TagList.vue';
    import { getSchemaDTO, getSchemaMapSchema, getZippedSchemas } from '@/shared/helpers/api.ts';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import TreeView from '@/shared/TreeView/TreeView.vue';
    import saveAs from 'file-saver';
    import MappingTester from './MappingTester.vue';
    import SchemaDTOInspector from './SchemaDTOInspector.vue';
    import SchemaFilterDropdown from './SchemaFilterDropdown.vue';

    export default {
        components: {
            Spinner,
            SchemaDTOInspector,
            TagList,
            MappingTester,
            SchemaFilterDropdown,
            TreeView
        },
        mixins: [
            showMixin
        ],
        props: {
            identity: {
                default: null,
                type: String
            }
        },
        data: function() {
            return {
                queryOptions: {},
                treeOptions: {
                    maxDepth: 2,
                    rootObjectKey: 'SchemaDTOList',
                    limitRenderDepth: 3
                },
                warningTreeOptions: {
                    maxDepth: 2,
                    rootObjectKey: 'Warnings',
                    limitRenderDepth: 3
                },
                schemaList: null,
                schemaMapSchemaList: null,
                loading: false,
                loadingSchemaList: false,
                inputChange: false,
                activeTab: 0,
                schemaFromFilter: [],
                schemaToFilterList: [],
                allPromisesCount: 0,
                progressBar: 0,
                noSchemaMappingWarning: false
            };
        },
        computed: {
            allWarnings: function() {
                const warnings = [];
                this.schemaList.forEach(schema => warnings.push.apply(warnings, schema.warnings));
                return warnings;
            },
            SchemaMapSchema1Flat: function() {
                const tempSchemas = [];
                if (this.schemaMapSchemaList) {
                    this.schemaMapSchemaList.map(schema => schema.attributes).forEach(attribute => {
                        attribute.filter(att => att.definitionName === 'Schema1' || att.definitionName === 'Schema2').forEach(value => {
                            if (!tempSchemas.includes(value.displayValue)) {
                                tempSchemas.push(value.displayValue);
                            }
                        });
                    });
                }
                return tempSchemas.sort();
            },
            SchemaMapSchema2Flat() {
                const tempSchemas = [];
                if (this.schemaMapSchemaList) {
                    this.schemaMapSchemaList.forEach(schema => {
                        if (this.schemaFromFilter.includes(schema.attributes[0].displayValue)) {
                            tempSchemas.push(schema.attributes[1].displayValue);
                        }
                        if (this.schemaFromFilter.includes(schema.attributes[1].displayValue)) {
                            tempSchemas.push(schema.attributes[0].displayValue);
                        }
                    });
                }
                const schema2ListFlat = [...new Set(tempSchemas)];

                return schema2ListFlat.sort();
            },
            schemaToFilter() {
                const tempSchema = [];
                this.schemaToFilterList.forEach(s => {
                    if (this.SchemaMapSchema2Flat.includes(s)) {
                        tempSchema.push(s);
                    }
                });
                return tempSchema;
            },
            numberOfWarnings: function() {
                if (this.schemaList[0]) {
                    return this.schemaList[0].warnings.length || 0;
                }
                return 0;
            },
            schemaMapSchemaAttributes: function() {
                return this.schemaMapSchemaList
                    ?.filter(map => {
                        const schema1 = map.attributes[0].displayValue;
                        const schema2 = map.attributes[1].displayValue;
                        const right = this.schemaFromFilter.includes(schema1) && this.schemaToFilter.includes(schema2);
                        const left = this.schemaFromFilter.includes(schema2) && this.schemaToFilter.includes(schema1);
                        return right || left;
                    })
                    .map(schemaMapSchema => {
                        return {
                            'Mapping': [schemaMapSchema.attributes[0].displayValue, schemaMapSchema.attributes[1].displayValue],
                            'MappingName': schemaMapSchema.name,
                            'Comments': schemaMapSchema.attributes.find(attr => attr.definitionName === 'Comments')?.displayValue ?? '',
                            'InternalComments': schemaMapSchema.attributes.find(attr => attr.definitionName === 'InternalComments')?.displayValue ?? '',
                            'PassOnUnmappedClasses': schemaMapSchema.attributes.find(attr => attr.definitionName === 'PassOnUnmappedClasses')?.displayValue ?? ''
                        };
                    }) ?? [];
            },
            attributesTableLeftWidth: function() {
                let widest = 0;
                this.schemaMapSchemaAttributes.forEach(attr => {
                    if (attr.Mapping[0].length > widest) {
                        widest = attr.Mapping[0].length;
                    }
                });
                widest += 2;
                return widest + 'ch';
            },
            attributesTableRightWidth: function() {
                let widest = 0;
                this.schemaMapSchemaAttributes.forEach(attr => {
                    if (attr.Mapping[1].length > widest) {
                        widest = attr.Mapping[1].length;
                    }
                });
                widest += 2;
                return widest + 'ch';
            },
            hasSchemasWithMappings: function() {
                return this.schemaList.filter(schema => schema.mapping)?.length > 0;
            }
        },
        mounted: async function() {
            this.schemaFromFilter = [this.identity];

            this.queryOptions = {
                schemaName: this.identity || 'CommonLibrary',
                version: null,
                includeAllExtensions: true,
                scope: null,
                includeMappingForSchema: null
            };

            await this.loadSchemaMapSchema();
            await this.loadSchema();
        },
        methods: {
            loadSchema: async function() {
                let count = 0;
                this.progressBar = 0;
                if (this.schemaFromFilter.length > 0) {
                    this.loading = true;
                    this.schemaList = [];
                    const allPromises = [];
                    this.allPromisesCount = 0;
                    this.schemaFromFilter.forEach(schemaFrom => {
                        const currentFromQuery = JSON.parse(JSON.stringify(this.queryOptions));
                        currentFromQuery.schemaName = schemaFrom;
                        if (this.schemaToFilter.length > 0) {
                            this.schemaToFilter.forEach(async schemaTo => {
                                const currentQuery = JSON.parse(JSON.stringify(currentFromQuery));
                                currentQuery.includeMappingForSchema = schemaTo;
                                allPromises.push(getSchemaDTO(this, currentQuery).then((val) => {
                                    this.progress(++count);
                                    return val;
                                }));
                            });
                        } else {
                            allPromises.push(getSchemaDTO(this, currentFromQuery).then((val) => {
                                this.progress(++count);
                                return val;
                            }));
                        }
                    });
                    this.allPromisesCount = allPromises.length;
                    await Promise.all(allPromises).then((values) => {
                        values.forEach(val => {
                            this.schemaList.push(val.data);
                        });
                        this.loading = false;
                        this.inputChange = false;
                    }).finally(() => {
                        this.loading = false;
                        this.inputChange = false;
                    });
                }
            },
            progress(count) {
                this.progressBar = Math.round(count / this.allPromisesCount * 100);
            },
            loadSchemaMapSchema: async function() {
                try {
                    this.loadingSchemaList = true;
                    const response = await getSchemaMapSchema(this);
                    if (response) {
                        this.schemaMapSchemaList = response.filter(schema => schema.isValid);
                        if (!this.schemaMapSchemaList.some(schemaMap => [schemaMap.attributes[0].displayValue.toLowerCase(), schemaMap.attributes[1].displayValue.toLowerCase()].includes(this.identity.toLowerCase()))) {
                            this.noSchemaMappingWarning = true;
                            this.schemaFromFilter = [];
                        }
                    }
                } finally {
                    this.loadingSchemaList = false;
                }
            },
            updateSchemaFromFilter: function($event) {
                this.noSchemaMappingWarning = false;
                this.schemaFromFilter = $event;
                this.inputChange = true;
            },
            updateSchemaToFilter: function($event) {
                this.schemaToFilterList = $event;
                this.inputChange = true;
            },
            downloadSchema: async function() {
                const schemasToDownload = this.schemaList.filter(schema => schema.mapping);
                const schemaSources = [...new Set(schemasToDownload.map(schema => schema.name))];
                const schemaTargets = [...new Set(schemasToDownload.map(schema => schema.mapping.schema))];
                try {
                    const response = await getZippedSchemas(this, { 'sourceList': schemaSources, 'targetList': schemaTargets });
                    if (response.data) {
                        const filename = 'schemaPackage.zip';
                        const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                        await saveAs(blob, filename);
                    }
                } catch (ex) {
                    this.showError(ex);
                }
            }
        }
    };
</script>
<style scoped>
.schema_form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.warningWrapper {
    height: 500px;
    overflow: auto;
}
.warningTree {
    overflow-y: auto;
}
.margin-l-20 {
    margin-left: 20px;
}
.schema-parent {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
}
.schema-child {
    padding: 0.75rem 0.75rem;
    min-width: 200px;
}
.margin-top-20 {
    margin-top: 20px;
}
.attributes-wrapper {
    display: flex;
    flex-shrink: 1;
}
.mapping-side-left {
    text-align: right;
    margin-right: 1em;
}
.mapping-side-right {
    text-align: left;
    margin-left: 1em;
}
.mapping-container {
    display: flex;
}
</style>
